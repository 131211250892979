import React from "react";
import ActivityHeroSection from "./ActivityHeroSection";
import styled from "styled-components";

const NavBarBackground = styled.div`
  width: 100vw;
  height: 77px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;
const ActivityDetail = ({ loggedIn, setLoggedIn }) => {
  return (
    <div>
      <NavBarBackground></NavBarBackground>
      <ActivityHeroSection
        loggedIn={loggedIn}
        setLoggedIn={setLoggedIn}
      />
    </div>
  );
};

export default ActivityDetail;
