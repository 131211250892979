import React from "react";
import styled from "styled-components";
import ActivityResult from "./ActivityResult";

const Root = styled.div`
  background-color: #fff;
  margin-bottom: 20px;
  /* padding-top: 85px; */
`;
const NavBarBackground = styled.div`
  width: 100vw;
  height: 75.8px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;


const Activities = ({ message, message2, setLoggedIn }) => {

  return (
    <Root>
      <NavBarBackground></NavBarBackground>
      <ActivityResult
        message={message}
        message2={message2}
        setLoggedIn={setLoggedIn}
      />
    </Root>
  );
};

export default Activities;
