import React from "react";
import styled from "styled-components";
import SideBar from "../../SideBar/SideBar";
import BookingDetails from "./BookingDetails";

const Root = styled.div`
  background-color: #e3e3e3;
  padding-top: 85px;
  width: 100%;
`;
const Container = styled.div`
  margin: 0 auto;
  // width: 100%;
  padding: 30px;
  display: flex;
  justify-content: space-around;
  @media (max-width: 768px) {
    width: 100%;
    overflow: scroll;
    padding: 15px
  }
`;
const Leftsidebar = styled.div`
  // width: 20%;
  padding: 0 20px;
  justify-content: center;
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Rightsidebar = styled.div`
@media(max-width: 1440px){
  width:1360px;
  overflow: scroll;
}
  width: 80%;
  @media (max-width: 768px) {
    width:100%;
    overflow: scroll;
  }
`;

const Wrapper = styled.div`
  // margin-right: 25px;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  /* justify-content: start;
  align-items: flex-start; */
  @media(max-width:1440px){
    width:1360px;
  }
  @media (max-width: 768px) {
    width:100%;
    padding: 10px 0;
    justify-content: center;
    align-items: center;
  }
`;
const NavBarBackground = styled.div`
  width: 100vw;
  height: 75px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
    width: 100%;
  }
`;
const Bookinghistory = ({title}) => {
  document.title = title === undefined ? "" : title
  return (
    <Root>
      <NavBarBackground></NavBarBackground>
      <Container className="mob-pt-0">
        <Leftsidebar>
          <SideBar />
        </Leftsidebar>
        <Rightsidebar  >
          <Wrapper className="mobwrapper">
            <BookingDetails />
          </Wrapper>
        </Rightsidebar>
      </Container>
    </Root>
  );
};

export default Bookinghistory;
