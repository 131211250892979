import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import styled from "styled-components";
import CircularLoader from "../CircularLoader/CircularLoader";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default function RechargeAgainModel({ open, setOpen, Tokendata }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const HandleClick = () => {
    setLoading(true);
    navigate(`/topups?id=${Tokendata._id}`, { state: Tokendata });
  };
  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
            },
          })}
        >
          <Typography id="nested-modal-title" level="h2">
            Alert
          </Typography>
          <Typography id="nested-modal-description" textColor="text.tertiary">
            Plan not available, please select another plan.
          </Typography>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", sm: "row-reverse" },
            }}
          >
            <Button
              variant="solid"
              color="neutral"
              onClick={() => HandleClick()}
            >
              {loading ? (
                <LoaderWrapper>
                  <CircularLoader />
                </LoaderWrapper>
              ) : (
                "Continue"
              )}
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
