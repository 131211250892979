import React from "react";
import styled from "styled-components";

const Root = styled.div`
  padding-top: 85px;
`;
const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media(max-width:1200px){
    width: 100%;
    padding: 0 25px 0 25px;
  }

  @media (max-width: 768px) {
    width: 100vw;
  }
`;
const TextHeading = styled.div`
  margin-top: 50px;
  font-size: 44px;
  font-weight: bolder;
  color: #000000;
  @media (max-width: 1200px) {
    margin-top: 40px;
  }
  @media (max-width: 768px) {
    margin-top: 10px;
  }
  @media (max-width: 425px) {
    font-size: 2rem;
  }
`;

const Heading = styled.div`
  margin-top: 50px;
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
  @media (max-width: 425px) {
    font-size: 18px;
  }
`;

const Para = styled.div`
  margin-top: 20px;
  font-size: 18px;
  text-align: justify;
  // font-weight: bolder;
  li {
    color: #3e3e3e;
    font-size: 16px;
    padding-top: 10px;
    font-weight: 500;
    text-align: justify;
  }
  @media (max-width: 425px) {
    font-size: 18px;
  }
`;
const NavBarBackground = styled.div`
  width: 100vw;
  // height: 84.8px;
  height: 77px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;
const TermsAndCondition = ({title}) => {
  document.title = title === undefined ? "" : title
  return (
    <Root>
      <NavBarBackground></NavBarBackground>
      <Container>
        <TextHeading>Terms & Conditions</TextHeading>
        <Heading>Role of FloxyPay Travels</Heading>
        <Para>
          FloxyPay Travels acts as a facilitator and merely provides an online
          platform for the User to select and book a particular hotel. Hotels in
          this context include all categories of accommodations, such as hotels,
          homestays, bed and breakfast stays, and any other alternate
          accommodations.
        </Para>
        <Para>
          All the information pertaining to the hotel, including the category of
          the hotel, images, room type, amenities, and facilities available at
          the hotel, are as per the information provided by the hotel to
          FloxyPay Travels. This information is for reference only. Any
          discrepancy that may exist between the website pictures and the actual
          settings of the hotel shall be raised by the User with the hotel
          directly and shall be resolved between the User and the hotel.
          FloxyPay Travels will have no responsibility in that resolution
          process and shall not take any liability for such discrepancies.
        </Para>

        <Heading>Information from the hotel and the terms of the hotel</Heading>
        <Para>
          The hotel booking voucher that FloxyPay Travels issues to a User is
          solely based on the information provided or updated by the hotel
          regarding inventory availability. In no circumstances can FloxyPay
          Travels be held liable for failure on the part of a hotel to
          accommodate the User with a confirmed booking, the standard of service
          or any insufficiency in the services, or any other service-related
          issues at the hotel. The liability of FloxyPay Travels in case of
          denial of check-in by a hotel for any reason what-so-ever including
          over-booking, system or technical errors, or unavailability of rooms,
          etc., will be limited to either providing a similar alternate
          accommodation at the discretion of FloxyPay Travels (subject to
          availability at that time), or refunding the booking amount (to the
          extent paid) to the User. Any other service-related issues should be
          directly resolved by the User with the hotel.
        </Para>
        <Para>
          Hotels reserve the sole right of admission, and FloxyPay Travels has
          no say whatsoever in admission or denial of admission by the hotel.
          Unmarried or unrelated couples may not be allowed to check-in at some
          hotels as per their policies. Similarly, accommodation may be denied
          to guests posing as a couple if suitable proof of identification is
          not presented at the time check-in. Some hotels may also not allow
          local residents to check-in as guests. FloxyPay Travels will not be
          responsible for any check-in denied by the hotel due to the aforesaid
          reasons or any other reason not under the control of FloxyPay Travels.
          No refund would be applicable in case the hotel denies check-in under
          such circumstances.
        </Para>

        <Heading>responsibilities of the user</Heading>
        <Para>
          The User would be liable to make good any damage(s) caused by any act
          of him/ her/ or their accompanying guests (willful/negligent) to the
          property of the hotel in any manner whatsoever. The extent and the
          amount of the damage so caused would be determined by the concerned
          hotel. FloxyPay Travels would not, in any way, intervene in the same.
          <ul>
            <li>
              The primary guest must be at least 18 years old to be able to
              check into the hotel.
            </li>
            <li>
              The User has to be in possession of valid identity proof and
              address proof at the time of check-in. The hotel shall be within
              its rights to deny check-in to a User if valid identity proof is
              not presented at check-in time.
            </li>
            <li>
              Check-in time, check-out time, and any changes in those timings
              will be as per hotel policy & terms. Early check-in or late
              check-out request is subject to availability, and the hotel may
              charge an additional fee for providing such services.
            </li>
          </ul>
        </Para>

        <Heading>User charges by the hotel</Heading>
        <Para>
          The booking amount paid by the User is only for a stay at the hotel.
          Some bookings may include breakfast and/ or meals as confirmed at the
          time of booking. Any other services utilized by the User at the hotel,
          including laundry, room service, internet, telephone, extra food,
          drinks, beverages, etc., shall be paid by the User directly to the
          hotel.
        </Para>
        <Para>
          Hotels may charge a mandatory meal surcharge on festive periods like
          Christmas, New Year's Eve, or other festivals as decided by the hotel.
          All additional charges (including mandatory meal surcharges) need to
          be cleared directly at the hotel. FloxyPay Travels will have no
          control over waiving the same.
        </Para>

        <Heading>Payment for bookings and any additional payments</Heading>
        <Para>
          The Website may contain links to third-party websites. FloxyPay
          Travels does not control such websites and is not responsible for
          their contents. If a User accesses any third-party website, the same
          shall be done entirely at the User’s risk, and FloxyPay Travels shall
          assume no liability for the same.
        </Para>
        <Para>
          FloxyPay Travels is not responsible for any errors, omissions, or
          representations on any of its pages, links, or any linked website
          pages to the extent such information is updated or provided directly
          by the Service Providers or the advertisers.
        </Para>
        <Para>
          FloxyPay Travels does not endorse any advertisers on its Website or
          any linked sites in any manner. The Users are requested to verify the
          accuracy of all information provided on the third-party web pages.
        </Para>
        <Para>
          The linked sites are not under the control of FloxyPay Travels; hence,
          FloxyPay Travels is not responsible for the contents of any linked
          site(s) or any further links on such site(s), or any changes or
          updates to such sites. FloxyPay Travels is providing these links to
          the Users only as a convenience.
        </Para>
        <Para>
          If you know of or suspect any fraud or unauthorised use of your
          Payment Method, please contact your payment provider, who may cover
          any resulting charges, possibly minus an excess.
        </Para>
        <Para>
          If the currency selected on the Platform isn't the same as the Service
          Provider's currency, we may:
          <ul>
            <li>show prices in your own currency</li>
            <li>offer you the Pay In Your Own Currency option.</li>
          </ul>
        </Para>
        <Para>
          You’ll see our Currency Conversion Rate during checkout, in the
          Booking details of your Account, or (if you don’t have an Account) in
          the email we send you. If we charge you fees in connection with any
          such services, you’ll find the fee expressed as a percentage over
          European Central Bank rates. Your card issuer may charge you a foreign
          transaction fee.
        </Para>
        <Para>
          We will store your Payment Method details for future transactions
          after collecting your consent.
        </Para>

        <Heading>Policies</Heading>
        <Para>
          1. When you make a Booking, you accept the applicable policies as
          displayed in the booking process. You'll find each Service Provider's
          cancellation policy and any other policies (about age requirements,
          security/damage deposits, additional supplements for group Bookings,
          extra beds, breakfast, pets, cards accepted, etc.) on our Platform: on
          the Service Provider information pages, during the booking process, in
          the fine print, and/or in the confirmation email or ticket (if
          applicable).
        </Para>
        <Para>
          2. If you cancel a Booking or don’t show up, any cancellation/no-show
          fee and any refund will depend on the Service Provider’s
          cancellation/no-show policy.
        </Para>
        <Para>
          3. Some Bookings can’t be cancelled for free, while others can only be
          cancelled for free before a deadline.
        </Para>
        <Para>
          4. If you book a Travel Experience by paying in advance (including all
          price components and/or a damage deposit if applicable), the Service
          Provider may cancel the Booking without notice if they can't collect
          the balance on the date specified. If they do, any non-refundable
          payment you’ve made will only be refunded at their discretion. It's
          your responsibility to make sure the payment goes ahead on time.
        </Para>
        <Para>
          5. If you think you're not going to arrive on time, please contact
          your Service Provider and tell them when they can expect you so they
          don't cancel your Booking. If you’re late, we are not liable for the
          consequences (e.g. the cancellation of your Booking, or any fees the
          Service Provider may charge).
        </Para>
        <Para>
          6. As the person making the Booking, you are responsible for the
          actions and behaviour (in relation to the Travel Experience) of
          everyone in the group. You are also responsible for obtaining their
          permission before providing us with their personal data.
        </Para>

        <Heading>Rewards</Heading>
        <Para>
          To receive any type of Rewards, you must, when qualifying for and
          using the Credits:
          <ul>
            <li>have an Account with us</li>
            <li>be at least 18 years old</li>
            <li>meet the Individual Reward Criteria</li>
            <li>not violate the Rewards & Wallet Terms, and</li>
            <li>
              have a valid credit card to qualify for Credit Card Cashback
              Rewards.
            </li>
          </ul>
        </Para>
        <Para>
          When a Reward is available, the Individual Reward Criteria will
          explain how (and if) you can qualify for it. There may be:
          <ul>
            <li>
              time-sensitive restrictions (e.g., offers with expiry dates)
            </li>
            <li>
              platform restrictions (e.g., promotional codes that can only be
              used in our app)
            </li>
            <li>
              property restrictions (e.g., offers that can only be used with
              specific Service Providers)
            </li>
            <li>
              a minimum spend (e.g., a Reward that you’ll only earn when you
              spend at least a certain amount on a Booking)
            </li>
            <li>
              a maximum Reward value (for either monetary or non-monetary
              Rewards).
            </li>
          </ul>
        </Para>
        <Para>
          Rewards cannot be sold, encumbered, or transferred in any way to a
          third party. In the event of an Account holder’s death, their Account
          will be closed, and their Rewards (if any) will be cancelled.
        </Para>

        <Heading>Term and Termination.</Heading>
        <Para>
          This Agreement is effective upon the date that Partner accepts these
          terms. Partner may terminate this Agreement at any time through the
          platform, and FloxyPay Travels will endeavor to cease the display of
          advertising within forty-eight (48) hours of such cancellation. So
          long as any Request remains in effect, these Terms and Conditions
          shall also remain in effect. NEITHER FLOXYPAY TRAVELS NOR ANY OF ITS
          AFFILIATES WILL HAVE ANY OTHER LIABILITY OF ANY NATURE TO THE PARTNER
          OR ITS CUSTOMERS.
        </Para>

        <Heading>Liability, Warranty & Indemnity</Heading>
        <Para>
          a. except for (a) either party's indemnification obligations, (b)
          breaches of confidentiality, and (c) partner's use of the licensed
          content in a manner inconsistent with the terms of the agreement (a)
          neither party will be liable for any indirect, special, incidental,
          consequential, exemplary or punitive damages, including but not
          limited to damages for lost data, lost profits, lost revenue or costs
          of procurement of substitute goods or services, however, caused and
          under any theory of liability, including but not limited to contract
          or tort (including products liability, strict liability, and
          negligence), and whether or not such party was or should have been
          aware or advised of the possibility of such damage and notwithstanding
          the failure of essential purpose of any limited remedy stated herein.
          except for (a) either party's indemnification obligations, (b)
          breaches of confidentiality, and (c) the partner's use of the licensed
          content in a manner inconsistent with the terms of the agreement, in
          no event shall either party's liability for any claim arising out of
          this agreement exceed the amount partner has paid floxypay travels or
          $50,000, whichever is greater. the parties agree that (i) the mutual
          agreements made in this section reflect a reasonable allocation of
          risk and (ii) that each party would not enter into the agreement
          without these limitations on liability.
        </Para>
        <Para>
          b. except as otherwise stated herein, floxypay travels makes no
          warranties, express or implied, including without limitation, any
          implied warranties of merchantability or fitness for a particular
          purpose, or any warranties as to the number of visitors to or pages
          displayed on the floxypay travels site or the functionality,
          performance, or response times of the floxypay travels site. floxypay
          travels disclaims and shall not be liable for any other loss, injury,
          cost, or damage suffered by partner or any third party and shall in no
          event be liable for consequential, special, or incidental damages,
          including lost profits. this provision shall survive any expiration or
          termination of this agreement.
        </Para>
        <Para>
          c. Partner agrees to defend, indemnify and hold harmless FloxyPay
          Travels and each of FloxyPay Travels’s agents, customers, partners,
          subcontractors, and affiliates, and the officers, directors, and
          employees of any of the foregoing, from, against, and in respect of
          any and all losses, costs, (including reasonable attorney's fees)
          expenses, damages, assessments, or judgments (collectively,
          ''Liabilities''), resulting from any claim against any such parties in
          connection with Partner's Feed or data provided to FloxyPay Travels,
          except to the extent that such claims directly resulted from the gross
          negligence or willful misconduct of FloxyPay Travels.
        </Para>

        <Heading>Payments for bookings and cancellations</Heading>
        <Para>
          Reservations will be subject to cancellation and refund policies of
          the Activity Provider which may vary from time to time. FloxyPay
          Travels may charge applicable taxes or service charges, convenience
          fees, etc., wherever applicable.
        </Para>

        <Heading>Special offers and coupons</Heading>
        <Para>
          The following terms will additionally apply to any coupon provided by
          FloxyPay Travels:
          <ul>
            <li>
              FloxyPay Travels’s responsibility is limited to sharing the coupon
              with the User subject to receipt of payment from the User;
            </li>
            <li>
              The redemption of the coupon is User’s responsibility, and
              providing the service will be the Activity Provider’s
              responsibility;
            </li>
            <li>
              The expiry date of the coupon shall be mentioned on the coupon,
              and the User will not be able the use the coupon subsequent to the
              date of expiry;
            </li>
            <li>The coupon is not redeemable for cash;</li>
            <li>
              The coupon, once purchased, cannot be refunded or cancelled.
            </li>
          </ul>
        </Para>
      </Container>
    </Root>
  );
};

export default TermsAndCondition;
