import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import styled from "styled-components";
import Card from "react-bootstrap/Card";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import LoginPopup from "../Pages/Login/LoginPopup";
import { environmentVariables } from "../../config/config";
import ContentLoader from "react-content-loader";
import Loader from "../Shortlistedloader/Loader";
import { useAuth } from "../../ContextApi/Contextapi";

const Root = styled.div``;

const CardWrapper = styled.div`
  margin: 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 0 10px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const HeartWrapper = styled.div`
  width: 40px;
  border-radius: 3px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  /* left: 88%; */
  right: 15px;
  top: 15px;
  /* right: 1px; */
  background-color: #fff;
  cursor: pointer;
  :hover {
    color: red !important;
  }
`;
const CityWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const ShortCityShortlistedCards = ({ cityName }) => {
  const baseUrl = environmentVariables.apiUrl;
  const navigation = useNavigate();
  const [data, setData] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [userData, setUserData] = useState([]);
  const [isHeartLoading, setIsHeartLoading] = useState({});
  const { authData } = useAuth();
  const getCity = async () => {
    await axios({
      method: "post",
      url: `${baseUrl}auth/gethotelbythemeandcity`,
      data: {
        city: cityName.city,
        theme: cityName.theme.themeName,
      },
    })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const onClickLike = async (item, key) => {
    const compoundKey = `${item._id}-${key}`;
    if (authData === undefined) {
      setShowPopup(true);
    } else {
      setIsHeartLoading((prevState) => ({ ...prevState, [compoundKey]: true }));
      await axios({
        url: `${baseUrl}auth/getshortlisted/${item._id}`,
        method: "post",
        headers: {
          _token: authData?.token,
        },
      })
        .then((response) => {
          getUser(compoundKey);
        })
        .catch((error) => {
          // console.log("error", error);
        });
    }
  };

  const getUser = async (key) => {
    try {
      const data = await axios.get(`${baseUrl}auth/getuserdata`, {
        headers: {
          _token: authData?.token,
        },
      });
      setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
      setUserData(data.data.data.shortlistedArray);
    } catch (error) {
      setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
      return error?.message;
    }
  };

  useEffect(() => {
    getUser();
  }, [authData]);

  useEffect(() => {
    getCity();
  }, []);

  return (
    <Root>
      <Container>
        <CityWrapper>
          {data === undefined ? (
            <ContentLoader
              // width={2500}
              height={575}
              viewBox="0 0 1100 575"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="58" rx="2" ry="2" width="250" height="300" />
              <rect x="280" y="57" rx="2" ry="3" width="250" height="300" />
              <rect x="560" y="56" rx="2" ry="2" width="250" height="300" />
              <rect x="840" y="56" rx="2" ry="2" width="250" height="300" />
              <rect x="0" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="280" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="560" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="840" y="615" rx="2" ry="2" width="250" height="300" />
            </ContentLoader>
          ) : (
            <CardWrapper>
              {data &&
                data.map((item, key) => {
                  return (
                    <Card id="hotel-cards">
                      <Card.Img
                        style={{
                          maxHeight: "217px",
                          cursor: "pointer",
                          minHeight: "217px",
                        }}
                        variant="top"
                        src={
                          item.image[0] && item?.image[0] != "medianew"
                            ? `https://bastionex-travels.b-cdn.net/uploads/${item.image[0]}`
                            : `https://bastionex-travels.b-cdn.net/uploads/photo_2024-09-06_21-43-47.jpg`
                        }
                        onClick={() =>
                          navigation(`/hoteldetails/${item?._id}`)
                        }
                      />
                      <Tooltip title="Shortlist" styled={{ fontSize: "50px" }}>
                        <HeartWrapper onClick={() => onClickLike(item, key)}>
                          {isHeartLoading[`${item._id}-${key}`] ? (
                            <Loader />
                          ) : (
                            <i
                              style={{
                                fontSize: "20px",
                                color:
                                  userData && userData.includes(item._id)
                                    ? "red"
                                    : "#747474",
                              }}
                              className="fa fa-thin fa-heart style"
                            ></i>
                          )}
                        </HeartWrapper>
                      </Tooltip>
                      <Card.Body
                        style={{
                          maxHeight: "100px",
                          marginBottom: "20px",
                          minHeight: "100px",
                        }}
                      >
                        <Card.Title
                          style={{ fontWeight: "500", fontSize: "16px" }}
                        >
                          {item.hotelname}
                        </Card.Title>
                        <Card.Title
                          style={{ fontWeight: "300", fontSize: "14px" }}
                        >
                          {item.city} , {item.state}
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  );
                })}
            </CardWrapper>
          )}

          {showPopup && (
            <LoginPopup showPopup={showPopup} setShowPopup={setShowPopup} />
          )}
        </CityWrapper>
      </Container>
    </Root>
  );
};

export default ShortCityShortlistedCards;
