import React, { useEffect, useRef, useState, useContext } from "react";
import Card from "react-bootstrap/Card";
import styled from "styled-components";
import { FiThumbsUp } from "react-icons/fi";
import { FaArrowCircleUp } from "react-icons/fa";
import Percentage from "../../../images/percentage.png";
import { FaUser } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
// import { Modal, Title } from "react-bootstrap";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link as MUI_Link } from "@mui/material";
import Container from "react-bootstrap/Container";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Deals.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CheckAvilable from "./CheckAvilable";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
import { environmentVariables } from "../../../config/config";
import { AuthContext, useAuth } from "../../../ContextApi/Contextapi";
import { red } from "@mui/material/colors";
import { Button as MUIButton } from "@mui/material";
import { ElectricalServicesSharp } from "@mui/icons-material";
import Modal from "react-bootstrap/Modal";
import { LoaderForSelectActivities } from "../../Shortlistedloader/Loader";

const Root = styled.div`
  width: 100%;
`;
const TextHeading = styled.div`
  color: #000;
  display: flex;
  align-items: center;
  font-weight: 500;
`;

const TextContainerDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  background-color: #3554d1;
  width: 100%;
  color: white;
  margin-top: 10px;
  border: none;
  padding: 10px 0px;
  border-radius: 5px;
`;

const Input = styled.input`
  padding: 2px 10px;
  outline: none;
`;
const Form = styled.div`
  padding-top: 10px;
`;
// const InputWrapper = styled.div`
// display: inline-block;
// margin-left: 43px;
// `;

const InputTextWrapper = styled.div`
  position: relative;
`;

const TextIcon = styled.div`
  text-align: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Image = styled.img`
  display: block;
  margin: 0 auto;
`;
const Image1 = styled.img``;

const TextBtnOne = styled.div``;

const TextForm = styled.div`
  text-align: center;
`;
const Label = styled.label`
  color: #fff;
`;

const TextSelect = styled.div`
  padding: 0 10px;
  display: flex;
  justify-content: center;
`;

const TextFormWrapper = styled.div`
  background-color: #fff;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 480px) {
    height: 250px;
  }
`;

const TextSelectInput = styled.div`
  display: flex;
  padding-left: 10px;
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
`;
const Text = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 500;
  text-transform: uppercase;
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const TextOne = styled.div`
  color: #000;
  padding: 10px 20px;
`;

const TextSelectWrapper = styled.div`
  margin: 5px;
  box-shadow: 0px 0px 7px 2px #00000012;
  :focus {
    outline: none;
    border: none;
  }
`;

const TextSelect1 = styled.div`
  // padding: 0 10px;
  display: flex;
  justify-content: center;
`;

const TextSelectInput1 = styled.div`
  display: flex;
  width: 100%;
  // padding-left: 10px;
  flex-direction: column;
`;

const TextSelectWrapper1 = styled.div`
  margin: 10px 0px;
  box-shadow: rgba(73, 73, 73, 0.15) 0px 0px 8px 1px;
  :focus {
    outline: none;
    border: none;
  }
`;

const TextBorderWrapper = styled.div`
  background-color: #1a2a68;
  height: 50px;
`;

const TextBtnDiv = styled.button`
  text-align: center;
  color: #fff;
  background-color: #3554d1;
  cursor: pointer;
  font-size: 17px;
  /* margin-top: 10px; */
  width: 100%;
  padding: 12px;
  margin-top: 0px;
  border: none;
  :disabled {
    display: none;
  }
  :enabled {
    /* display: none; */
  }
`;

const inputStyle = {
  padding: "10px 8px",
  border: "none",
  borderRadius: "5px",
  width: "100%",
};

const inputStyleOne = {
  // padding: "2px 3px",
  border: "none",
  padding: "10px 8px",
  borderRadius: "5px",
  width: "100%",
};

const DateWrapper = styled.div``;

const DateContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  width: "80%";
`;

const ErrorMessage = styled.div`
  font-size: 12px;
  color: red;
  margin-bottom: 20px;
`;
const TimeInput = styled.input`
  /* Add your styles here */
  padding: 0.5rem;
  font-size: 1rem;
  @media (max-width: 480px) {
    font-size: 12px;
    padding: 0px;
    border: 1px solid #ededed;
  }
`;

const FormContainerNameValue = styled.div`
  @media (max-width: 480px) {
    display: flex;
    // flex-direction: "column";
  }
`;

const TableHeadSelectActivity = styled.td`
  @media (max-width: 480px) {
    font-size: 12px;
    padding: 5px;
  }
  ${(p) =>
    p.TableheadName &&
    `
    @media(max-width: 380px){
      width: 45px !important;
      padding: 10px 5px !important;
    }
  `}
`;

const CustomTableHeadMobile = styled.table`
  @media (max-width: 480px) {
    margin-left: 0px;
  }
`;
const ActivityAnchorTag = styled.a``;

const Deals = (props) => {
  const { id } = useParams();
  const baseUrl = environmentVariables.apiUrl;
  const { state } = useLocation();
  // const { authData, setAuthData } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const InputCheckIn = useRef(null);
  const InputCheckOut = useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [checkIn, setCheckIn] = useState();
  const [checkIn1, setCheckIn1] = useState();
  const [checkOut, setCheckOut] = useState();
  const [checkOut1, setCheckOut1] = useState();
  const [adults, setAdults] = useState();
  const [children1, setChildren1] = useState();
  const [adults1, setAdults1] = useState();
  const [rooms1, setRooms1] = useState();
  const [children, setChildren] = useState();
  const [infants, setInfants] = useState();
  const [checkInDate, setCheckInDate] = useState(new Date());
  const [checkOutDate, setCheckOutDate] = useState(new Date());
  const [inDate, setInDate] = useState();
  const { authData } = useAuth();

  const [priceRange, setPriceRange] = useState();
  const [themes, setThemes] = useState();
  const [checkOutFromParams, setCheckOutFromParams] = useState();
  const [adultsFromParams, setAdultsInFromParams] = useState();
  const [roomsFromParams, setRoomsFromParams] = useState();
  const [childrenFromParams, setChildrenFromParams] = useState();
  const [activities, setActivities] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [allPrices, setAllPrices] = useState([]);
  const [numberOfAdults, setNumberOfAdults] = useState([1, 2]);
  const [numberOfChildrens, setNumberOfChildrens] = useState([1, 2]);
  const [numberOfInfants, setNumberOfInfants] = useState([1, 2]);

  const [children2, setChildren2] = useState();
  const [adults2, setAdults2] = useState();
  const [rooms2, setRooms2] = useState();
  const [responseFromCheckedData, setResponseFromCheckedData] = useState("");
  const [showActivityGuestModal, setShowActivityGuestModal] = useState(false);
  const [targetActivity, setTargetActivity] = useState(null);
  const [changedAdults, setChangedAdults] = useState();
  const [changedChildren, setChangedChildren] = useState();
  const [changedInfants, setChangedInfants] = useState();
  const [changedPricingForActivities, setChangedPricingForActivities] =
    useState();
  const today = new Date();
  const [showAll, setShowAll] = useState(false);
  const getCountryName = localStorage?.getItem("countryname");
  const getISOCode = localStorage?.getItem("countryISOCode");
  const country_code = localStorage?.getItem("countryCodeForPricing");

  const navigation = useNavigate();

  // const [selectedTime, setSelectedTime] = useState("11:00");
  const handleTimeChange = (event, row) => {
    let targetActivity = row;
    let allSelctedActivities = [];
    for (let index = 0; index < selectedActivities.length; index++) {
      const element = selectedActivities[index];
      if (element.activityid === targetActivity.activityid) {
        let updatedValue = {
          ...targetActivity,
          activityTime: event.target.value,
        };
        allSelctedActivities.push(updatedValue);
      } else {
        allSelctedActivities.push(element);
      }
    }
    setSelectedActivities(allSelctedActivities);
  };
  console.log("4444444444", targetActivity);

  const handleClose1 = () => {
    setChildren1(children);
    setAdults1(adults);
    setRooms1(props?.rooms);
    setCheckIn1(checkIn);
    setCheckOut1(checkOut);
    setShow(false);
  };

  useEffect(() => {
    setCheckIn(state?.checkIn || new Date());
    setCheckOut(
      state?.checkOut || new Date(new Date().setDate(new Date().getDate() + 1))
    );
    props?.setRooms(
      typeof state?.rooms == "object" || !state?.rooms ? 1 : state?.rooms
    );
    setAdults(state?.adults || 2);
    setChildren(state?.children || 0);
    setInfants(state?.infants || 0);

    setThemes(state?.themes);
    setPriceRange(state?.priceRange);
    setSelectedActivities(
      state?.selectedActivities !== undefined ? state?.selectedActivities : []
    );
    // setSelectedTime(state?.)
  }, []);

  const handleCheckInChange = (date) => {
    setCheckIn(date);
    if (checkOut && date > checkOut) {
      setCheckOut(null);
    }
  };
  const setAdultsAndChildrens = async () => {
    let length = Number(props?.rooms) * 2;
    let newArray = [];
    for (let index = 1; index <= length; index++) {
      newArray.push(index);
    }
    setNumberOfAdults(newArray);
    setNumberOfChildrens(newArray);
    setNumberOfInfants(newArray);
  };
  useEffect(() => {
    if (props?.rooms) {
      setAdultsAndChildrens(props?.rooms);
    }
  }, [props?.rooms]);
  useEffect(() => {
    if (
      (props?.data?.city !== undefined && props?.data?.city) ||
      (props?.data?.filter.city !== undefined && props?.data?.filter?.city)
    ) {
      setLoader(true);
      let getCityName = props?.data?.city || props?.data?.filter?.city;
      var config = {
        method: "post",
        url: `${baseUrl}auth/getActivities?country_code=${country_code}`,
        headers: {},
        data: { city: getCityName, page: 1, limit: 90000 },
      };

      axios(config)
        .then(function (response) {
          if (response.data.success) {
            setActivities(response.data.message);
            setLoader(false);
          } else {
            setActivities([]);
            setLoader(false);
          }
        })
        .catch(function (error) {
          setLoader(false);

          setActivities([]);
        });
    }
  }, [props.data]);

  const handleActivity = (e, row) => {
    function filterResult(item) {
      return item.activityid != row._id;
    }
    if (e.target.checked) {
      let actvData = {
        activityid: row._id,
        activityName: row.hotelname,
        date: checkIn,
        adults: adults,
        child: children,
        infants,
        activityTime: "11:00",
        pricingData: row?.pricingData,
      };
      setSelectedActivities([...selectedActivities, actvData]);
    } else {
      // row
      let remainingData = selectedActivities.filter(filterResult);
      setSelectedActivities(remainingData);
    }
  };
  const handleActivityMore = (e, row) => {
    function filterResult(item) {
      return item._id == row.activityid;
    }
    let remainingData = activities.filter(filterResult);
    navigation(`/activityDetail/${row?.activityid}`, {
      state: {
        ...remainingData[0],
        hotelDetailSate: {
          // ...state,
          checkIn,
          checkOut,
          rooms: props?.rooms,
          adults,
          children,
          themes,
          priceRange,
        },
        selectedActivities: selectedActivities,
        currentActivity: row,
        isCombined: true,
        redirectTo: `/hoteldetails/${id}`,
      },
    });
  };
  const handleActivityGuest = (e, row) => {
    console.log(
      "rrrrrrrrrrrrrrr",
      row,
      changedPricingForActivities,
      changedInfants,
      authData
    );
    setShowActivityGuestModal(true);
    setTargetActivity(row);
    setChangedAdults(row?.adults);
    setChangedChildren(row?.child);
    setChangedInfants(row?.infants);
    setChangedPricingForActivities(row?.changedPricingForActivities);

    const defaultPricingOption = row?.pricingData?.find(
      (item) => item._id === row?.changedPricingForActivities
    );

    // Set the selected option for this activity
    if (defaultPricingOption) {
      setSelectedOptionForActivities((prev) => ({
        ...prev,
        [row.activityid]: defaultPricingOption,
      }));
    }
  };
  const handleCloseActivityGuestModal = () => {
    setShowActivityGuestModal(false);
    setTargetActivity(null);
  };

  const handleSelectedActivities = async () => {
    let allSelctedActivities = [];
    for (let index = 0; index < selectedActivities.length; index++) {
      const element = selectedActivities[index];
      if (element.activityid == targetActivity.activityid) {
        let updatedValue = {
          ...targetActivity,
          adults: changedAdults,
          child: changedChildren,
          infants: changedInfants,
          changedPricingForActivities: changedPricingForActivities,
        };
        allSelctedActivities.push(updatedValue);
      } else {
        allSelctedActivities.push(element);
      }
    }
    setSelectedActivities(allSelctedActivities);
  };

  // const applyActivityGuests = () => {
  //   // console.log("tanuj.999", changedPricingForActivities, changedInfants);
  //   handleSelectedActivities();
  //   handleCloseActivityGuestModal();
  // };

  const applyActivityGuests = () => {
    const updatedActivities = selectedActivities.map((activity) => {
      if (activity.activityid === targetActivity.activityid) {
        return {
          ...activity,
          changedAdults,
          changedChildren,
          changedInfants,
          changedPricingForActivities:
            selectedOptionForActivities[activity.activityid]?._id, // Apply the selected option
        };
      }
      return activity;
    });

    setSelectedActivities(updatedActivities);
    setShowActivityGuestModal(false);
  };

  const setDateforEveryActivities = async (date, row) => {
    let targetActivity = row;
    let allSelctedActivities = [];
    for (let index = 0; index < selectedActivities.length; index++) {
      const element = selectedActivities[index];
      if (element.activityid === targetActivity.activityid) {
        let updatedValue = { ...targetActivity, date: date };
        allSelctedActivities.push(updatedValue);
      } else {
        allSelctedActivities.push(element);
      }
    }
    setSelectedActivities(allSelctedActivities);
  };
  const handleActivityDateChange = (date, row) => {
    setDateforEveryActivities(date, row);
  };
  const displayedActivities = showAll ? activities : activities.slice(0, 6);

  // const getAllPrices = () => {
  //   let config = {
  //     method: "get",
  //     url: `${baseUrl}auth/getpricerange?countryCode=${getISOCode}`,
  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       setAllPrices(response?.data?.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setAllPrices([]);
  //     });
  // };
  // useEffect(() => {
  //   getAllPrices();
  // }, []);

  console.log("getCountryName", getCountryName);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpenForActivity, setIsOpenForActivity] = useState(false);
  const [selectedOptionForActivities, setSelectedOptionForActivities] =
    useState({});

  const handleSelect = (item) => {
    setSelectedOption(item);
    setIsOpen(false);
    setPriceRange(item?._id); // setting the value of selected price range
  };

  // const getPriceForCountry = (item) => {
  //   if (authData?.country == "United Arab Emirates") {
  //     return item?.originalPrice; // Use originalPrice for AE
  //   } else if (authData?.country === "India") {
  //     return item?.price; // Use price for IN
  //   }
  //   return item?.price; // Fallback to price
  // };
  const getPriceForCountry = (item) => {
    if (authData?.country == "United Arab Emirates") {
      return {
        price: item?.originalPrice, // Use originalPrice for AE
        currency: "AED", // Set currency to AED
      };
    } else if (authData?.country === "India") {
      return {
        price: item?.price, // Use price for IN
        currency: "INR", // Set currency to INR
      };
    }
    return {
      price: item?.price, // Fallback to price
      currency: item?.currency || "INR", // Fallback to INR if currency is undefined
    };
  };

  const handleSelectForActivities = (item, activityId) => {
    setSelectedOptionForActivities((prev) => ({
      ...prev,
      [activityId]: item, // Update the selected option for the specific activity
    }));
    setIsOpenForActivity(false);
    setChangedPricingForActivities(item?._id); // Optional: if you want to store pricing ID globally
  };
  return (
    <>
      <Root>
        <Container
          style={{
            padding: "0",
          }}
        >
          <TextContainerDiv>
            <Card
              style={{
                width: "97%",
                backgroundColor: "#fff",
                border: "none",
              }}
            >
              <Card.Body>
                <TextHeading>
                  <FiThumbsUp style={{ marginRight: "10px" }} />
                  Find the best deals
                </TextHeading>
                <Form>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: "6px",
                      boxShadow: "0px 0px 8px 1px #00000026",
                      borderRadius: "5px",

                      // backgroundColor: "#dbdbdb",
                    }}
                    onClick={() => setError("")}
                  >
                    <DatePicker
                      className=""
                      placeholderText=" CheckIn*"
                      // dateFormat="MM/dd/yyyy"
                      selected={checkIn}
                      onChange={handleCheckInChange}
                      selectsStartcheckIn
                      startDate={checkIn}
                      endDate={checkOut}
                      // maxDate={checkOut}
                      ref={InputCheckIn}
                      minDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                    ></DatePicker>
                    <div
                      onClick={() => {
                        InputCheckIn.current.setOpen(true);
                      }}
                      style={{
                        position: "relative",
                        // zIndex: "999999",
                        // top: "10%",
                        // left: "43%",
                        right: "5px",
                        top: "5px",
                        fontSize: "20px",
                        cursor: "pointer",
                        // borderRight : "2px solid red",
                      }}
                    >
                      <i className="fas fa-calendar-alt"></i>
                    </div>
                    <DatePicker
                      className="custom-date-picker-input"
                      placeholderText=" CheckOut*"
                      // dateFormat="MM/dd/yyyy"
                      selected={checkOut}
                      onChange={(date) => setCheckOut(date)}
                      startDate={checkIn}
                      endDate={checkOut}
                      minDate={checkIn}
                      ref={InputCheckOut}
                      dateFormat="dd/MM/yyyy"
                      //   width='100px'
                      // styled={{ width: "98%",backgroundColor:'red',color:'green'}}
                    ></DatePicker>
                    <div
                      onClick={() => InputCheckOut.current.setOpen(true)}
                      style={{
                        // top: "10%",
                        // left: "90%",
                        right: "5px",
                        top: "5px",
                        fontSize: "20px",
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      <i className="fas fa-calendar-alt"></i>
                    </div>
                  </div>

                  <InputTextWrapper onClick={handleShow}>
                    <FaUser
                      className="userIcon"
                      style={{
                        position: "relative",
                        top: "40px",
                        left: "15px",
                        fontSize: "20px",
                        color: "#2196f3",
                      }}
                    />
                    <Input
                      type="text"
                      placeholder="1 Room 2 Adults*"
                      style={{
                        width: "100%",
                        // textAlign: "center",
                        outline: "none",
                        border: "none",
                        padding: "20px 50px",
                        boxShadow: "rgba(73, 73, 73, 0.15) 0px 0px 8px 1px",
                        borderRadius: "5px",
                      }}
                      value={`${
                        props?.rooms > 1
                          ? `${props?.rooms} Rooms`
                          : `${props?.rooms} Room`
                      }, ${
                        adults > 1 ? `${adults} Adults` : `${adults} Adult`
                      }, ${
                        children > 1
                          ? `${children} Children`
                          : `${children} Child`
                      }, ${
                        infants > 1 ? `${infants} Infants` : `${infants} Infant`
                      }`}
                      readOnly
                    />
                    <TextBtnOne
                      onClick={handleShow}
                      style={{ cursor: "pointer" }}
                    >
                      <FaRegEdit
                        className="editIcon"
                        style={{
                          fontSize: "20px",
                          color: "#2196f3",
                        }}
                      />
                    </TextBtnOne>
                  </InputTextWrapper>
                  {error !== "" && <ErrorMessage>{error}</ErrorMessage>}
                  <TextSelect1>
                    <TextSelectInput1>
                      {/* <TextSelectWrapper1>
                        <select
                          style={inputStyleOne}
                          onChange={(e) => setThemes(e.target.value)}
                          value={themes}
                        >
                          <option selected value="0">
                            Select Theme
                          </option>
                          <option value="hill">Hill</option>
                          <option value="heritage">Heritage</option>
                          <option value="romantic">Romantic</option>
                          <option value="beach">Beach</option>
                          <option value="wildlife">Wildlife</option>
                          <option value="family">Family</option>
                          <option value="honeymoon">Honeymoon</option>
                          <option value="adventure">Adventure</option>
                          <option value="group">Group</option>
                        </select>
                      </TextSelectWrapper1> */}
                      {loader ? (
                        <LoaderForSelectActivities />
                      ) : displayedActivities.length ? (
                        <TextSelectWrapper1>
                          <fieldset className="checkbox-group">
                            <legend className="checkbox-group-legend">
                              Select Activities{" "}
                              <span className="optional">(optional)</span>
                            </legend>
                            <div className="scrollable-container">
                              {displayedActivities.map((row, index) => {
                                let image =
                                  row.image[0] !== undefined && row.image[0]
                                    ? `https://bastionex-travels.b-cdn.net/uploads/${row.image[0]}`
                                    : "https://dummyimage.com/300x200/000/fff";

                                let isCheckedList = selectedActivities.filter(
                                  (item) => {
                                    return row._id == item.activityid;
                                  }
                                );

                                return (
                                  <>
                                    <div className="checkbox" key={index}>
                                      <label className="checkbox-wrapper">
                                        <input
                                          type="checkbox"
                                          checked={isCheckedList.length}
                                          onChange={(e) => {
                                            handleActivity(e, row);
                                          }}
                                          className="checkbox-input"
                                        />
                                        <span className="checkbox-tile">
                                          <span className="checkbox-icon">
                                            <Image1
                                              height={60}
                                              width={110}
                                              src={image}
                                            />
                                          </span>
                                          <span className="checkbox-label">
                                            {/* {row.hotelname.length > 15
                                                ? `${row.hotelname.substring(
                                                    0,
                                                    15
                                                  )}...`
                                                : row.hotelname} */}
                                            {row.hotelname.substring(0, 25)}
                                          </span>
                                        </span>
                                      </label>
                                      {/* {
                                              (displayedActivities.length - 1 == index) ?
                                                <div className="more-div">
                                                  <MUI_Link component="button" onClick={() => setShowAll(!showAll)}>{showAll ? 'less' : 'more'}</MUI_Link>
                                                </div>
                                                : null
                                            } */}
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                            <div className="more-div">
                              <MUI_Link
                                component="button"
                                onClick={() => setShowAll(!showAll)}
                              >
                                {showAll ? "less" : "more"}
                              </MUI_Link>
                            </div>

                            {selectedActivities.length ? (
                              <CustomTableHeadMobile className="table table-bordered TableHeadMobile">
                                <thead>
                                  <tr>
                                    <th
                                      className="actheading activityNameColumn"
                                      scope="col"
                                    >
                                      Name
                                    </th>
                                    <th className="actheading" scope="col">
                                      Date & Time
                                    </th>
                                    <th className="actheading" scope="col">
                                      Members
                                    </th>
                                    <th className="actheading" scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedActivities.map((row, index) => {
                                    return (
                                      <>
                                        <tr key={index}>
                                          <TableHeadSelectActivity
                                            TableheadName
                                          >
                                            <div> {row.activityName}</div>
                                          </TableHeadSelectActivity>
                                          <TableHeadSelectActivity>
                                            <div
                                              style={{
                                                border: "1px solid #cccc",
                                                borderRadius: "5px",
                                              }}
                                            >
                                              <DatePicker
                                                name="date"
                                                placeholderText="Date"
                                                selected={row.date}
                                                onChange={(date) =>
                                                  handleActivityDateChange(
                                                    date,
                                                    row
                                                  )
                                                }
                                                minDate={checkIn}
                                                maxDate={checkOut}
                                                width="100px"
                                                dateFormat="dd/MM/yyyy"
                                              ></DatePicker>
                                            </div>
                                            <TimeInput
                                              type="time"
                                              id="timeInput"
                                              name="timeInput"
                                              value={row.activityTime}
                                              onChange={(e) =>
                                                handleTimeChange(e, row)
                                              }
                                            />
                                          </TableHeadSelectActivity>
                                          <TableHeadSelectActivity>
                                            <div
                                              onClick={(e) =>
                                                handleActivityGuest(e, row)
                                              }
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                color: "blue",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <span>{row.adults} adults</span>
                                              <span>{row.child} child</span>
                                              <span>{row.infants} infants</span>
                                              {/* <span>
                                                {
                                                  row?.changedPricingForActivities
                                                }{" "}
                                                pricing
                                              </span> */}
                                            </div>
                                          </TableHeadSelectActivity>
                                          <TableHeadSelectActivity>
                                            <ActivityAnchorTag
                                              action="#"
                                              onClick={(e) =>
                                                handleActivityMore(e, row)
                                              }
                                              style={{
                                                cursor: "pointer",
                                                color: "blue",
                                                textDecoration: "underline",
                                              }}
                                            >
                                              more
                                            </ActivityAnchorTag>
                                          </TableHeadSelectActivity>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                              </CustomTableHeadMobile>
                            ) : null}
                          </fieldset>
                        </TextSelectWrapper1>
                      ) : null}

                      {/* <TextSelectWrapper1>
                        <select
                          style={inputStyle}
                          onChange={(e) => setPriceRange(e.target.value)}
                          value={priceRange}
                        >
                          <option selected value="0">
                            Select Room type
                          </option>
                          {props?.data?.pricingData?.map((item, index) => {
                            return (
                              <option value={item?._id} key={index}>
                                <p>
                                  {item?.room_type_excursion_option}
                                  <h1>({item?.board_basis_ticket_option})</h1>
                                  {item?.currency} {item?.price}
                                </p>
                              </option>
                            );
                          })}
                        </select>
                      </TextSelectWrapper1> */}
                      <div className="text-select-wrapper">
                        <div
                          className="selected-option"
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          {selectedOption ? (
                            <div className="dropdown-option">
                              <div className="dropdown-firstwrapper">
                                <span className="item-title">
                                  {selectedOption.room_type_excursion_option}
                                </span>{" "}
                                <span className="item-subtitle">
                                  ({selectedOption.board_basis_ticket_option})
                                </span>
                              </div>
                              <span className="item-price">
                                {selectedOption.currency} {selectedOption.price}
                              </span>
                            </div>
                          ) : (
                            "Select Room type"
                          )}
                        </div>
                        {isOpen && (
                          <div className="options-wrapper">
                            {props?.data?.pricingData?.map((item, index) => (
                              <div
                                className="dropdown-option"
                                key={index}
                                onClick={() => handleSelect(item)}
                              >
                                <div className="dropdown-firstwrapper">
                                  {" "}
                                  <span className="item-title">
                                    {item?.room_type_excursion_option}
                                  </span>{" "}
                                  <span className="item-subtitle">
                                    ({item?.board_basis_ticket_option})
                                  </span>
                                </div>

                                <span className="item-price">
                                  {item?.currency} {item?.price}
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </TextSelectInput1>
                  </TextSelect1>

                  <Button>
                    <CheckAvilable
                      hotelData={props?.data?.filter || props?.data}
                      state={{
                        adult: adults,
                        children: children,
                        infants: infants,
                        rooms: props?.rooms,
                        checkIn: checkIn,
                        checkOut: checkOut,
                        checkInTime: checkIn,
                        checkOutTime: checkOut,
                        priceRange: priceRange,
                        getISOCode: getISOCode,
                        themes: themes,
                        selectedActivities: selectedActivities,
                      }}
                      loggedIn={props.loggedIn}
                      setLoggedIn={props.setLoggedIn}
                      error={error}
                      setError={setError}
                    />{" "}
                  </Button>
                </Form>
              </Card.Body>
              {/* <Card.Body style={{ backgroundColor: "white" }}>
                <TextIcon>
                  <FaArrowCircleUp />
                </TextIcon>
                <TextWrapper>
                  Please enter your dates above to see all available rooms and
                  best deals.
                </TextWrapper>
              </Card.Body> */}
              {/* <Card.Body
                style={{
                  backgroundColor: "#f6f6f6",
                  borderRadius: " 0 0 5px 5px",
                }}
              >
                <Image src={Percentage} alt="Percentage Icon" />
                <TextWrapper>
                  <span>Best Price Guaranteed!</span>
                  <span>
                    We compare prices across 30 sites to get you the best deals.
                  </span>
                </TextWrapper>
              </Card.Body> */}
            </Card>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header
                style={{ backgroundColor: "#fff", textAlign: "center" }}
                closeButton
              >
                <div className="d-flex">
                  <span className="text-danger"></span>
                </div>
              </Modal.Header>
              <TextFormWrapper>
                <FormContainerNameValue>
                  <Text>
                    <TextOne>Rooms*</TextOne>
                    <TextOne>Adults*</TextOne>
                    <TextOne>Children</TextOne>
                    <TextOne>Infants</TextOne>
                  </Text>
                  <TextSelect>
                    {/* <Label htmlFor="">Room  </Label> */}
                    <TextSelectInput>
                      <TextSelectWrapper>
                        <select
                          style={inputStyle}
                          onChange={(e) => {
                            props?.setRooms(e.target.value);
                          }}
                          value={props?.rooms}
                        >
                          <option selected value="0">
                            Select
                          </option>
                          <option value="1">1 Room</option>
                          <option value="2">2 Rooms</option>
                          <option value="3">3 Rooms</option>
                          <option value="4">4 Rooms</option>
                          <option value="5">5 Rooms</option>
                          <option value="6">6 Rooms</option>
                          <option value="7">7 Rooms</option>
                          <option value="8">8 Rooms</option>
                          <option value="9">9 Rooms</option>
                        </select>
                      </TextSelectWrapper>
                      <TextSelectWrapper>
                        <select
                          style={inputStyleOne}
                          onChange={(e) => setAdults(e.target.value)}
                          value={adults}
                        >
                          <option selected value="0">
                            Select
                          </option>
                          {numberOfAdults.map((value, index) => {
                            return (
                              <option value={value} key={index}>
                                {value} Adult{value > 1 ? "s" : ""}
                              </option>
                            );
                          })}
                        </select>
                      </TextSelectWrapper>
                      <TextSelectWrapper>
                        <select
                          style={inputStyleOne}
                          onChange={(e) => setChildren(e.target.value)}
                          value={children}
                        >
                          <option selected value="0">
                            Select
                          </option>
                          {numberOfChildrens.map((value, index) => {
                            return (
                              <option value={value} key={index}>
                                {value} {value > 1 ? "Children" : "Child"}
                              </option>
                            );
                          })}
                        </select>
                      </TextSelectWrapper>
                      <TextSelectWrapper>
                        <select
                          style={inputStyleOne}
                          onChange={(e) => setInfants(e.target.value)}
                          value={infants}
                        >
                          <option selected value="0">
                            Select
                          </option>
                          {numberOfInfants.map((value, index) => {
                            return (
                              <option value={value} key={index}>
                                {value} {value > 1 ? "Infants" : "Infant"}
                              </option>
                            );
                          })}
                        </select>
                      </TextSelectWrapper>
                    </TextSelectInput>
                  </TextSelect>
                </FormContainerNameValue>
              </TextFormWrapper>
              <TextBorderWrapper onClick={handleClose1}>
                <TextBtnDiv
                  // disabled={rooms !== "0" && adults !== "0" ? false : true}
                  style={{
                    opacity: props?.rooms !== "0" && adults !== "0" ? 1 : 0.5,
                  }}
                >
                  <BsCheckCircleFill /> Apply
                </TextBtnDiv>
              </TextBorderWrapper>
            </Modal>

            <Modal
              show={showActivityGuestModal}
              onHide={handleCloseActivityGuestModal}
            >
              <Modal.Header
                style={{ backgroundColor: "#fff", textAlign: "center" }}
                closeButton
              >
                <div className="d-flex">
                  <span className="text-danger"></span>
                </div>
              </Modal.Header>
              <div className="activity_popup_main_wrapper">
                <div className="activity_popup_first_wrapper">
                  <div>
                    <div className="activity_popup_headings">Adults*</div>
                    <select
                      style={inputStyleOne}
                      onChange={(e) => setChangedAdults(e.target.value)}
                      value={changedAdults}
                    >
                      <option selected value="0">
                        Select
                      </option>
                      <option value={1}>1 Adult</option>
                      <option value={2}>2 Adults</option>
                      <option value={3}>3 Adults</option>
                      <option value={4}>4 Adults</option>
                      <option value={5}>5 Adults</option>
                      <option value={6}>6 Adults</option>
                      <option value={7}>7 Adults</option>
                      <option value={8}>8 Adults</option>
                      <option value={9}>9 Adults</option>
                      <option value={10}>10 Adults</option>
                      <option value={11}>11 Adults</option>
                      <option value={12}>12 Adults</option>
                      <option value={13}>13 Adults</option>
                      <option value={14}>14 Adults</option>
                      <option value={15}>15 Adults</option>
                    </select>
                  </div>
                  <div>
                    <div className="activity_popup_headings">Children</div>
                    <select
                      style={inputStyleOne}
                      onChange={(e) => setChangedChildren(e.target.value)}
                      value={changedChildren}
                    >
                      <option selected value="0">
                        Select
                      </option>
                      <option value={1}>1 Child</option>
                      <option value={2}>2 Children</option>
                      <option value={3}>3 Children</option>
                      <option value={4}>4 Children</option>
                      <option value={5}>5 Children</option>
                      <option value={6}>6 Children</option>
                      <option value={7}>7 Children</option>
                      <option value={8}>8 Children</option>
                      <option value={9}>9 Children</option>
                      <option value={10}>10 Children</option>
                      <option value={11}>11 Children</option>
                      <option value={12}>12 Children</option>
                      <option value={13}>13 Children</option>
                      <option value={14}>14 Children</option>
                      <option value={15}>15 Children</option>
                    </select>
                  </div>
                  <div>
                    <div className="activity_popup_headings">Infants</div>
                    <select
                      style={inputStyleOne}
                      onChange={(e) => setChangedInfants(e.target.value)}
                      value={changedInfants}
                    >
                      <option selected value="0">
                        Select
                      </option>
                      <option value={1}>1 Infant</option>
                      <option value={2}>2 Infants</option>
                      <option value={3}>3 Infants</option>
                      <option value={4}>4 Infants</option>
                      <option value={5}>5 Infants</option>
                      <option value={6}>6 Infants</option>
                      <option value={7}>7 Infants</option>
                      <option value={8}>8 Infants</option>
                      <option value={9}>9 Infants</option>
                      <option value={10}>10 Infants</option>
                      <option value={11}>11 Infants</option>
                      <option value={12}>12 Infants</option>
                      <option value={13}>13 Infants</option>
                      <option value={14}>14 Infants</option>
                      <option value={15}>15 Infants</option>
                    </select>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="activity_popup_headings">
                      Select Ticket Option...
                    </div>

                    <div className="text-select-wrapper">
                      {/* <div
                        className="selected-option"
                        onClick={() => setIsOpenForActivity(!isOpenForActivity)}
                      >
                        {selectedOptionForActivities ? (
                          <div className="dropdown-option">
                            <div className="dropdown-firstwrapper">
                              <span className="item-title">
                                {
                                  selectedOptionForActivities.room_type_excursion_option
                                }
                              </span>{" "}
                              <span className="item-subtitle">
                                (
                                {
                                  selectedOptionForActivities.board_basis_ticket_option
                                }
                                )
                              </span>
                            </div>
                            <span className="item-price">
                              {selectedOptionForActivities.currency}{" "}
                              {selectedOptionForActivities.price}
                            </span>
                          </div>
                        ) : (
                          "Select Ticket Option"
                        )}
                      </div>
                      {isOpenForActivity && (
                        <div className="options-wrapper">
                          {targetActivity?.pricingData?.map((item, index) => (
                            <div
                              className="dropdown-option"
                              key={index}
                              onClick={() => handleSelectForActivities(item)}
                            >
                              <div className="dropdown-firstwrapper">
                                {" "}
                                <span className="item-title">
                                  {item?.room_type_excursion_option}
                                </span>{" "}
                                <span className="item-subtitle">
                                  ({item?.board_basis_ticket_option})
                                </span>
                              </div>

                              <span className="item-price">
                                {item?.currency} {item?.price}
                              </span>
                            </div>
                          ))}
                        </div>
                      )} */}
                      <div
                        className="selected-option"
                        onClick={() => setIsOpenForActivity(!isOpenForActivity)}
                      >
                        {selectedOptionForActivities?.[
                          targetActivity?.activityid
                        ] ? (
                          <div className="dropdown-option">
                            <div className="dropdown-firstwrapper">
                              <span className="item-title">
                                {
                                  selectedOptionForActivities?.[
                                    targetActivity?.activityid
                                  ]?.room_type_excursion_option
                                }
                              </span>
                              <span className="item-subtitle">
                                (
                                {
                                  selectedOptionForActivities?.[
                                    targetActivity?.activityid
                                  ]?.board_basis_ticket_option
                                }
                                )
                              </span>
                            </div>
                            <span className="item-price">
                              {
                                getPriceForCountry(
                                  selectedOptionForActivities?.[
                                    targetActivity?.activityid
                                  ]
                                ).currency
                              }{" "}
                              {
                                getPriceForCountry(
                                  selectedOptionForActivities?.[
                                    targetActivity?.activityid
                                  ]
                                ).price
                              }
                            </span>
                          </div>
                        ) : (
                          "Select Ticket Option"
                        )}
                      </div>

                      {isOpenForActivity && (
                        <div className="options-wrapper">
                          {targetActivity?.pricingData?.map((item, index) => (
                            <div
                              className="dropdown-option"
                              key={index}
                              onClick={() =>
                                handleSelectForActivities(
                                  item,
                                  targetActivity?.activityid
                                )
                              } // Pass activityId to identify which activity is being updated
                            >
                              <div className="dropdown-firstwrapper">
                                <span className="item-title">
                                  {item?.room_type_excursion_option}
                                </span>
                                <span className="item-subtitle">
                                  ({item?.board_basis_ticket_option})
                                </span>
                              </div>
                              <span className="item-price">
                                {getPriceForCountry(item).currency}{" "}
                                {getPriceForCountry(item).price}
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <TextBorderWrapper onClick={applyActivityGuests}>
                <TextBtnDiv
                  // disabled={changedAdults !== "0" ? false : true}
                  style={{ opacity: changedAdults !== "0" ? 1 : 0.5 }}
                >
                  <BsCheckCircleFill /> Apply
                </TextBtnDiv>
              </TextBorderWrapper>
            </Modal>
          </TextContainerDiv>
        </Container>
      </Root>
    </>
  );
};

export default Deals;
