import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { AuthContext, useAuth } from "../../ContextApi/Contextapi";
import AddIcon from "@mui/icons-material/Add";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import Swal from "sweetalert2";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Timer from "./Timer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import { styled as styleMat } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LoginPopup from "../Pages/Login/LoginPopup";
import { OTPSchema } from "./Schema/OTPSchema";
import { environmentVariables } from "../../config/config";
import { useFormik } from "formik";
import {
  Button as MIButton,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Checkbox } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import "./HotelAccordian.css";
import {
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

const GuestDialog = styleMat(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function GuestDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Root = styled.div`
  margin-top: -50px;
`;
const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  /* padding: 40px; */
  box-shadow: rgba(73, 73, 73, 0.15) 0px 0px 8px 1px;
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 90vw;
    display: block;
  }
`;

const HeadText = styled.div`
  font-size: 24px;
  text-transform: capitalize;
  color: #d7810d;
  font-weight: 700;
  ${(p) =>
    p.ImportantImformation &&
    `
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 700; 
    padding:10px 0;
    `}
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const AccordianWrapper = styled.div`
  width: 100%;
`;
const FirstSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 7px;
  padding-top: 0;
  @media (max-width: 768px) {
    padding: 0;
  }
`;
const HotelNameTextTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 30px;

  ${(p) =>
    p.SecondAccordian &&
    `
   padding:15px 15px;
`}
  @media (max-width:768px) {
    padding: 0%;
  }
`;
const HotelNameText = styled.text`
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
const TravellerDetailsText = styled.div`
  font-size: 24px;
  text-transform: capitalize;
  color: #051036;
  font-weight: 700;
`;
const TravellerInfoText = styled.div`
  font-size: 14px;
  line-height: 11px;
  color: #9b9b9b;
  ${(p) =>
    p.MealText &&
    `
padding-left:10px;
margin-left: 0px;
`}
  ${(p) =>
    p.Information &&
    `
padding-left:10px;
margin-left: 0px;
`}
${(p) =>
    p.SecondAccordian &&
    `
font-size: 16px;
font-weight: 500;
padding: 0px 15px 20px;
margin-left: 0px;
`}

@media (max-width:768px) {
    line-height: 14px;
    margin: 0;
    padding: 5px 0;
    font-size: 12px;
  }
`;
const Button = styled.button`
  /* width: 140px; */
  padding: 5px 20px;
  border: none;
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
  border-color: rgb(231, 231, 231);
  background-color: #051036;
  margin: 0px 0 0px 0;
  margin-left: 40px;
  ${(p) =>
    p.opennewaccordian &&
    `
    background-color: #008000;
    color: white;
    padding: 10px 0;
    margin: 10px;
    font-size: 16px;
    border-radius:5px;
`}
  @media (max-width:768px) {
    /* width: 96%; */
    padding: 10px 10px 10px 32px;
    font-size: 12px;
    margin-left: 20px;
  }
`;

const AddressText = styled.div`
  font-size: 14px;
  line-height: 11px;
  padding-top: 10px;
  /* margin-left: 25px; */
  color: #fff;
  ${(p) =>
    p.MealText &&
    `
padding-left:10px;
margin-left: 0px;
`}
  ${(p) =>
    p.Information &&
    `
padding-left:10px;
margin-left: 0px;
`}
${(p) =>
    p.SecondAccordian &&
    `
font-size: 16px;
font-weight: 500;
padding: 20px 15px;
margin-left: 0px;
`} 
@media (max-width:768px) {
    line-height: 20px;
    margin: 0;
    padding: 0;
  }
`;
const ListText = styled.div`
  font-size: 15px;
  line-height: 11px;
  padding-left: 10px;
  margin-left: 0px;
  @media (max-width: 768px) {
    line-height: 18px;
  }
`;

const SecondSectionWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  /* border-bottom: 1px dotted #ccc; */
  /* border-top: 1px dotted #ccc; */
  /* background-color: #f2f2f266; */
  padding: 10px 15px;
  border-top: 4px solid white;
  margin: 10px 0;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0;
    margin: 0;
  }
`;
const CheckInOutWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const CheckInWrapper = styled.div`
  /* padding: 0 30px; */
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    display: flex;
    width: 93%;
    flex-direction: column;
    margin: 10px 0 !important;
    align-items: flex-start;
  }
`;
const ThirdSection = styled.div`
  /*padding: 10px 15px;*/
  padding: 10px 60px;
  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;
const chechBoxClass = {
  // margin: "0px 5px 0px 15px"
  marginRight: "3px",
};
const InclusionWrapper = styled.div``;

const PointWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
  @media (max-width: 768px) {
    align-items: flex-start;
    // display: inline-block;
  }
  @media (max-width: 600px) {
    align-items: flex-start;
    display: inline-block;
  }
`;

const FourthSection = styled.div`
  background-color: #fff8ed;
  border-bottom: 1px dashed #ffc4c2;
  border-top: 1px dashed #ffc4c2;
  padding: 0 40px 10px 70px;
  color: black;
  @media (max-width: 768px) {
    font-size: 10px;
    padding: 0 40px 10px 20px;
  }
`;
const DotWrapper = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 20px;
  background-color: #000;
`;
const DotWrapperContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
`;

const InputFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  // ${(p) => p.Secondone && `max-height:200px;min-height:200px;`}
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;
const InputField = styled.input`
  border: 1px solid #c7c6c6;
  padding: 10px 14px;
  margin-top: 0px;
  border-radius: 8px;
  width: 100%;
  @media (max-width: 768px) {
    margin-top: 5px;
  }
`;

const SecondAccordianWrapper = styled.div`
  /* margin: 15px 10px; */
  padding-top: 24px;
`;

const ContinueButton = styled.div`
  width: 20%;
  background-color: green;
  justify-content: center;
  display: flex;
  padding: 10px;
  margin: 15px 20px;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 100%;
    padding: 15px 20px;
    margin: 0;
    margin-top: 10px;
  }
`;
const FirstContainer = styled.div`
  background-color: #1676c3;
  padding: 40px 20px 20px 60px;
  @media (max-width: 768px) {
    padding: 20px 20px 0 20px;
  }
`;

const SecondContainer = styled.div`
  padding: 0 40px 10px 40px;
  background-color: #fff;
  @media (max-width: 768px) {
    padding: 0 10px 20px;
  }
`;
const HotelInfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const ErrorMessage = styled.text`
  color: red;
  font-size: 12px;
  padding: 4px 0;
`;

const VerifiedIconWrapper = styled.div`
  position: relative;
`;

const FormWrapper = styled.div``;
const TableHeadStyledUpdate = styled(TableHead)`
  width: 100%;
  @media (max-width: 768px) {
    display: none !important;
  }
`;
const TableRowStyledUpdate = styled(TableRow)`
  width: 100% !important;
  @media (max-width: 768px) {
    display: flex !important;
    flex-direction: column !important;
  }
`;

const GuestDialogTitleStyledUpdate = styled(GuestDialogTitle)`
  background: rgb(141 153 194 / 83%);
  @media (max-width: 768px) {
    display: flex !important;
    flex-direction: column !important;
  }
`;

const TimeInput = styled.input`
  /* Add your styles here */
  padding: 0.5rem;
  font-size: 1rem;
  height: 45px;
  border: none;
`;
const DateTimeActivities = styled.div`
  display: flex;
`;
const GestForm = styled.div``;
const InnerGuestForm = styled.div`
  display: flex;
  flex-direction: column;
`;
const InnerGuestForm2 = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`;
const MainDetailsWrapper = styled.div`
  display: flex;

  ${(p) =>
    p.mtop &&
    `
  margin-top: 10px;
  @media(max-width: 500px){
    display: flex;
    flex-direction: column;
    paddint-top:10px;
  }
  `}
  ${(p) =>
    p.Jc &&
    `
  justify-content: space-between;
      gap: 5px;
  @media(max-width: 500px){
    display: flex;
    flex-direction: column;
  }
  `}
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${(p) =>
    p.title &&
    `width:20%;
  @media(max-width: 500px){
    width:25%;
  }
  `}
  ${(p) =>
    p.GuestFirstNameWrapper &&
    `
  width:40%;
  @media(max-width: 500px){
    width:100%;
    padding:12px 0;
  }
  `}
  ${(p) =>
    p.GuestLastNameWrapper &&
    `
  width:40%;
  @media(max-width: 500px){
    width:100%;
  }
  `}
`;
const TitleHead = styled.div``;
const MainRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const MainCell = styled.div``;
const HeadingInfo = styled.div`
  margin-bottom: 2px;
  font-size: 18px;
  padding: 0px 10px;
  color: rgb(43 61 121 / 83%);
  font-weight: 500;
`;
const HeadinginallMasterGuests = styled.div`
  margin: 10px 0px 0px 10px;
  font-weight: 600;
  font-size: larger;
`;

const RadioOption = styled.label`
  margin-bottom: 8px;
  margin-left: 10px;
  display: flex;
  align-items: center;
`;
const ActivitiesPara = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin: 1px;
  margin: 0 10px;
  @media (max-width: 1040px) {
    margin: 0px;
  }
  @media (max-width: 768px) {
    margin: 0px;
  }
`;

const RadioInput = styled.input`
  margin-right: 8px;
`;

const StyledSelectContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const CustomSelect = styled.select`
  padding-right: 25px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
`;

const IconWrapper = styled.span`
  position: absolute;
  top: 50%;
  right: 5px; /* Adjust the right position as needed */
  transform: translateY(-50%);
  color: #fff;
  z-index: 1;
`;

const DatePickerDateandTime = styled(DatePicker)`
  padding-left: 0px !important;
`;

const ActivityTableHead = styled.th`
  ${(p) =>
    p.DateAndMember &&
    `
@media(max-width: 976px){
display: none;
}
`}
`;

const ActivityTableRowMobileView = styled.tr`
  @media (max-width: 976px) {
    display: flex;
    flex-direction: column;
  }
`;

const AdultsInputfield = styled.input`
  margin-left: 10px;
  @media (max-width: 976px) {
    border: none;
  }
  @media (max-width: 425px) {
    border: none;
    padding: 0 0 0 3px;
    margin-left: 5px;
  }
`;

const TableDataNameDateMember = styled.td`
  ${(p) =>
    p.TableDataName &&
    `
@media(max-width: 976px){
padding-bottom: 0px !important;
}

`}
  ${(p) =>
    p.TableDataDate &&
    `
@media(max-width: 976px){
  padding-bottom: 0px !important;
  padding-left: 0px;
  }

`}
${(p) =>
    p.TableDataMember &&
    `
@media(max-width: 976px){
  padding-top: 0px !important;
  }

`}
`;
// const ChildrenInputfield = styled.input`
// margin-left:10px;
// `;
// const InfantsInputfield = styled.input`
// margin-left:10px;
// `;

const DateTimePickerDiv = styled.div`
  // border: 1px solid #cccccc;
  width: 105px;
  border-radius: 5px @media (max-width: 976px) {
    border: none !important;
  }
`;

const BootstrapDialog = styleMat(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
const HotelAccordian = ({ setLoggedIn }) => {
  const baseUrl = environmentVariables.apiUrl;
  const { state } = useLocation();
  const getCountryCode = localStorage.getItem("countryISOCode");
  const country_code_for_pricing = localStorage?.getItem(
    "countryCodeForPricing"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [otpSuccess, setOTPSuccess] = useState(false);
  const [totalPrice, setTotalPrice] = useState();
  const [showOtp, setShowOtp] = useState(false);
  const [error, setError] = useState();
  const [nameerror, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [numberError, setNumberError] = useState(null);
  const [otpError, setOtpError] = useState();
  const [loading, setLoading] = React.useState(false);
  const [error1, setError1] = useState();
  const [error2, setError2] = useState();
  const [otp, setOtp] = useState();
  const [history, setHistory] = useState();
  // const { authData, setAuthData } = useContext(AuthContext);
  const [expanded, setExpanded] = useState("panel1");
  const [diff, setDiff] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [otPLengthError, setOTPLengthError] = useState("");
  const [selecetdGuestList, setSelectedGuestList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [guestOpen, setGuestOpen] = useState(false);

  const [guestTitle, setGuestTitle] = useState("Mr");
  const [guestFirstName, setGuestFirstName] = useState("");
  const [guestLastName, setGuestLastName] = useState("");
  const [guestGroupAge, setGuestGroupAge] = useState("0-5");
  const [guestBellow12year, setGuestbellow12year] = useState(0);

  const [guestTitleError, setGuestTitleError] = useState("");
  const [guestFirstNameError, setGuestFirstNameError] = useState("");
  const [guestBellow12yearError, setGuestBellow12yearError] = useState("");

  const [isLoadingSaveGuestButton, setIsLoadingSaveGuestButton] =
    useState(false);
  const [editGuest, setEditGuest] = useState(null);
  const [selectedGuets, setSelectedGuests] = useState([]);
  const [selectedGuestsForChildren, setSelectedGuestsForChildren] = useState(
    []
  );
  const [selectedGuestsForInfants, setSelectedGuestsForInfants] = useState([]);

  const [isMySelf, setIsMySelf] = useState(true);
  const [selectedOption, setSelectedOption] = useState("adults");

  document.title =
    state?.hotelData?.hotelname !== undefined
      ? state?.hotelData?.hotelname
      : "";
  const [timer, setTimer] = useState(120);
  const [allMasterGuests, setAllMasterGuests] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState(
    state?.selectedActivities
  );
  const { authData } = useAuth();
  // console.log("authData", authData?.country);
  const country_code = authData?.country == "India" ? "IN" : "AED";
  const options = [
    { label: "Adults", value: "adults" },
    { label: "Children (2y-17y)", value: "bellow18year" },
    { label: "Infants (Below 2y)", value: "bellow2year" },
  ];

  const handleOptionChange = (value) => {
    setSelectedOption(value);

    setGuestTitle(
      value === "bellow2year" || value === "bellow18year" ? "Master" : "Mr"
    );

    // setGuestTitle(e.target.checked === true ? "Master" : "Mr");
    // onChange(value);
  };

  useEffect(() => {
    const checkInTime = moment(state.formData.checkIn, "DD/MM/YYYY").valueOf();
    const checkoutTime = moment(
      state.formData.checkOut,
      "DD/MM/YYYY"
    ).valueOf();
    setDiff(checkoutTime - checkInTime / (1000 * 3600 * 24));
  }, [diff]);

  const navigation = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const handleClose = () => {
    setOpenAlert(false);
    setShowPopup(!showPopup);
  };
  const handleCloseCrossIcon = () => {
    setOpenAlert(false);
  };
  const isLoggedIn = () => {
    if (authData) {
      return true;
    } else {
      return false;
    }
  };

  const initialValues = {
    adult: state?.formData?.adult,
    children: state?.formData?.children,
    infants: state?.formData?.infants,
    activityTime: state?.formData?.activityTime,
    rooms: Number(state?.formData?.rooms),
    checkIn: new Date(
      moment(state?.formData?.checkIn, "DD/MM/YYYY").format("MM/DD/YYYY")
    ),
    checkOut: new Date(
      moment(state?.formData?.checkOut, "DD/MM/YYYY").format("MM/DD/YYYY")
    ),
    title: "Mr",
    name: authData?.username,
    email: authData?.email,
    countryCode: "+91",
    number: authData?.mobileNumber,
    otp: "",
    rooms:
      state?.formData &&
      state?.formData?.rooms === undefined &&
      state?.hotelData?.type === "activity"
        ? 99999999999
        : +state?.formData?.rooms,
    isBreakfast: false,
    isLunch: false,
    isDinner: false,
    isGSTDetail: false,
    registrationNo: "",
    companyName: "",
    companyAddress: "",
  };

  let formik = useFormik({
    initialValues,
    validationSchema: OTPSchema,
    onSubmit: async (values, action) => {
      if (isLoading === false) {
        if (!isLoggedIn()) {
          authData === undefined && setOpenAlert(true);
          return;
        }
        setShowOtp(true);
        setIsLoading(true);
        await axios({
          url: `${baseUrl}auth/addcustomerdetails`,
          method: "post",
          data: {
            name: values.name,
            email: values.email,
            mobile: values.number,
          },
          headers: { _token: authData?.token },
        })
          .then((response) => {
            setOTPSuccess(true);
            Swal.fire({
              icon: "success",
              title: "Otp sent",
              timer: "1500",
            });
            setIsLoading(false);
            setTimer(120);
          })
          .catch((error) => {
            if (error.response.data.error === "Email Already exist") {
              setError("Email already Exist,Use Another Email");
            } else if (
              error.response.data ===
              "Too many requests, please try again later."
            ) {
              setError2(error.response.data);
            }
            setIsLoading(false);
          });
      }
    },
  });

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    formik;

  const paymentOnClick = async () => {
    if (isLoadingSubmit === false) {
      setIsLoadingSubmit(true);
      // if (otp !== undefined && otp.length !== 4) {
      //   setOTPLengthError("Otp must be 4 digit long");
      // }

      let requestData = {
        title: values.title,
        name: values.name,
        email: values.email,
        mobile: values.number,
        otp: otp,
        type: state.hotelData.type === "activity" ? "activity" : "hotel",
        countryCode: values.countryCode,
        isBreakfast: values.isBreakfast,
        isLunch: values.isLunch,
        isDinner: values.isDinner,
        isGSTDetail: values.isGSTDetail,
        registrationNo: values.registrationNo,
        companyName: values.companyName,
        companyAddress: values.companyAddress,
        // price: totalPrice,
        price:
          state.formData.rooms * state.price * diff -
          (state.formData.rooms * state.price * diff * 10) / 100,
        rooms: values.rooms,
        id: state.hotelData._id,
        priceRange: state.formData.priceRange,
        themeRange: state.formData.themes,
        adult: values.adult,
        children: values.children,
        checkIn: new Date(values.checkIn).getTime(),
        checkOut: new Date(values.checkOut).getTime(),
        guestDetails: selecetdGuestList,
        isCombined: false,
        activityTime: values.activityTime,
        infants: values.infants,
      };
      if (
        selectedActivities !== undefined &&
        selectedActivities &&
        selectedActivities.length
      ) {
        requestData.isCombined = selectedActivities.length ? true : false;
        requestData.activities = selectedActivities.map((item) => {
          return { ...item, date: new Date(item.date).getTime() };
        });
      }

      await axios({
        url: `${baseUrl}auth/verifyotpofphone`,
        method: "post",
        data: requestData,
        headers: { _token: authData?.token },
      })
        .then((response) => {
          setHistory(response.data.response);

          setIsLoadingSubmit(false);
          setShowOtp(false);
          Swal.fire({
            icon: "success",
            text: "Thank you for your enquiry. We'll respond within 30 minutes.",
          });

          values.name = "";
          values.email = "";
          values.number = "";
          setOtp("");
          // setName("");
          // setEmail("");
          // setNumber("");
          setError1("");
          setLoading(true);
          navigation("/bookinghistory");
        })
        .catch((error) => {
          setIsLoadingSubmit(false);
          if (error.response.data === "unauthorised user") {
            setOpenAlert(true);
          } else {
            if (error.response.data.message === "Invalid OTP") {
              setError1("Invalid OTP, Use valid OTP");
            } else if (
              error.response.data.message === `"OtpCode" is required`
            ) {
              setError1(error.response.data.message);
            } else if (`"OtpCode" is not allowed to be empty`) {
              setError1(error.response.data.message);
            }
          }
        });
    }
  };
  const proceedToPayment = async () => {
    //  console.log("eeeeeeeee",convertedPrice)
    if (isLoadingSubmit === false) {
      setIsLoadingSubmit(true);
      // console.log("000000000",documents)
      let hotelConvertedPrice = 0;
      if (selectedActivities.length > 0 && state.hotelData.type == "hotel") {
        const hotelPrice = state?.hotelData?.pricingData.find(
          (item) => item._id === state?.formData?.priceRange
        )?.price;

        hotelConvertedPrice += parseFloat(hotelPrice) * values?.rooms;
      } else if (state.hotelData.type == "activity") {
        hotelConvertedPrice = convertedPrice;
      } else {
        hotelConvertedPrice = convertedPrice;
      }

      // console.log("hotelConvertedPrice",selectedActivities)

      // const activityPriceMap = Object.fromEntries(
      //   documents
      //     .filter((doc) => doc.type === "activity")
      //     .map((doc) => [doc.hotelId, doc.convertedPrice])
      // );

      let requestData = {
        title: values.title,
        name: values.name,
        email: values.email,
        mobile: values.number,
        type:
          selectedActivities.length > 0 && state.hotelData.type == "hotel"
            ? "both"
            : state.hotelData.type === "activity"
            ? "activity"
            : "hotel",
        countryCode: values.countryCode,
        isGSTDetail: values.isGSTDetail,
        registrationNo: values.registrationNo,
        companyName: values.companyName,
        companyAddress: values.companyAddress,
        price: convertedPrice,
        hotel_id: state.hotelData._id,
        price_id: state.formData.priceRange,
        adult: values.adult,
        children: values.children,
        infants: values.infants,
        checkIn: new Date(values.checkIn).getTime(),
        checkOut: new Date(values.checkOut).getTime(),
        guestDetails: selecetdGuestList,
        isCombined: false,
        activityTime: values.activityTime,
        hotelAmount: hotelConvertedPrice,
        currency:
          authData && authData?.country == "India"
            ? "INR"
            : authData?.country == "United Arab Emirates"
            ? "AED"
            : country_code_for_pricing == "IN"
            ? "INR"
            : "AED",
      };
      if (state?.hotelData?.type != "activity") {
        requestData.rooms = values?.rooms;
      }
      // if (
      //   selectedActivities !== undefined &&
      //   selectedActivities &&
      //   selectedActivities.length
      // ) {
      //   requestData.isCombined = selectedActivities.length ? true : false;
      //   // requestData.activities = selectedActivities.map((item) => {
      //   //   return { ...item, date: new Date(item.date).getTime() };
      //   // });
      //   requestData.activities = selectedActivities.map((item) => ({
      //     ...item,
      //     date: new Date(item.date).getTime(),
      //     // activityAmount: activityPriceMap[item?.activityid] || null,
      //   }));
      // }
      if (selectedActivities !== undefined && selectedActivities.length) {
        requestData.isCombined = selectedActivities.length ? true : false;

        requestData.activities = selectedActivities.map((activity) => {
          const { changedPricingForActivities, adults, pricingData } =
            activity || {};

          // Find matching price in the pricingData array
          const activityPrice = pricingData?.find(
            (item) => item?._id === changedPricingForActivities
          )?.price;

          // Calculate the activityAmount (price * number of adults)
          const activityAmount = activityPrice
            ? parseFloat(activityPrice) * Number(adults)
            : null;

          return {
            ...activity,
            date: new Date(activity.date).getTime(), // Convert date to timestamp
            activityAmount, // Attach the calculated activityAmount
          };
        });
      }
      

      console.log(
        "rtttttttttttttt",
        requestData,
        // documents,
        selectedActivities,
        // activityPriceMap,
        state,
        "valuw==========",
        values,
        hotelConvertedPrice
      );

      await axios({
        url: `${baseUrl}auth/create_order`,
        method: "post",
        data: requestData,
        headers: { _token: authData?.token },
      })
        .then((response) => {
          console.log("response================", response?.data);
          setHistory(response.data.response);

          setIsLoadingSubmit(false);
          setShowOtp(false);
          // Swal.fire({
          //   icon: "success",
          //   text: "Thank you for your enquiry. We'll respond within 30 minutes.",
          // });

          values.name = "";
          values.email = "";
          values.number = "";
          setOtp("");
          // setName("");
          // setEmail("");
          // setNumber("");
          setError1("");
          setLoading(true);
          window.location.href = response?.data?.data?.url;
        })
        .catch((error) => {
          console.log("error----------", error?.response);
          setIsLoadingSubmit(false);
          if (error.response.data === "unauthorised user") {
            setOpenAlert(true);
          } else {
            if (error.response.data.message === "Invalid OTP") {
              setError1("Invalid OTP, Use valid OTP");
            } else if (
              error.response.data.message === `"OtpCode" is required`
            ) {
              setError1(error.response.data.message);
            } else if (`"OtpCode" is not allowed to be empty`) {
              setError1(error.response.data.message);
            }
          }
        });
    }
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isCtrlPressed = event.ctrlKey || event.metaKey; // Check if Ctrl key is pressed

    if (
      keyCode === 8 || // Allow backspace key (key code 8)
      keyCode === 46 || // Allow delete key (key code 46)
      (keyCode >= 48 && keyCode <= 57) || // Allow numeric keys (0-9)
      (isCtrlPressed && keyValue === "r") || // Allow Ctrl+R combination
      keyCode === 37 || // Allow left arrow key (key code 37)
      keyCode === 39 || // Allow right arrow key (key code 39)
      (isCtrlPressed && (keyValue === "c" || keyValue === "v")) // Allow Ctrl+C and Ctrl+V combinations
    ) {
      // Check for plus (+) and minus (-) symbols
      if (keyValue === "+" || keyValue === "-") {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  };

  const getAllGuest = async () => {
    await axios({
      url: `${baseUrl}auth/guests`,
      method: "get",
      headers: { _token: authData?.token },
    })
      .then((response) => {
        setAllMasterGuests(response.data.data);
      })
      .catch((error) => {
        setAllMasterGuests([]);
      });
  };

  const saveGuest = async ({
    title,
    firstName,
    lastName,
    guestBellowyear,
    groupAge,
  }) => {
    setIsLoadingSaveGuestButton(true);
    await axios({
      url: `${baseUrl}auth/guests`,
      method: "post",
      data: {
        title,
        firstName,
        lastName,
        bellowyear: guestBellowyear,
        groupAge,
      },
      headers: { _token: authData?.token },
    })
      .then((response) => {
        getAllGuest();
        setGuestTitle("Mr");
        setGuestFirstName("");
        setGuestLastName("");
        setGuestGroupAge("0-5");
        setSelectedOption("adults");

        setAllMasterGuests([response.data.data, ...allMasterGuests]);
        //  clear form data

        // setGuestbellow12year(0);

        setIsLoadingSaveGuestButton(false);
      })
      .catch((error) => {
        setIsLoadingSaveGuestButton(false);
      });
  };
  const handleGuestForm = async () => {
    if (guestTitle) {
      if (guestFirstName) {
        if (guestFirstName.length > 1) {
          setGuestFirstNameError("");
          await saveGuest({
            title: guestTitle,
            firstName: guestFirstName,
            lastName: guestLastName,
            guestBellowyear: selectedOption.toString(),
            groupAge: guestGroupAge,
          });
          getAllGuest();
        } else {
          setGuestFirstNameError("Name should be atleast 2 character");
        }
      } else {
        setGuestFirstNameError("First Name is required");
      }
    } else {
      setGuestTitleError("Title is required");
    }
  };
  const updateGuest = async ({
    title,
    firstName,
    lastName,
    guestBellowyear,
    groupAge,
  }) => {
    // update Guest
    setIsLoadingSaveGuestButton(true);
    await axios({
      url: `${baseUrl}auth/guests/${editGuest._id}`,
      method: "put",
      data: {
        title,
        firstName,
        lastName,
        bellowyear: guestBellowyear,
        groupAge,
      },
      headers: { _token: authData?.token },
    })
      .then((response) => {
        getAllGuest();
        //  clear form data
        setGuestTitle("Mr");
        setGuestFirstName("");
        setGuestLastName("");
        setGuestGroupAge("0-5");
        setGuestbellow12year(0);

        setIsLoadingSaveGuestButton(false);

        setEditGuest(null);
      })
      .catch((error) => {
        setIsLoadingSaveGuestButton(false);
      });
  };
  const handleUpdateGuest = async () => {
    if (guestTitle) {
      if (guestFirstName) {
        if (guestFirstName.length > 1) {
          setGuestFirstNameError("");
          await updateGuest({
            title: guestTitle,
            firstName: guestFirstName,
            lastName: guestLastName,
            guestBellowyear: selectedOption.toString(),
            groupAge: guestGroupAge,
          });
        } else {
          setGuestFirstNameError("Name should be atleast 2 character");
        }
      } else {
        setGuestFirstNameError("Name is required");
      }
    } else {
      setGuestTitleError("Title is required");
    }
  };
  const editHandler = (row) => {
    // console.log("roe",row)
    setEditGuest(row);
    setGuestTitle(row.title);
    setGuestFirstName(row.firstName);
    setGuestLastName(row.lastName);
    setSelectedOption(row.bellowyear);
    setGuestGroupAge(row?.groupAge);
  };

  const deleteGuest = async (id) => {
    await axios({
      url: `${baseUrl}auth/guests/${id}`,
      method: "delete",
      headers: { _token: authData?.token },
    })
      .then((response) => {
        getAllGuest();
      })
      .catch((error) => {});
  };
  // console.log("123123123",selecetdGuestList.length,Number(values.adult),Number(values.children),Number(values.infants))
  const selectGuestHandler = (event, row) => {
    if (event.target.checked) {
      // If checked is true
      let adult = Number(values.adult) > 0 ? Number(values.adult) : 0;
      let totalGuestAddPermission = adult;

      // const totalGuestAddPermission = Number(values.adults);
      if (selectedGuets.length >= totalGuestAddPermission) {
        // If the limit is reached, remove the oldest guest(s)
        if (row.bellowyear === "adults") {
          const guestsToRemove =
            selectedGuets.length - totalGuestAddPermission + 1;
          let newSelectedGuests = [...selectedGuets.slice(guestsToRemove), row];
          setSelectedGuests(newSelectedGuests);
        } else {
          console.log(
            "Selected guest doesn't meet the age criteria or no room for adults."
          );
        }
      } else {
        // Select one guest
        setSelectedGuests([...selectedGuets, row]);
      }
    } else {
      // If checked is false => remove a guest from the selected guests
      let filterResultData = selectedGuets.filter(
        (item) => item._id !== row._id
      );
      setSelectedGuests(filterResultData);
    }
  };
  const selectGuestHandlerChild = (event, row) => {
    const childLimit = Number(values.children) || 0;

    if (event.target.checked) {
      // If checked is true
      if (childLimit > 0) {
        // If the limit is not 0, add the selected child
        setSelectedGuestsForChildren((prevSelected) => {
          const newSelected = [...prevSelected, row];
          // If the limit is reached, remove the oldest guest
          if (newSelected.length > childLimit) {
            return newSelected.slice(1);
          }
          return newSelected;
        });
      } else {
        // If the limit is 0, do nothing
        console.log("childLimit is 0");
      }
    } else {
      // If checked is false => remove a guest from the selected guests
      const filterResultData = selectedGuestsForChildren.filter(
        (item) => item._id !== row._id
      );
      setSelectedGuestsForChildren(filterResultData);
    }
  };
  const selectGuestHandlerInfants = (event, row) => {
    const infantLimit = Number(values.infants) || 0;

    if (event.target.checked) {
      // If checked is true
      if (infantLimit > 0) {
        // If the limit is not 0, add the selected child
        setSelectedGuestsForInfants((prevSelected) => {
          const newSelected = [...prevSelected, row];
          // If the limit is reached, remove the oldest guest
          if (newSelected.length > infantLimit) {
            return newSelected.slice(1);
          }
          return newSelected;
        });
      } else {
        // If the limit is 0, do nothing
        console.log("infantLimit is 0");
      }
    } else {
      // If checked is false => remove a guest from the selected guests
      const filterResultData = selectedGuestsForInfants.filter(
        (item) => item._id !== row._id
      );
      setSelectedGuestsForInfants(filterResultData);
    }
  };

  const selectedGuestHandler = () => {
    setGuestOpen(false);

    const combinedSelectedGuests = [
      ...selectedGuets,
      ...selectedGuestsForChildren,
      ...selectedGuestsForInfants,
    ];

    setSelectedGuestList(combinedSelectedGuests);
  };
  const handleCheckInChange = (date) => {
    formik.setFieldValue("checkIn", date);
    if (values.checkOut && date > values.checkOut) {
      formik.setFieldValue("checkOut", null);
    }
  };
  const handleTimeChangeIndividual = (e) => {
    formik.setFieldValue("activityTime", e.target.value);
  };
  const handleCheckOutChange = (date) => {
    // let checkOutDate = moment(date);
    formik.setFieldValue("checkOut", date);
  };

  // Function to add a new row
  const addRow = () => {
    setGuestOpen(true);
    // setGuests([...guests, { id: guests.length + 1, name: '', age: '', gender: "" }]);
  };

  // Function to delete a row
  const deleteRow = (row) => {
    const updatedRows = selecetdGuestList.filter(
      (item) => item._id !== row._id
    );
    setSelectedGuestList(updatedRows);
    // setSelectedGuests(updatedRows);

    const adults = updatedRows.filter((item) => item.bellowyear === "adults");
    const bellow18 = updatedRows.filter(
      (item) => item.bellowyear === "bellow18year"
    );
    const bellow2 = updatedRows.filter(
      (item) => item.bellowyear === "bellow2year"
    );

    // Update the individual state arrays
    setSelectedGuests(adults);
    setSelectedGuestsForChildren(bellow18);
    setSelectedGuestsForInfants(bellow2);
  };
  const handleGuestClose = () => {
    setGuestOpen(false);
  };
  useEffect(() => {
    if (isMySelf) {
      formik.setFieldValue("name", authData?.username, true);
      formik.setFieldValue("email", authData?.email, true);
      formik.setFieldValue("number", authData?.mobileNumber, true);
    } else {
      formik.setFieldValue("name", "", true);
      formik.setFieldValue("email", "", true);
      formik.setFieldValue("number", "", true);
    }
    formik.setFieldValue("countryCode", "+91", true);
    formik.setFieldValue("title", "Mr", true);
  }, [isMySelf, authData]);
  const handleRemoveErrors = () => {
    formik.setErrors({});
    formik.setTouched({});
  };
  const handleKeyPressOfAdult = (e) => {
    var key = e.which || e.KeyCode;
    if (key >= 48 && key <= 57) {
      return true;
    } else {
      return false;
    }
  };

  const handleCheckedBellow = (e) => {
    setGuestbellow12year(e.target.checked === true ? 1 : 0);
    setGuestTitle(e.target.checked === true ? "Master" : "Mr");
  };

  const setDateforEveryActivities = async (date, row) => {
    let targetActivity = row;
    let allSelctedActivities = [];

    for (let index = 0; index < selectedActivities.length; index++) {
      const element = selectedActivities[index];

      if (element.activityid === targetActivity.activityid) {
        let updatedValue = { ...targetActivity, date: date };
        allSelctedActivities.push(updatedValue);
      } else {
        allSelctedActivities.push(element);
      }
    }
    setSelectedActivities(allSelctedActivities);
  };
  const handleActivityDateChange = (date, row) => {
    setDateforEveryActivities(date, row);
  };
  const handleTimeChange = (event, row) => {
    // setSelectedTime(event.target.value);
    // setTargetActivity(row);
    // setChangedAdults(row.adults);
    // setChangedChildren(row.child);

    let targetActivity = row;
    let allSelctedActivities = [];
    for (let index = 0; index < selectedActivities.length; index++) {
      const element = selectedActivities[index];
      if (element.activityid === targetActivity.activityid) {
        let updatedValue = {
          ...targetActivity,
          activityTime: event.target.value,
        };
        allSelctedActivities.push(updatedValue);
      } else {
        allSelctedActivities.push(element);
      }
    }
    setSelectedActivities(allSelctedActivities);
  };
  const handleActivityAdultChange = (event, row) => {
    let targetActivity = row;
    let allSelctedActivities = [];
    for (let index = 0; index < selectedActivities.length; index++) {
      const element = selectedActivities[index];
      if (element.activityid === targetActivity.activityid) {
        let updatedValue = {
          ...targetActivity,
          adults: event.target.value,
        };
        allSelctedActivities.push(updatedValue);
      } else {
        allSelctedActivities.push(element);
      }
    }
    setSelectedActivities(allSelctedActivities);
  };
  // console.log("allSelctedActivities",selectedActivities)

  const handleActivityChildrenChange = (event, row) => {
    let targetActivity = row;
    let allSelctedActivities = [];
    for (let index = 0; index < selectedActivities.length; index++) {
      const element = selectedActivities[index];
      if (element.activityid === targetActivity.activityid) {
        let updatedValue = {
          ...targetActivity,
          child: event.target.value,
        };
        allSelctedActivities.push(updatedValue);
      } else {
        allSelctedActivities.push(element);
      }
    }
    setSelectedActivities(allSelctedActivities);
  };
  const handleActivityInfantChange = (event, row) => {
    let targetActivity = row;
    let allSelctedActivities = [];
    for (let index = 0; index < selectedActivities.length; index++) {
      const element = selectedActivities[index];
      if (element.activityid === targetActivity.activityid) {
        let updatedValue = {
          ...targetActivity,
          infants: event.target.value,
        };
        allSelctedActivities.push(updatedValue);
      } else {
        allSelctedActivities.push(element);
      }
    }
    setSelectedActivities(allSelctedActivities);
  };
  const handleKeyPressText = (e) => {
    const onlyLettersRegex = /^[A-Za-z\s]+$/;

    if (!onlyLettersRegex.test(e.key)) {
      e.preventDefault();
    }
  };

  const loadAllcountries = () => {
    let config = {
      method: "get",
      url: `${baseUrl}auth/getallcountries`,
    };
    axios
      .request(config)
      .then((response) => {
        let responseData = response?.data?.data?.filter((item) => {
          return item?.isoCode == "AE" || item?.isoCode == "IN";
        });

        // setAllCountries(response.data.data);
        setCountries(responseData);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    getAllGuest();
    loadAllcountries();
  }, [authData]);

  const [documents, setDocuments] = useState([]);
  const [convertedPrice, setConvertedPrice] = useState();
  const [ids, setIds] = useState([]);

  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (selectedActivities.length > 0 && state.hotelData.type == "hotel") {
      let totalPrice = 0;

      const { pricingData } = state?.hotelData || {};
      const { priceRange } = state?.formData || {};
      const { rooms } = values || {};
      if (pricingData && priceRange && rooms) {
        const hotelPrice = pricingData.find(
          (item) => item._id === priceRange
        )?.price;

        if (hotelPrice) {
          totalPrice += parseFloat(hotelPrice) * rooms;
        }
      }

      selectedActivities.forEach((activity) => {
        const { changedPricingForActivities, adults, pricingData } =
          activity || {};

        if (activity) {
          const activityPrice = pricingData?.find(
            (item) => item?._id === changedPricingForActivities
          )?.price;

          if (activityPrice) {
            // Multiply the price by the number of adults and add it to the total price
            totalPrice += parseFloat(activityPrice) * Number(adults);
          }
        }
      });

      setConvertedPrice(Number(totalPrice)?.toFixed(2));
    } else if (state.hotelData.type === "activity") {
      const { pricingData } = state?.hotelData || {};
      const mainId = state?.formData?.priceRange || {};

      if (pricingData && mainId) {
        // Find the matching pricing data object by _id
        const matchingPrice = pricingData.find(
          (item) => item._id === mainId
        )?.price;

        if (matchingPrice) {
          setConvertedPrice(
            (Number(matchingPrice) * Number(values?.adult))?.toFixed(2)
          );
        } else {
          setConvertedPrice();
        }
      }
    } else {
      const { pricingData } = state?.hotelData || {};
      const mainId = state?.formData?.priceRange || {};

      if (pricingData && mainId) {
        const matchingPrice = pricingData.find(
          (item) => item._id === mainId
        )?.price;

        if (matchingPrice) {
          setConvertedPrice(
            (Number(matchingPrice) * Number(values?.rooms))?.toFixed(2)
          );
        } else {
          setConvertedPrice();
        }
      }
    }
    // let allIds = [];
    // let activityIds = [];

    // if (state?.selectedActivities?.length > 0) {
    //   activityIds = state.selectedActivities.map(
    //     (activity) => activity.changedPricingForActivities
    //   );
    // }

    // // Get priceRangeId regardless of whether selectedActivities has elements
    // const priceRangeId = state?.formData?.priceRange;

    // // Combine all the ids into one array
    // if (priceRangeId) {
    //   allIds = [...activityIds, priceRangeId];
    // } else {
    //   allIds = [...activityIds];
    // }

    // // Store the ids in state
    // setIds(allIds);

    // const idsToFind = allIds;
    // let data = {
    //   ids: idsToFind,
    //   roomQty: values?.rooms,
    //   activityPersonNumbers: values?.adult,
    // };
    // if (selectedActivities?.length > 0) {
    //   // Extract all adults from selected activities
    //   const totalAdults = selectedActivities.reduce((acc, activity) => {
    //     return acc + (Number(activity?.adults) || 0); // Summing up adults
    //   }, 0);

    //   // Add the totalAdults to the data object
    //   data.totalAdults = totalAdults;
    // }
    // console.log("state7777", selectedActivities,state,data);

    // const config = {
    //   method: "post",
    //   url: `${baseUrl}auth/getpricingdata?country_code=${country_code}`,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: data,
    // };

    // const fetchData = async () => {
    //   try {
    //     const response = await axios.request(config);
    //     setDocuments(response?.data?.data);
    //     setConvertedPrice(response?.data?.totalConvertedPrice);
    //   } catch (error) {
    //     setDocuments([]);
    //     console.error("Error fetching pricing data:", error);
    //   }
    // };

    // fetchData();
  }, [authData, state, values?.rooms, selectedActivities, values?.adult]);

  console.log("state_tanuj", authData?.country, country_code_for_pricing);
  return (
    <Root>
      <Container>
        <AccordianWrapper>
          <FirstContainer>
            <FirstSectionWrapper>
              <HotelNameTextTextWrapper>
                <HotelInfoWrapper>
                  <HotelNameText>{state?.hotelData?.hotelname}</HotelNameText>
                  <Button className="btn-hotel">
                    {authData && authData?.country == "India"
                      ? "INR"
                      : authData?.country == "United Arab Emirates"
                      ? "AED"
                      : country_code_for_pricing == "IN"
                      ? "INR"
                      : "AED"}{" "}
                    {convertedPrice}
                  </Button>
                </HotelInfoWrapper>
                <AddressText>
                  {state?.hotelData?.area}, {state?.hotelData?.state}
                </AddressText>
              </HotelNameTextTextWrapper>
            </FirstSectionWrapper>
            <SecondSectionWrapper>
              <CheckInOutWrapper>
                {state?.formData?.checkOut === undefined ? (
                  <>
                    <CheckInWrapper>
                      <label className="text-light">Activity Date*</label>
                      <DatePicker
                        name="checkIn"
                        placeholderText=" Activity Date*"
                        selected={values.checkIn ? values.checkIn : null}
                        onChange={handleCheckInChange}
                        selectsStartcheckIn
                        autoComplete="off"
                        startDate={new Date(values.checkIn)}
                        endDate={new Date(values.checkOut)}
                        minDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      ></DatePicker>
                    </CheckInWrapper>

                    <CheckInWrapper>
                      <label className="text-light">Activity Time*</label>
                      <TimeInput
                        type="time"
                        id="activityTime"
                        name="activityTime"
                        value={values.activityTime || "11:00"}
                        onChange={handleTimeChangeIndividual}
                      />
                    </CheckInWrapper>
                  </>
                ) : (
                  <CheckInWrapper>
                    <label className="text-light">Check In*</label>
                    <DatePicker
                      name="checkIn"
                      placeholderText=" CheckIn*"
                      selected={values.checkIn ? values.checkIn : null}
                      onChange={handleCheckInChange}
                      selectsStartcheckIn
                      autoComplete="off"
                      startDate={new Date(values.checkIn)}
                      endDate={new Date(values.checkOut)}
                      minDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                    ></DatePicker>
                  </CheckInWrapper>
                )}
                {state?.formData?.checkOut !== undefined && (
                  <CheckInWrapper>
                    <label className="text-light">Check Out*</label>
                    <DatePicker
                      name="checkOut"
                      placeholderText="CheckOut*"
                      selected={
                        values.checkOut
                          ? new Date(
                              moment(values.checkOut, "DD/MM/YYYY").format(
                                "MM/DD/YYYY"
                              )
                            )
                          : null
                      }
                      autoComplete="off"
                      onChange={handleCheckOutChange}
                      startDate={new Date(values.checkIn)}
                      endDate={new Date(values.checkOut)}
                      minDate={values.checkIn}
                      dateFormat="dd/MM/yyyy"
                    ></DatePicker>
                  </CheckInWrapper>
                )}
                {state?.formData?.rooms !== undefined && (
                  <CheckInWrapper>
                    <label className="text-light">Rooms*</label>
                    <input
                      type="number"
                      name="rooms"
                      onChange={handleChange}
                      value={values.rooms}
                      className="form-control"
                      onKeyDown={handleKeyPress}
                      style={{ borderRadius: 0, padding: "10px" }}
                      placeholder="Rooms*"
                      onWheel={(e) => e.target.blur()}
                    />
                  </CheckInWrapper>
                )}
                <CheckInWrapper>
                  <label className="text-light">
                    {state.hotelData.type === "activity"
                      ? "No of Tickets"
                      : "Adults"}
                    *
                  </label>
                  <input
                    type="number"
                    placeholder={
                      state.hotelData.type === "activity"
                        ? "No of Tickets*"
                        : "Adults*"
                    }
                    value={values?.adult}
                    name="adult"
                    onChange={handleChange}
                    className="form-control"
                    onKeyDown={handleKeyPress}
                    onKeyPress={handleKeyPressOfAdult}
                    style={{ borderRadius: 0, padding: "10px" }}
                    onWheel={(e) => e.target.blur()}
                  />
                  {errors.adult && touched.adult ? (
                    <span className="text-danger pos-absolute">
                      {errors.adult}
                    </span>
                  ) : null}
                </CheckInWrapper>

                {state.hotelData.type !== "activity" && (
                  <>
                    <CheckInWrapper>
                      <label className="text-light">Children*</label>
                      <input
                        type="number"
                        name="children"
                        placeholder="Children*"
                        onChange={handleChange}
                        value={values.children}
                        className="form-control"
                        onKeyDown={handleKeyPress}
                        style={{ borderRadius: 0, padding: "10px" }}
                        onWheel={(e) => e.target.blur()}
                      />
                      {errors.children ? (
                        <span className="text-danger pos-absolute">
                          {errors?.children}
                        </span>
                      ) : null}
                    </CheckInWrapper>
                    <CheckInWrapper>
                      <label className="text-light">Infants*</label>
                      <input
                        type="number"
                        name="infants"
                        placeholder="Infants*"
                        onChange={handleChange}
                        value={values.infants}
                        className="form-control"
                        onKeyDown={handleKeyPress}
                        style={{ borderRadius: 0, padding: "10px" }}
                        onWheel={(e) => e.target.blur()}
                      />
                      {errors.infants ? (
                        <span className="text-danger pos-absolute">
                          {errors?.infants}
                        </span>
                      ) : null}
                    </CheckInWrapper>
                  </>
                )}
              </CheckInOutWrapper>
              {/* 
              {state?.formData.priceRange !== undefined && (
                <CheckInWrapper>
                  <label className="text-light">
                    Price Range
                  </label>
                  <input
                    type="text"
                    placeholder="Price*"
                    value={`${state.formData.priceRange}`}
                    className="form-control custom-chip bg-info"
                    onWheel={(e) => e.target.blur()}
                  />
                </CheckInWrapper>
              )} */}
            </SecondSectionWrapper>
          </FirstContainer>
          {state?.selectedActivities !== undefined &&
          state.selectedActivities.length ? (
            <ThirdSection>
              <InclusionWrapper>
                <PointWrapper>
                  <TravellerDetailsText>Activities</TravellerDetailsText>
                </PointWrapper>
                <PointWrapper>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <ActivityTableHead>Activity</ActivityTableHead>
                        <ActivityTableHead DateAndMember>
                          Date & Time
                        </ActivityTableHead>
                        <ActivityTableHead DateAndMember>
                          Members
                        </ActivityTableHead>
                        <ActivityTableHead DateAndMember>
                          Pricing
                        </ActivityTableHead>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedActivities.map((row, index) => {
                        return (
                          <ActivityTableRowMobileView key={index}>
                            <TableDataNameDateMember TableDataName>
                              <div>{row.activityName}</div>
                            </TableDataNameDateMember>
                            <TableDataNameDateMember TableDataDate>
                              {" "}
                              <DateTimeActivities>
                                <DateTimePickerDiv>
                                  <DatePickerDateandTime
                                    name="date"
                                    placeholderText="Date*"
                                    selected={row.date}
                                    onChange={(date) =>
                                      handleActivityDateChange(date, row)
                                    }
                                    minDate={values.checkIn}
                                    maxDate={values.checkOut}
                                    width="100px"
                                    dateFormat="dd/MM/yyyy"
                                  ></DatePickerDateandTime>
                                </DateTimePickerDiv>
                                <TimeInput
                                  type="time"
                                  // id="activityTime"
                                  // name="activityTime"
                                  value={row.activityTime}
                                  onChange={(e) => handleTimeChange(e, row)}
                                />
                              </DateTimeActivities>
                            </TableDataNameDateMember>
                            <TableDataNameDateMember
                              TableDataMember
                              className="member_activities"
                            >
                              <ActivitiesPara>
                                Adults{" "}
                                <AdultsInputfield
                                  type="number"
                                  name="activityadults"
                                  onChange={(event) =>
                                    handleActivityAdultChange(event, row)
                                  }
                                  value={row.adults}
                                  className="form-control"
                                  // onKeyDown={handleKeyPress}
                                  // style={{ borderRadius: 0, padding: "10px" }}
                                  placeholder="Adults*"
                                />
                              </ActivitiesPara>
                              <ActivitiesPara>
                                Children{" "}
                                <AdultsInputfield
                                  type="number"
                                  name="activitychildren"
                                  onChange={(event) =>
                                    handleActivityChildrenChange(event, row)
                                  }
                                  value={row.child}
                                  className="form-control"
                                  // onKeyDown={handleKeyPress}
                                  // style={{ borderRadius: 0, padding: "10px" }}
                                  placeholder="Children*"
                                />
                              </ActivitiesPara>
                              <ActivitiesPara>
                                Infants{" "}
                                <AdultsInputfield
                                  type="number"
                                  name="activityinfants"
                                  onChange={(event) =>
                                    handleActivityInfantChange(event, row)
                                  }
                                  value={row.infants}
                                  className="form-control" // onKeyDown={handleKeyPress}
                                  // style={{ borderRadius: 0, padding: "10px" }}
                                  placeholder="Infants*"
                                />
                              </ActivitiesPara>
                              {/* {Number(row.adults) + Number(row.child)} */}
                            </TableDataNameDateMember>
                            <TableDataNameDateMember
                              TableDataMember
                              className="member_activities"
                            >
                              <div>{row?.changedPricingForActivities}</div>
                            </TableDataNameDateMember>
                          </ActivityTableRowMobileView>
                        );
                      })}
                    </tbody>
                  </table>
                </PointWrapper>
              </InclusionWrapper>
            </ThirdSection>
          ) : null}
          {/* {state?.hotelData?.type !== "activity" && (
            <ThirdSection>
              <InclusionWrapper>
                <PointWrapper>
                  <TravellerDetailsText>Upgrade Your Stay</TravellerDetailsText>
                </PointWrapper>
                <PointWrapper PointWrapperStyled>
                  <label className="checkbox_inline">
                    <input
                      type="checkbox"
                      name="isBreakfast"
                      onChange={handleChange}
                      style={chechBoxClass}
                      onWheel={(e) => e.target.blur()}
                    />
                    Add&nbsp;<b>Breakfast</b>
                  </label>
                  <label className="checkbox_inline">
                    <input
                      type="checkbox"
                      name="isLunch"
                      onChange={handleChange}
                      style={chechBoxClass}
                      onWheel={(e) => e.target.blur()}
                    />
                    Add&nbsp;<b>Lunch</b>
                  </label>
                  <label className="checkbox_inline">
                    <input
                      type="checkbox"
                      name="isDinner"
                      onChange={handleChange}
                      style={chechBoxClass}
                      onWheel={(e) => e.target.blur()}
                    />
                    Add&nbsp;<b>Dinner</b>
                  </label>
                </PointWrapper>
              </InclusionWrapper>
            </ThirdSection>
          )} */}
          {/* <ThirdSection>
            <InclusionWrapper>
              <PointWrapper>
                <Icon>
                  <i className="fa-solid fa-xmark"></i>
                </Icon>
                <AddressText MealText>No meals included</AddressText>
              </PointWrapper>
              <PointWrapper>
                <Icon>
                  <i className="fa-solid fa-xmark"></i>
                </Icon>
                <AddressText MealText>
                  <Span>Non-Refundable </Span> | On Cancellation, You will not
                  get any refund{" "}
                  <Span
                    Cancellation
                    style={{ cursor: "pointer" }}
                    onClick={() => navigation("/cancellationpolicy")}
                  >
                    Cancellation policy details
                  </Span>
                </AddressText>
              </PointWrapper>
            </InclusionWrapper>
          </ThirdSection> */}
          <FourthSection>
            <HeadText ImportantImformation>Important information</HeadText>
            <DotWrapperContainer>
              <DotWrapper />
              <ListText Information>
                Accepted ID proofs: Passport, Aadhar, Driving License, and Govt.
                ID.
              </ListText>
            </DotWrapperContainer>
            <DotWrapperContainer>
              <DotWrapper />
              <ListText Information>
                Please note PAN cards and Non-Govt IDs are not valid proofs.
              </ListText>
            </DotWrapperContainer>
            <DotWrapperContainer>
              <DotWrapper />
              <ListText Information>
                Our property staff follows stringent hygiene guidelines.
              </ListText>
            </DotWrapperContainer>
            <DotWrapperContainer>
              <DotWrapper />
              <ListText Information>No entry for guests with fever.</ListText>
            </DotWrapperContainer>
          </FourthSection>
          <SecondContainer>
            <AccordionDetails>
              <SecondAccordianWrapper>
                <HotelNameTextTextWrapper SecondAccordian>
                  <TravellerDetailsText>
                    Traveller's Detail
                  </TravellerDetailsText>
                </HotelNameTextTextWrapper>
                <TravellerInfoText SecondAccordian>
                  {" "}
                  Your details will be utilized solely to communicate your
                  booking information.
                </TravellerInfoText>

                <FormWrapper>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    className="acoordian_i_am_looking_for"
                    sx={{ marginLeft: "20px" }}
                  >
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      I am booking for
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(e) => setIsMySelf(e.target.value === "myself")}
                    >
                      <FormControlLabel
                        checked={isMySelf}
                        onClick={handleRemoveErrors}
                        // onChange={(e) =>
                        //   setIsMySelf(e.target.checked ? true : false)
                        // }
                        value="myself"
                        control={<Radio />}
                        label="My Self"
                      />
                      <FormControlLabel
                        checked={!isMySelf}
                        // onChange={(e) =>
                        //   setIsMySelf(e.target.checked ? false : true)
                        // }
                        value="someoneelse"
                        control={<Radio />}
                        label="Someone Else"
                      />
                    </RadioGroup>
                  </FormControl>
                  <InputFieldWrapper>
                    <LabelWrapper>
                      <FormLabel>Full Name* </FormLabel>
                      <div className="input-group">
                        <div className="input-group-addon">
                          <select
                            className="addonSelect"
                            name="title"
                            onChange={handleChange}
                            value={values.title}
                          >
                            <option value={"Mr"}>Mr</option>
                            <option value={"Ms"}>Ms</option>
                            <option value={"Mrs"}>Mrs</option>
                            <option value={"Sheikh"}>Sheikh</option>
                          </select>
                        </div>
                        <input
                          type="text"
                          className="form-control addonInput"
                          placeholder="Full Name*"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onClick={() => setError("")}
                          onWheel={(e) => e.target.blur()}
                        />
                      </div>
                      {errors.name && touched.name ? (
                        <ErrorMessage>{errors.name}</ErrorMessage>
                      ) : null}
                      {/* <ErrorMessage>{nameerror ? nameerror : " "}</ErrorMessage> */}
                    </LabelWrapper>
                    <LabelWrapper>
                      <FormLabel>
                        Email Address*{" "}
                        <span className="noticSpan">
                          (Booking invoice will be sent to this email ID )
                        </span>
                      </FormLabel>
                      <InputField
                        type="email"
                        placeholder="Email*"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onClick={() => setError("")}
                      />
                      {errors.email && touched.email ? (
                        <ErrorMessage>{errors.email}</ErrorMessage>
                      ) : null}
                      {/* <ErrorMessage>
                        {emailError ? emailError : " "}
                      </ErrorMessage> */}
                    </LabelWrapper>
                  </InputFieldWrapper>
                  <InputFieldWrapper Secondone>
                    <LabelWrapper>
                      <FormLabel>Mobile Number* </FormLabel>
                      <VerifiedIconWrapper>
                        <div className="input-group">
                          <div className="input-group-addon">
                            <select
                              className="addonSelect"
                              name="countryCode"
                              onChange={handleChange}
                              value={values.countryCode}
                            >
                              {countries.map((row, index) => {
                                return (
                                  <option value={row.phonecode} key={index}>
                                    {row?.phonecode}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <input
                            className="form-control addonInput"
                            type="number"
                            placeholder="Mobile Number*"
                            name="number"
                            value={values.number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onClick={() => setError("")}
                            // onKeyDown={handleKeyPress}
                            // onPaste={handlePaste}
                            onPaste={(event) => {
                              const pastedText =
                                event.clipboardData.getData("text/plain");
                              if (!/^\d+$/.test(pastedText)) {
                                event.preventDefault();
                              }
                            }}
                            onKeyPress={handleKeyPress}
                            onWheel={(e) => e.target.blur()}
                          />
                        </div>
                        {errors.number && touched.number ? (
                          <ErrorMessage>{errors.number}</ErrorMessage>
                        ) : null}
                      </VerifiedIconWrapper>
                    </LabelWrapper>
                    {otpSuccess && showOtp && (
                      <LabelWrapper>
                        <FormLabel>OTP* </FormLabel>
                        <VerifiedIconWrapper>
                          <InputField
                            type="number"
                            placeholder="Enter OTP*"
                            name="otp"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            onClick={() => {
                              setError("");
                              setError1("");
                              setError2("");
                            }}
                            onKeyDown={handleKeyPress}
                          />
                          <p style={{ fontSize: "12px" }}>
                            OTP will expire in next 2 minutes
                          </p>
                          <p>
                            {timer === 0 ? (
                              <p
                                onClick={handleSubmit}
                                className="resend-button"
                              >
                                Resend OTP
                              </p>
                            ) : (
                              <Timer timer={timer} setTimer={setTimer} />
                            )}
                          </p>
                          {errors.otp && touched.otp ? (
                            <ErrorMessage>{errors.otp}</ErrorMessage>
                          ) : null}
                          <ErrorMessage>{error2 ? error2 : ""}</ErrorMessage>
                          <ErrorMessage>{error1 ? error1 : ""}</ErrorMessage>
                        </VerifiedIconWrapper>
                      </LabelWrapper>
                    )}
                  </InputFieldWrapper>

                  {/* <InputFieldWrapper>
                    <LabelWrapper>
                      <FormGroup>
                        <FormControlLabel
                          name="isGSTDetail"
                          onClick={handleChange}
                          control={<Checkbox checked={values.isGSTDetail} />}
                          label="Enter GST Details"
                        />
                      </FormGroup>
                    </LabelWrapper>
                  </InputFieldWrapper>
                  <InputFieldWrapper
                    style={{ display: values.isGSTDetail ? "" : "none" }}
                  >
                    <LabelWrapper>
                      <FormGroup>
                        <FormLabel>Registration Number</FormLabel>
                        <InputField
                          type="text"
                          placeholder="Registration Number"
                          name="registrationNo"
                          value={values.registrationNo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onClick={() => setError("")}
                        />
                        {errors.registrationNo && touched.registrationNo ? (
                          <ErrorMessage>{errors.registrationNo}</ErrorMessage>
                        ) : null}
                      </FormGroup>
                    </LabelWrapper>
                    <LabelWrapper>
                      <FormGroup>
                        <FormLabel>Registered Company Name</FormLabel>
                        <InputField
                          type="text"
                          placeholder="Registered Company Name"
                          name="companyName"
                          value={values.companyName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onClick={() => setError("")}
                        />
                        {errors.companyName && touched.companyName ? (
                          <ErrorMessage>{errors.companyName}</ErrorMessage>
                        ) : null}
                      </FormGroup>
                    </LabelWrapper>
                    <LabelWrapper>
                      <FormGroup>
                        <FormLabel>Registered Company Address</FormLabel>
                        <InputField
                          type="text"
                          placeholder="Registered Company Address"
                          name="companyAddress"
                          value={values.companyAddress}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onClick={() => setError("")}
                        />
                        {errors.companyAddress && touched.companyAddress ? (
                          <ErrorMessage>{errors.companyAddress}</ErrorMessage>
                        ) : null}
                      </FormGroup>
                    </LabelWrapper>
                  </InputFieldWrapper> */}

                  <InputFieldWrapper>
                    <Box
                      component="div"
                      sx={{ marginLeft: "20px", paddingTop: "20px" }}
                      className="accordian_add_guest"
                    >
                      <MIButton
                        variant="outlined"
                        size="small"
                        disabled={
                          values.rooms &&
                          (values.adult > 0 || values.children > 0)
                            ? false
                            : true
                        }
                        color="primary"
                        onClick={addRow}
                        startIcon={<AddIcon />}
                      >
                        Add guest
                      </MIButton>
                      <Table>
                        <TableBody>
                          {selecetdGuestList && selecetdGuestList.length > 0 ? (
                            selecetdGuestList.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{`${row.title} ${row.firstName} ${row.lastName}`}</TableCell>
                                <TableCell>
                                  <IconButton
                                    aria-label="delete"
                                    color="error"
                                    onClick={() => deleteRow(row)}
                                  >
                                    <RemoveCircleOutlineIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <></>
                          )}
                        </TableBody>
                      </Table>
                      <LoadingButton
                        variant="contained"
                        fullWidth={false}
                        className="sendenqirybtn"
                        disabled={
                          Object.keys(errors).length > 0 ||
                          selecetdGuestList.length !==
                            Number(values.adult) +
                              Number(values.children) +
                              Number(values.infants) ||
                          values.adult == 0
                        }
                        loading={isLoadingSubmit}
                        size="large"
                        color="primary"
                        // onClick={paymentOnClick}
                        onClick={proceedToPayment}
                        sx={{ marginTop: "5px" }}
                      >
                        Proceed to Pay{" "}
                        {authData && authData?.country == "India"
                          ? "INR"
                          : authData?.country == "United Arab Emirates"
                          ? "AED"
                          : country_code_for_pricing == "IN"
                          ? "INR"
                          : "AED"}{" "}
                        {convertedPrice}
                      </LoadingButton>
                    </Box>
                  </InputFieldWrapper>
                </FormWrapper>
              </SecondAccordianWrapper>
            </AccordionDetails>
          </SecondContainer>
        </AccordianWrapper>
        <BootstrapDialog
          onClose={handleCloseCrossIcon}
          aria-labelledby="customized-dialog-title"
          open={openAlert}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseCrossIcon}
          >
            Alert
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>Please Login to continue ...</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleClose}
              style={{
                width: "140px",
                border: "none",
                borderColor: "rgb(231,231,231)",
                backgroundColor: "#008000",
                color: "white",
                padding: "10px 0",
                margin: "10px",
                cursor: "pointer",
                fontSize: "16px",
                borderRadius: "8px",
              }}
            >
              Login
            </Button>
          </DialogActions>
        </BootstrapDialog>

        <GuestDialog
          fullWidth={true}
          maxWidth={"sm"}
          onClose={handleGuestClose}
          aria-labelledby="customized-dialog-title"
          open={guestOpen}
          sx={{ zIndex: 200 }}
        >
          {/* <GuestDialogTitleStyledUpdate
            id="customized-dialog-title"
            onClose={handleGuestClose}
          >
            Select Guests{" "}
          </GuestDialogTitleStyledUpdate> */}
          <DialogContent dividers>
            <Typography gutterBottom>
              {editGuest ? "Update Guest" : "Add New Guest"}
            </Typography>
            <GestForm>
              <InnerGuestForm>
                <MainDetailsWrapper Jc>
                  <TitleWrapper title>
                    <TitleHead>Title</TitleHead>
                    {selectedOption === "adults" ? (
                      <StyledSelectContainer>
                        <CustomSelect
                          className="form-control"
                          name="title"
                          value={guestTitle}
                          onChange={(e) => setGuestTitle(e.target.value)}
                        >
                          <option value={"Mr"}>Mr</option>
                          <option value={"Ms"}>Ms</option>
                          <option value={"Mrs"}>Mrs</option>
                          <option value={"Miss"}>Miss</option>
                          <option value={"Sheikh"}>Sheikh</option>
                        </CustomSelect>
                        <IconWrapper>
                          <i className="fa-solid fa-angle-down"></i>
                        </IconWrapper>
                      </StyledSelectContainer>
                    ) : selectedOption === "children" ? (
                      <select
                        className="form-control"
                        name="title"
                        value={guestTitle}
                        onChange={(e) => setGuestTitle(e.target.value)}
                      >
                        <option value={"Master"}>Master</option>
                      </select>
                    ) : (
                      <select
                        className="form-control"
                        name="title"
                        value={guestTitle}
                        onChange={(e) => setGuestTitle(e.target.value)}
                      >
                        <option value={"infants"}>Master</option>
                      </select>
                    )}
                  </TitleWrapper>

                  <TitleWrapper GuestFirstNameWrapper>
                    <TitleHead>First Name</TitleHead>

                    <input
                      type="text"
                      value={guestFirstName}
                      name="name"
                      onChange={(e) => setGuestFirstName(e.target.value)}
                      className="form-control"
                      placeholder=" first Name"
                      onKeyDown={handleKeyPressText}
                      onWheel={(e) => e.target.blur()}
                    />
                    <span className="text-danger customerrormsg">
                      {guestFirstNameError ? guestFirstNameError : ""}
                    </span>
                  </TitleWrapper>

                  <TitleWrapper GuestLastNameWrapper>
                    <TitleHead>Last Name</TitleHead>

                    <input
                      type="text"
                      value={guestLastName}
                      name="name"
                      onChange={(e) => setGuestLastName(e.target.value)}
                      className="form-control"
                      placeholder=" Last Name"
                      onKeyDown={handleKeyPressText}
                      onWheel={(e) => e.target.blur()}
                    />
                  </TitleWrapper>
                  <TitleWrapper GuestLastNameWrapper>
                    <TitleHead>Select Age Group(Yrs)</TitleHead>

                    <StyledSelectContainer>
                      <CustomSelect
                        className="form-control"
                        name="guestGroupAge"
                        value={guestGroupAge}
                        onChange={(e) => setGuestGroupAge(e.target.value)}
                      >
                        <option value={"0-5"}>0-5</option>
                        <option value={"5-10"}>5-10</option>
                        <option value={"10-15"}>10-15</option>
                        <option value={"15-20"}>15-20</option>
                        <option value={"20+"}>20+</option>
                        <option value={"60+"}>60+</option>
                      </CustomSelect>
                      <IconWrapper>
                        <i className="fa-solid fa-angle-down"></i>
                      </IconWrapper>
                    </StyledSelectContainer>
                  </TitleWrapper>
                </MainDetailsWrapper>

                {state?.hotelData?.type != "activity" && (
                  <MainDetailsWrapper Mtop>
                    {options.map((option) => (
                      <RadioOption key={option.value}>
                        <RadioInput
                          type="radio"
                          value={option.value}
                          checked={selectedOption === option.value}
                          onChange={() => handleOptionChange(option.value)}
                        />
                        {option.label}
                      </RadioOption>
                    ))}
                  </MainDetailsWrapper>
                )}

                <MainDetailsWrapper mtop>
                  {editGuest ? (
                    <LoadingButton
                      loading={isLoadingSaveGuestButton}
                      disabled={isLoadingSaveGuestButton}
                      onClick={handleUpdateGuest}
                      variant="contained"
                      size="small"
                      color="primary"
                    >
                      Update
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      loading={isLoadingSaveGuestButton}
                      disabled={isLoadingSaveGuestButton}
                      onClick={handleGuestForm}
                      variant="contained"
                      size="small"
                      color="primary"
                    >
                      Save
                    </LoadingButton>
                  )}
                </MainDetailsWrapper>
              </InnerGuestForm>
              <hr />
              <InnerGuestForm2>
                <HeadingInfo>
                  Please select{" "}
                  {/* {
                    state?.hotelData?.type != "activity" ? 
                  } */}
                  {state?.hotelData?.type !== "activity"
                    ? Number(values.adult) > 1
                      ? `${Number(values.adult)} Adults`
                      : `${Number(values.adult)} Adult`
                    : Number(values.adult) > 1
                    ? `${Number(values.adult)} Members`
                    : `${Number(values.adult)} Member`}{" "}
                  {Number(values.children) > 0 &&
                    (Number(values.children) > 1
                      ? `${Number(values.children)} Children`
                      : `${Number(values.children)} Child`)}{" "}
                  {Number(values.infants) > 0 &&
                    (Number(values.infants) > 1
                      ? `${Number(values.infants)} Infants`
                      : `${Number(values.infants)} Infant`)}
                </HeadingInfo>

                {state?.hotelData?.type !== "activity" && (
                  <HeadinginallMasterGuests>Adults</HeadinginallMasterGuests>
                )}
                {allMasterGuests?.adults &&
                allMasterGuests?.adults.length > 0 ? (
                  allMasterGuests?.adults.map((row, index) => {
                    const searchObject = row;
                    const foundObject = selectedGuets.find(
                      (obj) => obj._id === searchObject._id
                    );
                    return (
                      <MainRow>
                        <MainCell>
                          <Checkbox
                            checked={foundObject ? true : false}
                            onClick={(event) => {
                              selectGuestHandler(event, row);
                            }}
                          />
                          {row.title} {row.firstName} {row.lastName} (
                          {row?.groupAge})Yrs
                        </MainCell>
                        <MainCell>
                          <ButtonGroup
                            size="small"
                            aria-label="small button group"
                          >
                            <MIButton
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                editHandler(row);
                              }}
                            >
                              Edit
                            </MIButton>
                            <MIButton
                              variant="outlined"
                              color="secondary"
                              onClick={() => {
                                deleteGuest(row._id);
                              }}
                            >
                              delete
                            </MIButton>
                          </ButtonGroup>
                        </MainCell>
                      </MainRow>
                    );
                  })
                ) : (
                  <MainRow>
                    {" "}
                    <MainCell className="text-danger text-center">
                      No saved guests found
                    </MainCell>{" "}
                  </MainRow>
                )}

                {state?.hotelData?.type != "activity" && (
                  <>
                    <HeadinginallMasterGuests>
                      Children
                    </HeadinginallMasterGuests>
                    {allMasterGuests?.bellow18 &&
                    allMasterGuests?.bellow18.length > 0 ? (
                      allMasterGuests?.bellow18.map((row, index) => {
                        const searchObject = row;
                        const foundObject = selectedGuestsForChildren.find(
                          (obj) => obj._id === searchObject._id
                        );
                        return (
                          <MainRow>
                            <MainCell>
                              <Checkbox
                                checked={foundObject ? true : false}
                                onClick={(event) => {
                                  selectGuestHandlerChild(event, row);
                                }}
                              />
                              {row.title} {row.firstName} {row.lastName}
                            </MainCell>
                            <MainCell>
                              <ButtonGroup
                                size="small"
                                aria-label="small button group"
                              >
                                <MIButton
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    editHandler(row);
                                  }}
                                >
                                  Edit
                                </MIButton>
                                <MIButton
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => {
                                    deleteGuest(row._id);
                                  }}
                                >
                                  delete
                                </MIButton>
                              </ButtonGroup>
                            </MainCell>
                          </MainRow>
                        );
                      })
                    ) : (
                      <MainRow>
                        {" "}
                        <MainCell className="text-danger text-center">
                          No saved guests found
                        </MainCell>{" "}
                      </MainRow>
                    )}
                    <HeadinginallMasterGuests>Infants</HeadinginallMasterGuests>
                    {allMasterGuests?.bellow2 &&
                    allMasterGuests?.bellow2.length > 0 ? (
                      allMasterGuests?.bellow2.map((row, index) => {
                        const searchObject = row;
                        const foundObject = selectedGuestsForInfants.find(
                          (obj) => obj._id === searchObject._id
                        );
                        return (
                          <MainRow>
                            <MainCell>
                              <Checkbox
                                checked={foundObject ? true : false}
                                onClick={(event) => {
                                  selectGuestHandlerInfants(event, row);
                                }}
                              />
                              {row.title} {row.firstName} {row.lastName}
                            </MainCell>
                            <MainCell>
                              <ButtonGroup
                                size="small"
                                aria-label="small button group"
                              >
                                <MIButton
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    editHandler(row);
                                  }}
                                >
                                  Edit
                                </MIButton>
                                <MIButton
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => {
                                    deleteGuest(row._id);
                                  }}
                                >
                                  delete
                                </MIButton>
                              </ButtonGroup>
                            </MainCell>
                          </MainRow>
                        );
                      })
                    ) : (
                      <MainRow>
                        {" "}
                        <MainCell className="text-danger text-center">
                          No saved guests found
                        </MainCell>{" "}
                      </MainRow>
                    )}
                  </>
                )}
              </InnerGuestForm2>
            </GestForm>
            {/* <Table>
              <TableHeadStyledUpdate>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell colSpan={2}>First Name</TableCell>
                  <TableCell colSpan={2}>Last Name</TableCell>
                </TableRow>
              </TableHeadStyledUpdate>
              <TableBody>
                <TableRowStyledUpdate>
                  <TableCell style={{ width: "110px" }}>
                    <select
                      className="form-control"
                      name="title"
                      value={guestTitle}
                      onChange={(e) => setGuestTitle(e.target.value)}
                    >
                      <option value={"Mr"}>Mr</option>
                      <option value={"Ms"}>Ms</option>
                      <option value={"Mrs"}>Mrs</option>
                      <option value={"Miss"}>Miss</option>
                      <option value={"Master"}>Master</option>
                    </select>
                    <span className="text-danger">
                      {guestTitleError ? guestTitleError : ""}
                    </span>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <input
                      type="text"
                      value={guestFirstName}
                      name="name"
                      onChange={(e) => setGuestFirstName(e.target.value)}
                      className="form-control"
                      placeholder=" first Name"
                      onWheel={(e) => e.target.blur()}
                    />
                    <span className="text-danger">
                      {guestFirstNameError ? guestFirstNameError : ""}
                    </span>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <input
                      type="text"
                      value={guestLastName}
                      name="name"
                      onChange={(e) => setGuestLastName(e.target.value)}
                      className="form-control"
                      placeholder=" Last Name"
                      onWheel={(e) => e.target.blur()}
                    />
                  </TableCell>
                </TableRowStyledUpdate>
                <TableRowStyledUpdate>
                  <TableCell colSpan={3}>
                    <label className="checkbox_inline">
                      <input
                        type="checkbox"
                        onWheel={(e) => e.target.blur()}
                        value="1"
                        checked={guestBellow12year}
                        // checked={false}
                        onClick={(e) => {
                          handleCheckedBellow(e);
                        }}
                        style={chechBoxClass}
                      />{" "}
                      Below 12 years of age
                    </label>
                  </TableCell>
                  <TableCell colSpan={2}>
                    {editGuest ? (
                      <LoadingButton
                        loading={isLoadingSaveGuestButton}
                        disabled={isLoadingSaveGuestButton}
                        onClick={handleUpdateGuest}
                        variant="contained"
                        size="small"
                        color="primary"
                      >
                        Update
                      </LoadingButton>
                    ) : (
                      <LoadingButton
                        loading={isLoadingSaveGuestButton}
                        disabled={isLoadingSaveGuestButton}
                        onClick={handleGuestForm}
                        variant="contained"
                        size="small"
                        color="primary"
                      >
                        Save
                      </LoadingButton>
                    )}
                  </TableCell>
                </TableRowStyledUpdate>

                <TableRow>
                  <TableCell colSpan={3}>
                    <b>Saved Guests</b>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>

                {allMasterGuests && allMasterGuests.length > 0 ? (
                  allMasterGuests.map((row, index) => {
                    const searchObject = row;
                    const foundObject = selectedGuets.find(
                      (obj) => obj._id === searchObject._id
                    );

                    return (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Checkbox
                            checked={foundObject ? true : false}
                            onClick={(event) => {
                              selectGuestHandler(event, row);
                            }}
                          />
                          {row.title} {row.firstName} {row.lastName}
                        </TableCell>
                        <TableCell colSpan={2}>
                          <ButtonGroup
                            size="small"
                            aria-label="small button group"
                          >
                            <MIButton
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                editHandler(row);
                              }}
                            >
                              Edit
                            </MIButton>
                            <MIButton
                              variant="outlined"
                              color="secondary"
                              onClick={() => {
                                deleteGuest(row._id);
                              }}
                            >
                              delete
                            </MIButton>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    {" "}
                    <TableCell colSpan={4} className="text-danger text-center">
                      No saved guests found
                    </TableCell>{" "}
                  </TableRow>
                )}
              </TableBody>
            </Table> */}
          </DialogContent>
          <DialogActions>
            <MIButton
              variant="contained"
              size="small"
              color="primary"
              onClick={handleGuestClose}
              className="cancelbutton"
            >
              Cancel
            </MIButton>
            <MIButton
              variant="contained"
              color="primary"
              size="small"
              disabled={
                selectedGuets.length === Number(values.adult) &&
                selectedGuestsForChildren.length === Number(values.children) &&
                selectedGuestsForInfants.length === Number(values.infants)
                  ? false
                  : true
              }
              onClick={selectedGuestHandler}
            >
              Done
            </MIButton>
          </DialogActions>
        </GuestDialog>
      </Container>
      {showPopup && (
        <LoginPopup
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          setLoggedIn={setLoggedIn}
        />
      )}
      {/* {loginState === 'signUp' && <SignUp setLoginState={setLoginState} />} */}
    </Root>
  );
};

export default HotelAccordian;
