import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { BiRupee } from "react-icons/bi";
import { BsInfoCircle, BsArrowRight, BsCheck } from "react-icons/bs";

const TextRightWrapper = styled.div`
  /* margin-top: 20px; */
  display: flex;

  width: 70%;
  @media (max-width: 768px) {
    margin-top: 30px;
    width: 100vw;
  }
`;

const RightWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 8px #0000002e;
`;

const TextHeading = styled.div`
  font-size: 24px;
  font-weight: bold;
  /* padding: 10px 0px 0px 30px; */
  padding: 20px 30px;
`;

const TextWrapperHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
`;

const TextHeadingOne = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
`;

const TextHeadingTwo = styled.div`
  font-weight: 700;
  color: #4a4a4a;
`;

const TextPara = styled.div`
  font-size: 12px;
  margin-left: 3px;
  color: #9b9b9b;
`;
const TextLine = styled.div`
  border-bottom: 1px solid #d8d8d8;
  margin: 0px 30px;
`;

const TextWrapperHeadOne = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
`;

const TextHeadOne = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  color: #4a4a4a;
  display:flex;
  flex-direction:column;
`;

const TextFirst = styled.div``;
const TextSecond = styled.div`
  font-size:medium;
`;

const TextHeadTwo = styled.div`
  font-weight: 700;
  color: #4a4a4a;
`;
const TextHeadFive = styled.div`
  font-weight: 700;
  color: #e02020;
`;
const TextHeadFour = styled.div`
  width: 60px;
  font-weight: 700;
  color: #4a4a4a;
`;

const TextInfoIcon = styled.span`
  font-size: 1.3rem;
`;
const TextWrapperHeadTwo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
`;

const TextHeadThree = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
`;
const TextInput = styled.div`
  input {
    transform: scale(1.5);
  }
`;
const TextInfoCircle = styled.div`
  font-size: 1.3rem;
  margin-right: 40px;
`;

const TextWrapperColor = styled.div`
  background-color: #f2f2f2;
`;

const TextWrapperRight = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-size: 17px;
  font-weight: bold;
  padding: 10px 0px 0px 30px;
`;

const TextParagraph = styled.p`
  padding: 10px 30px;
  font-size: 12px;
`;
const TextParagraphWrapper = styled.div`
  background-color: #ffedd1;
  padding: 4px 0px 4px 10px;
  margin: 10px 30px;
  border-radius: 5px;
  font-size: 12px;
`;
const Input = styled.input`
  margin: 10px 30px;
  padding: 15px 20px;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 700;
  outline: 0;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 1px 8px #0000002e;
  width: calc(100% - 100px);
`;

const TextArrowIcon = styled.div`
  width: 50px;
  height: 55px;
  line-height: 52px;
  text-align: center;
  border-radius: 0px 6px 6px 0px;
  background-color: #0091ff;
  color: #fff;
  position: absolute;
  right: 40px;
  cursor: pointer;
`;
const Text = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
const TextCouponWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 8px #0000002e;
  padding: 10px 0;
`;

const TextSignWrapper = styled.div`
  font-size: 17px;
  font-weight: bold;
  padding: 10px 0px 0px 30px;
`;

const TextSign = styled.span`
  a {
    text-decoration: none;
  }
`;

const ParaOne = styled.div`
  padding: 5px 0px 0px 30px;
  font-size: 12px;
`;

const TextCheck = styled.span`
  color: #53b2fe;
  font-size: 16px;
`;

const TextLineOne = styled.div`
  border-bottom: 1px solid #d8d8d8;
  margin: 5px 30px;
`;
const TextRupeIcon = styled.span`
  font-size: 19px;
`;

const BookingCard = ({ state, setState, difference }) => {

  const [roomPerNight, setRoomPerNight] = useState();
  const [roomAccordingPrice, setAccordingPrice] = useState();
  const [mainDifference, setMainDifference] = useState(difference!==undefined && difference);

  const [mainPrice, setMainPrice] = useState();
  const [roomTotalPrice, setRoomTotalPrice] = useState();

  useEffect(() => {
    setTimeout(() => {
      setRoomPerNight(state.price);
      setAccordingPrice(state.formData.rooms * state.price);
      setMainPrice(state.formData.rooms * state.price * difference);
      setRoomTotalPrice((state.formData.rooms * state.price * difference * 10) / 100);
      setState(
        state.formData.rooms * state.price * difference -
          (state.formData.rooms * state.price * difference * 10) / 100
      );
    }, 1000);
  }, [difference]);

  return (
    <>
      <Container>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <TextRightWrapper>
            <RightWrapper>
              <TextHeading>PRICE BREAK-UP</TextHeading>
              <TextLine></TextLine>
              <TextWrapperHead>
                <TextHeadingOne>
                  Room/Night<TextPara>Base Price</TextPara>
                </TextHeadingOne>
                <TextHeadingTwo>
                  <TextRupeIcon>
                    <BiRupee />
                  </TextRupeIcon>
                  {state.price}
                </TextHeadingTwo>
              </TextWrapperHead>
              <TextLine></TextLine>

              <TextWrapperHeadOne>
                <TextHeadOne>
                  <TextFirst>
                    Price per room
                  </TextFirst>
                  <TextSecond>
                  ({state.price} * {`${state.formData.rooms==='1' ? state.formData.rooms + " room": state.formData.rooms + " rooms"}`})
                  </TextSecond>                
                </TextHeadOne>
                <TextHeadTwo>
                  <TextRupeIcon>
                    <BiRupee />
                  </TextRupeIcon>
                  {state.formData.rooms * state.price}
                </TextHeadTwo>
              </TextWrapperHeadOne>
              <TextLine></TextLine>

              <TextWrapperHeadOne>
                <TextHeadOne>
                  <TextFirst>
                  Total Price 
                  </TextFirst>
                  <TextSecond>
                  ({state.price} * {`${state.formData.rooms==='1' ? state.formData.rooms + " room": state.formData.rooms + " rooms"}`} * {difference===1?difference+" day":difference+" days"})
                  </TextSecond>
                </TextHeadOne>
                <TextHeadTwo>
                  <TextRupeIcon>
                    <BiRupee />
                  </TextRupeIcon>
                  {state.formData.rooms * state.price * difference}
                </TextHeadTwo>
              </TextWrapperHeadOne>
              <TextLine></TextLine>

              <TextWrapperHeadOne>
                <TextHeadOne>
                  Total Discount{" "}
                  {/* <TextInfoIcon>
                    <BsInfoCircle />
                  </TextInfoIcon> */}
                </TextHeadOne>
                <TextHeadTwo>
                  <TextRupeIcon>
                    -<BiRupee />
                  </TextRupeIcon>
                  {(state.formData.rooms * state.price * difference * 10) / 100}
                </TextHeadTwo>
              </TextWrapperHeadOne>
              <TextLine></TextLine>

              {/* <TextWrapperHeadOne>
               <TextHeadOne>Price after Discount </TextHeadOne>
               <TextHeadTwo><TextRupeIcon><BiRupee/></TextRupeIcon>8,998</TextHeadTwo>
            </TextWrapperHeadOne>
            <TextLine></TextLine> */}

              {/* <TextWrapperHeadOne>
               <TextHeadOne>Hotel Taxes <TextInfoIcon><BsInfoCircle/></TextInfoIcon></TextHeadOne>
               <TextHeadTwo><TextRupeIcon><BiRupee/></TextRupeIcon>1,080</TextHeadTwo>
            </TextWrapperHeadOne> */}
              <TextLine></TextLine>

              {/* <TextWrapperHeadTwo>
          <TextInput><input type="checkbox"/></TextInput>
          <TextHeadThree> Donate<BiRupee/>5 to support responsible tourism initiatives.<a href='#'> T&Cs</a> </TextHeadThree>
          <TextInfoCircle><BsInfoCircle/></TextInfoCircle>
          <TextHeadFour><TextRupeIcon><BiRupee/></TextRupeIcon>5</TextHeadFour>
          </TextWrapperHeadTwo> */}

              <TextWrapperColor>
                <TextWrapperHeadOne>
                  <TextHeadOne>Total Amount to be paid </TextHeadOne>
                  <TextHeadFive>
                    <TextRupeIcon>
                      <BiRupee />
                    </TextRupeIcon>
                    {state.formData.rooms * state.price * difference -
                      (state.formData.rooms * state.price * difference * 10) / 100}
                  </TextHeadFive>
                </TextWrapperHeadOne>
              </TextWrapperColor>
            </RightWrapper>

            {/* <TextWrapperRight>
        <TextCouponWrapper>
         <Heading>Coupon Codes</Heading>
         <TextParagraph>No coupon codes applicable for this property.</TextParagraph>
         <TextParagraphWrapper>Gift cards may be applied in next step</TextParagraphWrapper>
         <Text>
         <Input type="text" placeholder='Have a Coupon Code'/>
         <TextArrowIcon>
         <BsArrowRight/>
         </TextArrowIcon>
         </Text>
        </TextCouponWrapper>
        </TextWrapperRight> */}

            {/* <TextWrapperRight>
        <TextCouponWrapper>
            <TextSignWrapper>WHY <TextSign><a href='#'>SIGN UP</a></TextSign> OR <TextSign><a href='#'>LOGIN</a></TextSign></TextSignWrapper>
            <ParaOne><TextCheck><BsCheck/></TextCheck> Get access to <b>Secret Deals</b></ParaOne>
            <TextLineOne></TextLineOne>
            <ParaOne><TextCheck><BsCheck/></TextCheck> <b>Book Faster</b> - we’ll save & pre-enter your details</ParaOne>
            <TextLineOne></TextLineOne>
            <ParaOne><TextCheck><BsCheck/></TextCheck> <b>Manage your Bookings</b> from one place</ParaOne>
        </TextCouponWrapper>
        </TextWrapperRight> */}
          </TextRightWrapper>
          {/* </Col> */}
        </Row>
      </Container>
    </>
  );
};

export default BookingCard;
