import styled from "styled-components";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import DescriptionIcon from "@mui/icons-material/Description";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import "./GiftCard.css";
import { useState } from "react";

const Items = styled.div`
  padding: 15px;
  width: 185px;
  text-align: center;
  cursor: pointer;
   @media (max-width: 768px) {
    width: 100%;
  }
  ${(p) =>
    p.select &&
    `
    border-bottom: 4px solid #3554D1;
    color: #3554D1;
  `};
`;
const TabText = styled.p`
  font-size: 17px;
  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
  }
`;

const TabContainer = styled.div`
  display: flex;
  width: 40%;
  margin: 0 auto;
  justify-content: center;
   @media (max-width: 768px) {
    width: 100%;
  }
`;
function GiftCardTabs({ active }) {
  const [selected, setselected] = useState(1);
  return (
    <>
      <TabContainer>
        <Items select={active === "lists"}>
          <CardGiftcardIcon fontSize="large" />
          <TabText>Choose E-Gift Card</TabText>
        </Items>
        <Items className="border-left" select={active === "cardbyid"}>
          <DescriptionIcon fontSize="large" />
          <TabText>Enter Details</TabText>
        </Items>
        {/* <Items>
          <CreditScoreIcon fontSize="large"/>
          <TabText>Payment</TabText>
        </Items> */}
      </TabContainer>
    </>
  );
}
export default GiftCardTabs;
