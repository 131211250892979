import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const BannerSection = styled.div`
  background-color: white;
  margin: 0 auto;
  width: 1200px;
  cursor: pointer;
  @media (max-width: 1200px) {
    width: 100%;
  }

  // @media (max-width: 768px) {
  //   width: 740px;
  // }
  // @media (max-width: 390px) {
  //   width: 370px;
  // }
`;
const BannerImg = styled.img`
  border-radius: 4px;
  width: 100%;

  @media (max-width: 390px) {
    min-height: 130px;
  }
 
`;
const ActivityBanner = ({ image, cityName }) => {
  const navigate = useNavigate();
  const handlegetActivity = () => {
    navigate("/activities", { state: cityName });
  };
  return (
    <>
      <BannerSection title={`activities ${cityName ? "in " + cityName : ""}`}>
        <BannerImg
          src={image}
          onClick={() => {
            handlegetActivity();
          }}
        />
      </BannerSection>
    </>
  );
};

export default ActivityBanner;
