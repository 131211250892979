import React, { useEffect, useState } from "react";

const Timer = ({ timer, setTimer }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((prevState) => prevState - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timer, setTimer]);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  const displayMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const displaySeconds = seconds < 10 ? `0${seconds}` : seconds;

  return <div>Resend OTP in: {`${displayMinutes}:${displaySeconds}`}</div>;
};

export default Timer;
