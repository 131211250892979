import React from "react";
// import { Accordion } from 'react-bootstrap';
import styled from "styled-components";
// import hotelimg from "../../images/hotelimg1.jpg";

const Root = styled.div`
  background-color: #c5cdd3;
  min-height: 120px;
  /* margin: 0 auto; */
`;
const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 85%;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;
const HeadingWrapper = styled.div``;
const HeadText = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding: 30px 0 0;
  @media (max-width: 768px) {
    padding: 30px 20px;
  }
`;

const BookingHeroSection = () => {
  return (
    <Root>
      <Container>
        <HeadingWrapper>
          <HeadText> Review your Booking </HeadText>
        </HeadingWrapper>
      </Container>
    </Root>
  );
};

export default BookingHeroSection;
