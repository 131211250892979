import { ListGroup } from "react-bootstrap";

function TermAndConditions() {
    return ( 
        <ListGroup as="ol" numbered variant="flush"> {/* Use variant="flush" to remove borders */}
            <ListGroup.Item>Tristique senectus et netus et malesuada fames ac turpis.</ListGroup.Item>
            <ListGroup.Item>Maecenas volutpat blandit aliquam etiam erat velit scelerisque.</ListGroup.Item>
            <ListGroup.Item>Arcu cursus vitae congue mauris rhoncus. Nulla facilisi cras fermentum odio.</ListGroup.Item>
            <ListGroup.Item>In pellentesque massa placerat duis ultricies lacus sed. Semper eget duis at tellus at urna condimentum.</ListGroup.Item>
            <ListGroup.Item>Fames ac turpis egestas maecenas pharetra convallis posuere. Felis eget nunc lobortis mattis.</ListGroup.Item>
        </ListGroup>
     );
}

export default TermAndConditions;