import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "react-bootstrap/Card";
import "./HotelWithLocation.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import LoginPopup from "../Pages/Login/LoginPopup";
import { environmentVariables } from "../../config/config";
import Radar from "radar-sdk-js";
import ContentLoader from "react-content-loader";
import CircularLoader from "../CircularLoader/CircularLoader";
import Loader from "../Shortlistedloader/Loader";
import { useAuth } from "../../ContextApi/Contextapi";
const Root = styled.div`
  padding: 10px 0;
`;
const Container = styled.div`
  width: 1200px;
  margin: 110px auto 0px auto;
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 1600px) {
    width: 1200px;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
`;
const ContainerMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;


const HeartWrapper = styled.div`
  width: 40px;
  border-radius: 3px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  /* left: 88%; */
  right: 15px;
  top: 15px;
  /* right: 1px; */
  background-color: #fff;
  cursor: pointer;
  :hover {
    color: red !important;
  }
`;

const CarouselAfterSelection = styled.div`
  display: grid;
  padding: 0 20px;
  //   margin-top:150px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 768px) {
    // display: flex;
    // flex-direction: column;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
  }
  @media (max-width: 650px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
  @media (max-width: 425px) {
    grid-template-columns: 1fr;
    
  }
`;
const ActivityResult = ({ message, message2,setLoggedIn }) => {
  Radar.initialize("prj_live_pk_8cef6dee5f31e220f09eba68a33c1556053a8e3b");
  const baseUrl = environmentVariables.apiUrl;
  const [loading, setLoading] = useState(false);
  const getCityName = localStorage.getItem("cityname");
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const navigation = useNavigate();
  const [isHeartLoading, setIsHeartLoading] = useState({});
  const { authData } = useAuth();

  const onClickLike = async (item, key) => {
    const compoundKey = `${item._id}-${key}`;
    if (authData === undefined) {
      setShowPopup(true);
    } else {
      setIsHeartLoading((prevState) => ({ ...prevState, [compoundKey]: true }));
      await axios({
        url: `${baseUrl}auth/getshortlisted/${item._id}`,
        method: "post",
        headers: {
          _token: authData?.token,
        },
      })
        .then((response) => {
          getUser(compoundKey);
        })
        .catch((error) => {
          // console.log("error", error);
        });
    }
  };

  const getUser = async (key) => {
    try {
      const data = await axios.get(`${baseUrl}auth/getuserdata`, {
        headers: {
          _token: authData?.token,
        },
      });
      setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
      setUserData(data.data.data.shortlistedArray);
    } catch (error) {
      setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
      return error?.message;
    }
  };

  const getActivitiesData = async () => {
    setLoading(true);
    let params = {};
    if (getCityName || message2) {
      params.city = getCityName || message2;
    }
    await axios({
      url: `${baseUrl}auth/getAllActivities`,
      method: "post",
      data: params,
    })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setData(response.data.data.records);
        }
      })
      .catch((error) => {
        setLoading(false);
        setData([]);
      });
  };
  useEffect(() => {
    getUser();
  }, [authData]);
  useEffect(() => {
    getActivitiesData();
  }, []);

  const getComponents = () => {
    if (data === null || data === undefined) {
      return (
        <div
          style={{
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "500",
            width: "100vw",
          }}
        >
          Failed to fetch
        </div>
      );
    } else if (data.length === 0) {
      return (
        <div
          style={{
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "500",
          }}
        >
          Data not Found
        </div>
      );
    } else {
      return data.map((item, key) => {
        let imageData =
          item.image[0] !== undefined && item.image[0]
            ? `https://bastionex-travels.b-cdn.net/uploads/${item.image[0]}`
            : "https://dummyimage.com/300x200/000/fff";
        return (
          <div
            key={key}
            className="col-xl-4 col-lg-4 col-md-4 col-sm-4 mb-4"
            style={{ width: "100%" }}
          >
            <Card>
              <Card.Img
                style={{
                  minHeight: "200px",
                  cursor: "pointer",
                  maxHeight: "200px",
                }}
                src={imageData}
                onClick={() => navigation(`/activityDetail/${item?._id}`)}
              />

              <Tooltip title="Shortlist" styled={{ fontSize: "50px" }}>
                <HeartWrapper onClick={() => onClickLike(item, key)}>
                  {isHeartLoading[`${item._id}-${key}`] ? (
                    <Loader />
                  ) : (
                    <i
                      style={{
                        fontSize: "20px",
                        color:
                          userData && userData.includes(item._id)
                            ? "red"
                            : "#747474",
                      }}
                      className="fa fa-thin fa-heart style"
                    ></i>
                  )}
                </HeartWrapper>
              </Tooltip>
              {/* <TextHeading>{item.hotelname}</TextHeading> */}
              {/* <TextWrapper>
                            <TextHeading>{item.hotelname}</TextHeading>
                            <IconLocation>
                                <MdLocationPin style={{ color: "rgb(12, 176, 155)" }} />
                            </IconLocation>
                            <TextLocation>{item.city}</TextLocation>
                            </TextWrapper>
                            <TextIconThree>
                            <TextIconOne>
                                &#8377; {item.rooms[0] !== undefined && item.rooms[0].price} /
                                night
                            </TextIconOne>
                            </TextIconThree> */}
              <Card.Body
                style={{
                  maxHeight: "100px",
                  marginBottom: "20px",
                  minHeight: "100px",
                }}
              >
                <Card.Title style={{ fontWeight: "500", fontSize: "16px" }}>
                  {item.hotelname}
                </Card.Title>
                <Card.Title style={{ fontWeight: "300", fontSize: "14px" }}>
                  {item.city} , {item.state}
                </Card.Title>
              </Card.Body>
            </Card>
            {showPopup && (
              <LoginPopup showPopup={showPopup} setShowPopup={setShowPopup} setLoggedIn={setLoggedIn} />
            )}
          </div>
        );
      });
    }
  };
  return (
    <Root>
      <Container>
        <div className="row">
          {loading ? (
            <ContentLoader
              width={2500}
              height={575}
              viewBox="0 0 1100 575"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="58" rx="2" ry="2" width="250" height="300" />
              <rect x="280" y="57" rx="2" ry="3" width="250" height="300" />
              <rect x="560" y="56" rx="2" ry="2" width="250" height="300" />
              <rect x="840" y="56" rx="2" ry="2" width="250" height="300" />
              <rect x="0" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="280" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="560" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="840" y="615" rx="2" ry="2" width="250" height="300" />
            </ContentLoader>
          ) : (
            <>
              <div className="col-md-12 mb-5">
                <h3>
                  Activities{" "}
                  {getCityName || message2
                    ? " in " + (getCityName || message2)
                    : ""}
                </h3>
              </div>
              <CarouselAfterSelection className="row">
                {getComponents()}
              </CarouselAfterSelection>
            </>
          )}
        </div>
      </Container>
      <ContainerMobile>
        <div className="row mt-4">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularLoader></CircularLoader>
            </div>
          ) : (
            <CarouselAfterSelection className="row mt-4">
              {getComponents()}
            </CarouselAfterSelection>
          )}
        </div>
      </ContainerMobile>
    </Root>
  );
};

export default ActivityResult;
