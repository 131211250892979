import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";
import LoginPopup from "../Pages/Login/LoginPopup";
import Card from "react-bootstrap/Card";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import { environmentVariables } from "../../config/config";
import CircularLoader from "../CircularLoader/CircularLoader";
import { FormControl, MenuItem, Select } from "@mui/material";
import TextBackgroundImg from "./../../images/giftcardtextbg.png";
import ReactCountryFlag from "react-country-flag";
import { useQuery } from "react-query";

const Root = styled.div`
  padding: 0 0 10px 0;
  background-color: #ededed;
`;

const Container = styled.div`
  width: 100%;
  margin: 30px auto 0px;
  display: flex;
  @media (max-width: 1600px) {
  }
  @media (max-width: 1024px) {
    width: 90%;
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
  }
`;
const CarouselAfterSelection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0;
  gap: 20px 16px;

  @media (max-width: 1440px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
    gap: 24px 17px !important;
  }
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1025px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    gap: 30px 17px !important;
  }
`;
const LowerContainer = styled.div`
  margin: 25px -15px 0px 15px !important;
  @media (max-width: 768px) {
    margin: 30px 0px 0px 0px !important;
  }
`;

const FormControlStyle = styled(FormControl)`
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  text-align: center !important;
  width: 100% !important;
`;

const GiftCardHeadingWrapper = styled.div`
display: flex;
justify-content: center;
text-align: center;
align-items: center;
background-image: url(${TextBackgroundImg});
background-repeat: no-repeat;
background-size: cover;
background-position: center;
margin-bottom:20px ;
`;
const GiftCardHeadText = styled.div`
  font-weight: 300;
  font-size: 30px;
  padding: 20px 0;
  color: white;
`;
const GiftCardHeading = styled.text`
  font-size: 30px;
  font-weight: 600;
`;
const CardStyle = styled(Card)`
  padding: 0 !important;
  border-radius: 18px !important;
`;

const CustomTablePagination = styled(TablePagination)`
  display: flex !important;
  justify-content: flex-end !important;
  align-items: baseline !important;
  p {
    margin: 0px !important;
  }
  @media (max-width: 375px) {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
`;

const GirftCardNameWrapper = styled.div`
  padding: 12px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  color: #231f20;
`;

const CardImagewrapper = styled(Card.Img)`
  min-height: 270px;
  max-height: 270px;
  cursor: pointer;
  border-radius: 18px !important;
  transition: transform 0.5s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 1700px) {
    min-height: 250px;
    max-height: 250px;
  }
  @media (max-width: 1500px) {
    min-height: 230px;
    max-height: 230px;
  }
  @media (max-width: 1440px) {
    min-height: 230px;
    max-height: 230px;
  }
  @media (max-width: 1200px) {
    min-height: 180px;
    max-height: 180px;
  }
  @media (max-width: 1025px) {
    min-height: 180px;
    max-height: 180px;
  }
  @media (max-width: 992px) {
    min-height: 150px;
    max-height: 150px;
  }
  @media (max-width: 768px) {
    min-height: 190px;
    max-height: 190px;
  }
  @media (max-width: 600px) {
    min-height: 170px;
    max-height: 170px;
  }
  @media (max-width: 576px) {
    min-height: 270px;
    max-height: 270px;
  }
  @media (max-width: 480px) {
    min-height: 200px;
    max-height: 200px;
  }
`;

const CustomSelect = styled(Select)`
  border: 1px solid #e2e8f0;
  border-radius: 40px !important;
`;

const Msgdata = styled.div`
  color: #d63b3b;
  font-size: 20px;
  text-align: center;
  margin-bottom: 0.9rem;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 15px;
  }
  /* margin-bottom:5px; */
`;
const InnerContainer = styled.div`
  width: 80%;
  padding: 0px 50px;
  @media (max-width: 992px) {
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;
const AddContainer = styled.div`
  width: 20%;
  padding: 70px 20px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const CustomMenuItem = styled(MenuItem)``;

const GiftList = ({
  active,
  setActive,
  productId,
  setProductId,
  setSelectedCountry,
  selectedCountry
  // giftCard_token,
}) => {
  const baseUrl = environmentVariables.apiUrl;
  const [showPopup, setShowPopup] = useState(false);

  const [page, setPage] = useState(0);

  const [size, setSize] = useState(12);

  const countryCode = localStorage.getItem("countryISOCode");
  const [allCountries, setAllCountries] = useState([]);
  const [headingData, setHeadingData] = useState();
  const [giftAds, setGiftAds] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 12));
    setPage(0);
  };

  const fetchData = async (selectedCountry, countryCode, size, page) => {
    try {
      const config = {
        method: "post",
        url: `${baseUrl}auth/getproductbyisocodegiftcard/${
          selectedCountry || (countryCode === null ? "IN" : countryCode)
        }?size=${size}&page=${page + 1}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios(config);
      const { totalItems, totalPages, currentPage, data } = response.data;

      return {
        allProducts: data,
        totalPages,
        currentPage: currentPage - 1,
        totalItems,
      };
    } catch (error) {
      throw error;
    }
  };

  const {
    data: productData,
    isLoading,
    isError,
  } = useQuery(
    ["productData", selectedCountry, countryCode, size, page],
    () => fetchData(selectedCountry, countryCode, size, page),
    {
      retry: false,
    }
  );

  useEffect(() => {
    let config = {
      method: "get",
      url: `${baseUrl}auth/getallcountries`,
    };
    axios
      .request(config)
      .then((response) => {
        setAllCountries(response.data.data);
      })
      .catch((err) => {});
  }, []);
  const handleSelectChange = (event) => {
    console.log("rrrrrrrrr",event.target.value)
    setSelectedCountry(event.target.value);
  };

  const getGiftHeading = () => {
    let config = {
      method: "get",
      url: `${baseUrl}auth/getheading_giftcard_topup`,
    };

    axios
      .request(config)
      .then((response) => {
        setHeadingData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        setHeadingData();
      });
  };
  const getGiftAds = () => {
    let config = {
      method: "get",
      url: `${baseUrl}auth/getadsdata`,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setGiftAds(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        setGiftAds([]);
      });
  };
  useEffect(() => {
    getGiftHeading();
    getGiftAds();
  }, []);

 

  //   const config = {
  //     method: "post",
  //     url: `${baseUrl}auth/getproductbyisocodegiftcard?size=${size}&page=${page + 1}&isoCode=${
  //         selectedCountry || (countryCode === null ? "IN" : countryCode)
  //     }${selectedCategory ? `&brand=${selectedCategory}` : ''}`,
  //     headers: {
  //         "Content-Type": "application/json",
  //     },
  // };

  if (isLoading) {
    return (
      <ContentLoader
        width={2500}
        height={575}
        viewBox="0 0 1100 575"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ width: "100%" }}
      >
        <rect x="0" y="58" rx="2" ry="2" width="250" height="300" />
        <rect x="280" y="57" rx="2" ry="3" width="250" height="300" />
        <rect x="560" y="56" rx="2" ry="2" width="250" height="300" />
        <rect x="840" y="56" rx="2" ry="2" width="250" height="300" />
        <rect x="0" y="615" rx="2" ry="2" width="250" height="300" />
        <rect x="280" y="615" rx="2" ry="2" width="250" height="300" />
        <rect x="560" y="615" rx="2" ry="2" width="250" height="300" />
        <rect x="840" y="615" rx="2" ry="2" width="250" height="300" />
      </ContentLoader>
    );
  }

  return (
    <>
      <Root>
        <GiftCardHeadingWrapper>
          <GiftCardHeadText>{headingData?.heading}</GiftCardHeadText>
        </GiftCardHeadingWrapper>
        <Container>
          <InnerContainer>
            <FormControlStyle className="formcontrolselect">
              {/* <GiftCardHeadingWrapper>
              <GiftCardHeading>Giftcard</GiftCardHeading> 
            </GiftCardHeadingWrapper> */}

              {!isLoading && (
                <CustomSelect
                  value={selectedCountry}
                  onChange={handleSelectChange}
                  className="selectoption"
                  name="selectedOptions"
                >
                  <MenuItem value="">Select Country</MenuItem>

                  {allCountries.map((row, index) => {
                    return (
                      // (row.isoName === "IN" || row.isoName === "AE") && (
                      <CustomMenuItem
                        key={index}
                        value={row.isoCode}
                        // className="menulist_giftcard"
                        style={{
                          display: "flex !important",
                          justifyContent: "flex-start !important",
                        }}
                      >
                        <span style={{ padding: "0 8px 0 15px" }}>
                          <ReactCountryFlag
                            countryCode={row.isoCode}
                            svg
                            style={{
                              width: "1.5em",
                              height: "1.5em",
                            }}
                            title={row.isoCode}
                          />
                        </span>

                        <span
                          style={{
                            textTransform: "uppercase",
                            marginRight: "10px",
                            overflow: "hidden",
                            fontWeight: "500",
                            color: "#1E293B",
                            fontSize: "14px",
                          }}
                        >
                          {row.name}
                        </span>
                        {/* <OptionImage src={`${imageUrl}`} alt="img" /> */}
                      </CustomMenuItem>
                      // )
                    );
                  })}
                </CustomSelect>
              )}
            </FormControlStyle>
            <LowerContainer className="row">
              {isError ? (
                <Msgdata>No products were found for the given country</Msgdata>
              ) : (
                <CarouselAfterSelection className="row">
                  {productData?.allProducts?.map((item, index) => {
                    return (
                      <div
                        className=""
                        style={{
                          width: "100%",
                          padding: "0",
                          margin: "0 !important",
                        }}
                        key={index}
                      >
                        <CardStyle>
                          <CardImagewrapper
                            src={item.logoUrls[0]}
                            onClick={() => {
                              setActive("cardbyid");
                              setProductId(item.productId);
                            }}
                          />
                          <Tooltip
                            title="Shortlist"
                            styled={{ fontSize: "50px" }}
                          ></Tooltip>
                        </CardStyle>
                        <GirftCardNameWrapper>
                          {item.productName}
                        </GirftCardNameWrapper>
                        {showPopup && (
                          <LoginPopup
                            showPopup={showPopup}
                            setShowPopup={setShowPopup}
                          />
                        )}
                      </div>
                    );
                  })}
                </CarouselAfterSelection>
              )}

              {!isLoading && (
                <CustomTablePagination
                  component="div"
                  rowsPerPage={size}
                  rowsPerPageOptions={[12, 24, 36, 48, 60]}
                  count={productData?.totalItems}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </LowerContainer>
          </InnerContainer>
          <AddContainer>
            {giftAds?.length > 0 ? (
              <>
                {giftAds?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => (window.location.href = item.slug)}
                      style={{width:"100%",borderRadius:"5px", }}
                    >
                      <img
                        src={`https://bastionex-travels.b-cdn.net/uploads/adsimages/${item.image}`}
                        style={{width:"100%", marginBottom:"20px"}}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </AddContainer>
        </Container>
      </Root>
    </>
  );
};

export default GiftList;
