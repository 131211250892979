import React, { useState } from "react";
import styled from "styled-components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Rating from "./Rating";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginPopup from "../../Pages/Login/LoginPopup";
import { environmentVariables } from "../../../config/config";
import { useAuth } from "../../../ContextApi/Contextapi";

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const InnerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const RatingReviewMainWrpper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
const ReviewRatingWrapper = styled.div`
  /* margin:0 20px; */
  margin: 15px 0;
`;
const ReviewRating = styled.text`
  color: #00000099;
  font-size: 16px;
  line-height: 26px;
`;
const NamedateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: flex-start;
  margin: 0 15px;
`;
const IconWrapper = styled.div`
  position: relative;
`;
const DropDownDiv = styled.div`
  position: absolute;
  top: 25px;
  right: 0px;
  border-radius: 10px;
  /* left: 0px; */
  background: white;
  // border: 1px solid rgb(204, 204, 204);
  padding: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
  z-index: 1000;
`;
const DropdownButton = styled.button`
  border: none;
  width: 100%;
  padding: 2px 20px;
  background-color: ${(p) => (p.savemain ? "#1976d2" : "white")};
  color: ${(p) => (p.savemain ? "#fff" : "inherit")};
  text-align: left;
  cursor: pointer;

  ${(p) =>
    p.editsave &&
    `
    width: auto !important;
    border: 0.5px solid lightgrey;
    margin: 6px;
    border-radius: 20px;
  `}

  &:hover {
    background-color: ${(p) => (p.savemain ? "#1976d2" : "rgb(245, 245, 245)")};
  }
`;
const EditMoreCancelDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const NameDateIconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;
const ReviewerName = styled.text``;
const ReviewerDate = styled.text`
  color: #00000099;
  font-size: 14px;
  padding: 2px 0;
`;
const ReviewerIconWrapper = styled.div`
  background-color: red;
  width: 40px;
  height: 40px;
  color: white;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
`;
const ReviewerIcon = styled.text``;
const ReviewerNameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 100%;
`;
const MoreIconDiv = styled.div`
  cursor: pointer;
  padding: 8px;
  &:hover {
    background-color: rgb(245, 245, 245);
    border-radius: 50%;
  }
`;

const LineWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 30px 0;
`;
const Line = styled.div`
  width: 90%;
  height: 2px;
  background-color: #f5f5f5;
`;
const EditModediv = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:hover {
    border-color: #666;
  }

  &:focus {
    outline: none;
    border-color: #3399ff;
    box-shadow: 0 0 5px rgba(51, 153, 255, 0.7);
  }
`;

const ViewRating = ({
  value,
  getRating,
  setReviewShow,
  showIcon,
  getAverageRating,
  setLoggedIn,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [newRating, setNewRating] = useState(value.rating);
  const [newReview, setNewReview] = useState(value.review);
  const [time, setTime] = useState(value.updatedAt);
  const [showOptions, setShowOptions] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const baseUrl = environmentVariables.apiUrl;
  const { authData } = useAuth();
  const handleMoreVertClick = () => {
    setShowOptions(!showOptions);
  };

  const handleSaveClick = () => {
    // Add logic to save the newRating and newReview
    // You can make an API call here to update the backend

    // After saving, set editMode back to false
    let data = JSON.stringify({
      rating: newRating,
      review: newReview,
      hotelId: value.hotelId,
    });

    let config = {
      method: "post",
      url: `${baseUrl}auth/editratingreview`,
      headers: {
        _token: authData?.token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.message === "Problems occurs") {
          toast.error("Something Went Wrong", { autoClose: 3000 });
          setShowOptions(false);
          setEditMode(false);
        } else {
          setNewRating(response.data.data.rating);
          setNewReview(response.data.data.review);
          setTime(response.data.data.updatedAt);
          setShowOptions(false);
          setEditMode(false);
          getAverageRating();
        }
      })
      .catch((error) => {
        setShowOptions(false);
        setEditMode(false);
      });
  };

  const handleRatingChange = (selectedRating) => {
    setNewRating(selectedRating);
  };

  const handleEdit = () => {
    if (authData === undefined) {
      setShowPopup(true);
      setShowOptions(!showOptions);
    } else {
      setEditMode(!editMode);
      setShowOptions(!showOptions);
    }
  };
  const handleDelete = () => {
    if (authData === undefined) {
      setShowPopup(true);
      setShowOptions(!showOptions);
    } else {
      let config = {
        method: "delete",
        url: `${baseUrl}auth/deleteratingreview/${value.hotelId}`,
        headers: {
          _token: authData?.token,
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          if (response?.data?.message === "Review Deleted Successfully") {
            setReviewShow(true);
            getRating();
            setShowOptions(!showOptions);
            getAverageRating();
            // toast.success(response.data.message, { autoClose: 3000 });
          } else {
            toast.error("Something Went Wrong", { autoClose: 3000 });
            setShowOptions(!showOptions);
            setEditMode(false);
          }
        })
        .catch((error) => {
          toast.error(error?.message || error?.response?.data?.message, {
            autoClose: 3000,
          });
        });
    }
  };
  function formatDate(timestamp) {
    const options = {
      timeZone: "Asia/Kolkata",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    const formattedDate = new Date(timestamp).toLocaleString("en-IN", options);
    return formattedDate;
  }
  return (
    <MainDiv onClick={() => showOptions && setShowOptions(false)}>
      <InnerDiv>
        <RatingReviewMainWrpper>
          {editMode ? (
            <>
              {/* <input
                type="number"
                value={newRating}
                onChange={(e) => setNewRating(e.target.value)}
              /> */}
              <Rating
                rating={newRating}
                setRating={setNewRating}
                handleRatingChange={handleRatingChange}
                style={{
                  margin: "0 20px",
                }}
              />
              <EditModediv>
                {/* <input
                  type="text"
                  value={newReview}
                  onChange={(e) => setNewReview(e.target.value)}
                /> */}
                <StyledTextarea
                  value={newReview}
                  onChange={(e) => setNewReview(e.target.value)}
                  rows={2} // You can adjust the number of rows as needed
                  cols={200} // You can adjust the number of columns as needed
                />
                <EditMoreCancelDiv>
                  <DropdownButton
                    editsave
                    onClick={() => {
                      setShowOptions(false);
                      setEditMode(false);
                    }}
                  >
                    Cancel
                  </DropdownButton>
                  <DropdownButton editsave savemain onClick={handleSaveClick}>
                    Save
                  </DropdownButton>
                </EditMoreCancelDiv>
              </EditModediv>
            </>
          ) : (
            <>
              <div>
                <span>
                  <i
                    style={{ color: "#CCCCCC" }}
                    className={
                      newRating >= 1
                        ? "fa-sharp fa-solid fa-star"
                        : newRating >= 0.5
                        ? "fa-sharp fa-solid fa-star-half-alt"
                        : "far fa-star"
                    }
                  ></i>
                </span>
                <span>
                  <i
                    style={{ color: "#CCCCCC" }}
                    className={
                      newRating >= 2
                        ? "fa-sharp fa-solid fa-star"
                        : newRating >= 1.5
                        ? "fa-sharp fa-solid fa-star-half-alt"
                        : "far fa-star"
                    }
                  ></i>
                </span>
                <span>
                  <i
                    style={{ color: "#CCCCCC" }}
                    className={
                      newRating >= 3
                        ? "fa-sharp fa-solid fa-star"
                        : newRating >= 2.5
                        ? "fa-sharp fa-solid fa-star-half-alt"
                        : "far fa-star"
                    }
                  ></i>
                </span>
                <span>
                  <i
                    style={{ color: "#CCCCCC" }}
                    className={
                      newRating >= 4
                        ? "fa-sharp fa-solid fa-star"
                        : newRating >= 3.5
                        ? "fa-sharp fa-solid fa-star-half-alt"
                        : "far fa-star"
                    }
                  ></i>
                </span>
                <span>
                  <i
                    style={{ color: "#CCCCCC" }}
                    className={
                      newRating >= 5
                        ? "fa-sharp fa-solid fa-star"
                        : newRating >= 4.5
                        ? "fa-sharp fa-solid fa-star-half-alt"
                        : "far fa-star"
                    }
                  ></i>
                </span>
              </div>

              <ReviewRatingWrapper>
                <ReviewRating>
                  {newReview || value.review ? newReview || value.review : ""}
                </ReviewRating>
              </ReviewRatingWrapper>
            </>
          )}
        </RatingReviewMainWrpper>
        <IconWrapper>
          {authData !== undefined && value.userId === authData?._id && (
            <MoreIconDiv>
              <MoreVertIcon onClick={handleMoreVertClick} />
            </MoreIconDiv>
          )}
          {showOptions && (
            <DropDownDiv>
              <DropdownButton onClick={handleEdit}>Edit</DropdownButton>
              <DropdownButton onClick={handleDelete}>Delete</DropdownButton>
            </DropDownDiv>
          )}
        </IconWrapper>
      </InnerDiv>
      <NameDateIconWrapper>
        <ReviewerIconWrapper>
          <ReviewerIcon>{value?.userName?.charAt(0)?.toUpperCase()}</ReviewerIcon>
        </ReviewerIconWrapper>
        <NamedateWrapper>
          <ReviewerNameWrapper>
            <ReviewerName>{value?.userName}</ReviewerName>
          </ReviewerNameWrapper>
          <ReviewerNameWrapper>
            <ReviewerDate> {formatDate(time)}</ReviewerDate>
          </ReviewerNameWrapper>
        </NamedateWrapper>
      </NameDateIconWrapper>
      <LineWrapper>
        <Line />
      </LineWrapper>
      <ToastContainer />
      {showPopup && (
        <LoginPopup
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          setLoggedIn={setLoggedIn}
        />
      )}
    </MainDiv>
  );
};

ViewRating.defaultProps = {
  color: "#cccccc",
};
export default ViewRating;
