import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SideBar from "../../SideBar/SideBar";
import axios from "axios";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { UpdatePasswordSchema } from "./Schemas/UpdatePasswordSchema";
import { environmentVariables } from "../../../config/config";
import CircularLoader from "../../CircularLoader/CircularLoader";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../ContextApi/Contextapi";

const Root = styled.div`
  background-color: #e3e3e3;
  width: 100%;
  padding-top: 85px;
`;
const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  display: flex;
  justify-content: space-around;
  @media (max-width: 768px) {
    width: 100vw;
  }
  @media (max-width: 768px) {
    width: 100%;
    overflow: scroll;
    padding: 15px;
  }
`;
const Leftsidebar = styled.div`
  padding: 0 20px;
  justify-content: center;
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Rightsidebar = styled.div`
  width: 80%;
  @media (max-width: 768px) {
    width: 95vw;
  }
`;

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 0px 10px 3rem 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  @media (max-width: 768px) {
    padding: 0px 20px 20px;
  }
`;
const HeadWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: start;
  text-align: center;
  padding-top: 20px;
`;
const HeadText = styled.text`
  font-size: 24px;
  font-weight: 300px;
  color: #0098d4;
`;
const TextWrapper = styled.div`
  padding: inherit;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: flex-start;
  text-align: center;
  flex-direction: column;
  padding-bottom: 10px;
`;
const Text = styled.text`
  font-size: 15px;
  text-align: left;
  font-weight: 500;
`;
const Inputfield = styled.input`
  border-radius: 5px;
  border: 1px solid #c9c9c9;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 10px;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  cursor: pointer;
  width: 100%;
  background-color: #2196f3;
  color: #fff;
  font-size: 20px;
  margin: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
`;
const InpputFieldWrapper = styled.div`
  margin-left: 0rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;
const LineWrapper = styled.div`
  width: 100%;
  margin: 1rem 0;
`;
const Line = styled.div`
  height: 1px;
  border: 0.2px solid #e3e3e3;
`;

const ProfileForm = styled.div`
  margin-left: 0rem;
  width: 600px;
  @media (max-width: 1024px) {
    margin-left: 0;
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`;
const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
`;

const NavBarBackground = styled.div`
  width: 100vw;
  height: 84.8px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const UpdatePassword = ({ title,setLoggedIn }) => {
  document.title = title === undefined ? "" : title;
  const baseUrl = environmentVariables.apiUrl;
  const [userData, setUserData] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();
  const { authData } = useAuth();
  // const handleClick = (e) => {
  //   e.preventDefault();
  //   // if(newPassword===""){
  //   //   setError("Please Enter New Password")
  //   // }else if(newPassword.length<=6){
  //   //   setError("Password should be of 8 characters")
  //   // }else if(newPassword.search(/[0-9]/)==-1){
  //   //   setError("Password should be of 1 numeric character")
  //   // }else if(newPassword.search(/[a-z]/)==-1){
  //   //   setError("Password should be of 1 Lower Case character")
  //   // }else if(newPassword.search(/[A-Z]/)==-1){
  //   //   setError("Password should be of 1 Upper Case character")
  //   // }else if(newPassword.search(/[!\@\#\$\%\^\&\*\(\)\_\-\+\=\<\,\>\?]/)==-1){
  //   //   setError("Password should be of 1 Special character")
  //   // }else{
  //   //   setError("")
  //   // }

  //   if (newPassword === confirmNewPassword) {
  //     axios({
  //       method: "put",
  //       url: `${baseUrl}auth/updateuserpassword`,
  //       data: {
  //         oldpassword: oldPassword,
  //         newpassword: newPassword,
  //         confirmnewpassword: confirmNewPassword,
  //       },
  //       headers: {
  //         _token: JSON.parse(localStorage.getItem("authdata")).data.data.token,
  //       },
  //     })
  //       .then((response) => {
  //         const userInfo = JSON.parse(localStorage.getItem("authdata"));

  //         const newUpdatedUserInfo = {
  //           message: "user login",
  //           data: {
  //             name: userInfo.data.name,
  //             address: userInfo.data.address,
  //             cPassword:
  //               response.data.data.cPassword || userInfo.data.cPassword,
  //             country: userInfo.data.country,
  //             email: userInfo.data.email,
  //             gender: userInfo.data.gender,
  //             mobile: userInfo.data.mobile,
  //             password: response.data.data.password || userInfo.data.password,
  //             profile: userInfo.data.profile,
  //             token: userInfo.data.token,
  //             id: userInfo.data.id,
  //           },
  //         };
  //         localStorage.setItem("authdata", JSON.stringify(newUpdatedUserInfo));

  //         setResData(response.data);
  //         Swal.fire({
  //           icon: "success",
  //           title: response.data.message,
  //           timer: "1000",
  //         });
  //       })
  //       .catch((error) => {
  //         setError(error.response.data.message);
  //         // setError('Details are not valid');
  //       });
  //   } else {
  //     setError("Password Must be correct");
  //   }
  // };

  const getUserData = () => {
    if (authData !== undefined) {
      axios({
        method: "get",
        url: `${baseUrl}auth/getuserprofile`,
        headers: {
          _token: authData?.token,
        },
      })
        .then((response) => {
          setUserData(response.data.message);
        })
        .catch((error) => {
          setError(error.response.data);
        });
    }
  };
  useEffect(() => {
    getUserData();
  }, []);

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: UpdatePasswordSchema,
      onSubmit: async (values, action) => {
        setIsLoading(true);
        axios({
          method: "put",
          url: `${baseUrl}auth/updateuserpassword`,
          data: {
            oldpassword: values.oldPassword,
            newpassword: values.newPassword,
            confirmnewpassword: values.confirmNewPassword,
          },
          headers: {
            _token: authData?.token,
          },
        })
          .then((response) => {
            setIsLoading(false);
            if (
              response.data.message ===
              "Current Password and New Password should not be same"
            ) {
              Swal.fire({
                icon: "error",
                title: "Current Password and New Password should not be same",
                timer: "1000",
              });
            } else if (
              response.data.message ===
              "Existing Password is not correct,Try again later"
            ) {
              Swal.fire({
                icon: "error",
                title: "Existing Password is not correct,Try again later",
                timer: "1000",
              });
            } else if (response.data.message === "User Does Not exists") {
              Swal.fire({
                icon: "error",
                title: "User Does Not exists",
                timer: "1000",
              });
            } else {
              // socket.emit("user_update_password", authData?.email);
              Swal.fire({
                icon: "success",
                title: response.data.message,
                timer: "1000",
              });
              setLoggedIn(false);

              localStorage.removeItem("authdata");
              localStorage.removeItem("userdata");
              navigation("/");
            }

            // const newUpdatedUserInfo = {
            //   data: {
            //     message: "user login",
            //     data: {
            //       name: userInfo.data.data.name,
            //       address: userInfo.data.data.address,
            //       cPassword:
            //         response.data.data.cPassword ||
            //         userInfo.data.data.cPassword,
            //       country: userInfo.data.data.country,
            //       email: userInfo.data.data.email,
            //       gender: userInfo.data.data.gender,
            //       mobile: userInfo.data.data.mobile,
            //       password:
            //         response.data.data.password || userInfo.data.data.password,
            //       profile: userInfo.data.data.profile,
            //       token: userInfo.data.data.token,
            //       id: userInfo.data.data.id,
            //     },
            //     expirationTimestamp: userInfo.expirationTimestamp,
            //   },
            // };

            // // const newUpdatedUserInfo = {
            // //   data:{
            // //   message: "user login",
            // //   data: {
            // //     name: response.data.data.name || userInfo.data.data.name,
            // //     address: response.data.data.address || userInfo.data.data.address,
            // //     cPassword: userInfo.data.data.cPassword,
            // //     country: response.data.data.country || userInfo.data.data.country,
            // //     email: userInfo.data.data.email,
            // //     gender: response.data.data.gender || userInfo.data.data.gender,
            // //     mobile: response.data.data.mobile || userInfo.data.data.mobile,
            // //     password: userInfo.data.data.password,
            // //     profile: response.data.data.profile || userInfo.data.data.profile,
            // //     token: userInfo.data.data.token,
            // //     id: userInfo.data.data.id,
            // //   },expirationTimestamp:userInfo.expirationTimestamp
            // //   }
            // // };
            // localStorage.setItem(
            //   "authdata",
            //   JSON.stringify(newUpdatedUserInfo)
            // );

            // setResData(response.data);
            // Swal.fire({
            //   icon: "success",
            //   title: response.data.message,
            //   timer: "1000",
            // });

            // action.resetForm();
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error.response.data.message);
            // setError('Details are not valid');
          });
      },
    });
  return (
    <Root>
      <NavBarBackground></NavBarBackground>
      <Container>
        <Leftsidebar>
          <SideBar />
        </Leftsidebar>
        <Rightsidebar>
          <Wrapper>
            <HeadWrapper>
              <HeadText>Update Password</HeadText>
            </HeadWrapper>
            <LineWrapper>
              <Line />
            </LineWrapper>
            <ProfileForm>
              <TextWrapper>
                <Text>Current Password* </Text>
                <InpputFieldWrapper>
                  <Inputfield
                    type="password"
                    autoComplete={false}
                    placeholder="Current Password*"
                    name="oldPassword"
                    autoFocus={true}
                    value={values.oldPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onClick={() => setError("")}
                  />
                </InpputFieldWrapper>
              </TextWrapper>
              {errors.oldPassword && touched.oldPassword ? (
                <ErrorMessage>{errors.oldPassword}</ErrorMessage>
              ) : null}
              <TextWrapper>
                <Text>New Password* </Text>
                <InpputFieldWrapper>
                  <Inputfield
                    type="password"
                    placeholder="New Password*"
                    name="newPassword"
                    autoComplete={false}
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onClick={() => setError("")}
                  />
                </InpputFieldWrapper>
              </TextWrapper>
              {errors.newPassword && touched.newPassword ? (
                <ErrorMessage>{errors.newPassword}</ErrorMessage>
              ) : null}
              <TextWrapper>
                <Text>Confirm New Password* </Text>
                <InpputFieldWrapper>
                  <Inputfield
                    type="password"
                    placeholder="Confirm New Password*"
                    name="confirmNewPassword"
                    autoComplete={false}
                    value={values.confirmNewPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onClick={() => setError("")}
                  />
                </InpputFieldWrapper>
              </TextWrapper>
              {errors.confirmNewPassword && touched.confirmNewPassword ? (
                <ErrorMessage>{errors.confirmNewPassword}</ErrorMessage>
              ) : null}
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <ButtonWrapper>
                <Button type="button" onClick={handleSubmit}>
                  {isLoading ? (
                    <LoaderWrapper>
                      <CircularLoader />
                    </LoaderWrapper>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </ButtonWrapper>
            </ProfileForm>
          </Wrapper>
        </Rightsidebar>
      </Container>
    </Root>
  );
};

export default UpdatePassword;
