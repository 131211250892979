import React, { useEffect, useState } from "react";
import styled, {createGlobalStyle} from "styled-components";
import GiftCardTabs from "./GiftCardTabs";
import GiftList from "./GiftList";
import GiftCardDescription from "./GiftCardDesciption";
import CardDetail from "./CardDetail";
import { useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";



const GlobalStyle = createGlobalStyle`
  .prUVl {
    background-color: white !important;
  }
`;

const Root = styled.div`
  padding-top: 85px;
`;
const NavBarBackground = styled.div`
  width: 100vw;
  height: 77px;
  position: fixed;
  // background-color: white;
  // z-index: 100000000;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;

const GiftCard = ({ title, loggedIn, setLoggedIn }) => {
  document.title = title === undefined ? "" : title;
  const countryCode = localStorage.getItem("countryISOCode");
  const [selectedCountry, setSelectedCountry] = useState(
    countryCode === null ? "IN" : countryCode
  );
  const location = useLocation();
  const [active, setActive] = useState(
    location?.state === null ? "lists" : "cardbyid"
  );
  const [productId, setProductId] = useState(
    location?.state === null ? "" : location?.state?.productId
  );

  const [againData, setAgainData] = useState(location?.state);


  return (
    <Root className="mob-pt-0">
      <NavBarBackground></NavBarBackground>
      {/* <GiftCardTabs active={active} /> */}
      <GlobalStyle />
      {active === "lists" ? (
        <GiftList
          active={active}
          setActive={setActive}
          productId={productId}
          setProductId={setProductId}
          setSelectedCountry={setSelectedCountry}
          selectedCountry={selectedCountry}
        />
      ) : (
        <CardDetail
          active={active}
          setActive={setActive}
          productId={productId}
          setProductId={setProductId}
          loggedIn={loggedIn}
          againData={againData}
          setAgainData={setAgainData}
          setLoggedIn={setLoggedIn}
        />
      )}

      {/* <GiftCardDescription /> */}
    </Root>
  );
};

export default GiftCard;
