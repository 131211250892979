import ListGroup from "react-bootstrap/ListGroup";

function HowToUse({ poductData }) {
  let contentList = poductData?.redeemInstruction?.verbose.replace(
    /&#13;/g,
    ""
  );
  contentList = contentList?.split(". ").filter(Boolean);
  return (
    // <ListGroup as="ol" numbered variant="flush">
    //   {" "}
    //   <ListGroup.Item>{poductData?.redeemInstruction?.verbose}</ListGroup.Item>
    // </ListGroup>
    <ul style={{ padding: "0 30px 0 20px" }}>
      {contentList?.map((item, index) => (
        <li style={{ padding: "5px 0" }} key={index}>
          {item.trim()}
        </li>
      ))}
    </ul>
  );
}

export default HowToUse;
