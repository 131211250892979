import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import SideBar from "../../SideBar/SideBar";
import "./ShortlistedPlace.css";
import Card from "react-bootstrap/Card";
import HotelImg from "../../../images/hotelimg1.jpg";
import { getShortlistedHotel } from "./WishListedHotel";
import axios from "axios";
import { AuthContext, useAuth } from "../../../ContextApi/Contextapi";
import { Tooltip } from "@mui/material";
import LoginPopup from "./LoginPopup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { environmentVariables } from "../../../config/config";
import CircularLoader from "../../CircularLoader/CircularLoader";
import { useNavigate } from "react-router-dom";
// import getShortlistedHotel from "./WishListedHotel";

const Root = styled.div`
  background-color: #e3e3e3;
  width: 100%;
  padding-top: 85px;
`;
const Container = styled.div`
  /* width: 1200px; */
  margin: 0 auto;
  padding: 30px;
  display: flex;
  justify-content: space-around;
  @media (max-width: 768px) {
    width: 100%;
    overflow: scroll;
    padding: 15px;
  }
`;
const Leftsidebar = styled.div`
  // width: 20%;
  padding: 0 20px;
  justify-content: center;
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Rightsidebar = styled.div`
  width: 80%;
  @media (max-width: 768px) {
    width: 95vw;
  }
`;
const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 0px 20px 2rem 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  @media (max-width: 768px) {
    padding: 0;
  }
`;
const HeadWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: start;
  text-align: center;
  padding-top: 20px;
  @media (max-width: 768px) {
    padding: 20px 0 0px 20px;
  }
`;
const HeadText = styled.text`
  font-size: 24px;
  font-weight: 300px;
  color: #0098d4;
`;
const LineWrapper = styled.div`
  width: 100%;
  margin: 1rem 0;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;
const Line = styled.div`
  height: 1px;
  border: 0.2px solid #e3e3e3;
`;
const ShortlistWrapper = styled.div`
  background-color: #fcf9d7;
  width: 100%;
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const ShortText = styled.div`
  color: red;
  font-size: 16px;
  font-weight: 300;
`;

const InnerWrapper = styled.div`
  padding: 15px 0;
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  /* grid-gap: 10px; */
  row-gap: 15px;
  column-gap: 10px;
  @media (max-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1250px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 20px;
  }
  @media (max-width: 630px) {
    grid-template-columns: 1fr 1fr;
    padding: 0 20px;
  }
  @media (max-width: 425px) {
    grid-template-columns: 1fr;
  }
`;

const CardWrapper = styled.div`
  @media (max-width: 768px) {
    // padding-left: 5%;
  }
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

const HeartWrapper = styled.div`
  width: 40px;
  border-radius: 3px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  /* left: 88%; */
  right: 15px;
  top: 15px;
  /* right: 1px; */
  background-color: #fff;
  cursor: pointer;
  :hover {
    color: red !important;
  }
`;
const NavBarBackground = styled.div`
  width: 100vw;
  height: 84.8px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;
const ShortlistedPlace = ({ title }) => {
  document.title = title === undefined ? "" : title;
  const baseUrl = environmentVariables.apiUrl;
  const navigation = useNavigate();
  const [data, setData] = useState([]);
  // const { authData, setAuthData } = useContext(AuthContext);
  const [userData, setUserData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [key1, setKey1] = React.useState("");
  const { authData } = useAuth();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getHotel = () => {
    setIsLoading(true);
    axios({
      method: "get",
      url: `${baseUrl}auth/shortlisted`,
      headers: {
        _token: authData?.token,
      },
    })
      .then((response) => {
        setData(response.data.data);
        setIsLoading(false);
        if (
          response?.data?.data === null ||
          response?.data?.data === undefined ||
          response?.data?.data?.length === 0
        ) {
          setData([]);
          setIsLoading(false);
        }
        // setData([]);
      })
      .catch((error) => {
        if (error.response.data.message === "Data not found") {
          setData([]);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    getHotel();
  }, []);

  const handleClose1 = async (item, key) => {
    // alert(item._id)
    setIsLoading(true);
    if (authData === undefined) {
      setShowPopup(true);
    } else {
      await axios({
        url: `${baseUrl}auth/getshortlisted/${item._id}`,
        method: "post",
        headers: {
          _token: authData?.token,
        },
      })
        .then((response) => {
          getUser();
          setOpen(false);
          setIsLoading(false);
          getHotel();
        })
        .catch((error) => {
          // console.log("error", error);
        });
    }
  };
  const onClickLike = async (item, key) => {
    setIsLoading(false);
    setOpen(true);
    setKey1(key);
  };
  const getComponents = () => {
    if (data === null || data === undefined) {
      return (
        <ShortlistWrapper>
          <ShortText>Data not found</ShortText>
        </ShortlistWrapper>
      );
    } else if (data.length === 0) {
      return (
        <ShortlistWrapper>
          <ShortText>You haven't shortlisted any place yet</ShortText>
        </ShortlistWrapper>
      );
    } else {
      return (
        <InnerWrapper>
          {data.map((item, key) => {
            let defaultImage = "https://dummyimage.com/300x200/000/fff";
            let image =
              item.image[0] != undefined && item?.image[0] != "medianew"
                ? `https://bastionex-travels.b-cdn.net/uploads/${item.image[0]}`
                : defaultImage;
            return (
              <CardWrapper key={key}>
                <Card id="shortlisted-card" style={{ minHeight: "16.5rem" }}>
                  <Card.Img
                    style={{
                      maxHeight: "160px",
                      cursor: "pointer",
                      minHeight: "160px",
                    }}
                    variant="top"
                    src={image}
                    onClick={() =>
                      navigation(
                        item.type !== "activity"
                          ? `/hoteldetails/${item?._id}`
                          : `/activityDetail/${item?._id}`
                        // { state: item }
                      )
                    }
                  />
                  <Tooltip title="Shortlist" styled={{ fontSize: "50px" }}>
                    <HeartWrapper onClick={() => onClickLike(item, key)}>
                      <i
                        style={{
                          fontSize: "20px",
                          color:
                            userData && userData.includes(item._id)
                              ? "red"
                              : "#747474",
                        }}
                        className="fa fa-thin fa-heart style"
                      ></i>
                    </HeartWrapper>
                  </Tooltip>
                  <Card.Body
                    style={{
                      maxHeight: "80px",
                      marginBottom: "20px",
                      minHeight: "80px",
                    }}
                  >
                    <Card.Title
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "left",
                      }}
                    >
                      {item.hotelname}
                    </Card.Title>
                    <Card.Title style={{ fontWeight: "300", fontSize: "14px" }}>
                      {item.city} , {item.state}
                    </Card.Title>
                  </Card.Body>
                </Card>
                {key1 === key && (
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Are you sure you want to remove hotel?"}
                    </DialogTitle>
                    {/* <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Let Google help apps determine location. This means sending anonymous
                            location data to Google, even when no apps are running.
                          </DialogContentText>
                        </DialogContent> */}
                    <DialogActions>
                      <Button onClick={handleClose}>No</Button>
                      <Button onClick={() => handleClose1(item, key)} autoFocus>
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
              </CardWrapper>
            );
          })}
        </InnerWrapper>
      );
    }
  };
  const getUser = async () => {
    try {
      const data = await axios.get(`${baseUrl}auth/getuserdata`, {
        headers: {
          _token: authData?.token,
        },
      });
      setUserData(data.data.data.shortlistedArray);
    } catch (error) {
      return error?.message;
    }
  };
  useEffect(() => {
    getUser();
  }, [authData]);

  return (
    <Root>
      <NavBarBackground></NavBarBackground>
      <Container>
        <Leftsidebar>
          <SideBar />
        </Leftsidebar>
        <Rightsidebar>
          <Wrapper>
            <HeadWrapper>
              <HeadText> Shortlisted</HeadText>
            </HeadWrapper>
            <LineWrapper>
              <Line />
            </LineWrapper>
            {isLoading === true ? (
              <ShortlistWrapper>
                <CircularLoader></CircularLoader>
              </ShortlistWrapper>
            ) : (
              getComponents()
            )}
          </Wrapper>
        </Rightsidebar>
        {showPopup && (
          <LoginPopup showPopup={showPopup} setShowPopup={setShowPopup} />
        )}
      </Container>
    </Root>
  );
};

export default ShortlistedPlace;
