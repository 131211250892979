import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SearchIcon from "../../images/SearchIconNavbar.png";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { environmentVariables } from "../../config/config";

const MainRoot = styled.div`
  width: 30%;
  border-radius: 50px;
  border: 1px solid #ffffffb3;
  z-index: 0;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    border-radius: 0;
  }
`;
const Root = styled.div`
  z-index: 10000;
  position: relative;
`;

const IconSearch = styled.div`
  width: 5%;
  top: -3px;
  left: 15px;
  font-size: 26px;
  /* background-color: #0cb09b; */
  display: inline-block;
  position: absolute;
  height: 59px;
  line-height: 55px;
  text-align: center;
  @media (max-width: 768px) {
    width: 59px;
  }
`;

const Input = styled.input`
  width: ${(props) => (props.isGiftsRoute ? "100%" : "95%")};
  height: 55px;
  /* font-size: 20px; */
  color: #fff;
  outline: none;
  background-color: ${(props) =>
    props.isGiftsRoute ? "#E8EBEF" : "transparent"};
  padding: 0 10px 0 50px;
  border-radius: ${(props) => (props.isGiftsRoute ? "40px" : "none")};

  /* opacity: 1; */
  font-size: 16px;
  /* border: 1px solid rgba(75, 233, 245, 0.541); */
  border: none;
  @media (max-width: 768px) {
    width: 80%;
  }
`;
const FilterContainer = styled.div`
  /* margin-top: 5px; */
  position: absolute;
  top: 60px;
  width: 100%;
  height: auto;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 0 0 5px 5px;
  /* ::-webkit-scrollbar{
    display: none;
  } */
`;

const Wrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
`;
const FilteredData = styled.div`
  /* margin-left: 10px; */
  cursor: pointer;
  :hover {
    background-color: #3554d1 !important;
    color: #fff;
    /* padding: 10px; */
    width: 100%;
  }
`;
const Image = styled.img``;

const DetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 0px 0px;
`;

const InsideWrapper1 = styled.div``;

const InsideWrapper2 = styled.div``;

const SearchBar = ({ setSearchPopup, show, setShow }) => {
  const baseUrl = environmentVariables.apiUrl;
  const [search, setSearch] = useState("");
  const [cursor, setCursor] = useState(0);
  const [filter, setFilter] = useState();
  const navigation = useNavigate();
  const location = useLocation();
  // const debouncedSearch = debounce(getSearchData, 300);

  const handleChange = (event) => {
    const data = event.target.value;
    if (data.length >= 3) {
      setSearch(data);
      // debouncedSearch();
    } else {
      setSearch();
    }
  };
  function debounce(func, delay) {
    let timeout;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, delay);
    };
  }

  const abortController = new AbortController();
  const getSearchData = () => {
    if (search && baseUrl) {
      axios({
        method: "post",
        url: `${baseUrl}auth/filteredhoteldetails/${search}`,
        signal: abortController.signal,
      })
        .then((response) => {
          setFilter(response.data.message);
        })
        .catch((error) => {
          setFilter([]);
        });
    }
  };

  useEffect(() => {
    getSearchData();
    return () => {
      abortController.abort();
    };
  }, [search]);

  const KeyDown = (e) => {
    if (e.keyCode === 38 && cursor > 0) {
      // setSelect(filter[cursor]?.hotelname)
      setCursor((preState) => preState - 1);
    } else if (e.keyCode === 40 && cursor < 5) {
      // setSelect(filter[cursor]?.hotelname)
      setCursor((preState) => preState + 1);
    } else if (e.keyCode === 38 && cursor === 0) {
      setCursor(5);
    } else if (e.keyCode === 40 && cursor === 5) {
      setCursor(0);
    }

    if (e.keyCode === 13) {
      setSearch(filter[cursor]?.hotelname);
      // console.log("fffffffffffffaaaaaaa",filter[cursor])
      navigation(`/hoteldetails/${filter[cursor]?._id}`);
      setShow(false);
      setSearch("");
    }
    if (e.keyCode === 9) {
      setSearch(filter[cursor]?.hotelname);
    }
  };

  return (
    <MainRoot>
      <Root
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
        }}
      >
        <IconSearch>
          <Image src={SearchIcon} alt="img" />
        </IconSearch>
        <Input
          type="search"
          placeholder={"Search by City"}
          value={search}
          onChange={handleChange}
          onKeyDown={(e) => KeyDown(e)}
          // autoComplete="off"
          autoComplete={false}
          isGiftsRoute={
            location.pathname.includes("/gifts") ||
            location.pathname.includes("/topups")
          }
        />
      </Root>
      <div>
        {show && (
          <FilterContainer>
            {filter === undefined || search === undefined ? (
              <></>
            ) : filter.length !== 0 ? (
              filter.slice(0, 5).map((value, key) => {
                return (
                  <Wrapper>
                    <FilteredData
                      key={key}
                      style={
                        cursor === key
                          ? {
                              backgroundColor: "#3554d1",
                              padding: "13px 20px",
                              width: "100%",
                              color: "#fff",
                              // borderRadius:"5px"
                            }
                          : {
                              backgroundColor: "#fff",
                              padding: "10px 20px",
                              width: "100%",
                            }
                      }
                      onClick={() => {
                        setShow(false);
                        navigation(`/hoteldetails/${value?._id}`);
                        setSearch("");
                      }}
                    >
                      <DetailWrapper>
                        <InsideWrapper1>{value.hotelname}</InsideWrapper1>
                        <InsideWrapper2>{value.city}</InsideWrapper2>
                      </DetailWrapper>
                    </FilteredData>
                  </Wrapper>
                );
              })
            ) : (
              <FilteredData
                style={{
                  backgroundColor: "#fff",
                  padding: "10px",
                  width: "100%",
                }}
              >
                No Matching Result
              </FilteredData>
            )}
          </FilterContainer>
        )}
      </div>
      {/* </OverLay> */}
    </MainRoot>
  );
};

export default SearchBar;
