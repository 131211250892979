import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Root = styled.div`
  /* padding-top: 85px; */
  /* margin: 20px 0; */
  /* background-color: #e3e3e3; */
  /* padding: 20px 0; */
  @media (max-width: 768px) {
    display: none;
  }
`;
const Container = styled.div`
  /* width: 1100px;
margin: 0 auto; */
`;
const HeadWrapper = styled.div`
  display: flex;
  // align-items: flex-start;
  // justify-content: center;
  // text-align: center;
  padding: 15px 25px;
`;
const HeadText = styled.text`
  font-size: 20px;
    color: rgb(104 104 104);
`;
const LinkWrapper = styled.div`
    min-width: 300px;
    width: 100%;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 17px;
    margin: 0;
    font-weight: 300;
    /* background-color: #fff; */
    /* border-left: 6px solid rgb(213 213 213); */
    padding: 15px 25px;
    color: rgb(52 52 52);
  :hover {
    background-color: rgb(27 122 197);
    border-left:5px solid #0098D4;
    color: #fff;
  }
  ${(p) =>
    p.select &&
    `
     background-color: rgb(27 122 197);
     color:#fff;
     border-left:5px solid #0098D4;
     padding: 15px 25px;
    // border-right : 2px solid #0098D4;
    // border-top : 2px solid #0098D4;
    // border-bottom : 2px solid #0098D4;
  `};
`;
const Link = styled.text`
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
`;
const Wrapper = styled.div`
  background-color: #fff;
  max-width: 300px;
  border-radius: 10px;
  /* height: 50vh; */
  /* padding-bottom: 8rem; */
`;
const NavBarBackground = styled.div`
  width: 100vw;
  height: 84.80px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;
const SideBar = () => {
  const [selected, setselected] = useState(1);
  const navigation = useNavigate();

  useEffect(() => {
    
    if (window.location.href.split("/").pop() === "bookinghistory") {
      setselected(1);
    } else if (window.location.href.split("/").pop() === "shortlistedplace") {
      setselected(2);
    } else if (window.location.href.split("/").pop() === "gift-topup-history") {
      setselected(3);
    } else if (window.location.href.split("/").pop() === "profilesetting") {
      setselected(4);
    } else if (window.location.href.split("/").pop() === "updatepassword") {
      setselected(5);
    }
  }, [selected]);

  return (
    <Root>
      <NavBarBackground></NavBarBackground>
      <Container>
        <Wrapper>
          <HeadWrapper>
            <HeadText>My Account</HeadText>
          </HeadWrapper>
          {/* <hr /> */}

          <LinkWrapper
            select={selected === 1}
            onClick={() => {
              setselected(1);
              navigation("/bookinghistory");
            }}
          >
            <Link>My Bookings</Link>
          </LinkWrapper>
          <LinkWrapper
            select={selected === 2}
            onClick={() => {
              setselected(2);
              navigation("/shortlistedplace");
            }}
          >
            <Link>Shortlisted Places</Link>
          </LinkWrapper>
          <LinkWrapper
            select={selected === 3}
            onClick={() => {
              setselected(3);
              navigation("/gift-topup-history");
            }}
          >
            <Link>Giftcard & Topup History</Link>
          </LinkWrapper>
          <LinkWrapper
            select={selected === 4}
            onClick={() => {
              setselected(4);
              navigation("/profilesetting");
            }}
          >
            <Link>User Profile</Link>
          </LinkWrapper>
          <LinkWrapper
            select={selected === 5}
            onClick={() => {
              setselected(5);
              navigation("/updatepassword");
            }}
          >
            <Link>Update Password</Link>
          </LinkWrapper>
          {/* <LinkWrapper>
            <Link>Upcoming Booking</Link>
         </LinkWrapper> */}
          {/* <LinkWrapper>
            <Link
              onClick={() => {
               setselected("Completed Booking");
               navigation("/completebooking");
             }}
            >Completed Booking</Link>
         </LinkWrapper> */}
          {/* <LinkWrapper>
            <Link>Visited places</Link>
         </LinkWrapper> */}
        </Wrapper>
      </Container>
    </Root>
  );
};

export default SideBar;
