import React, { useState } from "react";
import {
  CloseIcon,
  OverLay,
} from "../../styledcomponents/popupStyledcomponents";
import styled from "styled-components";
import Login from "./Login";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import VerifyPassword from "./VerifyPassword";
import OtpforMailVerification from "./OtpforMailVerification";
const Root = styled.div`
  position: fixed;
  width: 466px;
  padding: 30px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  @media (max-width: 560px) {
    width: 90%;
  }
`;
function LoginPopup({
  setShowPopup,
  showPopup,
  pageRequest = "login",
  setLoginHandler,
  setLoggedIn
}) {
  const [loginState, setLoginState] = useState("login");

  return (
    <OverLay
      onClick={() => {
        setShowPopup(false);
      }}
    >
      <Root onClick={(e) => e.stopPropagation()}>
        <CloseIcon onClick={() => setShowPopup(false)}>
          <i className="fa-solid fa-xmark"></i>
        </CloseIcon>
        {loginState === "login" && (
          <Login
            setLoginState={setLoginState}
            setShowPopup={setShowPopup}
            pageRequest={pageRequest}
            setLoginHandler={setLoginHandler}
            setLoggedIn={setLoggedIn}
          />
        )}
        {loginState === "signUp" && (
          <SignUp setLoginState={setLoginState} setShowPopup={setShowPopup} />
        )}
        {loginState === "forgotPassword" && (
          <ForgotPassword
            setLoginState={setLoginState}
            setShowPopup={setShowPopup}
          />
        )}
        {loginState === "verifyotp" && (
          <VerifyPassword setShowPopup={setShowPopup}></VerifyPassword>
        )}
        {loginState === "mailverificationotp" && (
          <OtpforMailVerification
            setShowPopup={setShowPopup}
            setLoginState={setLoginState}
          ></OtpforMailVerification>
        )}
      </Root>
    </OverLay>
  );
}

export default LoginPopup;
