import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import BangaloreInput from "./BangaloreInput";
import BangaloreResorts from "./BangaloreResorts";
import ResortsCard from "./ResortsCard";

const Root = styled.div`
  background-color: #e9e9e9;
  padding: 125px 0 40px 0;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const NavBarBackground = styled.div`
  width: 100vw;
  height: 84.8px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;
const About = () => {
  const windowRef = useRef(null);
  useEffect(() => {
    // if (window !== "undefined") {
    //   window.scrollTo(0, 0);
    // }
    windowRef.current.scrollTo(0, 0);
  }, []);
  return (
    <Root ref={windowRef}>
      <NavBarBackground></NavBarBackground>
      <BangaloreResorts />
      {/* <BangaloreInput/> */}
      <ResortsCard />
    </Root>
  );
};

export default About;
