import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "react-bootstrap/Card";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { environmentVariables } from "../../config/config";

const Root = styled.div`
  padding: 40px 0;
  @media (max-width: 768px) {
    padding: 0;
  }
  /* background-color: #e9e9e9; */
`;
const Container = styled.div`
  width: 1200px;
  margin: auto;
  @media (max-width: 1600px) {
    width: 1200px;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    width: 100vw;
    padding: 0;
  }
`;
const CityWrapper = styled.div`
  /* margin: 80px 0; */
`;

const CardTextWrapper = styled.div`
  position: absolute;
  bottom: 118px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;

  @media (max-width: 390px) {
    width: 90%;
  }
`;

const TextWrapper = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start; */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 50px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    width: 100vw;
    padding-bottom: 10px;
    padding-left: 0px;
    align-items: center;
  }
`;
const Text = styled.text`
  /* font-size: 32px;
  padding: 10px 0; */
  font-size: 26px;
  font-weight: 500;
  padding: 10px 0;
  @media (max-width: 768px) {
    font-size: 25px;
  }
`;
const Button = styled.button`
  color: #fff;
  transition: 0.6s ease;
  z-index: 1;
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  font-weight: 500;
  border-color: #fff;
  background-color: #fff;
  color: #000;
  padding: 12px 0px;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  @media (max-width: 768px) {
    left: 96px;
  }
`;
const Overlay = styled.text`
  width: 100%;
  position: absolute;
  transition: 0.6s ease;
  border-radius: 10px;
  height: 100%;
  background: linear-gradient(270deg, rgba(5, 16, 54, 0.5), #051036 72.43%);
  /* width: 258px; */
  opacity: 0;
  &:hover {
    opacity: 1;
    & ${Button} {
      bottom: 25px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CardWrapper = styled.div`
  margin: 0 10px;
  padding: 0 10px;
  @media (max-width: 768px) {
    width: 100vw;
    margin: 0%;
  }
`;

const HotelWithState = ({ chooseMessage, chooseMessage1 }) => {
  const baseUrl = environmentVariables.apiUrl;
  const [data, setData] = useState([]);
  const navigation = useNavigate();

  const getHotelData = async () => {
    await axios({
      method: "get",
      url: `${baseUrl}auth/getcitiesdata`,
    })
      .then((response) => {
        setData(response.data.message);
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };
  useEffect(() => {
    getHotelData();
  }, []);

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          // arrows: false,
        },
      },
    ],
  };

  const getCity = localStorage.getItem("city");
  const callApi = async (x) => {
    await axios({
      method: "post",
      url: `${baseUrl}auth/gethotel`,
      data: {
        state: x,
      },
    })
      .then((response) => {
        navigation("/hotel", { state: response.data });
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };
  const getCityData = (slug, city) => {
    localStorage.setItem("city", slug);
    localStorage.setItem("cityname", city);
    callApi(slug || getCity);
    chooseMessage(slug);
    chooseMessage1(city);
  };
  const handlePopularCityLink = (city) => {
    if (city === "Udaipur") {
      getCityData("Rajasthan", city);
    } else if (city === "Ajmer") {
      getCityData("Rajasthan", city);
    } else if (city === "Jaisalmer") {
      getCityData("Rajasthan", city);
    } else if (city === "Bangalore") {
      getCityData("Karnataka", city);
    } else if (city === "Mysore") {
      getCityData("Karnataka", city);
    }
  };
  return (
    <Root>
      <Container>
        <CityWrapper>
          <TextWrapper>
            <Text>Popular Destinations</Text>
          </TextWrapper>
          <CardWrapper>
            <Slider {...settings}>
              {data.map((item, key) => {
                if (
                  item.cityName === "Bangalore" ||
                  item.cityName === "Jaisalmer" ||
                  item.cityName === "Udaipur" ||
                  item.cityName === "Ajmer" ||
                  item.cityName === "Mysore"
                ) {
                  return (
                    <CardWrapper key={key}>
                      <Card
                        style={{
                          position: "relative",
                          borderRadius: "10px",
                        }}
                      >
                        <Card.Img
                          style={{
                            maxHeight: "300px",
                            minHeight: "300px",
                            cursor: "pointer",
                            borderRadius: "10px",
                          }}
                          variant="top"
                          src={`https://bastionex-travels.b-cdn.net/homepage_populardestination/${item.picture}`}
                          onClick={() => handlePopularCityLink(item.cityName)}
                        />

                        <Card.Body
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            padding: 0,
                          }}
                        >
                          <CardTextWrapper>
                            <Card.Title
                              style={{
                                fontWeight: "500",
                                fontSize: "35px",
                                textAlign: "center",
                                color: "#ffff",
                                fontFamily: "'Oswald', sans-serif",
                                zIndex: "999",
                                left: "0",
                              }}
                              onClick={() =>
                                handlePopularCityLink(item.cityName)
                              }
                            >
                              {item.cityName}
                            </Card.Title>
                          </CardTextWrapper>
                        </Card.Body>
                        <Overlay>
                          <Button
                            onClick={() => handlePopularCityLink(item.cityName)}
                          >
                            Discover
                          </Button>
                        </Overlay>
                      </Card>
                    </CardWrapper>
                  );
                }
              })}
            </Slider>
          </CardWrapper>
        </CityWrapper>
      </Container>
    </Root>
  );
};

export default HotelWithState;
