import React, { useEffect } from "react";
import styled from "styled-components";
import Herosection from "./Herosection";
import HotelCollection from "./HotelCollection";
import HotelSection from "./HotelSection";
import HotelWithState from "./HotelWithState";
import RangeForm from "../RangeForm/RangeForm";
import { useState } from "react";
import FilteredDataOnHome from "./FilteredDataOnHome";
import Radar from "radar-sdk-js";
import { useNavigate } from "react-router-dom";
import "./Home.css";

const Root = styled.div``;

function Home({
  message,
  message2,
  showDistanceSlider,
  setShowDistanceSlider,
  title,
  setLoggedIn,
  setResCity,
  setResState,
  setResCountry,
  resCity,
  resState,
  resCountry,
}) {
  document.title = title === undefined ? "" : title;
  const [filter, setFilter] = useState("");
  const [stayType, setStayType] = useState("all");
  const [interested, setInterested] = useState("all");
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);

  const navigation = useNavigate();

  Radar.initialize("prj_live_pk_8cef6dee5f31e220f09eba68a33c1556053a8e3b");

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);

      Radar.reverseGeocode(
        {
          latitude: lat,
          longitude: long,
        },
        function (err, result) {
          if (!err) {
            console.log("location", result);
            setResState(result.addresses[0].state);
            setResCity(result.addresses[0].city);
            setResCountry(result.addresses[0].country);
          } else {
            setResState(null);
            setResCity(null);
          }
        }
      );
    } else {
      setLat(null);
      setLong(null);
    }
  };
  const showPosition = (position) => {
    setLat(position.coords.latitude);
    setLong(position.coords.longitude);
    setShowDistanceSlider(true);
  };

  useEffect(() => {
    getLocation();
  }, []);

  const callApi = (x, y) => {
    navigation("/hotel", { state: { stateName: x, cityName: y } });
  };
  useEffect(() => {
    if (resCity !== "" && resCity !== null) {
      callApi(resState, resCity);
      localStorage.setItem("city", resState);
      localStorage.setItem("cityname", resCity);
      localStorage.setItem("countryname", resCountry);
      if (resCountry?.toLowerCase() == "united arab emirates") {
        localStorage.setItem("countryISOCode", "AE");
        localStorage.setItem("countryCodeForPricing", "AE");
      } else {
        localStorage.setItem("countryISOCode", "IN");
        localStorage.setItem("countryCodeForPricing", "IN");
      }
    }
  }, [resState, resCity]);
  const chooseMessage = (slug) => {
    // console.log({ slug });
  };

  const chooseMessage1 = (city) => {
    // console.log({ city });
  };

  return (
    <Root>
      <Herosection message={message} message2={message2} />
      <RangeForm
        message={message}
        message2={message2}
        interested={interested}
        setInterested={setInterested}
        stayType={stayType}
        setStayType={setStayType}
      ></RangeForm>
      {interested !== "all" || stayType !== "all" ? (
        <FilteredDataOnHome
          interested={interested}
          stayType={stayType}
          setLoggedIn={setLoggedIn}
        />
      ) : (
        <HotelSection filter={filter} setLoggedIn={setLoggedIn} />
      )}
      <HotelWithState
        chooseMessage={chooseMessage}
        chooseMessage1={chooseMessage1}
      />
      <HotelCollection message={message} message2={message2} />
    </Root>
  );
}

export default Home;
