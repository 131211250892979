import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SideBar from "../../SideBar/SideBar";
import axios from "axios";
import CircularLoader from "../../CircularLoader/CircularLoader";
import { environmentVariables } from "../../../config/config";
import Model from "../../TopupCard/Model";
import { LoaderWrapper } from "../../styledcomponents/popupStyledcomponents";
import RechargeAgainModel from "../../TopupCard/RechargeAgainModel";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import SendGiftAgain from "../../GiftCard/SendGiftAgainModel";
import { useAuth } from "../../../ContextApi/Contextapi";
import { useQuery } from "react-query";
import ContentLoader from "react-content-loader";

const Root = styled.div`
  background-color: #e3e3e3;
  width: 100%;
  padding-top: 85px;
`;
const Container = styled.div`
  /* width: 1200px; */
  margin: 0 auto;
  padding: 30px;
  display: flex;
  justify-content: space-around;
  @media (max-width: 768px) {
    width: 100%;
    overflow: scroll;
    padding: 15px;
  }
`;
const Leftsidebar = styled.div`
  // width: 20%;
  padding: 0 20px;
  justify-content: center;
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;
const NavBarBackground = styled.div`
  width: 100vw;
  height: 84.8px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;
const Rightsidebar = styled.div`
  width: 80%;
  @media (max-width: 1360px) {
    width: 1360px;
    overflow: scroll;
  }
  @media (max-width: 768px) {
    width: 100%;
    overflow: scroll;
  }
`;
const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 0px 10px 3rem 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  @media (max-width: 1360px) {
    width: 1360px;
    overflow: scroll;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    overflow: scroll;
  }
`;
const HeadWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: start;
  text-align: center;
  padding-top: 20px;
  @media (max-width: 768px) {
    padding: 20px 0 0px 20px;
  }
`;
const HeadText = styled.text`
  font-size: 20px;
  color: rgb(104 104 104);
`;
const LineWrapper = styled.div`
  width: 100%;
  margin: 1rem 0;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;
const Line = styled.div`
  height: 1px;
  border: 0.2px solid #e3e3e3;
`;
const MainTab = styled.div`
  display: flex;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
  }
`;
const FirstTab = styled.div``;
const SecondTab = styled.div``;
const DataWrapper = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    width: 1360px;
    padding: 0 0 20px 0px;
  }
`;
const RecentlyUploadedHeader = styled.div`
  display: grid;
  // grid-template-columns: 15% 15% 15% 15% 25% 15%;
  margin: 15px 0%;
  padding: 14px 0px;
  @media (max-width: 768px) {
    // display: none;
    width: 1360px;
    overflow: scroll;
    padding: 0 20px;
  }
`;
const RecentlyUploaded = styled.div`
  background: #fff;
  display: grid;
  // grid-template-columns: 25% 15% 15% 15% 15% 5% 10%;
  // grid-template-columns:26% 14% 14% 13% 12% 8% 13%;
  -webkit-box-align: center;
  align-items: center;
  margin: 15px 0%;
  padding: 14px 0px;
  box-shadow: 0px 0px 5px 5px #0000;
  border-radius: 5px;
  @media (max-width: 768px) {
    width: 1360px;
    padding: 0 20px;
  }
`;

const RecentlyUploadedDate = styled.div`
  // @media (max-width: 768px) {
  // display: none;
  // width: 1024px;
  // }
`;
const DocInfo = styled.div`
  display: flex;
`;
const DocName = styled.div`
  margin-left: 4px;
  margin-right: 2px;
  // font-weight: 600;
`;
const RecentlyUploadedHeaderElem = styled.div`
  color: #6c7074;
`;
const TableBodyCellStatusSuccess = styled.div`
  color: #00a154;
  background-color: rgb(153 255 206 / 24%);
  text-align: center;
  font-weight: 500;
  border-radius: 5px;
  width: 110px;
  font-size: 14px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TableBodyCellStatusFailed = styled.div`
  color: #f76c6c;
  background-color: #f28d8d;
  text-align: center;
  font-weight: 500;
  border-radius: 5px;
  width: 110px;
  font-size: 14px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;
const StyledButton = styled.button`
  width: 120px;
  background-color: #3554d1;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 6px 15px;
  font-size: 13px;
  cursor: pointer;
  // width: 150px;
  &:hover {
    background-color: #0056b3;
  }
`;

const GiftTopupHistory = ({ title }) => {
  const baseUrl = environmentVariables.apiUrl;
  const [activeTab, setActiveTab] = useState("gift");
  // const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  // const [open, setOpen] = useState(false);
  const [rechargeValue, setRechargeValue] = useState();
  const [giftCardValue, setGiftCardValue] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertForGift, setOpenAlertForGift] = useState(false);
  const navigate = useNavigate();
  const [loadingStates, setLoadingStates] = useState({});
  const [loadingStatesForGift, setLoadingStatesForGift] = useState({});
  const { authData } = useAuth();
  function formatDate(timestamp) {
    const options = {
      timeZone: "Asia/Kolkata",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    const formattedDate = new Date(timestamp).toLocaleString("en-IN", options);
    return formattedDate.replace(",", ""); // Remove the comma after the year
  }



  const RechargeAction = (item) => {
    setLoadingStates((prevState) => ({ ...prevState, [item._id]: true }));

    let data = JSON.stringify({
      number: item.recieverMobile,
      amount: item.localAmount,
    });
    let config = {
      method: "post",
      url: `${baseUrl}auth/checkTopUp/${item.recieverCountryCode}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        // setLoading(false);
        setLoadingStates((prevState) => ({
          ...prevState,
          [item._id]: false,
        }));
        if (response.data.message === "success") {
          navigate(`/topups?id=${item._id}`, { state: item });
        } else {
          setRechargeValue(item);
          setOpenAlert(true);
        }
      })
      .catch((error) => {
        // setLoading(false);
        setLoadingStates((prevState) => ({
          ...prevState,
          [item._id]: false,
        }));
        Swal.fire({
          icon: "error",
          title: "Something Went Wrong",
        });
      });
  };
  const SendAgain = (item) => {
    setLoadingStatesForGift((prevState) => ({
      ...prevState,
      [item._id]: true,
    }));

    let config = {
      method: "post",
      url: `${baseUrl}auth/getproductbyidgiftcard/${item.productId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        // setLoading(false);
        setLoadingStatesForGift((prevState) => ({
          ...prevState,
          [item._id]: false,
        }));
        if (response.status === 200) {
          navigate(`/gifts?id=${item._id}`, { state: item });
        } else {
          setGiftCardValue(item);
          setOpenAlertForGift(true);
        }
        // if (response.data.message === "success") {
        //   navigate(`/topups?id=${item._id}`, { state: item });
        // } else {
        //   setRechargeValue(item);
        //   setOpenAlert(true);
        // }
      })
      .catch((error) => {
        // setLoading(false);
        setLoadingStatesForGift((prevState) => ({
          ...prevState,
          [item._id]: false,
        }));
        Swal.fire({
          icon: "error",
          title: "Something Went Wrong",
        });
      });
  };
  const getComponents = () => {
    if (activeTab === "gift") {
      return giftTopupData?.data?.map((item, key) => {
        return (
          item?.giftcardstatus !== undefined && (
            <RecentlyUploaded
              style={{
                gridTemplateColumns: "23% 18% 9% 20% 15% 15%",
              }}
              key={key}
            >
              <DocInfo>
                {/* <DocImage /> */}
                {/* <Grid component="img" src={item.image[0]} ></Grid> */}
                <DocName>
                  {/* {item?.transactionId === undefined
                      ? "NA"
                      : item?.transactionId}{" "} */}
                  {item?.orderid}
                </DocName>
              </DocInfo>
              <RecentlyUploadedDate>
                {item?.giftCardRes === undefined
                  ? "NA"
                  : item?.giftCardRes?.product?.productName}
              </RecentlyUploadedDate>
              <RecentlyUploadedDate>
                {item?.recieverCurrencyCode} {item?.recieverLocalAmount}
              </RecentlyUploadedDate>
              <RecentlyUploadedDate>{item?.recieverEmail}</RecentlyUploadedDate>
              <RecentlyUploadedDate>
                {formatDate(item?.createdAt)}
              </RecentlyUploadedDate>
              <RecentlyUploadedDate>
                <StyledButton
                  onClick={() => SendAgain(item)}
                  type="submit"
                  disabled={loadingStatesForGift[item._id]}
                >
                  {loadingStatesForGift[item._id] ? (
                    <LoaderWrapper>
                      <CircularLoader />
                    </LoaderWrapper>
                  ) : (
                    "Send Again"
                  )}
                </StyledButton>
              </RecentlyUploadedDate>
            </RecentlyUploaded>
          )
        );
      });
    } else {
      return giftTopupData?.data?.map((item, key) => {
        return (
          item?.topupstatus !== undefined && (
            <RecentlyUploaded
              style={{
                gridTemplateColumns: "15% 15% 15% 10% 15% 15% 15%",
              }}
              key={key}
            >
              <DocInfo>
                {/* <DocImage /> */}
                {/* <Grid component="img" src={item.image[0]} ></Grid> */}
                <DocName>{item?.recieverMobile}</DocName>
              </DocInfo>
              <RecentlyUploadedDate>
                {item?.topupRes === undefined
                  ? "NA"
                  : item?.topupRes?.operatorName}
              </RecentlyUploadedDate>
              <RecentlyUploadedDate>
                {item?.transactionId === undefined ? "NA" : item?.transactionId}
              </RecentlyUploadedDate>
              <RecentlyUploadedDate>
                {item?.localCurrencyCode} {item?.localAmount}
              </RecentlyUploadedDate>
              <RecentlyUploadedDate>
                {item?.topupstatus === "Success" ? (
                  <TableBodyCellStatusSuccess>
                    {item?.topupstatus?.charAt(0).toUpperCase() +
                      item?.topupstatus?.slice(1).toLowerCase()}
                  </TableBodyCellStatusSuccess>
                ) : (
                  <TableBodyCellStatusFailed>
                    {item?.topupstatus?.charAt(0).toUpperCase() +
                      item?.topupstatus?.slice(1).toLowerCase()}
                  </TableBodyCellStatusFailed>
                )}
              </RecentlyUploadedDate>
              <RecentlyUploadedDate>
                {formatDate(item?.createdAt)}
              </RecentlyUploadedDate>
              <RecentlyUploadedDate>
                {/* <Button onClick={() => RechargeAction(item)}>
                  Recharge Again
                </Button> */}
                <StyledButton
                  onClick={() => RechargeAction(item)}
                  type="submit"
                  disabled={loadingStates[item._id]}
                >
                  {loadingStates[item._id] ? (
                    <LoaderWrapper>
                      <CircularLoader />
                    </LoaderWrapper>
                  ) : (
                    "Recharge Again"
                  )}
                </StyledButton>
              </RecentlyUploadedDate>
            </RecentlyUploaded>
          )
        );
      });
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Update the active tab when a tab is clicked
  };

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(10);

  const getgifttopupHistory = async (
    activeTab,
    email,
    token,
    page,
    perPage
  ) => {
    try {
      const data = {
        tab: activeTab,
        email: email,
      };

      const config = {
        method: "post",
        url: `${baseUrl}auth/gift-topup-history?page=${page}&perPage=${perPage}`,
        headers: {
          _token: token,
        },
        data: data,
      };

      const response = await axios(config);
      const responseData = response.data.data.sort(
        (a, b) => b.createdAt - a.createdAt
      );

      return {
        totalItems: response?.data?.totalDataLength,
        data: responseData,
      };
    } catch (err) {
      throw err;
    }
  };

  const {
    data: giftTopupData,
    isLoading,
    isError,
  } = useQuery(
    [
      "giftTopupHistory",
      activeTab,
      authData?.email,
      authData?.token,
      page,
      perPage,
    ],
    () =>
      getgifttopupHistory(
        activeTab,
        authData?.email,
        authData?.token,
        page,
        perPage
      )

  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const PaginationControls = () => {
    const totalPages = Math.ceil(giftTopupData?.totalItems / perPage);

    const handlePrevClick = () => {
      if (page > 1) {
        handlePageChange(page - 1);
      }
    };

    const handleNextClick = () => {
      if (page < totalPages) {
        handlePageChange(page + 1);
      }
    };

    return (
      <div className="pagination-controls pagination_in_BH">
        <button onClick={handlePrevClick} disabled={page === 1}>
          Previous
        </button>
        <span>
          Page {page} of {totalPages}
        </span>
        <button onClick={handleNextClick} disabled={page === totalPages}>
          Next
        </button>
      </div>
    );
  };



  return (
    <Root>
      <NavBarBackground></NavBarBackground>
      <Container>
        <Leftsidebar>
          <SideBar />
        </Leftsidebar>
        <Rightsidebar>
          <Wrapper>
            <HeadWrapper>
              <HeadText> Gift Card & Topup history </HeadText>
            </HeadWrapper>
            <LineWrapper>
              <Line />
            </LineWrapper>

            <MainTab className="btntab">
              <FirstTab
                className={`tab ${activeTab === "gift" ? "active" : ""}`}
                onClick={() => handleTabClick("gift")}
              >
                Gift Card History
              </FirstTab>
              <SecondTab
                className={`tab ${activeTab === "topup" ? "active" : ""}`}
                onClick={() => handleTabClick("topup")}
              >
                Topup History
              </SecondTab>
            </MainTab>
            <DataWrapper>
              {activeTab === "gift" ? (
                <RecentlyUploadedHeader
                  style={{
                    gridTemplateColumns: "23% 18% 9% 20% 15% 15%",
                    fontWeight: 600,
                  }}
                >
                  <RecentlyUploadedHeaderElem>
                    Transaction Id
                  </RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>
                    Product Name
                  </RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>Price</RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>
                    Reciever Email
                  </RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>Date</RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>
                    Action
                  </RecentlyUploadedHeaderElem>
                </RecentlyUploadedHeader>
              ) : (
                <RecentlyUploadedHeader
                  style={{
                    gridTemplateColumns: "15% 15% 15% 10% 15% 15% 15%",
                    fontWeight: 600,
                  }}
                >
                  <RecentlyUploadedHeaderElem>
                    Mobile No.
                  </RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>
                    Operator Name
                  </RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>
                    Transaction ID
                  </RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>
                    Amount
                  </RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>
                    Status
                  </RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>Date</RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>
                    Action
                  </RecentlyUploadedHeaderElem>
                  {/* <RecentlyUploadedHeaderElem>Invoice</RecentlyUploadedHeaderElem> */}
                  {/* <RecentlyUploadedHeaderElem>Payment</RecentlyUploadedHeaderElem> */}
                </RecentlyUploadedHeader>
              )}
              {isLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularLoader></CircularLoader>
                </div>
              ) : isError ? (
                <div
                  style={{
                    margin: "20px",
                    fontSize: "30px",
                    fontWeight: "500",
                  }}
                >
                  No Booking Found
                </div>
              ) : (
                <div>
                  {getComponents()}
                  <PaginationControls />
                </div>
                
              )}
              {/* {isLoading === true || data?.length === 0 ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularLoader></CircularLoader>
                </div>
              ) : ( */}
              <>
                {/* {getComponents()} */}
               
              </>
              {/* )} */}
            </DataWrapper>
          </Wrapper>
        </Rightsidebar>
        {/* {showPopup && (
          <LoginPopup showPopup={showPopup} setShowPopup={setShowPopup} />
        )} */}
        {/* {open && (
          <Model
            open={open}
            setOpen={setOpen}
            amount={rechargeValue.amount}
            countryIsoName={rechargeValue.topupRes.countryCode}
            number={rechargeValue.recieverMobile}
            name={rechargeValue.name}
            rechargeValue={rechargeValue}
          />
        )} */}
        <RechargeAgainModel
          open={openAlert}
          setOpen={setOpenAlert}
          Tokendata={rechargeValue}
        />
        <SendGiftAgain
          open={openAlertForGift}
          setOpen={setOpenAlertForGift}
          giftdata={giftCardValue}
        />
      </Container>
    </Root>
  );
};

export default GiftTopupHistory;
