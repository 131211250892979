import styled from "styled-components";
import Nav from "react-bootstrap/Nav";
import { useState } from "react";
import HowToUse from "./HowToUse";
import "./GiftCard.css";
import AboutCard from "./AboutCard";
import TermAndConditions from "./TermAndConditions";
import Offers from "./Offers";

const Items = styled.div`
  padding-top: 10px;
  // width: 185px;
  text-align: center;
  cursor: pointer;
`;
const TabText = styled.p`
  font-size: 25px;
  font-weight: 500;
  margin: 0 !important;
  color: #0A3473;
`;

const TabContainer = styled.div`
  display: flex;
  width: 100%;
  //   margin: 0 auto;
  // border-bottom: 2px solid #cccc;
`;
const ContentContainer = styled.div`
  // padding-top: 20px;
  text-align: justify;
`;
const Root = styled.div`
  padding: 0 0 10px 0;
  // border-bottom: 1px solid #cccc;
`;

const Container = styled.div`
  width: 100%;
  margin: 20px auto;
  padding:0 0 0 20px;
  @media (max-width: 768px) {
    // display: none;
  }
  @media (max-width: 1600px) {
    width: 100%;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
    margin: 0px auto;
  }
`;

const GiftCardDescription = ({ poductData }) => {
  const [activeTabs, setActiveTabs] = useState(1);
  const handleTabs = (index) => {
    setActiveTabs(index);
  };

  return (
    <Root>
      <Container>
        <TabContainer>
          <Items
            className={activeTabs === 1 ? "card-active" : ""}
            onClick={(e) => {
              handleTabs(1);
            }}
          >
            <TabText>How To Use</TabText>
          </Items>
          {/* <Items className={activeTabs===2 ? 'card-active' : '' } onClick={(e)=>{ handleTabs(2) }}>
                        <TabText >About The Gift Card</TabText>
                    </Items> */}
          {/* <Items
            className={activeTabs === 3 ? "card-active" : ""}
            onClick={(e) => {
              handleTabs(3);
            }}
          >
            <TabText>Terms & Conditions</TabText>
          </Items>
          <Items
            className={activeTabs === 4 ? "card-active" : ""}
            onClick={(e) => {
              handleTabs(4);
            }}
          >
            <TabText>Offer</TabText>
          </Items> */}
        </TabContainer>
        <ContentContainer>
          {activeTabs === 1 ? (
            <HowToUse poductData={poductData} />
          ) : activeTabs === 2 ? (
            <AboutCard />
          ) : activeTabs === 3 ? (
            <TermAndConditions />
          ) : (
            <Offers />
          )}
        </ContentContainer>
      </Container>
    </Root>
  );
};

export default GiftCardDescription;
