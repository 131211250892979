import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import ContentLoader from "react-content-loader";
import Tooltip from "@mui/material/Tooltip";
import LoginPopup from "../Login/LoginPopup";
import Radar from "radar-sdk-js";
import { environmentVariables } from "../../../config/config";
import CircularLoader from "../../CircularLoader/CircularLoader";
import Loader from "../../Shortlistedloader/Loader";
import { useAuth } from "../../../ContextApi/Contextapi";
const HeadingWrapper = styled.text`
  color: #131313;
  font-size: 32px;
  font-weight: bold;
  text-transform: capitalize;
`;
const TextHeading = styled.div`
  color: #34495e;
  font-size: 17px;
  font-weight: 700;
  line-height: 50px;
  margin-left: 20px;
`;
const TextWrapper = styled.div`
  display: flex;
  margin-left: 15px;
  font-size: 16px;
  padding-bottom: 10px;
`;

const IconLocation = styled.div`
  /* margin-top: 3px; */
  margin-left: 2px;
`;
const TextLocation = styled.div`
  margin-left: 10px;
  color: #6e6666;
`;
const ContainerMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const Button = styled.button`
  background-color: #3554d1;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-bottom: 1px solid #008d35;
  box-sizing: border-box;
  height: 40px;
  border: none;
`;

const TextIconOne = styled.div`
  font-size: 14px;
  color: #34495e;
  cursor: pointer;
`;
const TextIconTwo = styled.div`
  font-size: 14px;
  margin-left: 50px;
  color: #34495e;
  cursor: pointer;
`;

const TextIconThree = styled.div`
  display: flex;
  margin: 5px auto;
`;

const Li = styled.text``;

const HeartWrapper = styled.div`
  width: 40px;
  border-radius: 3px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  /* left: 88%; */
  right: 15px;
  top: 15px;
  /* right: 1px; */
  background-color: #fff;
  cursor: pointer;
  :hover {
    color: red !important;
  }
`;

const AllHotelsByTheme = () => {
  const baseUrl = environmentVariables.apiUrl;
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  const [data, setData] = useState();
  // const { authData, setAuthData } = useContext(AuthContext);
  const [userData, setUserData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [resCity, setResCity] = useState("");
  const [error, setError] = useState("");
  const [isHeartLoading, setIsHeartLoading] = useState({});
  const { authData } = useAuth();
  const getCity = async () => {
    await axios({
      method: "post",
      url: `${baseUrl}auth/getdistance`,
      data: {
        mindistance: state.minDistance,
        maxdistance: state.maxDistance,
        time: 2,
        lat: `${state.lat}`,
        long: `${state.long}`,
        category: state.category,
        theme: state.theme,
        state: state.state,
        city: resCity,
      },
    })
      .then((response) => {
        setLoading(false);
        setData(response?.data?.data);
      })
      .catch((error) => {
        if (error.response.data.message === "not found") {
          setError("No similar getaways");
          setLoading(false);
          setData([]);
        }
      });
  };

  useEffect(() => {
    getCity();
  }, [state, resCity]);

  const onClickLike = async (item, key) => {
    const compoundKey = `${item._id}-${key}`;
    if (authData === undefined) {
      setShowPopup(true);
    } else {
      setIsHeartLoading((prevState) => ({ ...prevState, [compoundKey]: true }));
      await axios({
        url: `${baseUrl}auth/getshortlisted/${item._id}`,
        method: "post",
        headers: {
          _token: authData?.token,
        },
      })
        .then((response) => {
          getUser(compoundKey);
        })
        .catch((error) => {
          // console.log("error", error);
        });
    }
  };

  const getUser = async (key) => {
    try {
      const data = await axios.get(`${baseUrl}auth/getuserdata`, {
        headers: {
          _token: authData?.token,
        },
      });
      setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
      setUserData(data.data.data.shortlistedArray);
    } catch (error) {
      setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
      return error?.message;
    }
  };
  useEffect(() => {
    getUser();
  }, [authData]);

  Radar.initialize("prj_live_pk_8cef6dee5f31e220f09eba68a33c1556053a8e3b");

  useEffect(() => {
    Radar.reverseGeocode(
      {
        latitude: state.lat,
        longitude: state.long,
      },
      function (err, result) {
        if (!err) {
          setResCity(result.addresses[0].city);
        } else {
          // console.log(err, "error in radar");
        }
      }
    );
  }, [resCity]);

  return (
    <>
      <Container>
        <HeadingWrapper>
          {" "}
          {/* {`${
            state.theme !== "all" &&
            state.category !== "all" &&
            state.state !== "all"
              ? state.theme + `,` + state.category
              : state.category !== "all" &&
                state.theme !== "all" &&
                state.state === "all"
              ? state.theme + `,` + state.category
              : state.theme === "all" &&
                state.category !== "all" &&
                state.state === "all"
              ? state.category
              : state.theme !== "all" &&
                state.category === "all" &&
                state.state === "all"
              ? state.theme
              : state.theme !== "all" &&
                state.category === "all" &&
                state.state !== "all"
              ? state.theme
              : state.theme === "all" &&
                state.category !== "all" &&
                state.state !== "all"
              ? state.category
              : state.theme === "all" &&
                state.category === "all" &&
                state.state === "all"
              ? "Discover getaways"
              : state.theme === "all" &&
                state.category === "all" &&
                state.state !== "all" &&
                "Discover getaways"
          }`} */}
          {data === undefined
            ? "Fetching Getaways"
            : data.length !== 0
            ? "Discover getaways"
            : "No Similar getaways"}{" "}
        </HeadingWrapper>

        <div className="row mt-4">
          {loading && (
            <ContentLoader
              width={2500}
              height={575}
              viewBox="0 0 1100 575"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="58" rx="2" ry="2" width="250" height="300" />
              <rect x="280" y="57" rx="2" ry="3" width="250" height="300" />
              <rect x="560" y="56" rx="2" ry="2" width="250" height="300" />
              <rect x="840" y="56" rx="2" ry="2" width="250" height="300" />
              <rect x="0" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="280" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="560" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="840" y="615" rx="2" ry="2" width="250" height="300" />
            </ContentLoader>
          )}
          {data &&
            data.length !== 0 &&
            data.map((item, key) => {
              return (
                <div
                  key={key}
                  className="col-xl-4 col-lg-4 col-md-4 col-sm-4 mb-4"
                >
                  <Card>
                    <Card.Img
                      style={{
                        minHeight: "300px",
                        maxHeight: "300px",
                        cursor: "pointer",
                      }}
                      src={
                        item.image[0] && item?.image[0] != "medianew"
                          ? `https://bastionex-travels.b-cdn.net/uploads/${item.image[0]}`
                          : `https://bastionex-travels.b-cdn.net/uploads/photo_2024-09-06_21-43-47.jpg`
                      }
                      onClick={() => navigation(`/hoteldetails/${item?._id}`)}
                    />
                    <Tooltip title="Shortlist" styled={{ fontSize: "50px" }}>
                      <HeartWrapper onClick={() => onClickLike(item, key)}>
                        {isHeartLoading[`${item._id}-${key}`] ? (
                          <Loader />
                        ) : (
                          <i
                            style={{
                              fontSize: "20px",
                              color:
                                userData && userData.includes(item._id)
                                  ? "red"
                                  : "#747474",
                            }}
                            className="fa fa-thin fa-heart style"
                          ></i>
                        )}
                      </HeartWrapper>
                    </Tooltip>
                    <TextHeading
                      onClick={() => navigation(`/hoteldetails/${item?._id}`)}
                    >
                      <Li style={{ cursor: "pointer" }}>{item.hotelname}</Li>
                    </TextHeading>
                    <TextWrapper>
                      <IconLocation>
                        {/* <MdLocationPin style={{ color: "rgb(12, 176, 155)" }} /> */}
                      </IconLocation>
                      <TextLocation>{item.city}</TextLocation>
                    </TextWrapper>
                    {/* <TextPara>{item.overview}</TextPara> */}
                    <TextIconThree>
                      <TextIconOne>
                        &#8377;{" "}
                        {item.rooms[0] !== undefined && item.rooms[0].price} /
                        night
                      </TextIconOne>
                      <TextIconTwo>
                        {/* <FaPlusCircle style={{ color: "#34495e" }} /> Shortlist */}
                      </TextIconTwo>
                    </TextIconThree>
                    <Button
                      onClick={() => navigation(`/hoteldetails/${item?._id}`)}
                    >
                      Enter Dates for Offers
                    </Button>
                  </Card>
                  {showPopup && (
                    <LoginPopup
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                    />
                  )}
                </div>
              );
            })}
        </div>
      </Container>
      <ContainerMobile>
        <HeadingWrapper>
          {" "}
          {/* {`${
            state.theme !== "all" &&
            state.category !== "all" &&
            state.state !== "all"
              ? state.theme + `,` + state.category
              : state.category !== "all" &&
                state.theme !== "all" &&
                state.state === "all"
              ? state.theme + `,` + state.category
              : state.theme === "all" &&
                state.category !== "all" &&
                state.state === "all"
              ? state.category
              : state.theme !== "all" &&
                state.category === "all" &&
                state.state === "all"
              ? state.theme
              : state.theme !== "all" &&
                state.category === "all" &&
                state.state !== "all"
              ? state.theme
              : state.theme === "all" &&
                state.category !== "all" &&
                state.state !== "all"
              ? state.category
              : state.theme === "all" &&
                state.category === "all" &&
                state.state === "all"
              ? "Discover getaways"
              : state.theme === "all" &&
                state.category === "all" &&
                state.state !== "all" &&
                "Discover getaways"
          }`} */}
          {data === undefined
            ? "Fetching Getaways"
            : data.length !== 0
            ? "Discover getaways"
            : "No Similar getaways"}{" "}
        </HeadingWrapper>

        <div className="row mt-4">
          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularLoader></CircularLoader>
            </div>
          )}
          {data &&
            data.length !== 0 &&
            data.map((item, key) => {
              return (
                <div
                  key={key}
                  className="col-xl-4 col-lg-4 col-md-4 col-sm-4 mb-4"
                >
                  <Card>
                    <Card.Img
                      style={{
                        minHeight: "300px",
                        maxHeight: "300px",
                        cursor: "pointer",
                      }}
                      src={
                        item.image[0] && item?.image[0] != "medianew"
                          ? `https://bastionex-travels.b-cdn.net/uploads/${item.image[0]}`
                          : `https://bastionex-travels.b-cdn.net/uploads/photo_2024-09-06_21-43-47.jpg`
                      }
                      onClick={() => navigation(`/hoteldetails/${item?._id}`)}
                    />
                    <Tooltip title="Shortlist" styled={{ fontSize: "50px" }}>
                      <HeartWrapper onClick={() => onClickLike(item, key)}>
                        {isHeartLoading[`${item._id}-${key}`] ? (
                          <Loader />
                        ) : (
                          <i
                            style={{
                              fontSize: "20px",
                              color:
                                userData && userData.includes(item._id)
                                  ? "red"
                                  : "#747474",
                            }}
                            className="fa fa-thin fa-heart style"
                          ></i>
                        )}
                      </HeartWrapper>
                    </Tooltip>
                    <TextHeading
                      onClick={() => navigation(`/hoteldetails/${item?._id}`)}
                    >
                      <Li style={{ cursor: "pointer" }}>{item.hotelname}</Li>
                    </TextHeading>
                    <TextWrapper>
                      <IconLocation>
                        {/* <MdLocationPin style={{ color: "rgb(12, 176, 155)" }} /> */}
                      </IconLocation>
                      <TextLocation>{item.city}</TextLocation>
                    </TextWrapper>
                    {/* <TextPara>{item.overview}</TextPara> */}
                    <TextIconThree>
                      <TextIconOne>
                        &#8377;{" "}
                        {item.rooms[0] !== undefined && item.rooms[0].price} /
                        night
                      </TextIconOne>
                      <TextIconTwo>
                        {/* <FaPlusCircle style={{ color: "#34495e" }} /> Shortlist */}
                      </TextIconTwo>
                    </TextIconThree>
                    <Button
                      onClick={() => navigation(`/hoteldetails/${item?._id}`)}
                    >
                      Enter Dates for Offers
                    </Button>
                  </Card>
                  {showPopup && (
                    <LoginPopup
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                    />
                  )}
                </div>
              );
            })}
        </div>
      </ContainerMobile>
    </>
  );
};

export default AllHotelsByTheme;
