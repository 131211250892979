import styled from "styled-components";

export const Root = styled.div`
  background-color: #e9e9e9;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99999;
  transform: ${(props) => (props.show ? "scale(1)" : "scale(0)")};
  transform-origin: center;
  transition: 0.5s;
  /* transform: ${(props) => (props.show ? "scaleX(1)" : "scaleX(0)")}; */
  /* transform-origin: top; */
  /* transition: height 15s linear ease-in-out; */
  /* opacity: ${(props) => (props.show ? "1" : "0")}; */
  /* transition: opacity 2s linear; */
  @media (max-width: 768px) {
    overflow: scroll;
  }
`;

export const HeaderSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3554d1;
  width: 100%;
  /* height: 100px; */
  color: #fff;
  padding: 20px 0px;

  font-weight: bold;
  font-size: 18px;
  text-align: center;
`;

export const HeaderText = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #fff;
`;

export const HeaderTextCityName = styled.div`
  /* color: #ffefbf; */
  padding: 0 5px;
  text-shadow: 1px 1px 1px #000;
`;

export const HeaderContinueButton = styled.div`
  background-color: #6682f5;
  font-size: 14px;
  padding: 3px 10px;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 10px;
  @media (max-width: 425px) {
    margin: 5px 0;
  }
`;

export const CloseIcon = styled.i`
  font-size: 25px;
  position: absolute;
  top: 20px;
  right: 35px;
  color: #fff;
  cursor: pointer;
  :hover {
    color: #47c1f1;
  }
`;

export const ContentSection = styled.div``;

export const HeaderTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 425px) {
    flex-direction: column;
    /* margin: 2px 0; */
  }
`;

export const ContentInputText = styled.div`
  color: #333;
  font-size: 17px;
  line-height: 20px;
  padding: 20px 0 8px 7px;
`;

export const ContentInput = styled.input`
  border: 1px solid #999;
  line-height: 40px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 4px;
  width: 100%;
  padding: 4px 6px;
  padding-bottom: 10px;
`;

export const ContentHeadingCities = styled.div`
  margin: 20px 0;
  text-align: center;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  ${(p) =>
    p.CountriesName &&
    `
   font-size: 16px;
   padding:0 30px;
  `}
`;
export const ContentButtonWrapper = styled.div`
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 80%;
    margin: 0 auto;
  }
  @media (max-width: 940px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    width: 90%;
    margin-bottom: 56px;
  }
  @media (max-width: 550px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 425px) {
    grid-template-columns: 1fr 1fr;
    width: 90%;
  }
`;

export const ContentButtonCities = styled.div`
  border: 1px solid #ccc;
  background-color: #fff;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  padding: 0 20px;
  margin-right: 10px;
  margin-bottom: 5px;
  color: #666;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  border-radius: 5px;
  :hover {
    color: #6682f5;
  }
`;

export const InputWrapper = styled.div`
  width: 28%;
  @media (max-width: 940px) {
    width: 40%;
  }
  @media (max-width: 640px) {
    width: 50%;
  }
  @media (max-width: 425px) {
    width: 80%;
  }
`;

export const InputOuterWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const HorizontalLineWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 25px 0;
  // overflow:scroll;
`;

export const HoriZontalLine = styled.div`
  width: 60%;
  height: 1px;
  background-color: #ccc;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 425px) {
    width: 90%;
  }
`;

export const CountriesButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 auto;
  width: 60%;
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-gap: 2.1%;
  row-gap: 18px;
  @media (max-width: 940px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 940px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 640px) {
    grid-template-columns: 1fr 1fr;
    width: 75%;
  }
  @media (max-width: 425px) {
    grid-template-columns: 1fr;
    width: 88%;
    grid-gap: 1.1%;
  }
`;

export const CityNamesList = styled.div`
  // height: 150px;
  // overflow: scroll;
  position: absolute;
  top: 101%;
  background: #fff;
  width: 28%;
  @media (max-width: 768px) {
    width: 80%;
  }
`;
export const CityNamesList1 = styled.div`
  height: 250px;
  overflow: scroll;
  position: absolute;
  top: 101%;
  background: #fff;
  width: 28%;
  @media (max-width: 768px) {
    width: 80%;
  }
`;
export const CityNameOptions = styled.div`
  padding: 8px;
  cursor: pointer;
  :hover {
    background: #f8f8fa;
  }
`;
export const CountryButtonWapper = styled.div`
  height: 42px;
  box-shadow: 1px 1px 1px #ccc;
  line-height: 40px;
  float: left;
  padding: 0 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-right: 2.3%;
  margin-bottom: 2%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-radius: 5px;

  @media (max-width: 1390px) {
    width: 195px;
  }
  @media (max-width: 640px) {
    width: 100%;
  }
`;
export const FlagTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

export const CountryFlag = styled.div``;

export const CountryName = styled.div`
  padding: 0 15px;
  display: inline-block;
  color: #666;
  font-size: 14px;
  /* font-weight: bold; */
  :hover {
    color: #3554d1;
    transition: 0.9s;
  }
`;

export const IconWrapper = styled.div`
  display: inline-block;
  float: right;
  line-height: 40px;
  padding: 0px;
  color: #3554d1;
  font-size: 22px;
`;
