import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Herosection from "../Home/Herosection";
import HotelCollection from "../Home/HotelCollection";
import RangeForm1 from "../RangeForm/RangeForm1";
import HotelsWithLocation from "./HotelsWithLocation";
import GetHotelByTheme from "./GetHotelByTheme";
import { environmentVariables } from "../../config/config";
import ActivityBanner from "./ActivityBanner";
import axios from "axios";
import Radar from "radar-sdk-js";
import { useNavigate } from "react-router-dom";

const Root = styled.div`
  background-color: #fff;
  margin-bottom: 20px;
`;
const NavBarBackground = styled.div`
  width: 100vw;
  height: 77px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;

const Hotelpage = ({
  message,
  message2,
  data,
  setData,
  category,
  setCategory,
  defaultDataStatus,
  setDefaultDataStatus,
  statefromapp,
  setScrollAfterSelection,
  scrollAfterSelection,
  showDistanceSlider,
  setShowDistanceSlider,
  setLoggedIn,
  setResCity,
  setResState,
  setResCountry,
  resCity,
  resState,
  resCountry,
}) => {
  document.title =
    localStorage.getItem("cityname") == null
      ? "FloxyPay Travels"
      : resCity == null
      ? "FloxyPay Travels"
      : resCity + " - " + resState || "FloxyPay Travels";
  // console.log("jjjjjjjj", message2 + " - " + message, "jjj", resCity);
  const [minDistance, setMinDistance] = useState("");
  const baseUrl = environmentVariables.apiUrl;
  const getCity = localStorage.getItem("cityname");
  const getState = localStorage.getItem("city");
  const getCountry = localStorage.getItem("countryISOCode");
  const [resData, setResData] = useState();
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const navigation = useNavigate();

  // useEffect(() => {
  //   if (scrollAfterSelection === true && window !== "undefined") {
  //     window.scrollTo(0, window.innerHeight * 0.9);
  //     setScrollAfterSelection(false);
  //   }
  // }, [scrollAfterSelection]);

  const getCitiesImages = async () => {
    if (getCity) {
      try {
        const data = await axios.get(
          `${baseUrl}auth/getimageofactivitybanner/${
            localStorage.getItem("cityname") || message2
          }`
        );
        if (data.data.message === "Data not found") {
          setResData();
        } else {
          setResData(data.data.data);
        }
      } catch (error) {
        setResData();
      }
    }
  };
  useEffect(() => {
    getCitiesImages();
  }, [getCity]);

  Radar.initialize("prj_live_pk_8cef6dee5f31e220f09eba68a33c1556053a8e3b");

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);

      Radar.reverseGeocode(
        {
          latitude: lat,
          longitude: long,
        },
        function (err, result) {
          if (!err) {
            if (message2) {
              setResState(getState);
              setResCity(getCity);
              setResCountry(getCountry);
            } else {
              setResState(result.addresses[0].state);
              setResCity(result.addresses[0].city);
              setResCountry(result.addresses[0].country);
            }
          } else {
            setResState(null);
            setResCity(null);
          }
        }
      );
    } else {
      setLat(null);
      setLong(null);
    }
  };
  const showPosition = (position) => {
    setLat(position.coords.latitude);
    setLong(position.coords.longitude);
    setShowDistanceSlider(true);
  };

  useEffect(() => {
    getLocation();
  }, []);

  const callApi = (x, y) => {
    navigation("/hotel", { state: { stateName: x, cityName: y } });
  };
  useEffect(() => {
    if (resCity !== "" && resCity !== null) {
      // callApi(resState, resCity);
      // localStorage.setItem("city", resState);
      // localStorage.setItem("cityname", resCity);
      // localStorage.setItem("countryname", resCountry);
      if (resCountry?.toLowerCase() == "united arab emirates") {
        // localStorage.setItem("countryISOCode", "AE");
        localStorage.setItem("countryCodeForPricing", "AE");
      } else {
        // localStorage.setItem("countryISOCode", "IN");
        localStorage.setItem("countryCodeForPricing", "IN");
      }
    }
  }, [resState, resCity]);

  return (
    <Root>
      <NavBarBackground></NavBarBackground>
      <Herosection message={message} />
      <RangeForm1
        message={message}
        setShowDistanceSlider={setShowDistanceSlider}
        showDistanceSlider={showDistanceSlider}
        message2={message2}
        data={data}
        setData={setData}
        category={category}
        setCategory={setCategory}
        mindistance={minDistance}
        setmindistance={setMinDistance}
        setDefaultDataStatus={setDefaultDataStatus}
      ></RangeForm1>

      {defaultDataStatus === true || defaultDataStatus === "" ? (
        <HotelsWithLocation
          message={message}
          statefromapp={statefromapp}
          setLoggedIn={setLoggedIn}
        />
      ) : data !== "" || category !== "" || minDistance !== "" ? (
        <GetHotelByTheme
          message={message}
          maintheme={data}
          categories={category}
          mindistance={minDistance}
          setmindistance={setMinDistance}
          setLoggedIn={setLoggedIn}
          // originalJSONText={originalJSONText}
        />
      ) : data !== "" || category === "All" ? (
        <GetHotelByTheme
          message={message}
          maintheme={data}
          categories={category}
          mindistance={minDistance}
          setmindistance={setMinDistance}
          setLoggedIn={setLoggedIn}
          // originalJSONText={originalJSONText}
        />
      ) : (
        <HotelsWithLocation
          message={message}
          statefromapp={statefromapp}
          setLoggedIn={setLoggedIn}
          // originalJSONText={originalJSONText}
        />
      )}
      {/* {data!=="" || category!=="" || minDistance!=="" ? <GetHotelByTheme message={message} maintheme={data} categories={category}  mindistance={minDistance} setmindistance={setMinDistance} />:
      data!=="" || category==="All" ? <GetHotelByTheme message={message} maintheme={data} categories={category}  mindistance={minDistance} setmindistance={setMinDistance}/>
      : <HotelsWithLocation message={message} />} */}
      {localStorage.getItem("cityname") === null ? (
        <></>
      ) : resData?.image !== undefined &&
        resData?.image !== null &&
        resData?.city === getCity?.toLowerCase() ? (
        <ActivityBanner
          image={`${baseUrl}uploadscitiesimages/${resData?.image}`}
          cityName={message}
        />
      ) : (
        <></>
      )}

      {/* dubai_activity_banner.jpg */}
      <HotelCollection message={message} />
    </Root>
  );
};
export default Hotelpage;
