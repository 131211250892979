import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Banner from "../../images/bannerimg.webp";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import "./Home.css";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import {
  Button,
  ButtonWrapper,
  CheckInDatePickerWrapper,
  CheckOutDatePickerWrapper,
  Container,
  DateAdultWrapper,
  DateCheckInInput,
  DateCheckOutInput,
  DatePickerWrapper,
  DestinatioInput,
  DestinationWrapper,
  FormWrapper,
  Icon,
  InputWrapper,
  ModelQuantity,
  Modeltext,
  ModelTextWrapper,
  ModelWrapper,
  Number,
  Root,
  SearchIconWrapper,
  SubmitButton,
  SubmitButtonWrapper,
  Text,
  TextWrapper,
  VerticalLine,
  Wrapper,
} from "./HomesectionFormstyle";
import Radar from "radar-sdk-js";
import { useNavigate } from "react-router-dom";
import { environmentVariables } from "../../config/config";

const FilterContainer = styled.div`
  width: 320px;
  z-index: 9;
  height: auto;
  background-color: white;
  position: absolute;
  top: 67px;
  left: -15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  /* :hover {
    padding-bottom: 8px;
  } */
`;
const FilteredData = styled.div`
  // margin-left: 10px;
  /* margin-margin: 2px; */
  cursor: pointer;
  :hover {
    background-color: #3554d1 !important;
    padding: 10px;
    width: 100%;
    color: #fff;
  }
`;

const Section = styled.div`
  z-index: 4;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const BackgroundWrapper = styled.div`
  height: 100vh;
  background: linear-gradient(270deg, rgba(5, 16, 54, 0.5), #051036 72.43%);
  z-index: 0;
  background-size: cover;
  background-position: 0px 0px;
  position: relative;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    height: 100%;
    padding: 115px 0 50px;
  }

  @media (max-width: 768px) {
    height: 100%;
    padding: 115px 0 50px;
  }
`;
const Backgroundhover = styled.div`
  background: linear-gradient(270deg, rgba(5, 16, 54, 0.5), #051036 72.43%);
  height: 100vh;
  width: 100%;
  position: absolute;
  @media (max-width: 1024px) {
    height: 100%;
    padding: 115px 0 50px;
  }
  @media (max-width: 768px) {
    height: 100%;
    top: 0;
  }
`;
const DetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0;
`;
const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  @media (max-width: 1024px) {
    gap: 15px;
  }

  @media (max-width: 768px) {
    gap: 15px;
  }
`;
const Image = styled.img`
  cursor: pointer;
  // @media (max-width: 1024px) {
  //   width: 90% !important;
  //   // height: auto !important;
  // }

  @media (max-width: 768px) {
    max-width: 90% !important;
    height: auto !important;
  }
`;
const InsideWrapper1 = styled.div``;

const InsideWrapper2 = styled.div``;
const DialogWrapper = styled.div`
  /* display: flex;
 */
  background-color: red !important;
`;
const DialogStyle = styled(Dialog)`
  width: 100%;
  /* background-color: red; */
`;

function Herosection({ message, message2 }) {
  const baseUrl = environmentVariables.apiUrl;
  const getCity = localStorage.getItem("city");
  const getState = localStorage.getItem("cityname");
  const navigation = useNavigate();
  const InputCheckOut = useRef(null);
  const InputCheckIn = useRef(null);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState();
  const [resData, setResData] = useState("");
  const [cursor, setCursor] = useState(0);
  const [checkIn, setCheckIn] = useState(new Date());
  const [checkOut, setCheckOut] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [counter, setCounter] = useState(1);
  const [counter1, setCounter1] = useState(2);
  const [counter2, setCounter2] = useState(0);
  const [counter3, setCounter3] = useState(0);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isDatePickerOpen1, setIsDatePickerOpen1] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [rooms, setRooms] = useState();
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [resCity, setResCity] = useState("");
  const [adultCounterError, setAdultCounterError] = useState("");
  Radar.initialize("prj_live_pk_8cef6dee5f31e220f09eba68a33c1556053a8e3b");

  const handleChange = (event) => {
    const data = event.target.value;
    if (data.length >= 3) {
      setSearch(data);
      setShow(false);
    } else {
      setSearch();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRooms(rooms);
  };

  const incrementCounter = () => {
    if (counter - 1 >= 1) {
      setAdultCounterError("");
      setCounter(counter - 1);
    } else {
      setAdultCounterError(`At least ${counter} room is required.`);
    }
  };
  const decrementCounter = () => {
    setCounter(counter + 1);
  };
  const incrementCounter1 = () => {
    if (counter1 - 1 >= 1) {
      setAdultCounterError("");
      setCounter1(counter1 - 1);
    } else {
      setAdultCounterError(`At least ${counter1} adult is required.`);
    }
  };
  const decrementCounter1 = () => {
    if (counter1 + 1 >= 1 && counter1 + 1 <= counter * 2) {
      setAdultCounterError("");
      setCounter1(counter1 + 1);
    } else {
      setAdultCounterError(`You can select maximum ${counter1} adults.`);
    }
  };
  const incrementCounter2 = () => {
    if (counter2 - 1 >= 0) {
      setAdultCounterError("");
      setCounter2(counter2 - 1);
    } else {
      setAdultCounterError(`At least ${counter2} children is required.`);
    }
  };
  const incrementCounter3 = () => {
    if (counter3 - 1 >= 0) {
      setAdultCounterError("");
      setCounter3(counter3 - 1);
    } else {
      setAdultCounterError(`At least ${counter3} infant is required.`);
    }
  };
  const decrementCounter2 = () => {
    if (counter2 + 1 >= 1 && counter2 + 1 <= counter * 2) {
      setAdultCounterError("");
      setCounter2(counter2 + 1);
    } else {
      setAdultCounterError(`You can select maximum ${counter2} children.`);
    }
  };
  const decrementCounter3 = () => {
    if (counter3 + 1 >= 1 && counter3 + 1 <= counter * 2) {
      setAdultCounterError("");
      setCounter3(counter3 + 1);
    } else {
      setAdultCounterError(`You can select maximum ${counter3} Infants.`);
    }
  };

  const getCitiesImages = async () => {
    if (getCity || message) {
      try {
        const data = await axios.get(
          `${baseUrl}auth/getimagesacctocities/${getCity || message}`
        );
        setResData(data.data.data);
        // return data.data.message;
      } catch (error) {
        return error?.message;
      }
    }
  };
  useEffect(() => {
    Radar.reverseGeocode(
      {
        latitude: lat,
        longitude: long,
      },
      function (err, result) {
        if (!err) {
          setResCity(result.addresses[0].city);
        } else {
        }
      }
    );
  }, [resCity]);
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      setLat(-1);
      setLong(-1);
    }
  };
  const showPosition = (position) => {
    setLat(position.coords.latitude);
    setLong(position.coords.longitude);
  };
  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    getCitiesImages();
  }, [message]);

  useEffect(() => {
    if (
      toggle &&
      checkIn !== null &&
      checkOut !== null &&
      counter > 0 &&
      counter1 > 0
    ) {
      setIsSearchActive(true);
    } else {
      setIsSearchActive(false);
    }
  }, [search, checkIn, checkOut, counter, counter1, toggle]);

  const abortController = new AbortController();

  const getSearchData = async () => {
    if (search !== "" && search !== null) {
      await axios({
        method: "post",
        url: `${baseUrl}auth/filteredhoteldetailsbyhotelname_web/${search}?type=hotel`,
        signal: abortController.signal,
      })
        .then((response) => {
          setFilter(response.data.message);
        })
        .catch((error) => {
          setFilter([]);
        });
    }
  };
  const handleToggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };
  const handleToggleDatePicker2 = () => {
    setIsDatePickerOpen1(!isDatePickerOpen1);
  };
  useEffect(() => {
    getSearchData();
    return () => {
      abortController.abort();
    };
  }, [search]);

  const KeyDown = (e) => {
    if (e.keyCode === 38 && cursor > 0) {
      setCursor((preState) => preState - 1);
    } else if (e.keyCode === 40 && cursor < 5) {
      setCursor((preState) => preState + 1);
    } else if (e.keyCode === 38 && cursor === 0) {
      setCursor(5);
    } else if (e.keyCode === 40 && cursor === 5) {
      setCursor(0);
    }

    if (e.keyCode === 13) {
      setSearch(filter[cursor]?.hotelname);
      setShow(!show);
      setToggle(true);
    } else {
      setToggle(false);
    }
    if (e.keyCode === 9) {
      setSearch(filter[cursor]?.hotelname);
    }
  };

  const refHandle = () => {
    InputCheckOut.current.setOpen(true);
  };
  const handleCheckInChange = (date) => {
    setCheckIn(date);
    if (checkOut && date > checkOut) {
      setCheckOut(null);
    }
  };
  return (
    <Root onClick={() => setShow(true)}>
      <Container>
        <BackgroundWrapper
          style={{
            backgroundImage: `${
              localStorage.getItem("city") === null
                ? `url(${Banner})`
                : baseUrl && resData.image !== undefined
                ? `url(${baseUrl}uploadscitiesimages/${resData.image})`
                : ""
            } `,
          }}
        >
          <Backgroundhover></Backgroundhover>
          <Section>
            {" "}
            <TextWrapper>
              <Text className="bannertext">
                {localStorage.getItem("cityname") === null
                  ? "Find your favorite destination"
                  : `Your gateway to bliss: explore destinations from ${
                      getState || message
                    }`}{" "}
              </Text>
              <Text smalltext className="subtext">
                Dive into a joyful journey of top deals. Let us plan your great
                escape!
              </Text>
            </TextWrapper>
            <Wrapper>
              <FormWrapper>
                <DestinationWrapper>
                  <DestinatioInput
                    type="text"
                    placeholder="Destination , Resort"
                    value={search}
                    onChange={handleChange}
                    onKeyDown={(e) => KeyDown(e)}
                    onClick={() => setShow(true)}
                  />
                  <SearchIconWrapper>
                    <i className="fa-sharp fa-solid fa-magnifying-glass"></i>
                  </SearchIconWrapper>

                  
                  {!show && (
                    <FilterContainer>
                      {filter === undefined || search === undefined ? (
                        <></>
                      ) : filter?.length !== 0 ? (
                        filter?.map((value, key) => {
                          return (
                            <Wrapper>
                              <FilteredData
                                key={key}
                                style={
                                  cursor === key
                                    ? {
                                        backgroundColor: "#3554d1",
                                        color: "#fff",
                                        padding: "10px",
                                        width: "100%",
                                      }
                                    : {
                                        backgroundColor: "#fff",
                                        color: "#000",
                                        padding: "10px",
                                        width: "100%",
                                      }
                                }
                                onClick={() => {
                                  setShow(!show);
                                }}
                              >
                                <DetailWrapper
                                  onClick={(e) => {
                                    setSearch(value.hotelname);
                                    setToggle(true);
                                  }}
                                >
                                  <InsideWrapper1>
                                    {value.hotelname}
                                  </InsideWrapper1>
                                  <InsideWrapper2>{value.city}</InsideWrapper2>
                                </DetailWrapper>
                              </FilteredData>
                            </Wrapper>
                          );
                        })
                      ) : (
                        <FilteredData
                          style={{
                            backgroundColor: "#fff",
                            padding: "10px",
                            width: "100%",
                          }}
                        >
                          No Matching Result
                        </FilteredData>
                      )}
                    </FilterContainer>
                  )}
                </DestinationWrapper>

                <DateAdultWrapper>
                  <DatePickerWrapper>
                    <CheckInDatePickerWrapper>
                      <DatePicker
                        Icon
                        placeholderText="CheckIn"
                        // open={isDatePickerOpen}
                        selected={checkIn}
                        onChange={handleCheckInChange}
                        selectsStart
                        startDate={checkIn}
                        endDate={checkOut}
                        ref={InputCheckIn}
                        minDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      ></DatePicker>
                      <DateCheckInInput
                        onClick={() => InputCheckIn.current.setOpen(true)}
                      >
                        <div
                          onClick={() => {
                            InputCheckIn.current.setOpen(true);
                          }}
                          style={{
                            position: "relative",
                            right: "5px",
                            top: "0px",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                        >
                          <i className="fas fa-calendar-alt"></i>
                        </div>
                      </DateCheckInInput>
                    </CheckInDatePickerWrapper>
                    <VerticalLine />
                    <CheckOutDatePickerWrapper
                    // onClick={handleToggleDatePicker2}
                    >
                      <DatePicker
                        placeholderText=" CheckOut"
                        // open={isDatePickerOpen1}
                        selected={checkOut}
                        onChange={(date) => setCheckOut(date)}
                        onClickOutside={() => setIsDatePickerOpen1(false)}
                        onFocus={() => setIsDatePickerOpen1(true)}
                        startDate={checkIn}
                        endDate={checkOut}
                        minDate={checkIn}
                        ref={InputCheckOut}
                        dateFormat="dd/MM/yyyy"
                      ></DatePicker>
                      <DateCheckOutInput onClick={refHandle}>
                        <i className="fas fa-calendar-alt"></i>
                      </DateCheckOutInput>
                    </CheckOutDatePickerWrapper>
                  </DatePickerWrapper>
                </DateAdultWrapper>
                <InputWrapper>
                  <DestinatioInput
                    variant="outlined"
                    onClick={handleClickOpen}
                    type="text"
                    placeholder="1 Room , 2 Adult"
                    value={`${
                      counter1 === undefined
                        ? "0"
                        : counter1 > 1
                        ? `${counter1} Adults`
                        : `${counter1} Adult`
                    }, ${
                      counter2 === undefined
                        ? "0"
                        : counter2 > 1
                        ? `${counter2} Children`
                        : `${counter2} Child`
                    }, ${
                      counter3 === undefined
                        ? "0"
                        : counter3 > 1
                        ? `${counter3} Infants`
                        : `${counter3} Infant`
                    }, ${
                      counter === undefined
                        ? "0"
                        : counter > 1
                        ? `${counter} Rooms`
                        : `${counter} Room`
                    } `}
                    readOnly
                  />

                  <div style={{ position: "absolute", left: "20px" }}>
                    <i className="fa-solid fa-user"></i>
                  </div>
                  <DialogWrapper>
                    <DialogStyle
                      open={open}
                      keepMounted
                      onClose={handleClose}
                      aria-describedby="alert-dialog-slide-description"
                      fullWidth
                    >
                      <div>
                        <DialogTitle className="">
                          Select Rooms and Guests
                          <span
                            className="text-danger"
                            style={{
                              fontSize: "17px",
                              paddingLeft: "30px",
                              fontWeight: "300",
                            }}
                          >
                            {adultCounterError ? adultCounterError : ""}
                          </span>
                        </DialogTitle>

                        <DialogContent>
                          <ModelWrapper>
                            <ModelTextWrapper>
                              <Modeltext>
                                {counter > 1 ? "Rooms" : "Room"}{" "}
                                <span style={{ color: "red" }}> *</span>{" "}
                              </Modeltext>
                            </ModelTextWrapper>
                            <ModelQuantity>
                              <Icon onClick={incrementCounter}>-</Icon>
                              <Number>{counter}</Number>
                              <Icon onClick={decrementCounter}>+</Icon>
                            </ModelQuantity>
                          </ModelWrapper>
                          <ModelWrapper>
                            <ModelTextWrapper>
                              <Modeltext>
                                {counter1 > 1 ? "Adults" : "Adult"}{" "}
                                <span style={{ color: "red" }}> *</span>{" "}
                              </Modeltext>
                            </ModelTextWrapper>
                            <ModelQuantity>
                              <Icon onClick={incrementCounter1}>-</Icon>
                              <Number>{counter1}</Number>
                              <Icon onClick={decrementCounter1}>+</Icon>
                            </ModelQuantity>
                          </ModelWrapper>
                          <ModelWrapper>
                            <ModelTextWrapper>
                              <Modeltext>
                                {counter2 > 1 ? "Children" : "Child"} (2-17)
                              </Modeltext>
                            </ModelTextWrapper>
                            <ModelQuantity>
                              <Icon onClick={incrementCounter2}>-</Icon>
                              <Number>{counter2}</Number>
                              <Icon onClick={decrementCounter2}>+</Icon>
                            </ModelQuantity>
                          </ModelWrapper>
                          <ModelWrapper>
                            <ModelTextWrapper>
                              <Modeltext>
                                {counter3 > 1 ? "Infants" : "Infant"} (0-2)
                              </Modeltext>
                            </ModelTextWrapper>
                            <ModelQuantity>
                              <Icon onClick={incrementCounter3}>-</Icon>
                              <Number>{counter3}</Number>
                              <Icon onClick={decrementCounter3}>+</Icon>
                            </ModelQuantity>
                          </ModelWrapper>

                          <SubmitButtonWrapper>
                            <SubmitButton onClick={handleClose}>
                              Submit
                            </SubmitButton>
                          </SubmitButtonWrapper>
                        </DialogContent>
                      </div>
                    </DialogStyle>
                  </DialogWrapper>
                </InputWrapper>
                <ButtonWrapper>
                  <Button
                    onClick={() =>
                      isSearchActive &&
                      navigation(`/hoteldetails/${filter[0]?._id}`, {
                        state: {
                          checkIn: checkIn,
                          checkOut: checkOut,
                          rooms: counter,
                          adults: counter1,
                          children: counter2,
                          infants: counter3,
                          // filter: filter[0],
                        },
                      })
                    }
                    type="submit"
                    isSearchActive={isSearchActive}
                  >
                    Search
                  </Button>
                </ButtonWrapper>
              </FormWrapper>
            </Wrapper>
            <ImageWrapper className="topupimg">
              <Image
                src={`https://bastionex-travels.b-cdn.net/homepage_topup_giftcard_image/giftcard.png`}
                alt="image"
                className="img-fluid"
                onClick={() => navigation("/gifts")}
              ></Image>
              <Image
                src={`https://bastionex-travels.b-cdn.net/homepage_topup_giftcard_image/topup.png`}
                alt="image"
                className="img-fluid"
                onClick={() => navigation("/topups")}
              ></Image>
            </ImageWrapper>
          </Section>
        </BackgroundWrapper>
      </Container>
    </Root>
  );
}

export default Herosection;
