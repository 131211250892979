import React from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Banner from "../../images/bannerimg.jpg";
import Container from "react-bootstrap/Container";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import ImageSRC from '../../images/bannerimg.jpg';

const Root = styled.div`
  padding-top: 85px;
`;

const ImageWrapper = styled.div`
  background-image: url(${Banner});
  width: 100%;
  height: 25rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  @media (max-width: 768px) {
    background-size: cover;
    background-position: center;
  }
`;

const TextHeading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: #fff;
  @media (max-width: 425px) {
    font-size: 2rem;
  }
`;
//commgit
const TextHead = styled.div`
  font-size: 24px;
  color: #6e6666;
  text-align: center;
  font-family: "Roboto";
  font-weight: 400;
`;

const TextParaOne = styled.div`
  font-size: 14px;
  color: #6e6666;
  font-family: "Roboto", sans-serif;
  margin-top: 30px;
`;

const TextParaTwo = styled.div`
  font-size: 14px;
  color: #6e6666;
  font-family: "Roboto", sans-serif;
  margin-top: 15px;
`;
const NavBarBackground = styled.div`
  width: 100vw;
  height: 84.8px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;
const Image = styled.img`
`;
const Aboutus = ({ title }) => {
  document.title = title === undefined ? "" : title;
  return (
    <Root className="mob-pt-0">
      <NavBarBackground></NavBarBackground>
      <Container fluid className="mob-pt-0">
        <Row>
          {/* <ImageWrapper>
            <TextHeading></TextHeading>
          </ImageWrapper> */}
          <Image
            src={ImageSRC}
            className="img-fluid p-0"
          />
        </Row>
      </Container>
      <Container fluid style={{ backgroundColor: "#e9e9e9" }}>
        <Container style={{ padding: "50px 0px" }}>
          <TextHead>
            <FaQuoteLeft style={{ marginRight: "10px" }} /> The world is a book
            and those who do not travel read only one page.{" "}
            <FaQuoteRight style={{ marginLeft: "10px" }} />
          </TextHead>
          <TextParaOne>
            We are a bunch of folks extremely passionate about travel and living
            the good life. When we hear people say that the city is such a bore
            and there isn't anything to do other than hitting the malls, it
            really pains us. There are at least half a million fantastic
            experiences which make for exciting breaks, all just a short drive
            away! But, what is surprising is the glaring lack of visibility
            these getaways enjoy. Atleast a google search on interesting weekend
            getaways from bangalore certainly won't get you to them!
          </TextParaOne>
          <TextParaTwo>
            So, we decided to bridge this gap and aggregate these lovely
            experiences and present them to you in a manner that will make
            discovering and planning your next getaway a breeze.
          </TextParaTwo>
          <TextParaTwo>
            We realise that everyone has specific requirements for a getaway. So
            we collate a diverse mix of getaways which cater to various
            interests and budget ranges. We are very particular about the
            getaways we feature and do a lot of careful weeding behind the
            scenes to bring forward the best of the lot. We try to personally
            visit each of our getaways, rate them and also provide the best
            possible deals to you. You will never find us charging more than
            what the property owner would charge you. We also periodically
            review our providers based on user feedback to make sure only the
            finest survive on the site.{" "}
          </TextParaTwo>
          <TextParaTwo>
            Do keep your valuable suggestions and feedback rolling in and we
            will love to incorporate them.
          </TextParaTwo>
        </Container>
      </Container>
    </Root>
  );
};

export default Aboutus;
