import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "react-bootstrap/Card";
import "./HotelWithLocation.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import LoginPopup from "../Pages/Login/LoginPopup";
import { environmentVariables } from "../../config/config";
import Radar from "radar-sdk-js";
import ContentLoader from "react-content-loader";
import CircularLoader from "../CircularLoader/CircularLoader";
import Loader from "../Shortlistedloader/Loader";
import { useAuth } from "../../ContextApi/Contextapi";
const Root = styled.div`
  /* margin: 20px 0; */
  padding: 10px 0;
`;
const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 1600px) {
    width: 1200px;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
`;
const ContainerMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const HeartWrapper = styled.div`
  width: 40px;
  border-radius: 3px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  /* left: 88%; */
  right: 15px;
  top: 15px;
  /* right: 1px; */
  background-color: #fff;
  cursor: pointer;
  :hover {
    color: red !important;
  }
`;

const CarouselAfterSelection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
const MainData = styled.div`
  @media (max-width: 390px) {
    padding: 0px 10px;
  }
`;
const Msgdata = styled.div`
  color: #d63b3b;
  font-size: 20px;
  text-align: center;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 15px;
  }

  ${(p) =>
    p.errormessage &&
    `
    margin: 50px 0px;
`}
`;


const GetHotelByTheme = ({
  message,
  items,
  maintheme,
  categories,
  maxDistance,
  mindistance,
  setmindistance,
  setLoggedIn,
}) => {
  Radar.initialize("prj_live_pk_8cef6dee5f31e220f09eba68a33c1556053a8e3b");
  const baseUrl = environmentVariables.apiUrl;
  const [secondaryUrl, setSecondaryUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const getCity = localStorage.getItem("city");
  const getCityName = localStorage.getItem("cityname");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [userData, setUserData] = useState([]);
  const [themeData, setThemeData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [resCity, setResCity] = useState("");
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const navigation = useNavigate();
  const { state } = useLocation();
  const [isHeartLoading, setIsHeartLoading] = useState({});
  const { authData } = useAuth();
  const [laoding1, setLoading1] = useState(false);

  const getComponents = () => {
    return data.map((item, key) => (
      <MainData
        key={key}
        className="col-xl-4 col-lg-4 col-md-4 col-sm-4 mb-4"
        style={{ width: "100%" }}
      >
        <Card>
          <div style={{ height: "200px", overflow: "hidden" }}>
            <Card.Img
              style={{
                width: "100%",
                cursor: "pointer",
              }}
              variant="top"
              src={
                item.image[0] && item?.image[0] != "medianew"
                  ? `https://bastionex-travels.b-cdn.net/uploads/${item.image[0]}`
                  : `https://bastionex-travels.b-cdn.net/uploads/photo_2024-09-06_21-43-47.jpg`
              }
              onClick={() => navigation(`/hoteldetails/${item?._id}`)}
            />
          </div>

          <Tooltip title="Shortlist" styled={{ fontSize: "50px" }}>
            <HeartWrapper onClick={() => onClickLike(item, key)}>
              {isHeartLoading[`${item._id}-${key}`] ? (
                <Loader />
              ) : (
                <i
                  style={{
                    fontSize: "20px",
                    color:
                      userData && userData.includes(item._id)
                        ? "red"
                        : "#747474",
                  }}
                  className="fa fa-thin fa-heart style"
                ></i>
              )}
            </HeartWrapper>
          </Tooltip>
          <Card.Body
            style={{
              maxHeight: "100px",
              marginBottom: "20px",
              minHeight: "100px",
            }}
          >
            <Card.Title style={{ fontWeight: "500", fontSize: "16px" }}>
              {item.hotelname}
            </Card.Title>
            <Card.Title style={{ fontWeight: "300", fontSize: "14px" }}>
              {item.city} , {item.state}
            </Card.Title>
          </Card.Body>
        </Card>

        {showPopup && (
          <LoginPopup
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            setLoggedIn={setLoggedIn}
          />
        )}
      </MainData>
    ));
  };
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      setLat(-1);
      setLong(-1);
    }
  };
  const showPosition = (position) => {
    setLat(position.coords.latitude);
    setLong(position.coords.longitude);
  };
  useEffect(() => {
    getLocation();
  }, []);

  // useEffect(() => {
  //   setData(data);
  // }, [state]);

  const onClickLike = async (item, key) => {
    const compoundKey = `${item._id}-${key}`;
    if (authData === undefined) {
      setShowPopup(true);
    } else {
      setIsHeartLoading((prevState) => ({ ...prevState, [compoundKey]: true }));
      await axios({
        url: `${baseUrl}auth/getshortlisted/${item._id}`,
        method: "post",
        headers: {
          _token: authData?.token,
        },
      })
        .then((response) => {
          getUser(compoundKey);
        })
        .catch((error) => {
          // console.log("error", error);
        });
    }
  };

  const getUser = async (key) => {
    try {
      const data = await axios.get(`${baseUrl}auth/getuserdata`, {
        headers: {
          _token: authData?.token,
        },
      });
      setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
      setUserData(data.data.data.shortlistedArray);
    } catch (error) {
      setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
      return error?.message;
    }
  };
  const getThemesByName = () => {
    let config = {
      method: "get",
      url: `${baseUrl}auth/getthemebystate/${getCity || message}`,
    };
    axios
      .request(config)
      .then((response) => {
        setThemeData(response.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getHotelName = (pageNum = 1) => {
    let data;

    data = {
      city: getCityName,
      category: categories === "" ? "all" : categories,
      theme: maintheme,
      mindistance: mindistance[0] * 1000,
      maxdistance: mindistance[1] * 1000,
      lat: `${lat}`,
      long: `${long}`,
      page: pageNum, // Pass the current page to the backend
      limit: pageNum === 1 ? 12 : 4,
    };

    let config = {
      method: "post",
      url: `${baseUrl}auth/${secondaryUrl}`,
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const fetchedData = response?.data?.data;

        if (pageNum === 1) {
          setData(fetchedData);
        } else {
          setData((prevData) => [...prevData, ...fetchedData]);
        }

        setLoading(false);
        setLoading1(false);
        setHasMore(fetchedData.length > 0);
      })
      .catch((err) => {
        console.log(err, "ooo999");
        // setLoading(false);
        setData([]);

        setLoading(false);
        setHasMore(false);
      });
  };
  useEffect(() => {
    if (lat !== null && (mindistance[0] !== 0 || mindistance[1] !== 50)) {
      setSecondaryUrl("getdistance1");
    } else {
      setSecondaryUrl("gethotelbycategoryandtheme");
    }
    getHotelName();
  }, [maintheme, categories, mindistance]);

  const loadMoreData = () => {
    // setLoading(true);
    setLoading1(true);
    setPage((prevPage) => prevPage + 1); // Increment the page number
  };

  useEffect(() => {
    getThemesByName();
    getUser();
  }, [authData, getCity, resCity]);

  useEffect(() => {
    if (!authData) {
      setUserData(null);
    }
  }, [authData]);
  useEffect(() => {
    Radar.reverseGeocode(
      {
        latitude: lat,
        longitude: long,
      },
      function (err, result) {
        if (!err) {
          setResCity(result.addresses[0].city);
        } else {
          // console.log(err, "error in radar");
        }
      }
    );
  }, [resCity]);
  useEffect(() => {
    setmindistance([0, 50]);
  }, []);
  useEffect(() => {
    setLoading(true);
  }, [maintheme, categories, mindistance]);

  useEffect(() => {
    if (page > 1) {
      getHotelName(page);
    }
  }, [page]);

  // const handleScroll = () => {
  //   console.log("page", page, loading, hasMore);

  //   // if (
  //   //   window.innerHeight + document.documentElement.scrollTop ===
  //   //   document.documentElement.offsetHeight
  //   // ) {
  //   if (!loading && hasMore) {
  //     setPage((prevPage) => prevPage + 1);
  //   }
  //   // }
  // };

  // useEffect(() => {
  //   // Add scroll event listener
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [loading, hasMore]);

  return (
    <Root>
      <Container>
        <div className="row mt-4">
          {loading ? (
            <ContentLoader
              width={2500}
              height={575}
              viewBox="0 0 1100 575"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="58" rx="2" ry="2" width="250" height="300" />
              <rect x="280" y="57" rx="2" ry="3" width="250" height="300" />
              <rect x="560" y="56" rx="2" ry="2" width="250" height="300" />
              <rect x="840" y="56" rx="2" ry="2" width="250" height="300" />
              <rect x="0" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="280" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="560" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="840" y="615" rx="2" ry="2" width="250" height="300" />
            </ContentLoader>
          ) : data.length === 0 || data === null || data === undefined ? (
            <Msgdata errormessage>Data Unavailable: Keep Exploring!</Msgdata>
          ) : (
            <CarouselAfterSelection className="row mt-4">
              {getComponents()}
            </CarouselAfterSelection>
          )}
        </div>
        <div>
          {hasMore && (
            <div className="load_more_div">
              <button onClick={loadMoreData}>
                {laoding1 ? (
                  <div className="loader_get_hotel_by_theme"></div>
                ) : (
                  "Load More"
                )}
              </button>
            </div>
          )}
        </div>
      </Container>
      <ContainerMobile>
        <div className="row mt-4">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularLoader></CircularLoader>
            </div>
          ) : data.length === 0 || data === null || data === undefined ? (
            <Msgdata errormessage>Data Unavailable: Keep Exploring!</Msgdata>
          ) : (
            <CarouselAfterSelection className="row mt-4">
              {getComponents()}
            </CarouselAfterSelection>
          )}
        </div>
      </ContainerMobile>
    </Root>
  );
};

export default GetHotelByTheme;
