import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CircularLoader from "../CircularLoader/CircularLoader";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import LoginPopup from "../Pages/Login/LoginPopup";
import axios from "axios";
import { useContext } from "react";
import { AuthContext, useAuth } from "../../ContextApi/Contextapi";
import Tooltip from "@mui/material/Tooltip";
import { environmentVariables } from "../../config/config";
import ContentLoader from "react-content-loader";
import Loader from "../Shortlistedloader/Loader";

const Root = styled.div`
  /* margin: 20px 0; */
`;
const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 768px) {
    display: none;
  }
`;
const ContainerMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    width: 100vw;
    display: block;
  }
`;
const Text = styled.text`
  font-size: 26px;
  font-weight: 500;
  padding: 10px 0;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  justify-content: center;
  padding-left: 50px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    width: 100vw;
    padding-bottom: 10px;
  }
`;
const CardWrapper = styled.div`
  padding: 0 13px;
  display: grid !important;
  grid-template-columns: auto auto auto auto !important;
  @media (max-width: 768px) {
    display: block !important;
  }
`;
const CardWrapperInner = styled.div`
  padding: 0 13px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    padding-bottom: 20px;
  }
`;
const CityWrapper = styled.div`
  margin: 50px 0;
  @media (max-width: 768px) {
    width: 100vw;
    margin: 20px 0;
  }
`;
const HeartWrapper = styled.div`
  width: 40px;
  border-radius: 3px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  /* left: 88%; */
  right: 15px;
  top: 15px;
  /* right: 1px; */
  background-color: #fff;
  cursor: pointer;
  :hover {
    color: red !important;
  }
`;

const HotelSection = ({ interested, stayType, setLoggedIn }) => {
  const baseUrl = environmentVariables.apiUrl;
  const city = localStorage.getItem("cityname");
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [themeCarouselData, setThemeCarouselData] = useState(null);
  // const { authData, setAuthData } = useContext(AuthContext);
  const { authData } = useAuth();
  const [userData, setUserData] = useState([]);

  const navigation = useNavigate();
  const [isHeartLoading, setIsHeartLoading] = useState({});

  const getUser = async (key) => {
    try {
      const data = await axios.get(`${baseUrl}auth/getuserdata`, {
        headers: {
          _token: authData?.token,
        },
      });
      setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
      setUserData(data.data.data.shortlistedArray);
    } catch (error) {
      setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
      return error?.message;
    }
  };
  const getFilteredData = () => {
    axios({
      method: "post",
      url: `${baseUrl}auth/gethotelbycategoryandtheme`,
      data: { theme: interested, city: city },
    })
      .then((response) => {
        setThemeCarouselData(response?.data?.data.slice(0, 20));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getFilteredData();
  }, [stayType, interested]);
  useEffect(() => {
    getUser();
  }, [authData]);
  useEffect(() => {
    if (!authData) {
      setUserData(null);
    }
  }, [authData]);

  const onClickLike = async (item, key) => {
    const compoundKey = `${item._id}-${key}`;
    if (authData === undefined) {
      setShowPopup(true);
    } else {
      setIsHeartLoading((prevState) => ({ ...prevState, [compoundKey]: true }));
      await axios({
        url: `${baseUrl}auth/getshortlisted/${item._id}`,
        method: "post",
        headers: {
          _token: authData?.token,
        },
      })
        .then((response) => {
          getUser(compoundKey);
        })
        .catch((error) => {
        });
    }
  };


  useEffect(() => {
    setThemeCarouselData([]);
    setLoading(true);
  }, [interested, stayType]);

  return (
    <Root>
      <Container>
        <div className="row mt-4">
          {loading && (
            <ContentLoader
              width={2500}
              height={575}
              viewBox="0 0 1100 575"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="58" rx="2" ry="2" width="250" height="300" />
              <rect x="280" y="57" rx="2" ry="3" width="250" height="300" />
              <rect x="560" y="56" rx="2" ry="2" width="250" height="300" />
              <rect x="840" y="56" rx="2" ry="2" width="250" height="300" />
              <rect x="0" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="280" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="560" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="840" y="615" rx="2" ry="2" width="250" height="300" />
            </ContentLoader>
          )}
          {!loading && (
            <CityWrapper>
              <TextWrapper>
                <Text>{`${interested} ${stayType}`} </Text>
              </TextWrapper>

              <CardWrapper style={{ margin: "0 20px" }}>
                {showPopup && (
                  <LoginPopup
                    showPopup={showPopup}
                    setShowPopup={setShowPopup}
                    setLoggedIn={setLoggedIn}
                  />
                )}

                {themeCarouselData &&
                  themeCarouselData.map((item, key) => {
                    return (
                      <CardWrapperInner key={key}>
                        <Card>
                        <div style={{ height: "200px", overflow: "hidden" }}>
                          <Card.Img
                            style={{
                            width:"100%"
                            }}
                            variant="top"
                            src={
                              item.image[0] && item?.image[0] != "medianew"
                                ? `https://bastionex-travels.b-cdn.net/uploads/${item.image[0]}`
                                : `https://bastionex-travels.b-cdn.net/uploads/photo_2024-09-06_21-43-47.jpg`
                            }
                            onClick={() =>
                              navigation(`/hoteldetails/${item?._id}`)
                            }
                          />
                          </div>
                          <Tooltip
                            title="Shortlist"
                            styled={{ fontSize: "50px" }}
                          >
                            <HeartWrapper
                              onClick={() => onClickLike(item, key)}
                            >
                              {isHeartLoading[`${item._id}-${key}`] ? (
                                <Loader />
                              ) : (
                                <i
                                  style={{
                                    fontSize: "20px",
                                    color:
                                      userData && userData.includes(item._id)
                                        ? "red"
                                        : "#747474",
                                  }}
                                  className="fa fa-thin fa-heart style"
                                ></i>
                              )}
                            </HeartWrapper>
                          </Tooltip>

                          <Card.Body
                            style={{ minHeight: "100px", maxHeight: "100px" }}
                          >
                            <Card.Title
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              {item.hotelname}123
                            </Card.Title>
                            <Card.Title
                              style={{
                                fontWeight: "300",
                                fontSize: "14px",
                              }}
                            >
                              {item.state}, {item.country}
                            </Card.Title>
                          </Card.Body>
                        </Card>
                      </CardWrapperInner>
                    );
                  })}
              </CardWrapper>
            </CityWrapper>
          )}
        </div>
      </Container>
      <ContainerMobile>
        <div className="row mt-4">
          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularLoader></CircularLoader>
            </div>
          )}
          {!loading && (
            <CityWrapper>
              <TextWrapper>
                <Text>{`${interested} ${stayType}`} </Text>
              </TextWrapper>

              <CardWrapper style={{ margin: "0 20px" }}>
                {showPopup && (
                  <LoginPopup
                    showPopup={showPopup}
                    setShowPopup={setShowPopup}
                    setLoggedIn={setLoggedIn}
                  />
                )}

                {themeCarouselData &&
                  themeCarouselData.map((item, key) => {
                    return (
                      <CardWrapperInner key={key}>
                        <Card>
                          <Card.Img
                            style={{
                              maxHeight: "220px",
                              cursor: "pointer",
                              position: "relative",
                              minHeight: "160px",
                            }}
                            variant="top"
                            src={
                              item.image[0] && item?.image[0] != "medianew"
                                ? `https://bastionex-travels.b-cdn.net/uploads/${item.image[0]}`
                                : `https://bastionex-travels.b-cdn.net/uploads/photo_2024-09-06_21-43-47.jpg`
                            }
                            onClick={() =>
                              navigation(`/hoteldetails/${item?._id}`)
                            }
                          />
                          <Tooltip
                            title="Shortlist"
                            styled={{ fontSize: "50px" }}
                          >
                            <HeartWrapper
                              onClick={() => onClickLike(item, key)}
                            >
                              {isHeartLoading[`${item._id}-${key}`] ? (
                                <Loader />
                              ) : (
                                <i
                                  style={{
                                    fontSize: "20px",
                                    color:
                                      userData && userData.includes(item._id)
                                        ? "red"
                                        : "#747474",
                                  }}
                                  className="fa fa-thin fa-heart style"
                                ></i>
                              )}
                            </HeartWrapper>
                          </Tooltip>

                          <Card.Body>
                            <Card.Title
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              {item.hotelname}456
                            </Card.Title>
                            <Card.Title
                              style={{
                                fontWeight: "300",
                                fontSize: "14px",
                              }}
                            >
                              {item.state}, {item.country}
                            </Card.Title>
                          </Card.Body>
                        </Card>
                      </CardWrapperInner>
                    );
                  })}
              </CardWrapper>
            </CityWrapper>
          )}
        </div>
      </ContainerMobile>
    </Root>
  );
};

export default HotelSection;
