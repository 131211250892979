import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import Swal from "sweetalert2";
import { environmentVariables } from "../../../config/config";

import { useLocation } from "react-router-dom";
import LoginPopup from "./LoginPopup";
import CircularLoader from "../../CircularLoader/CircularLoader";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import { Button as MIButton } from "@mui/material";
import Chip from "@mui/material/Chip";
import "./BookingDetails.css";
import Pusher from "pusher-js";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ImageBanner from "../../../images/Adbanner4x.png";
import { Image } from "../../styledcomponents/popupStyledcomponents";
import { useAuth } from "../../../ContextApi/Contextapi";
const TextRoot = styled.div`
  /* background-color: #9f94942b; */
  /* padding: 20px 0px; */
  /* width: 65vw; */
  /* margin-top: 20px; */
  @media (max-width: 768px) {
    width: 90vw;
  }
`;
const DocInfo = styled.div`
  display: flex;
`;
const DocName = styled.div`
  // margin-left: 4px;
  // margin-right: 2px;
  // font-weight: 600;
`;

const Root = styled.div`
  /* margin: 0px 60px; */
  @media (max-width: 768px) {
    margin: 0px 20px;
  }
`;

const HeadingWrapper = styled.div`
  /* margin-left: -30px; */
`;
const Heading = styled.div`
  font-size: 1.75rem;
  color: #0098d4;
  @media (max-width: 768px) {
    padding-bottom: 10px;
  }
`;

const TextSelectField = styled.div`
  margin: 10px 0px 0px 10px;

  @media (max-width: 768px) {
    margin: 0;
    width: 100%;
  }
`;

const Select = styled.select`
  padding: 10px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  border: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
  }
`;
const RecentlyUploadedHeader = styled.div`
  display: grid;
  // grid-template-columns: 25% 15% 15% 15% 15% 5% 10%;
  margin: 15px 0;
  padding: 14px 15px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const RecentlyUploaded = styled.div`
  background: #fff;
  display: grid;
  // grid-template-columns: 25% 15% 15% 15% 15% 5% 10%;
  // grid-template-columns:26% 14% 14% 13% 12% 8% 13%;
  -webkit-box-align: center;
  align-items: center;
  margin: 15px 0;
  padding: 14px 15px;
  font-size: 13px;
  font-weight: 500;
  box-shadow: 0px 0px 5px 5px #0000;
  border-radius: 5px;
  @media (max-width: 768px) {
    display: flex;
    -webkit-box-pack: justify;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const RecentlyUploadedDate = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const RecentlyUploadedStatus = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: none;
  }
`;

const RecentlyUploadedHeaderElem = styled.div`
  color: rgb(122 122 122);
  font-size: 14px;
`;

const RecentlyUploadedButtonWrapper = styled.div`
  cursor: pointer;
`;

const TextMainWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const MainTab = styled.div`
  display: flex;
  /* margin-left: -30px; */
  margin-top: 15px;
`;
const FirstTab = styled.div``;
const SecondTab = styled.div``;

const TextWrapper1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const RootDiv = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: flex;
    padding-top: 5px;
  }
`;

const HotelWrapperScrollStyle = styled.div`
  @media (max-width: 1440px) {
    width: 1300px;
    overflow: scroll;
  }
  @media (max-width: 768px) {
    width: 100%;
    overflow: scroll;
  }
`;

const BookingDetails = () => {
  const [select, setSelect] = useState("");
  const [activeTab, setActiveTab] = useState("hotel");
  const baseUrl = environmentVariables.apiUrl;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [key1, setKey1] = useState(null);
  const [showPopup, setShowPopup] = useState(true);

  const [isDownloading, setIsDownloading] = useState(false);
  const location = useLocation();
  const hasQueryParams = location.search !== "";

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(10);

  const navigation = useNavigate();
  const { authData } = useAuth();

  useEffect(() => {
    if (window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, []);
  const handleClick = (item) => {
    navigation(`/bookinghoteldetails/${item?.orderid}`);
  };

  const bookingHistory = async () => {
    axios({
      method: "get",
      url: `${baseUrl}auth/getallorders?page=${page}&perPage=${perPage}&status=${select}&type=${activeTab}`,
      headers: { _token: authData?.token },
    })
      .then((response) => {
        console.log("res==============", response?.data?.data);
        // const responseData = response.data.data.sort(
        //   (a, b) => b.createdAt - a.createdAt
        // );
        // setTotalItems(response?.data?.totalDataLength);
        // setData(responseData);
        setData(response?.data?.data || []);
        setTotalItems(response?.data?.totalOrders || 0);
        setIsLoading(false);
      })
      .catch((error) => {
        setData([]);
      });
    // }, 1000);
  };
  useEffect(() => {
    setPage(1);
    if (page === 1) {
      bookingHistory();
      setIsLoading(true);
    }
  }, [select, authData, page, activeTab]);

  useEffect(() => {
    bookingHistory();
    setIsLoading(true);
  }, [page, activeTab]);

  let setLoginHandler = (data, expirationTimestamp, status) => {
    if (status) {
      // setAuthData({ data, expirationTimestamp });
      bookingHistory();
    }
  };
  // useEffect(() => {
  //   socket.on("user_booking_approved", (xyz) => {
  //     bookingHistory();
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    Pusher.logToConsole = true;
    const pusher = new Pusher("cbb68a6fad0862e7fd60", {
      cluster: "ap2",
    });

    const channelUserbookingApproved = pusher.subscribe(
      "user_booking_approved"
    );
    const handleUserBookingApproved = (receivedData) => {
      bookingHistory();
    };
    channelUserbookingApproved.bind("create", handleUserBookingApproved);

    return () => {
      channelUserbookingApproved.unbind("create", handleUserBookingApproved);
      pusher.unsubscribe("user_booking_approved");
    };
  }, []);

  // const functForBookingTable = async () => {
  //   const search = window.location.search;
  //   const params = new URLSearchParams(search);
  //   const foo = params.get("status");
  //   const orderId = params.get("orderid");

  //   await axios({
  //     url: `${baseUrl}auth/getoderstatus/${orderId}`,
  //     method: "get",
  //     headers: { _token: authData.data.data.token },
  //   })
  //     .then(async (response) => {
  //       if (response.data.data.status === "SUCCESS") {
  //         await axios({
  //           url: `${baseUrl}auth/booking/${hotelDetails._id}/${customerDetails._id}/${orderId}`,
  //           method: "post",
  //           data: {
  //             rooms: formData.rooms,
  //             price: roomsTotalPrice,
  //             adult: formData.adult,
  //             children: formData.children,
  //             checkIn: moment(formData.checkIn, "DD/MM/YYYY").format(
  //               "YYYY/MM/DD"
  //             ),
  //             checkOut: moment(formData.checkOut, "DD/MM/YYYY").format(
  //               "YYYY/MM/DD"
  //             ),
  //           },
  //           headers: { _token: authData.data.data.token },
  //         })
  //           .then((response) => {
  //             setBookingResData(response.data);
  //             navigation("/bookinghistory");
  //             bookingHistory();
  //             socket.emit("user_booking");
  //             // navigation('/bookinghistory');
  //           })
  //           .catch((error) => {
  //           });

  //         Swal.fire(
  //           "Your Payment Has been successfully done",
  //           `Your Order Id is ${response.data.data.systemid}`,
  //           "success"
  //         );
  //       } else {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Payment Failed",
  //           text: `Your Order Id is ${response.data.data.systemid}`,
  //         });
  //       }
  //       // setBookingResData(response.data);
  //       // navigation("/bookinghistory");
  //       // navigation('/bookinghistory');
  //     })
  //     .catch((error) => {
  //       // Swal.fire({
  //       //   icon: "error",
  //       //   title: "Something went wrong",
  //       //   // text: `Your Order Id is ${orderId}`,
  //       // });
  //     });
  //   // if (foo) {
  //   //   if (foo === "SUCCESS") {
  //   // Swal.fire(
  //   //   "Your Payment Has been successfully done",
  //   //   `Your Order Id is ${orderId}`,
  //   //   "success"
  //   // );

  //   //   } else {
  //   // Swal.fire({
  //   //   icon: "error",
  //   //   title: "Payment Failed",
  //   //   text: `Your Order Id is ${orderId}`,
  //   // });
  //   //   }
  // };

  // useEffect(() => {
  //   functForBookingTable();
  // }, []);

  function formatDate(timestamp) {
    const options = {
      timeZone: "Asia/Kolkata",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const formattedDate = new Date(timestamp).toLocaleString("en-IN", options);
    return formattedDate;
  }
  const Rediect = (item) => {
    window.location.href = `${item.paymentDetail.url}`;
  };

  const setKeyValue = (key) => {
    setKey1(key);
  };
  const handleDownload = async (state) => {
    try {
      let bookingId = state._id;
      setIsDownloading(true);

      // const response = await axios({
      //   url: `${baseUrl}auth/downloadInvoice/${bookingId}`,
      //   method: "get",
      //   headers: { _token: authData?.token },
      //   responseType: "text/html",
      // });

      // const tempElement = document.createElement("div");
      // tempElement.innerHTML = response.data.data;

      // const iframe = document.createElement("iframe");
      // iframe.style.display = "none";
      // document.body.appendChild(iframe);

      // iframe.contentDocument.open();
      // iframe.contentDocument.write(response.data.data);
      // iframe.contentDocument.close();

      // // Use html2pdf to convert the iframe content to a PDF
      // html2pdf(iframe.contentDocument.body, {
      //   margin: 10,
      //   filename: "generated_document.pdf",
      //   image: { type: "jpeg", quality: 0.98 },
      //   html2canvas: { scale: 2 },
      //   jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      // }).then(() => {
      //   // Remove the iframe from the DOM
      //   document.body.removeChild(iframe);
      //   setIsDownloading(false);
      // });

      // html2pdf(tempElement, {
      //   margin: 10,
      //   filename: `Invoice_${state._id}.pdf`,
      //   image: { type: "jpeg", quality: 0.98 },
      //   html2canvas: { scale: 2 },
      //   jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      // }).then(() => {
      //   setIsDownloading(false);
      //   Swal.fire("Invoice", `Invoice downloaded as PDF.`, "success");
      // }).catch((error) => {
      //   });

      // const response = await axios.get(`${baseUrl}auth/downloadInvoice/${bookingId}`, {
      //     responseType: 'blob',
      //     headers: {
      //         _token: authData?.token
      //     }
      //   });
      //   const blob = new Blob([response.data.data], { type: 'application/pdf' });

      //   // Create a link element to trigger the download
      //   const downloadLink = document.createElement('a');
      //   downloadLink.href = window.URL.createObjectURL(blob);
      //   downloadLink.download = 'invoice.pdf'; // Set the file name
      //   downloadLink.click();

      const response = await axios.get(
        `${baseUrl}auth/downloadInvoice/${bookingId}`,
        {
          responseType: "blob", // Important
          headers: {
            _token: authData?.token,
          },
        }
      );
      // console.log("response", response?.data);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Invoice_${bookingId}.pdf`;
      a.click();
      setIsDownloading(false);
    } catch (error) {
      console.log(error?.response);
      Swal.fire({
        icon: "error",
        text: `Somthing went wrong!`,
      });
    }
  };

  const getComponents = () => {
    if (data === null || data === undefined) {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularLoader></CircularLoader>
        </div>
      );
    } else if (data !== null && data.length === 0) {
      return (
        <div style={{ margin: "20px", fontSize: "30px", fontWeight: "500" }}>
          No Booking Found
        </div>
      );
    } else {
      return data.map((item, key) => {
        return (
          <RecentlyUploaded
            style={{
              gridTemplateColumns:
                activeTab === "hotel"
                  ? "28% 12% 13% 12% 13% 22%"
                  : activeTab == "both"
                  ? "25% 12% 12% 12% 12% 15% 12%"
                  : "32% 16% 16% 12% 24%",
              "@media (max-width: 1440px)": {
                gridTemplateColumns:
                  activeTab === "hotel"
                    ? "21% 14% 14% 14% 12% 25%"
                    : "32% 16% 16% 12% 24%",
              },
            }}
            key={key}
          >
            <DocInfo>
              <DocName>{item?.hotel_activity_data?.hotelname} </DocName>
            </DocInfo>
            <RecentlyUploadedDate>
              {formatDate(item.checkIn)}
            </RecentlyUploadedDate>
            {activeTab != "activity" && (
              <RecentlyUploadedDate>
                {formatDate(item.checkOut)}
              </RecentlyUploadedDate>
            )}
            <RecentlyUploadedDate>
              {formatDate(item.createdAt)}
            </RecentlyUploadedDate>
            {activeTab == "both" && (
              <RecentlyUploadedDate>
                {item?.activities?.length}{" "}
                {item?.activities?.length > 1 ? "Activities" : "Activity"}
              </RecentlyUploadedDate>
            )}

            <RecentlyUploadedStatus>
              {item.status === "success" ? (
                <Chip
                  variant="outlined"
                  sx={{
                    borderRadius: "3px !important",
                    background: "#eafdee !important",
                    color: "#00b046 !important",
                    fontSize: "11px",
                    width: "110px",
                  }}
                  label="Confirmed"
                  color="success"
                />
              ) : item.status === "cancel" ? (
                <Chip
                  variant="outlined"
                  sx={{
                    borderRadius: "3px !important",
                    background: "#faf2f2 !important",
                    color: "#d70000 !important",
                    fontSize: "11px",
                    width: "110px",
                  }}
                  label="Cancelled"
                  color="error"
                />
              ) : item.status === "complete" ? (
                <Chip
                  sx={{
                    borderRadius: "3px !important",
                    background: "#eafdee !important",
                    color: "#00b046 !important",
                    fontSize: "11px",
                    width: "110px",
                  }}
                  variant="outlined"
                  label="Completed"
                  color="success"
                />
              ) : (
                item.status == "failed" && (
                  <Chip
                    sx={{
                      borderRadius: "3px !important",
                      background: "#faf2f2 !important",
                      color: "#d70000 !important",
                      fontSize: "11px",
                      width: "110px",
                    }}
                    variant="outlined"
                    label="Failed"
                    color="error"
                  />
                )
              )}
            </RecentlyUploadedStatus>
            <RootDiv>
              <RecentlyUploadedButtonWrapper>
                <MIButton
                  onClick={() => handleClick(item)}
                  variant="contained"
                  size="small"
                  sx={{
                    display: "flex !important",
                    fontSize: "12px",
                    width: "110px",
                    height: "32px",
                  }}
                >
                  View
                </MIButton>
              </RecentlyUploadedButtonWrapper>
              {item?.status === "pending" ? (
                ""
              ) : item?.status === "approved" ? (
                <MIButton
                  className="paybtn"
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{
                    display: "flex !important",
                    fontSize: "12px",
                    width: "125px",
                    height: "32px",
                    marginLeft: "5px",
                  }}
                  onClick={() => Rediect(item)}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  Pay {item.paymentDetail.currency}{" "}
                  {item.paymentDetail.payAmount
                    ? (+item.paymentDetail.payAmount).toFixed(2)
                    : "0.00"}{" "}
                </MIButton>
              ) : item.status === "cancelled" ? (
                ""
              ) : (
                (item.status === "completed" || item.status === "confirmed") &&
                !(item.isCombined && item.type === "activity") && (
                  <LoadingButton
                    className="invoicebtn"
                    loading={key === key1 && isDownloading}
                    disabled={key === key1 && isDownloading}
                    fullWidth={true}
                    variant="contained"
                    size="small"
                    sx={{
                      display: "flex !important",
                      fontSize: "12px",
                      width: "125px",
                      height: "32px",
                      marginLeft: "5px",
                    }}
                    startIcon={<DownloadIcon />}
                    onClick={() => {
                      handleDownload(item);
                      setKeyValue(key);
                    }}
                  >
                    Invoice
                  </LoadingButton>
                )
              )}
            </RootDiv>
          </RecentlyUploaded>
        );
      });
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Update the active tab when a tab is clicked
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const PaginationControls = () => {
    const totalPages = Math.ceil(totalItems / perPage);
    const handlePrevClick = () => {
      if (page > 1) {
        handlePageChange(page - 1);
      }
    };

    const handleNextClick = () => {
      if (page < totalPages) {
        handlePageChange(page + 1);
      }
    };

    return (
      <div className="pagination-controls pagination_in_BH">
        <button onClick={handlePrevClick} disabled={page === 1}>
          Previous
        </button>
        <span>
          Page {page} of {totalPages}
        </span>
        <button onClick={handleNextClick} disabled={page === totalPages}>
          Next
        </button>
      </div>
    );
  };

  return (
    <>
      <TextMainWrapper>
        {/* <SideBar><LeftSlideBar/></SideBar>  */}
        <TextRoot className="mobtop">
          <Root className="btntabrow1">
            <TextWrapper>
              <HeadingWrapper>
                <Heading className="bookingtitle">Booking History</Heading>
              </HeadingWrapper>
            </TextWrapper>
            {/* <Image src={ImageBanner}/> */}
            <TextWrapper1 className="btntabrow">
              <MainTab className="btntab">
                <FirstTab
                  className={`tab ${activeTab === "hotel" ? "active" : ""}`}
                  onClick={() => handleTabClick("hotel")}
                >
                  Hotel Bookings
                </FirstTab>
                <SecondTab
                  className={`tab ${activeTab === "activity" ? "active" : ""}`}
                  onClick={() => handleTabClick("activity")}
                >
                  Activity Bookings
                </SecondTab>
                <SecondTab
                  className={`tab ${activeTab === "both" ? "active" : ""}`}
                  onClick={() => handleTabClick("both")}
                >
                  Package Bookings
                </SecondTab>
              </MainTab>
              <TextSelectField>
                <Select
                  onChange={(e) => {
                    setSelect(e.target.value);
                  }}
                  value={select}
                  required
                >
                  <option value="all">All</option>
                  <option value="success">Confirmed Booking</option>
                  <option value="failed">Failed Booking</option>
                  <option value="complete">Completed Booking</option>
                  {/* <option value="cancel">Cancelled Booking</option> */}
                </Select>
              </TextSelectField>
            </TextWrapper1>
          </Root>
          <HotelWrapperScrollStyle>
            {activeTab === "hotel" ? (
              <RecentlyUploadedHeader
                style={{
                  gridTemplateColumns: "28% 13% 13% 13% 11% 11% 11%",
                  fontWeight: 600,
                }}
              >
                <RecentlyUploadedHeaderElem>
                  Hotel Name
                </RecentlyUploadedHeaderElem>
                <RecentlyUploadedHeaderElem>
                  Check-In
                </RecentlyUploadedHeaderElem>
                <RecentlyUploadedHeaderElem>
                  Check-Out
                </RecentlyUploadedHeaderElem>
                <RecentlyUploadedHeaderElem>
                  Creation Date
                </RecentlyUploadedHeaderElem>
                <RecentlyUploadedHeaderElem>Status</RecentlyUploadedHeaderElem>
                <RecentlyUploadedHeaderElem>Action</RecentlyUploadedHeaderElem>
                {/* <RecentlyUploadedHeaderElem>Invoice</RecentlyUploadedHeaderElem> */}
                {/* <RecentlyUploadedHeaderElem>Payment</RecentlyUploadedHeaderElem> */}
              </RecentlyUploadedHeader>
            ) : activeTab === "activity" ? (
              <RecentlyUploadedHeader
                style={{
                  gridTemplateColumns: "32% 16% 16% 12% 12% 12%",
                  fontWeight: 600,
                }}
              >
                <RecentlyUploadedHeaderElem>
                  Activity Name
                </RecentlyUploadedHeaderElem>
                <RecentlyUploadedHeaderElem>
                  Activity Date
                </RecentlyUploadedHeaderElem>
                <RecentlyUploadedHeaderElem>
                  Creation Date
                </RecentlyUploadedHeaderElem>
                <RecentlyUploadedHeaderElem>Status</RecentlyUploadedHeaderElem>
                <RecentlyUploadedHeaderElem>Action</RecentlyUploadedHeaderElem>
                {/* <RecentlyUploadedHeaderElem>Invoice</RecentlyUploadedHeaderElem> */}
                {/* <RecentlyUploadedHeaderElem>Payment</RecentlyUploadedHeaderElem> */}
              </RecentlyUploadedHeader>
            ) : (
              activeTab == "both" && (
                <RecentlyUploadedHeader
                  style={{
                    gridTemplateColumns: "25% 12% 12% 12% 12% 15% 12%",
                    fontWeight: 600,
                  }}
                >
                  <RecentlyUploadedHeaderElem>
                    Hotel Name
                  </RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>
                    Check-In
                  </RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>
                    Check-Out
                  </RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>
                    Creation Date
                  </RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>
                    Number Of Activities
                  </RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>
                    Status
                  </RecentlyUploadedHeaderElem>
                  <RecentlyUploadedHeaderElem>
                    Action
                  </RecentlyUploadedHeaderElem>
                  {/* <RecentlyUploadedHeaderElem>Invoice</RecentlyUploadedHeaderElem> */}
                  {/* <RecentlyUploadedHeaderElem>Payment</RecentlyUploadedHeaderElem> */}
                </RecentlyUploadedHeader>
              )
            )}
            {isLoading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularLoader></CircularLoader>
              </div>
            ) : (
              <>
                {getComponents()}
                <PaginationControls />
              </>
            )}
          </HotelWrapperScrollStyle>
        </TextRoot>

        {showPopup && hasQueryParams && authData === undefined ? (
          <LoginPopup
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            pageRequest={"bookinghistory"}
            setLoginHandler={setLoginHandler}
          />
        ) : null}
      </TextMainWrapper>
    </>
  );
};

export default BookingDetails;
