import React, { useEffect, useState } from "react";
import { useRef } from "react";
import axios from "axios";
import {
  CloseIcon,
  ContentButtonCities,
  ContentButtonWrapper,
  ContentHeadingCities,
  ContentInput,
  ContentInputText,
  ContentSection,
  HeaderContinueButton,
  HeaderSection,
  HeaderText,
  HeaderTextCityName,
  HeaderTextWrapper,
  InputWrapper,
  InputOuterWrapper,
  Root,
  CityNamesList,
  CityNameOptions,
  CityNamesList1,
} from "./SelectCityPopUp.styles";
import { environmentVariables } from "../../config/config";

const SelectCityPopUp = ({
  stateData,
  state,
  show,
  setShow,
  getCityData,
  cityName,
  scrollAfterSelection,
  setScrollAfterSelection,
}) => {
  const baseUrl = environmentVariables.apiUrl;
  const [searchedCities, setSearchedCities] = useState([]);
  const [popularCityData, setPopularCityData] = useState();
  const [showCities, setShowCities] = useState(false);
  const [city, setCity] = useState("");
  const [cursor, setCursor] = useState(0);
  const getCityName = localStorage.getItem("cityname");


  const searchOptionsRef = useRef();
  const getSearchData = () => {
    setShowCities(true);
    axios
      .get(`${baseUrl}auth/getallcitynamefromhotel`)
      .then((res) => {
        if (res?.data?.message) {
          setSearchedCities(res?.data?.message);
        }
      })
      .catch((err) => console.log(err));
  };
  const getPopularCities = () => {
    axios
      .get(`${baseUrl}auth/getnameofcity`)
      .then((res) => {
        const uniqueObjects = Array.from(
          res.data.data
            .reduce((map, obj) => {
              const key = obj.city.toLowerCase();
              if (!map.has(key)) {
                map.set(key, obj);
              }
              return map;
            }, new Map())
            .values()
        );
        setPopularCityData(uniqueObjects);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPopularCities();
  }, []);

  let main_data = searchedCities.filter((val) => {
    if (val?.city.toLowerCase().includes(city.toLowerCase())) {
      return val;
    }
  });
  const handleUpDownCity = (e) => {
    if (e.keyCode === 38) {
      // setSelect(filter[cursor]?.hotelname)
      searchOptionsRef.current.scrollTop -= 44;
      if (cursor !== 0) {
        setCursor((preState) => preState - 1);
      }
    } else if (e.keyCode === 40) {
      searchOptionsRef.current.scrollTop += 44;
      if (cursor !== main_data.length - 1) {
        setCursor((preState) => preState + 1);
      }
    }
    if (e.keyCode === 13) {
      getCityData(
        main_data[cursor].state.charAt(0).toUpperCase() +
          main_data[cursor].state.slice(1),
        main_data[cursor].city,
        main_data[cursor].country,
        main_data[cursor].countryISOName
      );
      // setSearch(filter[cursor]?.hotelname);
      // navigation("/hoteldetails", { state: filter[cursor] });
      setShow(!show);
    }
    if (e.keyCode === 9) {
      getCityData(
        main_data[cursor].state.charAt(0).toUpperCase() +
          main_data[cursor].state.slice(1),
        main_data[cursor].city,
        main_data[cursor].country,
        main_data[cursor].countryISOName
      );
      setShow(!show);
    }
  };

  return (
    <Root
      onClick={(e) => {
        e.stopPropagation();
        setShowCities(false);
      }}
      show={show}
      // style={{width:"120vw",zIndex:12}}
    >
      <HeaderSection>
        <HeaderTextWrapper>
          {" "}
          {/* {localStorage.getItem("cityname") ||
            selectedCity ||
            selectedCity !== "" ? (
              <>
                <HeaderText>I am based in</HeaderText>
                <HeaderTextCityName>{cityName}</HeaderTextCityName>
              </>
            ):(<></>)} */}
          {getCityName === null ? (
            <>
              <HeaderText>I am based in</HeaderText>
            </>
          ) : (
            <>
              <HeaderText>I am based in</HeaderText>
              <HeaderTextCityName>{getCityName}</HeaderTextCityName>
            </>
          )}
          <HeaderContinueButton onClick={() => setShow(false)}>
            Continue
          </HeaderContinueButton>
          <CloseIcon
            onClick={() => setShow(false)}
            className="fa fa-times fa-2x"
          />
        </HeaderTextWrapper>
      </HeaderSection>
      <ContentSection>
        <InputOuterWrapper>
          <InputWrapper onClick={(e) => e.stopPropagation()}>
            {/* {localStorage.getItem("cityname") &&
              selectedCity &&
              selectedCity !== "" && (
                <ContentInputText>{selectedCity===null || selectedCity === undefined  ? <> </> : `Not in ${selectedCity} ?`}</ContentInputText>
              )} */}
            {
              <ContentInputText>
                {getCityName === null ? <> </> : `Not in ${getCityName} ?`}
              </ContentInputText>
            }
            <ContentInput
              value={city}
              onChange={(e) => setCity(e.target.value)}
              onClick={() => {
                getSearchData();
                setCursor(0);
              }}
              onKeyDown={(e) => handleUpDownCity(e)}
              placeholder="Select Your City"
              // onKeyDown={(e) => KeyDown(e)}
            />
          </InputWrapper>
          {showCities === true &&
            main_data &&
            (main_data.length === 0 ? (
              <CityNamesList>
                <CityNameOptions>No Matching Result</CityNameOptions>
              </CityNamesList>
            ) : main_data.length > 10 ? (
              <CityNamesList1 ref={searchOptionsRef}>
                {main_data.map((val, key) => {
                  if (val?.city.toLowerCase().includes(city.toLowerCase())) {
                    return (
                      <CityNameOptions
                        key={key}
                        style={
                          cursor === key
                            ? {
                                backgroundColor: "Lightgray",
                                padding: "10px",
                                width: "100%",
                              }
                            : {
                                backgroundColor: "#fff",
                                padding: "10px",
                                width: "100%",
                              }
                        }
                        onClick={() => {
                          getCityData(
                            val.state.charAt(0).toUpperCase() +
                              val.state.slice(1),
                            val.city,
                            val.country,
                            val.countryISOCode
                          );
                          setCity("");
                          setShow(false);
                          setShowCities(false);
                          setScrollAfterSelection(true);
                        }}
                      >
                        {val.city}
                      </CityNameOptions>
                    );
                  }
                })}
              </CityNamesList1>
            ) : (
              <CityNamesList>
                {main_data.map((val, key) => {
                  if (val?.city.toLowerCase().includes(city.toLowerCase())) {
                    return (
                      <CityNameOptions
                        key={key}
                        style={
                          cursor === key
                            ? {
                                backgroundColor: "Lightgray",
                                padding: "10px",
                                width: "100%",
                              }
                            : {
                                backgroundColor: "#fff",
                                padding: "10px",
                                width: "100%",
                              }
                        }
                        onClick={() => {
                          getCityData(
                            val.state.charAt(0).toUpperCase() +
                              val.state.slice(1),
                            val.city,
                            val.country,
                            val.countryISOCode
                          );
                          setCity("");
                          setShow(false);
                          setShowCities(false);
                          setScrollAfterSelection(true);
                        }}
                      >
                        {val.city}
                      </CityNameOptions>
                    );
                  }
                })}
              </CityNamesList>
            ))}
        </InputOuterWrapper>
        <ContentHeadingCities>Popular Destinations in UAE</ContentHeadingCities>
        <ContentButtonWrapper>
          {popularCityData &&
            popularCityData.map((item, index) => {
              return (
                item.country !== "India" && (
                  <ContentButtonCities
                    key={index}
                    onClick={() => {
                      getCityData(
                        item.state.charAt(0).toUpperCase() +
                          item.state.slice(1),
                        item.city,
                        item.country,
                        item.countryISOName
                      );
                      setShow(false);
                      setScrollAfterSelection(true);
                    }}
                  >
                    {item.city}
                  </ContentButtonCities>
                )
              );
            })}
        </ContentButtonWrapper>

        {/* <HorizontalLineWrapper>
          <HoriZontalLine />
        </HorizontalLineWrapper>
        <ContentHeadingCities>
          Popular Destinations in India
        </ContentHeadingCities>
        <ContentButtonWrapper>
          {popularCityData &&
            popularCityData.map((item, index) => {
              return (
                // <List
                //   key={index}
                //   onClick={() => {
                //     getCityData(
                //       item.cityName.charAt(0).toUpperCase() +
                //         item.cityName.slice(1),
                //       showDropDown
                //     );
                //   }}
                // >
                //   {item.cityName.charAt(0).toUpperCase() + item.cityName.slice(1)}
                // </List>
                item.country === "India" && (
                  <ContentButtonCities
                    onClick={() => {
                      getCityData(
                        item.state.charAt(0).toUpperCase() +
                          item.state.slice(1),
                        item.city,
                        item.country,
                        item.countryISOName
                      );
                      setShow(false);
                      setScrollAfterSelection(true);
                    }}
                  >
                    {item.city}
                  </ContentButtonCities>
                )
              );
            })}
        </ContentButtonWrapper> */}

        
        {/* <ContentHeadingCities>Ideas for a short break?</ContentHeadingCities> */}
        {/* <CountriesButtonWrapper>
          <CountryButtonWapper>
            <FlagTextWrapper>
              <CountryName>Hill Resort Near Bangalore</CountryName>
            </FlagTextWrapper>
            <IconWrapper>
              <i className="fa fa-arrow-circle-right"></i>
            </IconWrapper>
          </CountryButtonWapper>
          <CountryButtonWapper>
            <FlagTextWrapper>
              <CountryName>Luxury Resorts in Udaipur</CountryName>
            </FlagTextWrapper>
            <IconWrapper>
              <i className="fa fa-arrow-circle-right"></i>
            </IconWrapper>
          </CountryButtonWapper>
          <CountryButtonWapper>
            <FlagTextWrapper>
              <CountryName>Beach Resort Near Mysore</CountryName>
            </FlagTextWrapper>
            <IconWrapper>
              <i className="fa fa-arrow-circle-right"></i>
            </IconWrapper>
          </CountryButtonWapper>
          <CountryButtonWapper>
            <FlagTextWrapper>
              <CountryName>Water front Resort near Jaisalmer</CountryName>
            </FlagTextWrapper>
            <IconWrapper>
              <i className="fa fa-arrow-circle-right"></i>
            </IconWrapper>
          </CountryButtonWapper>
          <CountryButtonWapper>
            <FlagTextWrapper>
              <CountryName>Home Stay Near Ajmer</CountryName>
            </FlagTextWrapper>
            <IconWrapper>
              <i className="fa fa-arrow-circle-right"></i>
            </IconWrapper>
          </CountryButtonWapper>
          <CountryButtonWapper>
            <FlagTextWrapper>
              <CountryName>Spa Resorts Near Bikaner</CountryName>
            </FlagTextWrapper>
            <IconWrapper>
              <i className="fa fa-arrow-circle-right"></i>
            </IconWrapper>
          </CountryButtonWapper>
        </CountriesButtonWrapper> */}
      </ContentSection>
    </Root>
  );
};

export default SelectCityPopUp;
