import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { environmentVariables } from "../../config/config";

const Root = styled.div`
  padding: 80px 0;
  margin-top: 50px;
  position: relative;
  background-color: #e3e3e3;
  @media (max-width: 768px) {
    padding: 40px 0;
  }
`;
const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  @media (max-width: 1600px) {
    width: 1200px;
    padding: 0 40px;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 40px;
  }
  @media (max-width: 767px) {
    padding: 0 20px;
    flex-direction: column;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const Text = styled.div`
  color: #131313;
  font-size: 32px;
  font-weight: bold;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 25px;
  }
  /* margin-bottom:5px; */
`;

const Msgdata = styled.div`
  color: #d63b3b;
  font-size: 20px;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 15px;
  }
  /* margin-bottom:5px; */
`;

const Para = styled.div`
  color: #0f0f0f;
  width: 70%;
  text-align: center;
  font-weight: 300;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const ServicesSection = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  @media (max-width: 648px) {
    flex-direction: column;
  }
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 13px;
  border: 3px solid #ffff;
`;
const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;

  ${(p) =>
    p.bgImage &&
    `
   background-image:url(${p.bgImage});
`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
  width: 100%;
  border-radius: 10px;
  ${(p) =>
    p.height ? `height: ${p.height};margin-top:20px` : `height: 300px;`};
  position: relative;
  @media (max-width: 768px) {
    height: 300px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  // border-radius: 10px;
  padding: 0px 6px;
  // background: #ffff;
  background: #f7eeee57;
  width: 100%;
`;
const CardText = styled.div`
  color: back;
  font-size: 25px;
  font-weight: 600;

  @media (max-width: 767px) {
    font-size: 20px;
  }
`;

const HotelCollection = ({ message, message2 }) => {
  const navigation = useNavigate();
  const baseUrl = environmentVariables.apiUrl;
  const getCity = localStorage.getItem("city");
  const getCityName = localStorage.getItem("cityname");
  const [activities, setActivities] = useState([]);
  useEffect(() => {
    if (message || getCity || getCityName) {
      var config = {
        method: "post",
        url: `${baseUrl}auth/getActivities`,
        headers: {},
        data: { city: message2 || getCityName },
      };

      axios(config)
        .then(function (response) {
          if (response.data.success) {
            setActivities(response.data.message);
          } else {
            setActivities([]);
          }
        })
        .catch(function (error) {
          setActivities([]);
        });
    }
  }, [message, getCity, getCityName]);

  return (
    <Root>
      <Container>
        <Wrapper>
          <Text>Out of Ideas?</Text>
          <Para>Explore the bucket list now!</Para>
          <ServicesSection>
            {activities &&
              activities.map((item, key) => {
                return (
                  <Div key={key}>
                    {/* <LeftCardWrapper> */}
                    <Card
                      onClick={() =>
                        navigation(`/activityDetail/${item?._id}`, {
                          // state: item,
                          city: getCityName || message2,
                        })
                      }
                      bgImage={`https://bastionex-travels.b-cdn.net/uploads/${item.image[0]}`}
                    >
                      <TextWrapper>
                        <CardText>{item.hotelname}</CardText>
                      </TextWrapper>
                    </Card>
                    {/* </LeftCardWrapper> */}
                  </Div>
                );
              })}
            {activities.length === 0 ? (
              <>
                <div></div>
                <div style={{ margin: "0px auto" }}>
                  <Msgdata>Data Unavailable: Keep Exploring!</Msgdata>
                </div>
              </>
            ) : null}
          </ServicesSection>
        </Wrapper>
      </Container>
    </Root>
  );
};

export default HotelCollection;
