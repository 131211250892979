import * as Yup from "yup";

export const SignupSchema = Yup.object({
  username: Yup.string().min(2).max(25).required("Please Enter Your Name"),
  email: Yup.string().email().required("Please Enter Your Email"),
  newPassword: Yup.string()
    .required("Please Enter Your Password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: Yup.string()
    .required("Please Enter Confirm Password")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "Password and Confirm Password Must be Same"
    ),
  captchaValue: Yup.string().required("Please complete the reCAPTCHA"),
});
