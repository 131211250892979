import axios from "axios";
import React, { useEffect, useState, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import BrandLogo from "../../images/bastionextravel_logo3.png";
import BrandLogo from "../../images/LogoDark.png";
import styled from "styled-components";
import { environmentVariables } from "../../config/config";
import { Button } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import { AuthContext, useAuth } from "../../ContextApi/Contextapi";
import "./footer.css";
import moment from "moment";
import { Grid } from "@mui/material";

const ChatAPI = require("twilio-chat");

const Root = styled.div`
  /* background-color: #fff; */
  // background: linear-gradient(180deg, rgb(0, 0, 0) 20%, rgb(1 58 32) 100%);
  /* padding-top: 60px; */
  position: relative;
  @media (max-width: 767px) {
    padding-top: 30px;
  }
`;

const ChatIconWrapper = styled.div`
  position: fixed;
  bottom: 5%;
  right: 2%;
`;
const NotificationWrapper = styled.div`
  background: red;
  width: 32%;
  border-radius: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: -11%;
  right: 5%;
  font-size: 14px;
`;
const NotificationText = styled.text`
  color: #fff;
  font-weight: 500;
`;

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 70px 0 30px;
  border-bottom: 1px solid #e9e9e9;
  /* padding-bottom:40px; */
  @media (max-width: 1600px) {
    width: 1200px;
    padding: 70px 0 30px;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 70px 40px 30px;
  }
  @media (max-width: 767px) {
    padding: 0 20px;
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
const Div = styled.div`
  display: flex;
  justify-content: right;
  flex-direction: column;
  width: 33.33%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const InfoWrapper = styled.div`
  width: 30%;
  min-width: 30%;
  padding-right: 40px;
  @media (max-width: 888px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const Image = styled.img`
  width: 140px;
  margin-bottom: 10px;
`;

const Text = styled.div`
  color: #6c6c6c;
  line-height: 20px;
  letter-spacing: normal;
  text-align: justify;
  margin-bottom: 20px;
  word-break: break-word;
  font-size: 15px;
  font-weight: 500;
  @media (max-width: 767px) {
    line-height: 20px;
  }
`;
const A = styled.a`
  margin-right: 10px;
`;

const IconWrapper = styled.div`
  display: none;
  font-size: 18px;
  margin: 15px 0;
  @media (max-width: 888px) {
    margin-bottom: 20px;
  }
  @media (min-width: 767px) {
    display: flex;
  }
`;

const Link = styled.li`
  color: #6c6c6c;
  font-size: 15px;
  list-style: none;
  padding-bottom: 15px;
  font-weight: 500;
  @media (max-width: 767px) {
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

const Heading = styled.div`
  padding-bottom: 15px;
  color: #3a3a3a;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 18px;
  text-transform: uppercase;
  @media (max-width: 767px) {
    /* margin-bottom: 25px; */
    margin-bottom: 0;
    padding-bottom: 20px;
  }
`;

const FooterLink = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  @media (max-width: 888px) {
    flex-direction: column;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
const BottomText = styled.div`
  /* color: #ffffff; */
  font-size: 16px;
  text-decoration: none;
  font-weight: 500;
  text-align: center;
  background-color: #fff;
  padding-top: 20px;
  white-space: nowrap;
  padding-bottom: 20px;
  /* border-top: 1px solid #18ab6b; */
  /* margin-top:15px; */
  /* margin-bottom:15px; */
  /* text-align: center;
  padding-bottom: 10px;
  color: #7d7d7d;
  font-size: 16px; */
`;

const Line = styled.div`
  background-color: #f1f7f5;
  width: 100%;
  height: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Li = styled.text`
  cursor: pointer;
  /* color:red; */
`;

const LinkDesktopView = styled.ul`
  display: none;
  padding: 0;
  @media (min-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

const LinkMobileView = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

const EventIconWrapper = styled.div`
  display: none;
  color: #b6e1cd !important;
  @media (max-width: 767px) {
    display: flex;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinkWrapper = styled.div`
  display: flex;
  width: 60%;
  min-width: 60%;
  justify-content: space-between;
  @media (max-width: 888px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const CustomKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
  @media (max-width: 425px) {
    margin-left: 270px !important;
  }
`;

const SupportChatStyled = styled.div`
  @media (max-width: 768px) {
    // width: 300px;
    z-index: 999999999;
  }
  @media (max-width: 768px) {
    width: 100% !important;
  }
`;

const IconWrappperMobileView = styled.div`
  font-size: 18px;
  display: none;
  @media (max-width: 767px) {
    display: flex;
  }
`;

const ImageWrapper = styled.div``;

const CustomTextField = styled(TextField)`
  font-size: 4px;
  width: 300px !important;
  // width: 78%,
  @media (max-width: 425px) {
    // width: 235px,
    width: 72% !important;
  }
`;

function Footer({
  chooseMessage,
  chooseMessage1,
  setScrollAfterSelection,
  scrollAfterSelection,
  loggedIn,
}) {
  const baseUrl = environmentVariables.apiUrl;
  const getCity = localStorage.getItem("city");
  const getState = localStorage.getItem("cityname");
  const getCountry = localStorage.getItem("countryname");
  // const { authData, setAuthData } = useContext(AuthContext);
  const { authData } = useAuth();
  // Chat  States list
  const [chatClient, setChatClient] = useState(null);
  const [channel, setChannel] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [supportBoxHeight, setSupportBoxHeight] = useState("50px");
  const [boxTransition, setBoxTransition] = useState("all 0s");
  const [showcompanyList, setShowCompanyList] = useState(false);
  const [showServiceList, setShowServiceList] = useState(false);
  const [showcontactList, setShowContactList] = useState(false);
  const [popularCity, setPopularCity] = useState([]);
  const [data, setData] = useState([]);
  const navigation = useNavigate();
  const { state } = useLocation();
  let scrollDiv = useRef(null);
  const [isConversationBoxDisplay, setIsConversationBoxDisplay] =
    useState("none");
  const [displaySupportButton, setDisplaySupportButton] = useState("block");
  const [newMessageCount, setNewMessageCount] = useState(0);
  const [chatWindowOpen, setChatWindowOpen] = useState(false);

  const handlePopularCityLink = (state, city) => {
    getCityData(state, city);
  };
  const getCities = async () => {
    await axios({
      method: "get",
      url: `${baseUrl}auth/getcitiesdata`,
    })
      .then((response) => {
        setData(response.data.message);
      })
      .catch((error) => {});
  };

  const getCitiesData = async () => {
    const data = {
      country: getCountry === null ? "India" : getCountry,
    };

    const config = {
      method: "post",
      url: `${baseUrl}auth/getpopularcity`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setPopularCity(response.data.data);
      })
      .catch(function (error) {});
  };
  const addTransition = () => {
    setSupportBoxHeight("450px");
    setBoxTransition("all 5s");
  };
  const handleClickSupportButton = () => {
    addTransition();
    setIsConversationBoxDisplay("block");
    setDisplaySupportButton("none");
    // setNewMessageCount(0);
  };
  const handleClickDownArrow = () => {
    setIsConversationBoxDisplay("none");
    setDisplaySupportButton("block");
  };
  const callApi = async (x, y) => {
    navigation("/hotel", { state: { stateName: x, cityName: y } });
  };
  const getCityData = (slug, city) => {
    localStorage.setItem("city", slug);
    localStorage.setItem("cityname", city);
    callApi(slug || getCity, city);
    chooseMessage(slug);
    chooseMessage1(city);
  };
  const getTwilioAuthToken = async () => {
    return await axios({
      method: "POST",
      url: `${baseUrl}auth/generateToken`,
      data: {
        email: authData?.email,
      },
    });
  };

  const initializeChatClient = async () => {
    try {
      const response = await getTwilioAuthToken();
      const token = response.data.token;
      const client = await ChatAPI.Client.create(token);
      setChatClient(client);
      setNewMessageCount(0);
    } catch (error) {}
  };
  const handleChatWindowOpen = () => {
    setChatWindowOpen(true);
    setNewMessageCount(0); // Reset newMessageCount when chat window is opened
  };

  const handleChatWindowClose = () => {
    setChatWindowOpen(false);
  };
  const sendMessage = async () => {
    if (channel && inputText.trim() !== "") {
      try {
        let messageAttributes = null;
        let response = await channel.sendMessage(inputText, messageAttributes);
        setInputText("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    } else {
    }
  };

  async function getChannelByname(channelName) {
    if (chatClient) {
      try {
        const channel = await chatClient.getChannelByUniqueName(channelName);
        return { status: true, error: "", channel };
      } catch (error) {
        return { status: false, error: error.message, channel: null };
      }
    }
  }

  // const scrollToBottom = () => {
  //   setTimeout(() => {
  //     const scrollHeight = scrollDiv?.current?.scrollHeight;
  //     const height = scrollDiv?.current?.clientHeight;
  //     const maxScrollTop = scrollHeight - height;
  //     scrollDiv?.current?.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  //   }, 0);
  // };
  // const removeChannel = async (channel) => {
  //   try {
  //     await channel.delete();
  //   } catch (error) {
  //     console.error("Error deleting channel:", error);
  //   }
  // };

  // Assuming you have a valid Twilio Chat client
  const getAllChannels = async (client) => {
    try {
      const channels = await client.getSubscribedChannels();
    } catch (error) {
      console.error("Error retrieving channels:", error);
    }
  };

  const handleKeyEnter = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      // 👇 Get input value
      sendMessage();
    }
  };

  useEffect(() => {
    const joinChannel = async () => {
      if (chatClient) {
        try {
          let adminId = "63c6bfc502ca5fa191659aab";
          let GENERAL_CHANNEL_UNIQUE_NAME = authData?._id + "-" + adminId;
          let GENERAL_CHANNEL_NAME = "general";
          const { status, error, channel } = await getChannelByname(
            GENERAL_CHANNEL_UNIQUE_NAME
          );
          if (status) {
            setChannel(channel);

            if (
              channel.channelState != undefined &&
              channel.channelState.status != undefined &&
              channel.channelState.status !== "joined"
            ) {
              await channel.join();
              let adminIdentity = "admin@gmail.com";
              const member = await channel.add(adminIdentity);
            }
            const newMessages = await channel.getMessages();
            setMessages(newMessages.items || []);
            // scrollToBottom();
          } else {
            const newChannel = await chatClient.createChannel({
              uniqueName: GENERAL_CHANNEL_UNIQUE_NAME,
              friendlyName: authData?.username,
              isPrivate: false,
              attributes: {
                vendorId: null,
                userId: authData._id,
                adminId: adminId,
              },
            });
            setChannel(newChannel);

            await newChannel.join();
            // join another person
            let adminIdentity = "admin@gmail.com";
            const member = await newChannel.add(adminIdentity);

            const newMessages = await newChannel.getMessages();
            setMessages(newMessages.items || []);
            // scrollToBottom();
          }
        } catch (error) {
          console.error("Error joining channel:", error);
        }
      }
    };
    joinChannel();
    if (chatClient) {
      // getAllChannels(chatClient)
    }
  }, [chatClient, authData]);

  useEffect(() => {
    if (authData && loggedIn) {
      initializeChatClient();
    }
  }, [authData, loggedIn]);
  useEffect(() => {
    if (window !== "undefined") {
      window.scrollTo(0, 0);
    }
    getCities();
    return () => {
      if (chatClient) {
        chatClient.shutdown();
      }
    };
  }, []);
  useEffect(() => {
    getCitiesData();
  }, [getCountry]);

  useEffect(() => {
    const handleNewMessage = (message) => {
      // Handle the new message as desired
      setMessages((messages) => [...messages, message]);
      messages.push(message);
      if (!chatWindowOpen) {
        setNewMessageCount((count) => count + 1);
      }
      // scrollToBottom();
    };

    if (channel) {
      channel.on("messageAdded", handleNewMessage);
    }
    if (channel) {
      // removeChannel(channel)
    }

    return () => {
      if (channel) {
        channel.off("messageAdded", handleNewMessage);
      }
    };
  }, [channel, chatClient, chatWindowOpen]);

  return (
    <Root>
      {authData && loggedIn ? (
        <ChatIconWrapper style={{}}>
          <ChatIcon
            titleAccess="chat support"
            style={{
              display: displaySupportButton,
              fontSize: "70px",
              // position: "fixed",
              bottom: "5px",
              right: "5px",
              cursor: "pointer",
              background: "#2196F3",
              borderRadius: "50%",
              color: "white",
              padding: "15px",
            }}
            onClick={() => {
              handleClickSupportButton();
              handleChatWindowOpen();
              // scrollToBottom();
            }}
          ></ChatIcon>
          <NotificationWrapper>
            <NotificationText>
              {" "}
              {newMessageCount > 0 && newMessageCount}{" "}
            </NotificationText>
          </NotificationWrapper>
          {/* {newMessageCount > 0 && (
            <div className="new-message-notification">
              You have {newMessageCount} new message(s).
            </div>
          )} */}
          <div
            style={{
              position: "fixed",
              display: isConversationBoxDisplay,
              bottom: "0px",
              right: "25px",
            }}
          >
            <CustomKeyboardArrowDownIcon
              style={{
                borderRadius: "50%",
                border: "3px solid #716363",
                marginLeft: "340px",
                fontSize: "41px",
                background: "#2196F3",
                marginBottom: "-14px",
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => {
                handleClickDownArrow();
                handleChatWindowClose();
              }}
            />
            <SupportChatStyled
              style={{
                height: supportBoxHeight,
                transition: boxTransition,
                width: "400px",
                zIndex: "99999",
                borderRadius: "10px 10px 0px 0px",
                background: "white",
              }}
            >
              <div
                style={{
                  width: "100%",
                  background: "#2196F3",
                  lineHeight: "45px",
                  height: "50px",
                  textAlign: "center",
                  borderRadius: "10px 10px 0px 0px",
                  color: "white",
                }}
              >
                Support Chat
              </div>
              <div
                id="chatMessageBox"
                ref={scrollDiv}
                style={{
                  padding: "3px 5px 0px 5px",
                  height: "330px",
                  overflow: "scroll",
                  fontSize: "13px",
                }}
              >
                {messages.map((message) => {
                  let messageDateTime = moment(message.dateCreated).format(
                    "hh:mm A"
                  );
                  if (
                    moment(message.dateCreated).format("YYYY-MM-DD") <
                    moment().format("YYYY-MM-DD")
                  ) {
                    messageDateTime = moment(message.dateCreated).format(
                      "D MMM"
                    );
                  }
                  let messageAlignment =
                    message.author === authData?.email
                      ? "message_right"
                      : "message_left";
                  let textRadius =
                    message.author === authData?.email
                      ? "right-radius"
                      : "left-radius";

                  let textBackground =
                    message.author === authData?.email
                      ? "self-text-background"
                      : "chat-background";

                  let isDeleted =
                    message.attributes != null &&
                    message.attributes.deleted !== undefined
                      ? message.attributes.deleted
                      : false;
                  return (
                    <div
                      key={message.sid}
                      className={`chat_message_section ${messageAlignment}`}
                    >
                      <span
                        className={`text-message ${textBackground} ${textRadius}`}
                      >
                        {isDeleted ? (
                          <i>This message was deleted</i>
                        ) : (
                          message.body
                        )}
                      </span>
                      <p style={{ fontSize: "9px", paddingTop: "6px" }}>
                        {messageDateTime}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div style={{ paddingLeft: "10px", marginTop: "7px" }}>
                <Grid container={true}>
                  <CustomTextField
                    id="outlined-multiline-static"
                    // label="Type your message"
                    placeholder="Type your message"
                    multiline
                    rows={1}
                    size="medium"
                    // style={{ fontSize: "4px", width: "280px" }}
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    onKeyDown={handleKeyEnter}
                  />
                  <Button
                    onClick={sendMessage}
                    style={{ marginLeft: "12px", marginTop: "2px" }}
                    variant="contained"
                    sx={{ backgroundColor: "#2196F3" }}
                    size="medium"
                    endIcon={<SendIcon />}
                  ></Button>
                </Grid>
              </div>
            </SupportChatStyled>
          </div>
        </ChatIconWrapper>
      ) : null}

      <Container>
        <Wrapper>
          <FooterLink>
            <InfoWrapper className="footerabout">
              <ImageWrapper>
                <Image src={BrandLogo} />
              </ImageWrapper>
              <Text className="aboutfooter">
                Explore the world with FloxyPay Travels, your ultimate travel
                companion! Discover exciting destinations, luxurious
                accommodations, and unbeatable travel deals that fit your
                budget. Whether planning a solo adventure, a romantic getaway,
                or a family vacation, we've got you covered.
              </Text>
              {/* <IconWrapper>
                <A href="#">
                  <i
                    className="fa-brands fa-facebook"
                    style={{ color: "#7d7d7d", fontSize: "30px" }}
                  ></i>
                </A>
                <A href="#">
                  <i
                    className="fa-brands fa-twitter"
                    style={{ color: "#7d7d7d", fontSize: "30px" }}
                  ></i>
                </A>
                <A href="#">
                  <i
                    className="fa-brands fa-instagram"
                    style={{ color: "#7d7d7d", fontSize: "30px" }}
                  ></i>
                </A>
              </IconWrapper> */}
            </InfoWrapper>
            <LinkWrapper>
              <Div align="center" width="23.33%">
                <HeadingWrapper
                  onClick={() => setShowCompanyList(!showcompanyList)}
                >
                  <Heading className="ftitle">Company</Heading>
                  {!showcompanyList ? (
                    <EventIconWrapper
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowCompanyList(!showcompanyList);
                      }}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </EventIconWrapper>
                  ) : (
                    <EventIconWrapper
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowCompanyList(!showcompanyList);
                      }}
                    >
                      <i className="fa-solid fa-minus"></i>
                    </EventIconWrapper>
                  )}
                </HeadingWrapper>
                <LinkDesktopView>
                  <Link
                    onClick={() => {
                      navigation("/aboutus");
                    }}
                  >
                    <Li>About Us</Li>
                  </Link>
                  <Link
                    onClick={() => {
                      navigation("/contactus");
                    }}
                  >
                    <Li>Contact Us</Li>
                  </Link>
                  <Link
                    onClick={() => {
                      navigation("/privacypolicy");
                    }}
                  >
                    <Li>Privacy Policy</Li>
                  </Link>
                  <Link
                    onClick={() => {
                      navigation("/termsandcondition");
                    }}
                  >
                    <Li>Terms and Conditions</Li>
                  </Link>
                </LinkDesktopView>
                {showcompanyList && (
                  <LinkMobileView>
                    <Link
                      onClick={() => {
                        navigation("/aboutus");
                      }}
                    >
                      <Li>About Us</Li>
                    </Link>
                    <Link
                      onClick={() => {
                        navigation("/contactus");
                      }}
                    >
                      <Li>Contact Us</Li>
                    </Link>
                    <Link
                      onClick={() => {
                        navigation("/privacypolicy");
                      }}
                    >
                      <Li>Privacy Policy</Li>
                    </Link>
                    <Link
                      onClick={() => {
                        navigation("/termsandcondition");
                      }}
                    >
                      <Li>Terms and Conditions</Li>
                    </Link>
                  </LinkMobileView>
                )}
              </Div>
              <Div align="center" width="23.33%">
                <HeadingWrapper>
                  <Heading
                    className="ftitle"
                    onClick={() => setShowServiceList(!showServiceList)}
                  >
                    Popular Cities
                  </Heading>
                  {!showServiceList ? (
                    <EventIconWrapper
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowServiceList(!showServiceList);
                      }}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </EventIconWrapper>
                  ) : (
                    <EventIconWrapper
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowServiceList(!showServiceList);
                      }}
                    >
                      <i className="fa-solid fa-minus"></i>
                    </EventIconWrapper>
                  )}
                </HeadingWrapper>
                {/* {data.map((item, key) => {
                  if (
                    item.cityName === "Bangalore" ||
                    item.cityName === "Jaisalmer" ||
                    item.cityName === "Udaipur" ||
                    item.cityName === "Ajmer" ||
                    item.cityName === "Mysore"
                  ) {
                    return (
                      <LinkDesktopView
                        key={key}
                        onClick={() => handlePopularCityLink(item.cityName)}
                      >
                        <Link>
                          <Li style={{ cursor: "pointer" }}>{item.cityName}</Li>
                        </Link>
                      </LinkDesktopView>
                    );
                  }
                })} */}
                {popularCity.length !== 0 &&
                  popularCity.map((item, key) => {
                    return (
                      <LinkDesktopView
                        key={key}
                        onClick={() =>
                          handlePopularCityLink(item.state, item.city)
                        }
                      >
                        <Link>
                          <Li style={{ cursor: "pointer" }}>{item.city}</Li>
                        </Link>
                      </LinkDesktopView>
                    );
                  })}
                {showServiceList && (
                  <>
                    {popularCity.length !== 0 &&
                      popularCity.map((item, key) => {
                        return (
                          <LinkMobileView
                            key={key}
                            onClick={() =>
                              handlePopularCityLink(item.state, item.city)
                            }
                          >
                            <Link>
                              <Li style={{ cursor: "pointer" }}>{item.city}</Li>
                            </Link>
                          </LinkMobileView>
                        );
                      })}
                  </>
                )}
              </Div>
              {/* <Div align="center" width="23.33%">
                <HeadingWrapper
                  onClick={() => {
                    setShowContactList(!showcontactList);
                  }}
                >
                  <Heading>Connect With Us</Heading>
                  {!showcontactList ? (
                    <EventIconWrapper
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowContactList(!showcontactList);
                      }}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </EventIconWrapper>
                  ) : (
                    <EventIconWrapper
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowContactList(!showcontactList);
                      }}
                    >
                      <i className="fa-solid fa-minus"></i>
                    </EventIconWrapper>
                  )}
                </HeadingWrapper>
                {showcontactList && (
                  <LinkMobileView>
                    <Link>
                      <span style={{ fontWeight: "bold" }}>Phone:</span>{" "}
                      9876543210
                    </Link>
                    <Link>
                      <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                      info@Bastionex.com
                    </Link>
                    <Link>
                      <span style={{ fontWeight: "bold" }}>Address:</span>{" "}
                      101-102,Sector-16
                      <br />
                      Noida-201301
                    </Link>
                    <IconWrappperMobileView>
                      <A
                        href="https://www.facebook.com/Floxypay/"
                        target="_blank"
                      >
                        <i
                          className="fa-brands fa-facebook"
                          style={{ color: "#051036" }}
                        ></i>
                      </A>
                      <A href="https://twitter.com/floxy_pay" target="_blank">
                        <i
                          className="fa-brands fa-twitter"
                          style={{ color: "#051036" }}
                        ></i>
                      </A>
                      <A href="https://t.me/floxypayexchange" target="_blank">
                        <i
                          className="fa-brands fa-telegram"
                          style={{ color: "#051036" }}
                        ></i>
                      </A>
                    </IconWrappperMobileView>
                  </LinkMobileView>
                )}
                <LinkDesktopView>
                  <Link>
                    <span style={{ fontWeight: "bold" }}>Phone:</span>{" "}
                    9876543211
                  </Link>
                  <Link>
                    <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                    bookings@bastionextravel.net
                  </Link>
                  <IconWrapper>
                    <A
                      href="https://www.facebook.com/Floxypay/"
                      target="_blank"
                    >
                      <i
                        className="fa-brands fa-facebook"
                        style={{ color: "#051036", fontSize: "30px" }}
                      ></i>
                    </A>
                    <A href="https://twitter.com/floxy_pay" target="_blank">
                      <i
                        className="fa-brands fa-twitter"
                        style={{ color: "#051036", fontSize: "30px" }}
                      ></i>
                    </A>
                    <A href="https://t.me/floxypayexchange" target="_blank">
                      <i
                        className="fa-brands fa-telegram"
                        style={{ color: "#051036", fontSize: "30px" }}
                      ></i>
                    </A>
                  </IconWrapper>
                </LinkDesktopView>
              </Div> */}
            </LinkWrapper>
          </FooterLink>
          {/* <Line></Line> */}
        </Wrapper>
      </Container>
      <BottomText className="copyright">
        {" "}
        © 2023 FloxyPay Travel. All rights reserved.{" "}
        <span
          style={{
            color: "#00a97b",
            fontWeight: "700",
            fontSize: "16px",
            color: "#383838",
          }}
        ></span>
      </BottomText>
    </Root>
  );
}

export default Footer;
