// email: Yup.string().email().required("Please enter your email"),
//     newPassword: Yup.string().required("Please enter your password").matches(
//         /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
//         "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
//       ),
//     confirmPassword: Yup.string()
//       .required()
//       .oneOf([Yup.ref("newPassword"), null], "Password must match"),

import * as Yup from "yup";

export const UpdatePasswordSchema = Yup.object({
  oldPassword: Yup.string()
    .required("Please Enter Your Password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  newPassword: Yup.string()
    .required("Please Enter New Password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmNewPassword: Yup.string()
    .required("Please Enter Confirm Password")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "New Password and Confirm new password must match"
    ),
});
