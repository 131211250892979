import React, { createContext, useContext, useState, useEffect } from "react";
import CryptoJS from "crypto-js";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const storedEncryptedAuthData = localStorage.getItem("authdata");
  const [authData, setAuthData] = useState();

  const decryptData = (encryptedData) => {
    // Your decryption logic here
    const key = CryptoJS.enc.Hex.parse("lifeisoutside!1234567890@$%^&*()");
    const iv = CryptoJS.enc.Hex.parse("blkiajnij2345tgh");
    const decryptedText = CryptoJS.AES.decrypt(encryptedData, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return JSON.parse(decryptedText.toString(CryptoJS.enc.Utf8));
  };

  useEffect(() => {
    if (storedEncryptedAuthData) {
      const decryptedData = decryptData(storedEncryptedAuthData);
      setAuthData(decryptedData);
    }
  }, [storedEncryptedAuthData]);

  const updateAuthData = (newEncryptedAuthData) => {
    const decryptedData = decryptData(newEncryptedAuthData);

    setAuthData(decryptedData);
    localStorage.setItem("authdata", newEncryptedAuthData);
  };

  const logout = () => {
    setAuthData();
    localStorage.removeItem('authdata');
  };

  return (
    <AuthContext.Provider value={{ authData, updateAuthData, logout  }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};