import React from "react";
import styled from "styled-components";
import BookingCard from "./BookingCard";
import BookingHeroSection from "./BookingHeroSection";
import HotelAccordian from "./HotelAccordian";

const Root = styled.div`
  margin-bottom: 1rem;
  padding-top: 80px;
  @media (max-width: 768px) {
    padding-top: 50px;
  }
`;
const Wrapper = styled.div``;
const NavBarBackground = styled.div`
  width: 100vw;
  height: 77px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;
export const HotelBooking = () => {
  return (
    <Root>
      <NavBarBackground></NavBarBackground>
      <Wrapper>
        <BookingHeroSection />
        <HotelAccordian />
      </Wrapper>
      {/* <BookingCard /> */}
    </Root>
  );
};
