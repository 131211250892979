import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import LoginPopup from "../Pages/Login/LoginPopup";
import MenuIcon from "@mui/icons-material/Menu";
import loginicon from "../../images/loginicon.png";
// import BrandLogo from "../../images/bastionextravel_logo1.png";
import BrandLogo from "../../images/BrandLogo.png";
import BrandLogo2 from "../../images/LogoDark.png";
import axios from "axios";
import { AuthContext, useAuth } from "../../ContextApi/Contextapi";
import SearchBar from "./SearchBar";
import { CloseIcon } from "../styledcomponents/popupStyledcomponents";
import SelectCityPopUp from "../SelectCityPopUp/SelectCityPopUp";
import { environmentVariables } from "../../config/config";

const Root = styled.div`
  /* width: 1200px;
margin: 0 auto;  */

  position: fixed;
  z-index: 999999;
  @media (max-width: 1024px) {
    // background-color: rgb(5, 16, 54);
    width: 100vw;
  }
`;

const Main = styled.div`
  /* padding-bottom: 0px;
position: -webkit-sticky;
position:sticky;
top:0;
left:0;
z-index:100; */
`;

const MobileViewAppBar = styled.div`
  background-color: #051036;
  width: 100vw;
  /* border-radius:0px 50px 50px 0px; */
  padding: 10px 20px;
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 80px;
  justify-content: space-between;
  ${(p) =>
    p.scrollAppear &&
    `
   background-color:#f1f7f5;
`};
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  @media (min-width: 769px) {
    display: none;
  }
`;
const Logo = styled.img`
  width: 100px;
`;

const SmallDeviceLinkView = styled.div`
  background-color: #f1f7f5;
  position: fixed;
  width: 300px;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 99;
  padding-top: 20px;
  padding-left: 20px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  @media (max-width: 767px) {
  }
`;
const Link = styled.div`
  margin-top: 20px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  cursor: pointer;
  display: flex;
  @media (max-width: 768px) {
    margin-top: 30px;
    margin-right: 10px;
    font-size: 20px;
  }
`;

const UserNameText = styled.div`
  // color: #3452cc;
  color: red;
  font-size: 16px;
  font-weight: 500;
`;
const Navigation = styled.div`
  background-color: ${(props) =>
    props.hasScrolled ? "#051036" : "rgba(247, 247, 247, 0%)"};
  /* border-radius:0px 50px 50px 0px; */
  height: 84.4px;
  width: 100vw;
  margin-right: 25px;
  /* justify-content: flex-start; */
  /* margin-right:80px; */
  display: flex;
  align-items: center;
  padding: 10px 30px 10px 10px;
  justify-content: space-between;
  ${(p) =>
    p.scrollAppear &&
    `
   background-color:#f1f7f5;
`};
  /* box-shadow: 0px 0px 13px 2px rgb(0 0 0 / 5%); */
  /* 0px 1px 2px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); */
  @media (max-width: 768px) {
    display: none !important;
  }
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const NavLink = styled.div`
  margin-left: 25px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  position: relative;
  display: flex;
  cursor: pointer;
  @media (max-width: 900px) {
    margin-left: 15px;
  }
  @media (max-width: 845px) {
    margin-left: 10px;
    font-size: 14px;
  }
`;
const Image = styled.img``;

const DropDown = styled.div`
  // color:${(props) => (props.isGiftCardRoute ? "pink" : "yellow")};
  // style={{ color:IsOnGiftCardRoute ? "#051036" : "#fff"}}
  @media (max-width: 768px) {
    color: #000;
  }
`;
const DropDownSection = styled.div`
  position: absolute;
  top: 30px;
  left: -20px;
  z-index: 10;
  width: 250px;
  background-color: #fff;
  box-shadow: 0px 0px 13px 2px rgb(0 0 0 / 5%);
  /* padding-top:20px; */
  border-radius: 4px;
  ${(p) =>
    p.ProfileOption &&
    `
width:210px;
top: 45px;
right: 0px;
left: 20%;
background-color:#fff;
`}
  @media(max-width:767px) {
    position: relative;
    left: 0px;
    background: none;
    box-shadow: none;
    top: 0px;
    width: 100%;
    cursor: pointer;
  }
`;
const IconWrapper = styled.span`
  margin-left: 5px;
  color: #fff;
`;

const List = styled.div`
  font-size: 14px;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;

  :hover {
    box-shadow: 0px 0px 13px 2px rgb(0 0 0 / 5%);
    @media (max-width: 767px) {
      box-shadow: none;
    }
  }
`;
const Button = styled.button`
  height: 3rem;
  width: 8rem;
  align-items: center;
  color: ${(props) => (props.isGiftCardRoute ? "white" : "#fff")};
  border-radius: ${(props) => (props.isGiftCardRoute ? "40px" : "7px")};
  font-weight: 700;
  font-size: 14px;
  background-color: ${(props) =>
    props.isGiftCardRoute ? "#051036" : "transparent"};
  border: ${(props) =>
    props.isGiftCardRoute ? "1px solid #051036" : "1px solid #ffffff"};
  transition: 0.3s ease-in-out;
  cursor: pointer;
  :hover {
    background-color: #ffffff;
    color: #000000;
  }
  @media (max-width: 768px) {
    height: 35px;
    width: 35px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const Wrapper = styled.div`
  // position: relative;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin-left: 20px;
`;

const IconWrapperMb = styled.div`
  display: flex;
  align-items: center;
`;
function NavSection({
  showDistanceSlider,
  setShowDistanceSlider,
  chooseMessage,
  chooseMessage1,
  scrollAfterSelection,
  setScrollAfterSelection,
  showDropDown,
  setShowDropDown,
  profileOptionDrop,
  setProfileOptionDrop,
  message,
  message2,
  showSearchData,
  setShowSearchData,
  setData,
  data,
  setCategory,
  category,
  setDefaultDataStatus,
  loggedIn,
  setLoggedIn,
  originalJSONText,
  originalJSONTextForUser,
  userData,
  setUserEmail,
  userState,
  setUserState,
}) {
  const baseUrl = environmentVariables.apiUrl;
  const getCity = localStorage.getItem("city");
  const getState = localStorage.getItem("cityname");
  const [showNavigation, setShowNavigation] = useState(false);
  const headerRef = useRef();
  const [scrollAppear, setScrollAppear] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [state, setState] = useState("Delhi");
  const [cityName, setCityName] = useState(null);
  const navigation = useNavigate();
  const { authData } = useAuth();
  // const [showDropDown, setShowDropDown]= useState(false);
  const [searchPopup, setSearchPopup] = useState(false);
  // const [profileOptionDrop, setProfileOptionDrop] = useState(false);
  // const [location , setLocation] = useState(getCity)
  const [stateData, setStateData] = useState([]);
  const [stateName, setStateName] = useState([]);
  const [hasScrolled, setHasScrolled] = useState(false);
  const { logout } = useAuth();
  const location = useLocation();
  const IsOnGiftCardRoute =
    location.pathname.includes("/gifts") ||
    location.pathname.includes("/topups");

  const callApi = async (x, y) => {
    navigation("/hotel", { state: { stateName: x, cityName: y } });
  };

  const getCityData = (slug, city, country, countryISOCode) => {
    localStorage.setItem("city", slug);
    localStorage.setItem("cityname", city);
    localStorage.setItem("countryname", country);
    localStorage.setItem("countryISOCode", countryISOCode);
    setState(slug);
    setCityName(city);
    // setShowDropDown(!dropDown);
    callApi(slug || getCity, city);
    chooseMessage(slug);
    chooseMessage1(city);
  };

  const setShow = () => {
    setProfileOptionDrop(!profileOptionDrop);
  };
  const isAuth = () => {
    if (loggedIn) {
      return true;
    } else {
      return false;
    }
  };
  const HandleLogout = () => {
    // setProfileOptionDrop(!profileOptionDrop);
    // setLoggedIn(false);
    // // setAuthData(null);
    // localStorage.removeItem("authdata");
    // localStorage.removeItem("customerdetail");
    // localStorage.removeItem("city");
    // localStorage.removeItem("hotelDetail");
    // localStorage.removeItem("formData");
    // localStorage.removeItem("totalprice");
    // localStorage.removeItem("cityname");
    // localStorage.removeItem("countryname");
    // localStorage.removeItem("countryISOCode");
    // localStorage.removeItem("theme");

    // navigation("/");

    let config = {
      method: "get",
      url: `${baseUrl}auth/userlogout`,
      headers: {
        _token: authData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          // const pusher = new Pusher("cbb68a6fad0862e7fd60", {
          //   cluster: "ap2",
          //   encrypted: true,
          // });

          // const channel = pusher.subscribe("logout-channel");
          // channel.trigger("client-user-logout-event","logout-channel", {
          //   message: "User logged out",
          // });

          // pusher.trigger("user_logout", "create", {
          //   message: req.userData,
          // });

          setUserState(response.data.data);
          setLoggedIn(false);

          setProfileOptionDrop(!profileOptionDrop);
          logout();
          // setAuthData(null);
          // setUserEmail(response.data)

          // localStorage.removeItem("authdata");
          localStorage.removeItem("userdata");
          localStorage.removeItem("customerdetail");
          // localStorage.removeItem("city");
          localStorage.removeItem("hotelDetail");
          localStorage.removeItem("formData");
          localStorage.removeItem("totalprice");
          // localStorage.removeItem("cityname");
          localStorage.removeItem("countryname");
          // localStorage.removeItem("countryISOCode");
          localStorage.removeItem("theme");

          navigation("/hotel");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStateName = () => {
    let config = {
      method: "get",
      url: `${baseUrl}auth/getstatedata`,
    };
    axios
      .request(config)
      .then((response) => {
        setStateData(response.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    getStateName();
  }, [stateName]);

  const setHidden = () => {
    showDropDown === true
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "scroll");
  };
  useEffect(() => {
    setHidden();
  }, [showDropDown]);

  return (
    <Root>
      <SelectCityPopUp
        scrollAfterSelection={scrollAfterSelection}
        setScrollAfterSelection={setScrollAfterSelection}
        getCityData={getCityData}
        stateData={stateData}
        cityName={cityName}
        state={state}
        show={showDropDown}
        setShow={setShowDropDown}
      />
      {/* <Container> */}

      <Main ref={headerRef}>
        {/* <TextSearchBox>
            <Input
              type="text"
              placeholder="Where do you want to go ?"
              value=""
              onClick={() => {
                setSearchPopup(!searchPopup);
              }}
            />
            <SearchIcon>
              <GoSearch
                onClick={() => {
                  setSearchPopup(!searchPopup);
                }}
              />
            </SearchIcon>
          </TextSearchBox> */}
        {showPopup && (
          <LoginPopup
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            setLoggedIn={setLoggedIn}
          />
        )}
        <Navigation
          hasScrolled={hasScrolled}
          scrollAppear={scrollAppear}
          style={{ backgroundColor: IsOnGiftCardRoute ? "white" : "#051036" }}
        >
          <Image
            src={
              location.pathname.includes("/gifts") ||
              location.pathname.includes("/topups")
                ? BrandLogo2
                : BrandLogo
            }
            style={{ cursor: "pointer", marginLeft: "20px", width: "150px" }}
            onClick={() => {
              // localStorage.removeItem("city");
              // localStorage.removeItem("cityname");
              // localStorage.removeItem("countryISOCode");
              // localStorage.removeItem("countryname");
              navigation("/hotel");
            }}
          />
          <SearchBar
            searchPopup={searchPopup}
            setSearchPopup={setSearchPopup}
            setShow={setShowSearchData}
            show={showSearchData}
          />
          <NavWrapper
            style={{
              marginRight: "16px",
              marginTop: "0",
              position: "relative",
            }}
          >
            <NavLink>
              <div
                onClick={(e) => {
                  setData("");
                  setCategory("");
                  e.stopPropagation();
                  setShowDropDown(!showDropDown);
                  profileOptionDrop && setProfileOptionDrop(!profileOptionDrop);
                  setDefaultDataStatus(true);
                  setShowDistanceSlider(false);
                }}
                style={{ display: "flex" }}
              >
                <DropDown
                  style={{ color: IsOnGiftCardRoute ? "#051036" : "#fff" }}
                >
                  {` I am based in 
                  ${
                    localStorage.getItem("city") === null ? " " : `${getState}`
                  }`}
                </DropDown>
                <IconWrapper>
                  <i className="fa-solid fa-angle-down"></i>
                </IconWrapper>
              </div>
              {/* {showDropDown && (
                <>
                </>
              )}{" "} */}
            </NavLink>
            {/* {JSON.parse(localStorage.getItem("authdata"))!==null?"yes":"no"} */}
            {loggedIn ? (
              <Wrapper
                onClick={(e) => {
                  e.stopPropagation();
                  setShow(profileOptionDrop);
                  showDropDown && setShowDropDown(!showDropDown);
                }}
              >
                <ImageWrapper
                // onClick={() => setShow(profileOptionDrop)}
                >
                  <DropDown
                    style={{ color: IsOnGiftCardRoute ? "#051036" : "#fff" }}
                  >
                    {userData?.username || authData?.username}
                  </DropDown>
                  <IconWrapper>
                    <i className="fa-solid fa-angle-down"></i>
                  </IconWrapper>
                </ImageWrapper>

                {profileOptionDrop && (
                  <DropDownSection ProfileOption>
                    <List
                      onClick={() => {
                        setProfileOptionDrop(!profileOptionDrop);
                        navigation("/bookinghistory");
                      }}
                    >
                      My Bookings
                    </List>
                    <List
                      onClick={() => {
                        setProfileOptionDrop(!profileOptionDrop);
                        navigation("/shortlistedplace");
                      }}
                    >
                      Shortlisted Places
                    </List>
                    <List
                      onClick={() => {
                        setProfileOptionDrop(!profileOptionDrop);
                        navigation("/gift-topup-history");
                      }}
                    >
                      Gift card & Topup
                    </List>
                    <List
                      onClick={() => {
                        setProfileOptionDrop(!profileOptionDrop);
                        navigation("/profilesetting");
                      }}
                    >
                      User Profile
                    </List>
                    <List
                      onClick={() => {
                        setProfileOptionDrop(!profileOptionDrop);
                        navigation("/updatepassword");
                      }}
                    >
                      Update Password
                    </List>
                    <List
                      onClick={HandleLogout}
                      //   onClick={() => {
                      //   setProfileOptionDrop(!profileOptionDrop);
                      // }}
                    >
                      Logout
                    </List>
                  </DropDownSection>
                )}
              </Wrapper>
            ) : (
              <>
                <NavLink onClick={() => "/loginpage"}>
                  <Button
                    isGiftCardRoute={IsOnGiftCardRoute}
                    onClick={() => {
                      setShowPopup(!showPopup);
                    }}
                  >
                    Login
                  </Button>
                </NavLink>
              </>
            )}
          </NavWrapper>
        </Navigation>
        <MobileViewAppBar>
          <Logo
            src={BrandLogo}
            onClick={() => {
              navigation("/");
              localStorage.removeItem("city");
              localStorage.removeItem("cityname");
            }}
          />
          <IconWrapperMb>
            {" "}
            {/* <SearchIconMb onClick={() => setSearchPopup(!searchPopup)}>
              <IoIosSearch style={{ color: "#fff" }} />
            </SearchIconMb> */}
            <Link
              style={{ marginTop: "0", fontSize: "0.875rem" }}
              onClick={() => {
                setShowDropDown(!showDropDown);
              }}
            >
              <DropDown
                style={{ color: "#fff" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropDown(!showDropDown);
                  setShowDistanceSlider(false);
                }}
              >
                {`
                  ${
                    localStorage.getItem("city") === null
                      ? " I am based in  "
                      : `${getState}`
                  }`}
              </DropDown>
              <IconWrapper
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropDown(!showDropDown);
                }}
              >
                <i className="fa-solid fa-angle-down"></i>
              </IconWrapper>
            </Link>
            {isAuth() && (
              <MenuIcon
                style={{ color: "#fff" }}
                onClick={() => {
                  setShowNavigation(!showNavigation);
                }}
              />
            )}
            {!isAuth() && (
              <Button
                onClick={() => {
                  setShowPopup(!showPopup);
                }}
              >
                <Image src={loginicon} alt="img" />
              </Button>
            )}
          </IconWrapperMb>
        </MobileViewAppBar>
        {showNavigation && isAuth() && (
          <SmallDeviceLinkView>
            <CloseIcon
              onClick={() => {
                setShowNavigation(!showNavigation);
              }}
            >
              <i className="fa fa-window-close" aria-hidden="true"></i>
            </CloseIcon>
            {isAuth() && (
              <UserNameText>{`Hello, ${authData?.username}`}</UserNameText>
            )}

            {/* <Link
              onClick={() => {
                setShowDropDown(!showDropDown);
              }}
            >
              <DropDown
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropDown(!showDropDown);
                }}
              >
                I am based in
              </DropDown>
              <IconWrapper
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropDown(!showDropDown);
                }}
              >
                <i className="fa-solid fa-angle-down"></i>
              </IconWrapper>
            </Link> */}
            {/* {showDropDown && (
              <>
                <DropDownSection>
                  {cities.map((item, index) => (
                    <List
                      key={index}
                      onClick={() => {
                        setShowNavigation(!showNavigation);
                        getCityData(item.slug, showDropDown);
                      }}
                    >
                      {item.name}
                    </List>
                  ))}
                  <List
                    onClick={() => {
                      navigation("/blockchain_development");
                      setShowNavigation(!showNavigation);
                    }}
                  >
                    Delhi
                  </List>
                  <List
                    onClick={() => {
                      navigation("/game_development");
                      setShowNavigation(!showNavigation);
                    }}
                  >
                    Maharashtra
                  </List>
                  <List
                    onClick={() => {
                      navigation("/mobile_app_development");
                      setShowNavigation(!showNavigation);
                    }}
                  >
                    Rajashtan
                  </List>
                </DropDownSection>
              </>
            )} */}
            {isAuth() && (
              <>
                <Link
                  onClick={() => {
                    navigation("/profilesetting");
                    setShowNavigation(!showNavigation);
                  }}
                >
                  User Profile
                </Link>
                <Link
                  onClick={() => {
                    navigation("/shortlistedplace");
                    setShowNavigation(!showNavigation);
                  }}
                >
                  Shortlisted
                </Link>
                <Link
                  onClick={() => {
                    navigation("/bookinghistory");
                    setShowNavigation(!showNavigation);
                  }}
                >
                  My Bookings
                </Link>
                <Link
                  onClick={() => {
                    setShowNavigation(!showNavigation);
                    navigation("/gift-topup-history");
                  }}
                >
                  Gift card & Topup
                </Link>
                <Link
                  onClick={() => {
                    navigation("/updatepassword");
                    setShowNavigation(!showNavigation);
                  }}
                >
                  Update Password
                </Link>
              </>
            )}
            {isAuth() ? (
              <Link
                onClick={() => {
                  HandleLogout();
                  setShowNavigation(!showNavigation);
                }}
              >
                Logout
              </Link>
            ) : (
              <Link
                onClick={() => {
                  navigation("/contact_us");
                  setShowNavigation(!showNavigation);
                  setShowPopup(!showPopup);
                }}
              >
                Login
              </Link>
            )}
          </SmallDeviceLinkView>
        )}
      </Main>
      {/* </Container> */}
    </Root>
  );
}

export default NavSection;
