import React from "react";

import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";
import "./MoreHotel.css";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import ContentLoader from "react-content-loader";
import Card from "react-bootstrap/Card";
import Slider from "react-slick";
import Loader from "../Shortlistedloader/Loader";

const HeartWrapper = styled.div`
  width: 40px;
  border-radius: 3px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  /* left: 88%; */
  right: 15px;
  top: 15px;
  /* right: 1px; */
  background-color: #fff;
  cursor: pointer;
  :hover {
    color: red !important;
  }
`;
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const MoreHotels = ({
  sliderSettings,
  similerData,
  isSimilerLoading,
  onClickLike,
  userData,
  isHeartLoading,
}) => {
  const navigation = useNavigate();
  return (
    <>
      {isSimilerLoading ? (
        <>
          <LoaderContainer>
            <ContentLoader
              width={2500}
              height={575}
              viewBox="0 0 1100 575"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="58" rx="2" ry="2" width="250" height="300" />
              <rect x="280" y="57" rx="2" ry="3" width="250" height="300" />
              <rect x="560" y="56" rx="2" ry="2" width="250" height="300" />
              <rect x="840" y="56" rx="2" ry="2" width="250" height="300" />
              <rect x="0" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="280" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="560" y="615" rx="2" ry="2" width="250" height="300" />
              <rect x="840" y="615" rx="2" ry="2" width="250" height="300" />
            </ContentLoader>
          </LoaderContainer>
        </>
      ) : similerData.length ? (
        <>
          <Box
            component="h4"
            sx={{ margin: "20px 0px", paddingLeft: "20px", marginTop: "30px" }}
            className="text-left"
          >
            Recommendations, based on your previous bookings.
          </Box>
          <Slider {...sliderSettings}
            responsive={[
              {
                breakpoint: 1440, 
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1, 
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 768, 
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 550, 
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 425, 
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {similerData.map((item, key) => {
              return (
                <Card id="hotel-cards" key={key}>
                  <Card.Img
                    style={{
                      maxHeight: "217px",
                      cursor: "pointer",
                      minHeight: "160px",
                    }}
                    variant="top"
                    src={
                      item.image.length && item?.image[0] != "medianew"
                        ? `https://bastionex-travels.b-cdn.net/uploads/${item.image[0]}`
                        : "https://dummyimage.com/300x200/000/fff"
                    }
                    className="img-fluid mx-auto d-block"
                    onClick={() => navigation(`/hoteldetails/${item?._id}`)}
                  />
                  <Tooltip title="Shortlist" styled={{ fontSize: "50px" }}>
                    <HeartWrapper onClick={() => onClickLike(item, key)}>
                      {isHeartLoading[`${item._id}-${key}`] ? (
                        <Loader />
                      ) : (
                        <i
                          style={{
                            fontSize: "20px",
                            color:
                              userData && userData.includes(item._id)
                                ? "red"
                                : "#747474",
                          }}
                          className="fa fa-thin fa-heart style"
                        ></i>
                      )}
                    </HeartWrapper>
                  </Tooltip>
                  <Card.Body
                    style={{
                      maxHeight: "100px",
                      marginBottom: "20px",
                      minHeight: "100px",
                      display:"flex",
                      flexDirection: "column",
                      alignItems: "start",
                      textAlign: "flex-start",
                    }}
                  >
                    <Card.Title style={{ fontWeight: "500", fontSize: "16px", display:"flex", alignItems:"start", textAlign:"start" }}>
                      {item.hotelname}
                    </Card.Title>
                    <Card.Title style={{ fontWeight: "300", fontSize: "14px" }}>
                      {item.city} , {item.state}
                    </Card.Title>
                  </Card.Body>
                </Card>
              );
            })}
          </Slider>
        </>
      ) : null}
    </>
  );
};

export default MoreHotels;
