import * as Yup from "yup";

export const generateTopupSchemaRange = (minValue, maxValue) => {
  return Yup.object({
    amount: Yup.number()
      .positive("Please enter a positive number")
      .required("Please enter Card Amount")
      .min(minValue, `Amount Must be More Than ${minValue}`)
      .max(maxValue, `Amount Must be Less Than ${maxValue}`),
    selectedOptions: Yup.string().required("Please Select Country Code"),
    number: Yup.string().required("Please Enter Mobile No."),
  });
};
