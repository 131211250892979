import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { environmentVariables } from "../../config/config";
import moment from "moment";
import "../../index.css";
import Swal from "sweetalert2";
import { experimentalStyled as newstyled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import DownloadIcon from "@mui/icons-material/Download";
import { Button as MIButton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { LoadingButton } from "@mui/lab";
import MoreHotels from "./MoreHotels";
import "./Login/BookingDetails.css";
import { useAuth } from "../../ContextApi/Contextapi";
import CircularLoader from "../CircularLoader/CircularLoader";

const Item = newstyled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: "40px",
  textAlign: "left",
  boxShadow: "0px 0px 10px 8px #0000000a",
  color: theme.palette.text.secondary,
  border: "1px solid lightgray",
}));

const BackButtonWrapper = styled.div`
  position: absolute;
  top: 100px;
  left: 45px;
  cursor: pointer;
`;
const Root = styled.div`
  background-color: #e9e9e9;
  // padding: 30px 10px;
  // margin: 40px 10px;
  // padding-top: 85px;

  padding: 30px 40px;
  margin: 40px 0px;
  padding-top: 85px;
  margin-top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 100px 10px;
  }
`;
const Container = styled.div`
  width: 1370px;
  margin: 0 auto;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1440px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    // width: 100vw;
    margin-top: 20px;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const HotelNameText = styled.div`
  // font-size: 18px;
  font-size: 15px;
  color: #000;
  font-weight: initial;
  padding: 5px 0;
  font-family: arial;

  ${(p) =>
    p.HotelNameText &&
    `
      font-size:36px;
      font-weight:400;
  
  `}
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const AddressText = styled.div`
  display: flex;
  // font-size: 16px;
  font-size: 15px;
  font-weight: 400;
  line-height: 11px;
  color: #9b9b9b;
  ${(p) =>
    p.Area &&
    `

    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
    color:#666666;
`}
  ${(p) =>
    p.MealText &&
    `
padding-left:10px;
margin-left: 0px;
`}
  ${(p) =>
    p.Information &&
    `
padding-left:10px;
margin-left: 0px;
`}
${(p) =>
    p.SecondAccordian &&
    `
font-size: 16px;
font-weight: 500;
padding: 20px 15px;
margin-left: 0px;
`}
`;

const SecondSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px dotted #ccc;
  border-top: 1px dotted #ccc;
  background-color: #fff;
  padding: 10px 0px 0px;
  @media (max-width: 768px) {
    padding: 10px 0px 0px;
  }
  margin-top: 20px;
`;
const CustomerSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px dotted #ccc;
  // border-top: 1px dotted #ccc;
  background-color: #fff;
  // padding: 15px 15px;
`;

const CheckInOutWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  // padding: 0 10px;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: auto auto auto;
  }
`;

const CheckInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0;
`;

const DetailsWrapper = styled.div``;

const NavBarBackground = styled.div`
  width: 100vw;
  height: 77.8px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;

const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
`;

const LatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CancellationPolicyLink = styled.div`
  cursor: pointer;
  margin-top: 10px;
`;

const SimilarHotelWrapper = styled.div`
  margin-top: 50px;
`;
const DisplayLoader = styled.div`
  display: flex;
  justify-content: center;
  height: 90vh;
  align-items: center;
`;

const LoadingButtonCustom = styled(LoadingButton)`
  padding: 16px 0 !important;
`;

const BookingHotelDetails = () => {
  const baseUrl = environmentVariables.apiUrl;
  const { id } = useParams();
  const { state } = useLocation();
  const navigation = useNavigate();
  const [data, setData] = useState("");
  const [arr, setArr] = useState([]);
  const [cancel, setCancel] = useState();
  const [diff, setDiff] = useState();
  const { authData } = useAuth();
  const [showPopup, setShowPopup] = useState(false);
  const [userData, setUserData] = useState([]);

  const [isDownloading, setIsDownloading] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  // const { authData, setAuthData } = useContext(AuthContext);
  const [activities, setActivities] = useState([]);
  const [totalActivityAmount, setTotalActivityAmount] = useState(0);
  const [similerData, setSimilerData] = useState([]);
  const [isSimilerLoading, setIsSmililerLoading] = useState(false);
  const [isHeartLoading, setIsHeartLoading] = useState({});

  // loop:(1 > 4 )
  const sliderSettings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // initialSlide: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  // useEffect(() => {
  //   setData(state);
  //   for (let i of state.image) {
  //     setArr((oldItems) => [
  //       ...oldItems,
  //       { image: `https://bastionex-travels.b-cdn.net/uploads/${i}` },
  //     ]);
  //   }
  // }, []);

  const CancelBooking = () => {
    // setIsCancelled(true);
    let bookingId = id;
    axios({
      method: "post",
      url: `${baseUrl}auth/cancelorder?orderid=${bookingId}`,
      headers: { _token: authData?.token },
    })
      .then((response) => {
        Swal.fire("success", response?.data?.message, "success");
        getBookingDetails();
        // socket.emit("user_cancellation", response.data);
        // setCancel(response.data);
        // setIsCancelled(false);
        // navigation("/bookinghistory");
      })
      .catch((error) => {
        // setIsCancelled(false);
        Swal.fire({
          icon: "error",
          title: "Something went wrong!",
          text: error?.response?.data?.message || error?.message,
        });
      });
  };
  // const getActivitiesAndDetail = async (bookingId) => {
  //   axios({
  //     method: "get",
  //     url: `${baseUrl}auth/getActivitiesAndPaymentDetail/${bookingId}`,
  //     headers: { _token: authData?.token },
  //   })
  //     .then((response) => {
  //       setActivities(response.data.data.activitiesData);
  //       setTotalActivityAmount(Number(response.data.data.totalActivityAmount));
  //     })
  //     .catch((error) => {});
  // };
  // useEffect(() => {
  //   const checkInTime = moment(state.checkIn, "DD/MM/YYYY").valueOf();
  //   const checkoutTime = moment(state.checkOut, "DD/MM/YYYY").valueOf();
  //   // const diff =;
  //   setDiff((checkoutTime - checkInTime) / (1000 * 3600 * 24));
  //   if (state && state._id) {
  //     getActivitiesAndDetail(state._id);
  //   }
  // }, [authData]);

  // const Rediect = (item) => {
  //   window.location.href = `${item.paymentDetail.url}`;
  // };

  const handleDownload = async () => {
    // await axios({
    //   url: `${baseUrl}auth/downloadInvoice/${bookingId}`,
    //   method: "get",
    //   headers: { _token: authData?.token },
    //   responseType: "blob",
    // })
    //   .then((response) => {
    //     // Create a download link for the generated PDF
    //     const downloadLink = document.createElement("a");
    //     downloadLink.href = URL.createObjectURL(response.data);
    //     downloadLink.download = `Invoice_${state._id}.pdf`;
    //     downloadLink.click();
    //     setIsDownloading(false);
    //     Swal.fire("Invoice", `invoice downloaded successfully.`, "success");
    //   })
    //   .catch((error) => {
    //     setIsDownloading(false);
    //     Swal.fire({
    //       icon: "error",
    //       title: "Something went wrong!",
    //       text: `Somthing went wrong!`,
    //     });
    //   });
    try {
      let bookingId = id;
      setIsDownloading(true);

      // const response = await axios({
      //   url: `${baseUrl}auth/downloadInvoice/${bookingId}`,
      //   method: "get",
      //   headers: { _token: authData?.token },
      //   responseType: "text/html",
      // });

      // const tempElement = document.createElement("div");
      // tempElement.innerHTML = response.data.data;

      // const iframe = document.createElement("iframe");
      // iframe.style.display = "none";
      // document.body.appendChild(iframe);

      // iframe.contentDocument.open();
      // iframe.contentDocument.write(response.data.data);
      // iframe.contentDocument.close();

      // // Use html2pdf to convert the iframe content to a PDF
      // html2pdf(iframe.contentDocument.body, {
      //   margin: 10,
      //   filename: "generated_document.pdf",
      //   image: { type: "jpeg", quality: 0.98 },
      //   html2canvas: { scale: 2 },
      //   jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      // }).then(() => {
      //   // Remove the iframe from the DOM
      //   document.body.removeChild(iframe);
      //   setIsDownloading(false);
      // });

      // html2pdf(tempElement, {
      //   margin: 10,
      //   filename: `Invoice_${state._id}.pdf`,
      //   image: { type: "jpeg", quality: 0.98 },
      //   html2canvas: { scale: 2 },
      //   jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      // }).then(() => {
      //   setIsDownloading(false);
      //   Swal.fire("Invoice", `Invoice downloaded as PDF.`, "success");
      // }).catch((error) => {
      //   });

      // const response = await axios.get(`${baseUrl}auth/downloadInvoice/${bookingId}`, {
      //     responseType: 'blob',
      //     headers: {
      //         _token: authData?.token
      //     }
      //   });
      //   const blob = new Blob([response.data.data], { type: 'application/pdf' });

      //   // Create a link element to trigger the download
      //   const downloadLink = document.createElement('a');
      //   downloadLink.href = window.URL.createObjectURL(blob);
      //   downloadLink.download = 'invoice.pdf'; // Set the file name
      //   downloadLink.click();

      console.log("bookingId========", bookingId);
      const response = await axios.get(
        `${baseUrl}auth/downloadorderinvoice?orderid=${bookingId}`,
        {
          responseType: "blob", // Important
          headers: {
            _token: authData?.token,
          },
        }
      );
      console.log("response", response?.data);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Invoice_${bookingId}.pdf`;
      a.click();
      setIsDownloading(false);
    } catch (error) {
      console.log(error?.response);
      Swal.fire({
        icon: "error",
        text: `Somthing went wrong!`,
      });
      setIsDownloading(false);
    }
  };
  const getSimilerCollection = async () => {
    setIsSmililerLoading(true);
    let themeData = responseData?.hotel_activity_data?.hotelTheme?.join(",");
    // if (state.themeRange !== undefined && state.themeRange !== "") {
    //   themeData = state.themeRange;
    // } else if (state.hotelTheme !== undefined && state.hotelTheme.length) {
    //   themeData = state.hotelTheme.join(",");
    // }
    await axios({
      method: "get",
      url: `${baseUrl}auth/getsimilar`,
      params: {
        theme: themeData,
        city: responseData?.hotel_activity_data?.city,
      },
    })
      .then((response) => {
        setIsSmililerLoading(false);
        setSimilerData(response.data.data);
      })
      .catch((error) => {
        setIsSmililerLoading(false);
      });
  };
  useEffect(() => {
    getSimilerCollection();
  }, [state]);

  const getUser = async (key) => {
    if (authData !== undefined) {
      try {
        const data = await axios.get(`${baseUrl}auth/getuserdata`, {
          headers: {
            _token: authData?.token,
          },
        });
        setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
        setUserData(data.data.data.shortlistedArray);
      } catch (error) {
        setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
        return error?.message;
      }
    } else {
      setShowPopup(true);
    }
  };
  const onClickLike = async (item, key) => {
    const compoundKey = `${item._id}-${key}`;
    if (authData === undefined) {
      setShowPopup(true);
    } else {
      setIsHeartLoading((prevState) => ({ ...prevState, [compoundKey]: true }));
      await axios({
        url: `${baseUrl}auth/getshortlisted/${item._id}`,
        method: "post",
        headers: {
          _token: authData?.token,
        },
      })
        .then((response) => {
          getUser(compoundKey);
        })
        .catch((error) => {
          // console.log("error", error);
        });
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  const [responseData, setResponseData] = useState();
  const [responseLoader, setResLoader] = useState(false);

  const getBookingDetails = async () => {
    setResLoader(true);
    axios({
      method: "get",
      url: `${baseUrl}auth/getorderdetail?orderid=${id}`,
      headers: { _token: authData?.token },
    })
      .then((response) => {
        setResponseData(response?.data?.data);
        setResLoader(false);
        // setTotalActivityAmount(Number(response.data.data.totalActivityAmount));
      })
      .catch((error) => {
        setResponseData();
        setResLoader(false);
      });
  };

  useEffect(() => {
    getBookingDetails();
  }, [id, authData]);
  const totalActivitiesAmount = responseData?.activities?.reduce(
    (acc, activity) => acc + activity.activityAmount,
    0
  );
  console.log(
    "oiiiiiiiiiiii--------------",
    responseData,
    totalActivitiesAmount
  );

  return (
    <Root>
      {responseLoader ? (
        <DisplayLoader style={{ height: "90vh" }}>
          <CircularLoader />
        </DisplayLoader>
      ) : (
        <>
          {" "}
          <NavBarBackground></NavBarBackground>
          <BackButtonWrapper className="backbtn" onClick={() => navigation(-1)}>
            <i className="fa-solid fa-arrow-left"></i>
          </BackButtonWrapper>
          <Container className="mob-container">
            <SectionWrapper>
              <Box sx={{ flexGrow: 1, marginTop: "45px" }}>
                <Grid
                  container
                  className="hotelSection"
                  spacing={{ xs: 1, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={10} sm={8} md={8}>
                    <Item>
                      <Grid
                        container
                        sx={{ height: "180px" }}
                        spacing={{ xs: 1, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        <Grid item xs={12} sm={10} md={10} order={{ xs: 2 }}>
                          <Grid component="div" className="hotelname_wrapper">
                            <Box component="h4" sx={{ display: "inline-flex" }}>
                              {responseData?.hotel_activity_data?.hotelname}
                            </Box>
                            {responseData?.status === "success" ? (
                              <Chip
                                className="btnenqposition"
                                color="success"
                                sx={{ marginLeft: "10px", padding: "15px 8px" }}
                                label="Confirmed"
                                size="small"
                              />
                            ) : responseData?.status === "cancel" ? (
                              <Chip
                                className="btnenqposition"
                                color="error"
                                sx={{ marginLeft: "10px", padding: "15px 8px" }}
                                label="Cancelled"
                                size="small"
                              />
                            ) : responseData?.status === "complete" ? (
                              <Chip
                                className="btnenqposition"
                                color="success"
                                sx={{ marginLeft: "10px" }}
                                label="Completed"
                                size="small"
                              />
                            ) : (
                              responseData?.status === "failed" && (
                                <Chip
                                  className="btnenqposition"
                                  color="error"
                                  sx={{
                                    marginLeft: "10px",
                                    padding: "15px 8px",
                                  }}
                                  label="Failed"
                                  size="small"
                                />
                              )
                            )}
                          </Grid>

                          <Box
                            className="mtop0"
                            component="p"
                            sx={{ fontSize: "15px" }}
                          >
                            <i className="fa-solid fa-location-dot"></i>{" "}
                            {responseData?.hotel_activity_data?.area},{" "}
                            {responseData?.hotel_activity_data?.country}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} order={{ xs: 1 }}>
                          <Box
                            component="img"
                            sx={{
                              height: 100,
                              width: 150,
                            }}
                            alt="image."
                            src={`https://bastionex-travels.b-cdn.net/uploads/${responseData?.hotel_activity_data?.image[0]}`}
                          />
                        </Grid>
                      </Grid>
                      <SecondSectionWrapper className="sec-devider">
                        <CheckInOutWrapper className="displayblock">
                          <DividerWrapper className="w100">
                            <CheckInWrapper>
                              <AddressText>
                                {responseData?.type != "activity"
                                  ? "Check In"
                                  : "Activity Date"}
                              </AddressText>
                              <HotelNameText>
                                {moment(responseData?.type?.checkIn).format(
                                  "DD/MM/yyyy"
                                )}{" "}
                                {responseData?.type === "activity" &&
                                  responseData?.activityTime}
                              </HotelNameText>
                            </CheckInWrapper>
                          </DividerWrapper>
                          {responseData?.type !== "activity" ? (
                            <>
                              <DividerWrapper className="w100">
                                <CheckInWrapper>
                                  <AddressText>Check Out</AddressText>
                                  <HotelNameText>
                                    {moment(responseData?.checkOut).format(
                                      "DD/MM/yyyy"
                                    )}
                                  </HotelNameText>
                                </CheckInWrapper>
                              </DividerWrapper>

                              <DividerWrapper className="w100">
                                <CheckInWrapper>
                                  <AddressText>Room</AddressText>
                                  <HotelNameText>
                                    {responseData?.rooms}
                                  </HotelNameText>
                                </CheckInWrapper>
                              </DividerWrapper>
                            </>
                          ) : null}

                          <DividerWrapper>
                            <CheckInWrapper className="w100">
                              <AddressText>
                                {responseData?.type != "activity"
                                  ? "Adult"
                                  : "No of Tickets"}
                              </AddressText>
                              <HotelNameText>
                                {responseData?.adult}
                              </HotelNameText>
                            </CheckInWrapper>
                          </DividerWrapper>
                          {responseData?.type != "activity" && (
                            <>
                              <DividerWrapper>
                                <CheckInWrapper className="w100">
                                  <AddressText>Children</AddressText>
                                  <HotelNameText>
                                    {responseData?.children}
                                  </HotelNameText>
                                </CheckInWrapper>
                              </DividerWrapper>
                              <DividerWrapper>
                                <CheckInWrapper className="w100">
                                  <AddressText>Infants</AddressText>
                                  <HotelNameText>
                                    {responseData?.infants}
                                  </HotelNameText>
                                </CheckInWrapper>
                              </DividerWrapper>
                            </>
                          )}
                        </CheckInOutWrapper>
                        <DetailsWrapper></DetailsWrapper>
                      </SecondSectionWrapper>
                      <Box component="h5" sx={{ padding: "20px 0px 0px 0px" }}>
                        Customer Details
                      </Box>
                      <CustomerSectionWrapper>
                        <CheckInOutWrapper className="displayblock">
                          <DividerWrapper className="w100">
                            <CheckInWrapper>
                              <AddressText>Name</AddressText>
                              <HotelNameText>
                                {responseData?.customerData?.title
                                  ? responseData?.customerData?.title + "."
                                  : ""}{" "}
                                {responseData?.customerData?.name}
                              </HotelNameText>
                            </CheckInWrapper>
                          </DividerWrapper>

                          <DividerWrapper className="w100">
                            <CheckInWrapper>
                              <AddressText>Email</AddressText>
                              <HotelNameText>
                                {responseData?.customerData?.email}
                              </HotelNameText>
                            </CheckInWrapper>
                          </DividerWrapper>

                          <DividerWrapper className="w100">
                            <CheckInWrapper>
                              <AddressText>Mobile</AddressText>
                              <HotelNameText>
                                {responseData?.customerData?.title
                                  ? responseData?.customerData?.countryCode +
                                    "-"
                                  : ""}
                                {responseData?.customerData?.mobile}
                              </HotelNameText>
                            </CheckInWrapper>
                          </DividerWrapper>
                        </CheckInOutWrapper>
                      </CustomerSectionWrapper>
                      {responseData?.type == "both" ? (
                        <>
                          <Box
                            component="h5"
                            sx={{ padding: "20px 0px 0px 0px" }}
                          >
                            Include`s Activities
                          </Box>
                          <CustomerSectionWrapper>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Activity</th>
                                  <th>Date & Time</th>
                                  <th>Members</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {responseData?.activities.map((row, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{row?.activitydata?.hotelname}</td>
                                      <td>
                                        {moment(row.checkIn).format(
                                          "DD/MM/yyyy"
                                        )}{" "}
                                        {row.activityTime}
                                      </td>
                                      <td>
                                        {Number(row.adults) > 1
                                          ? `${Number(row.adults)} Adults`
                                          : `${Number(row.adults)} Adult`}
                                        ,{" "}
                                        {Number(row.child) > 1
                                          ? `${Number(row.child)} children`
                                          : `${Number(row.child)} child`}
                                        ,{" "}
                                        {Number(row.infants) > 1
                                          ? `${Number(row.infants)} infants`
                                          : `${Number(
                                              row.infants
                                            )} infant`}{" "}
                                      </td>
                                      <td>
                                        {responseData?.currency}{" "}
                                        {Number(row?.activityAmount)?.toFixed(
                                          2
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </CustomerSectionWrapper>
                        </>
                      ) : null}
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <Item>
                      {responseData?.guestDetails?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <Box component="h5">Guest Details</Box>
                          <Box
                            sx={{
                              width: "100%",
                              maxWidth: 360,
                              bgcolor: "background.paper",
                            }}
                          >
                            <TableContainer component="div">
                              <Table
                                sx={{ minWidth: "auto" }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell component="th">SR.</TableCell>
                                    <TableCell component="th" colSpan={2}>
                                      Name
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {responseData?.guestDetails?.length === 0 ? (
                                    <>
                                      <TableRow>
                                        <TableCell
                                          component="td"
                                          className="text-danger"
                                          scope="row"
                                          colSpan={3}
                                        >
                                          No guests
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  ) : (
                                    responseData?.guestDetails?.map(
                                      (item, index) => {
                                        return (
                                          <TableRow
                                            key={index}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                            }}
                                          >
                                            <TableCell
                                              component="td"
                                              scope="row"
                                            >
                                              {`${index + 1}.`}
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                              {" "}
                                              {item.title} {item.firstName}{" "}
                                              {item.lastName}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </>
                      )}

                      {responseData?.status === "success" ||
                      responseData?.status === "unpaid" ||
                      responseData?.status === "cancel" ||
                      responseData?.status === "complete" ||
                      responseData?.status === "failed" ? (
                        <>
                          <Box marginTop={2} component="h5">
                            Booking Details
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              // maxWidth: 360,
                              bgcolor: "background.paper",
                            }}
                          >
                            <TableContainer component="div">
                              <Table
                                sx={{ minWidth: "auto" }}
                                aria-label="simple table"
                              >
                                <TableBody>
                                  {/* {state.paymentDetail !== undefined &&
                              state.paymentDetail ? ( */}
                                  <>
                                    <TableRow>
                                      <TableCell component="td" scope="row">
                                        {responseData?.type != "activity"
                                          ? "Hotel"
                                          : "Activity"}{" "}
                                        Fare
                                      </TableCell>
                                      <TableCell sx={{ textAlign: "right" }}>
                                        {`${responseData?.currency} ${Number(
                                          responseData?.hotelAmount
                                        )?.toFixed(2)}`}
                                      </TableCell>
                                    </TableRow>
                                    {responseData?.type === "both" && (
                                      <>
                                        <TableRow>
                                          <TableCell component="td" scope="row">
                                            Total Activities Fare
                                          </TableCell>
                                          <TableCell
                                            sx={{ textAlign: "right" }}
                                          >
                                            {`${
                                              responseData?.currency
                                            } ${Number(
                                              totalActivitiesAmount
                                            )?.toFixed(2)}`}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell component="td" scope="row">
                                            Total Fare
                                          </TableCell>
                                          <TableCell
                                            sx={{ textAlign: "right" }}
                                          >
                                            {`${responseData?.currency} ${(
                                              Number(totalActivitiesAmount) +
                                              Number(responseData?.hotelAmount)
                                            )?.toFixed(2)}`}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    )}
                                    {/* <TableRow>
                                      <TableCell component="td" scope="row">
                                        Discount
                                      </TableCell>
                                      <TableCell sx={{ textAlign: "right" }}>
                                        {`${
                                          responseData?.paymentDetail?.currency
                                        } ${responseData?.paymentDetail?.discount?.toFixed(
                                          2
                                        )}`}
                                      </TableCell>
                                    </TableRow> */}
                                    {/* {responseData?.paymentDetail &&
                                    responseData?.paymentDetail?.paymentStatus
                                      ?.length === 0 ? (
                                      <TableRow>
                                        <TableCell
                                          sx={{ fontSize: "20px" }}
                                          component="td"
                                          scope="row"
                                        >
                                          Total Due
                                        </TableCell>
                                        <TableCell
                                          sx={{ fontSize: "20px" }}
                                        >{`${
                                          responseData?.paymentDetail.currency
                                        } ${responseData?.paymentDetail.payAmount?.toFixed(
                                          2
                                        )}`}</TableCell>
                                      </TableRow>
                                    ) : responseData?.paymentDetail &&
                                      responseData?.paymentDetail
                                        .paymentStatus !== undefined &&
                                      responseData?.paymentDetail
                                        .paymentStatus[0].status ===
                                        "SUCCESS" ? (
                                      <>
                                        <TableRow>
                                          <TableCell
                                            sx={{
                                              fontSize: "20px",
                                              padding: "15px 0",
                                              textAlign: "left",
                                            }}
                                            component="th"
                                            scope="row"
                                          >
                                            Total{" "}
                                            {responseData?.status ===
                                            "cancelled"
                                              ? "Amount"
                                              : "Paid"}
                                          </TableCell>
                                          {responseData?.type === "hotel" &&
                                          responseData?.isCombined === true ? (
                                            <TableCell
                                              sx={{ fontSize: "20px" }}
                                            >{`${
                                              responseData?.paymentDetail
                                                .currency
                                            } ${
                                              // Number(totalActivityAmount)
                                              //  +
                                              Number(
                                                responseData?.paymentDetail
                                                  .payAmount
                                              )?.toFixed(2)
                                            }`}</TableCell>
                                          ) : (
                                            <TableCell
                                              sx={{ fontSize: "20px" }}
                                            >{`${
                                              responseData?.paymentDetail
                                                .currency
                                            } ${responseData?.paymentDetail.payAmount?.toFixed(
                                              2
                                            )}`}</TableCell>
                                          )}
                                        </TableRow>
                                      </>
                                    ) : null} */}
                                  </>
                                  {/* ) : null} */}

                                  {/* {responseData?.status === "approved" ? (
                                    <TableRow>
                                      <TableCell colSpan={2}>
                                        <MIButton
                                          fullWidth={true}
                                          variant="contained"
                                          color="secondary"
                                          size="large"
                                          // onClick={() => Rediect(responseData)}
                                        >
                                          {responseData?.paymentDetail.currency}{" "}
                                          {responseData?.paymentDetail.payAmount?.toFixed(
                                            2
                                          )}{" "}
                                          Pay
                                        </MIButton>
                                      </TableCell>
                                    </TableRow>
                                  ) : ( */}
                                  {(responseData?.status == "success" ||
                                    responseData?.status == "complete" ||
                                    responseData?.status == "cancel") && (
                                    <>
                                      <TableRow>
                                        <TableCell
                                          colSpan={2}
                                          sx={{
                                            borderTop: "1px solid #e0e0e0",
                                            borderBottom: "0",
                                            paddingBottom: "0",
                                          }}
                                        >
                                          <LoadingButtonCustom
                                            loading={isDownloading}
                                            disabled={isDownloading}
                                            fullWidth={true}
                                            variant="contained"
                                            size="large"
                                            startIcon={<DownloadIcon />}
                                            onClick={() => handleDownload()}
                                          >
                                            Invoice
                                          </LoadingButtonCustom>
                                        </TableCell>
                                      </TableRow>
                                      {/* <TableRow>
                                        <TableCell
                                          colSpan={2}
                                          sx={{ border: "0" }}
                                        >
                                          <LatContainer>
                                            {responseData?.status != "failed" &&
                                              responseData?.status !=
                                                "complete" &&
                                              responseData?.status !=
                                                "cancel" && (
                                                <LoadingButtonCustom
                                                  loading={isCancelled}
                                                  disabled={isCancelled}
                                                  fullWidth={true}
                                                  variant="contained"
                                                  size="large"
                                                  style={{
                                                    backgroundColor: "#ad4040",
                                                  }}
                                                  onClick={() =>
                                                    CancelBooking()
                                                  }
                                                >
                                                  Cancel Your Booking
                                                </LoadingButtonCustom>
                                              )}

                                            <CancellationPolicyLink
                                              onClick={() =>
                                                navigation(
                                                  "/cancellationpolicy"
                                                )
                                              }
                                            >
                                              <span>Cancellation policy</span>
                                            </CancellationPolicyLink>
                                          </LatContainer>
                                        </TableCell>
                                      </TableRow> */}
                                    </>
                                  )}
                                  {/* )} */}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </>
                      ) : null}
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </SectionWrapper>
            <SimilarHotelWrapper>
              <MoreHotels
                sliderSettings={sliderSettings}
                similerData={similerData}
                isSimilerLoading={isSimilerLoading}
                onClickLike={onClickLike}
                userData={userData}
                isHeartLoading={isHeartLoading}
              />
            </SimilarHotelWrapper>
          </Container>
        </>
      )}
    </Root>
  );
};

export default BookingHotelDetails;
