import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import AllHotelsByTheme from "./AllHotelsByTheme";

const Root = styled.div`
  background-color: #e9e9e9;
  padding: 40px 0;
  padding-top: 85px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const NavBarBackground = styled.div`
  width: 100vw;
  height: 84.8px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;
const Filterbytheme = () => {
  const windowRef = useRef(null);
  useEffect(() => {
    // if (window !== "undefined") {
    //   window.scrollTo(0, 0);
    // }
    windowRef.current.scrollTo(0, 0);
  }, []);
  return (
    <Root ref={windowRef}>
      <NavBarBackground></NavBarBackground>
      <AllHotelsByTheme />
    </Root>
  );
};

export default Filterbytheme;
