import React, { useRef, useState } from "react";
import styled from "styled-components";
import Password from "../../../images/lock.png";
import EmailIcon from "../../../images/email-icon.png";
import User from "../../../images/user.png";
import axios from "axios";
import CircularLoader from "../../CircularLoader/CircularLoader";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Button,
  ErrorMessage,
  FooterText,
  Form,
  Heading,
  Image,
  Input,
  InputWrapper,
  Label,
  Link,
  LoaderWrapper,
  MainInputWrapper,
  SpanText,
  Text,
} from "../../styledcomponents/popupStyledcomponents";
import Swal from "sweetalert2";
import { SignupSchema } from "./Schemas/SignupSchema";
import { useFormik } from "formik";
import { environmentVariables } from "../../../config/config";
import { useNavigate } from "react-router-dom";

const Root = styled.div`
  width: 100%;
`;

function SignUp({ setLoginState, setShowPopup }) {
  const baseUrl = environmentVariables.apiUrl;
  const sitekey = environmentVariables.siteKey;
  const [agreeChecked, setAgreechecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();

  const [error, setError] = useState("");
  const captchaRef = useRef();

  const initialValues = {
    username: "",
    email: "",
    newPassword: "",
    confirmPassword: "",
    captchaValue: "",
  };

  let formik = useFormik({
    initialValues,
    validationSchema: SignupSchema,
    onSubmit: async (values, action) => {
      if (agreeChecked === false) {
        setError("Please Accept terms and conditions");
      } else {
        if (isLoading === false) {
          setIsLoading(true);
          await axios({
            method: "post",
            url: `${baseUrl}auth/register`,
            data: {
              username: values.username,
              email: values.email,
              password: values.newPassword,
              cpassword: values.confirmPassword,
              captchaValue: values.captchaValue,
            },
          })
            .then((response) => {
              if (response.data.message === "Email Already exist") {
                setIsLoading(false);
                Swal.fire({
                  icon: "error",
                  title: "Already have an account",
                });
              } else {
                localStorage.setItem(
                  "email",
                  JSON.stringify(response?.data?.data?.email)
                );
                setIsLoading(false);
                // socket.emit("user_registeration", response.data);
                setLoginState("mailverificationotp");
                // setData(JSON.stringify(response.data));
              }

              // Swal.fire({
              //   icon: "success",
              //   title:
              //     "Thank You for Register, Please Verify Your Email Address",
              //   timer: "1000",
              // });


              //
              // setShowPopup(false);
            })
            .catch((error) => {
              setShowPopup(false)
              setIsLoading(false);
              Swal.fire({
                icon: "error",
                title: error?.response?.data?.message,
              });
              // setError(error);
            });
          
          action.resetForm();
          captchaRef.current.reset();
          setAgreechecked(false);
        }
      }
    },
  });

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    formik;
  const handleKeyDown = (e) => {
    if (e.keyCode >= 48 && e.keyCode <= 57) {
      e.preventDefault(); // Prevent input of numeric characters
    }
  };
  const onChangeRecaptcha = (value) => {
    formik.setFieldValue("captchaValue", value);
  };

  return (
    <Root>
      <Heading>Create Account</Heading>
      <Form>
        <MainInputWrapper>
          <InputWrapper>
            <Image src={User} />
            <Input
              type="text"
              placeholder="User Name*"
              name="username"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              onClick={() => setError("")}
              onKeyDown={handleKeyDown}
            />
          </InputWrapper>
          {errors.username && touched.username ? (
            <ErrorMessage>{errors.username}</ErrorMessage>
          ) : null}
        </MainInputWrapper>

        <MainInputWrapper>
          <InputWrapper>
            <Image src={EmailIcon} />
            <Input
              type="email"
              placeholder="Your Email*"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              onClick={() => setError("")}
            />
          </InputWrapper>
          {errors.email && touched.email ? (
            <ErrorMessage>{errors.email}</ErrorMessage>
          ) : null}
        </MainInputWrapper>

        <MainInputWrapper>
          <InputWrapper>
            <Image src={Password} />
            <Input
              type="password"
              placeholder="Your Password*"
              name="newPassword"
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              onClick={() => setError("")}
            />
          </InputWrapper>
          {errors.newPassword && touched.newPassword ? (
            <ErrorMessage>{errors.newPassword}</ErrorMessage>
          ) : null}
        </MainInputWrapper>

        <MainInputWrapper>
          <InputWrapper>
            <Image src={Password} />
            <Input
              type="password"
              placeholder="Confirm Password*"
              name="confirmPassword"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              onClick={() => setError("")}
            />
          </InputWrapper>
          {errors.confirmPassword && touched.confirmPassword ? (
            <ErrorMessage>{errors.confirmPassword}</ErrorMessage>
          ) : null}
        </MainInputWrapper>

        <MainInputWrapper>
          <InputWrapper>
            <ReCAPTCHA
              sitekey={sitekey}
              onChange={onChangeRecaptcha}
              ref={captchaRef}
            />
          </InputWrapper>
          {errors.captchaValue && touched.captchaValue ? (
            <ErrorMessage className="form-error">
              {errors.captchaValue}
            </ErrorMessage>
          ) : null}
        </MainInputWrapper>

        {/* <Label style={{ marginTop: "20px" }}>
          <Input
            type="checkbox"
            checked={updatechecked}
            onChange={() => {
            }}
            onClick={() => setError("")}
          />
          <Text>Keep me updated on the latest news and offers.</Text>
        </Label> */}

        <Label>
          <Input
            type="checkbox"
            checked={agreeChecked}
            onChange={() => {
              setAgreechecked(!agreeChecked);
            }}
            onClick={() => setError("")}
          />
          <Text>
            {" "}
            I've read and accept the{" "}
            <SpanText
              onClick={() => {
                navigation("/termsandcondition");
                setShowPopup(false);
              }}
            >
              Terms of Use
            </SpanText>{" "}
            &{" "}
            <SpanText
              onClick={() => {
                navigation("/privacypolicy");
                setShowPopup(false);
              }}
            >
              Privacy Policy
            </SpanText>{" "}
          </Text>
        </Label>
        {error !== "" && <ErrorMessage>{error}</ErrorMessage>}
        {/* <ReCAPTCHA
                    sitekey="Your client site key"
                    onChange={onChange}
                /> */}
        <Button onClick={handleSubmit}>
          {isLoading ? (
            <LoaderWrapper>
              <CircularLoader />
            </LoaderWrapper>
          ) : (
            "Create an account"
          )}
        </Button>
        <FooterText>
          Already have an account?{" "}
          <Link
            style={{ color: "#0c6068", fontWeight: 600 }}
            onClick={() => {
              setLoginState("login");
            }}
          >
            Login
          </Link>
        </FooterText>
      </Form>
    </Root>
  );
}

export default SignUp;
