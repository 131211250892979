import React, { useEffect, useState, useRef, useContext } from "react";
import { Carousel } from "react-carousel-minimal";
import "./HeroSection.css";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
// import Hotel1 from '../../../images/hotelimg1.jpg';
import Deals from "../Deals/Deals";
import SimilarHotels from "./SimilarHotels";
import { environmentVariables } from "../../../config/config";
import Radar from "radar-sdk-js";
import axios from "axios";
import { AuthContext, useAuth } from "../../../ContextApi/Contextapi";
import Rating from "./Rating";
import ViewRating from "./ViewRating";
import Swal from "sweetalert2";
import { FaStar } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Shortlistedloader/Loader";

const Root = styled.div`
  background-color: #e9e9e9;
  padding-bottom: 30px;
  padding-top: 85px;
`;
const Container = styled.div`
  width: 1370px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 1400px) {
    width: 100vw;
  }
  @media (max-width: 1200px) {
    width: 100vw;
  }
  // @media (max-width: 1024px) {
  //   width: 768px;
  // }
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const RightsideWrapper = styled.div`
  /* padding: 0 10px; */
  width: 40%;
  margin: 15px 0;
  @media (max-width: 768px) {
    width: 100vw;
  }
`;
const LeftsideSection = styled.div`
  background-color: #fff;
  border-radius: 10px;
  margin-top: 15px;
  width: 60%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  padding: 20px 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // width: 80%;
  padding-right: 100px;
  @media (max-width: 768px) {
    width: 90vw;
  }
`;
const Text = styled.text`
  font-size: 28px;
  font-weight: 400;
  line-height: 33px;
  /* margin: 15px 0; */
  ${(p) =>
    p.heading &&
    `
font-size:18px;
font-weight: 400;
align-item:center;
margin: 0px;
padding: 10px 15px;
text-align: center;

`}
`;
const ParagraphWrapper = styled.div`
  padding-left: 15px;
  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 10px;
  }
`;
const Paragraph = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  padding: 0;
  text-align: justify;
  color: #818181;
  @media (max-width: 768px) {
    padding: 0;
    font-size: 14px;
    line-height: 22px;
  }
`;

const SidewrapperContainer = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    /* width: 100vw; */
  }
`;

const SimilarHotelWrapper = styled.div``;
const Circle = styled.div`
  width: 11px;
  height: 11px;
  color: #000;
  background-color: #000;
  border-radius: 5px;
`;
const PointWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Locationwrapper = styled.text`
  color: #2c2c2c;
  font-size: 22px;
  align-items: center;
  ${(p) =>
    p.details &&
    `
  color: #2c2c2c;
`}

  ${(p) =>
    p.LocationDetail &&
    `
  font-size: 16px;
  color: gray;
  margin: 20px 0 ;
`}
@media (max-width:768px) {
    font-size: 14px;
  }
`;
const Horizontalwrapper = styled.div``;
const ImageWrapper = styled.div`
  padding: 15px 0;
`;
const Image = styled.img``;
const LocationiconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const GetRatingWrapper = styled.div``;
const IconWrapper = styled.div`
  padding: 0px 10px 0px 0px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
`;
const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    // flex-direction: column;
    margin-bottom: 0px;
  }
`;
const PhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  padding: 0 10px 0 10px;
  :hover {
    color: white;
  }
  @media (max-width: 768px) {
    padding: 0 5px 0 0px;
  }
`;
const Icon = styled.div`
  position: absolute;
  left: 15%;

  ${(p) =>
    p.Photo &&
    `
  left:30%;
 
  `}
  ${(p) =>
    p.Facilities &&
    `
  left:25%;
  `}
    ${(p) =>
    p.Location &&
    `
  left:30%;
  `}

  :hover {
    color: white;
  }

  @media (max-width: 768px) {
    left: 7% !important;
  }
`;

const Headingwrapper = styled.div`
  background-color: #f5f5f5;
  border-radius: 5px;
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const FacilitiesNameWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;
const Facilitiesname = styled.text`
  margin: 0px 30px 0px 15px;
  font-weight: 500;
  font-size: 18px;
  @media (max-width: 768px) {
    margin: 0;
  }
`;
const Button = styled.button`
  /* background-color: gray; */
  color: #000;
  font-size: 16px;
  width: 200px;
  padding: 10px 0px 10px 20px;
  border: #0098d4;
  /* width: 185px; */
  border-radius: 5px;
  cursor: pointer;
  :hover {
    color: #fff;
    background-color: #3554d1;
  }
  @media (max-width: 768px) {
    margin: 4px 0;
    font-size: 14px;
    padding: 10px 10px 10px 32px;
    width: auto;
  }
  @media (max-width: 425px) {
    font-size: 11px;
  }
`;
const ScrollWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ImageCarousel = styled.div`
  /* display: flex;
flex-direction: column; */
  @media (max-width: 768px) {
    width: 90vw;
  }
`;
const LastHeadingwrapper = styled.div`
  margin-top: 15px;
`;
const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  @media (max-width: 768px) {
  }
`;

const ImageSection = styled.div`
  background-color: #fff;
  margin-top: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  padding: 20px 20px;
`;

const RatingBox = styled.div`
  width: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const RatingBox1 = styled.div`
  background-color: #3d8000;
  color: white;
  font-size: 20px;
  width: 100%;
  text-align: center;
  padding: 5px 0px;
  border-radius: 8px;
`;
const RatingBox2 = styled.div`
  background-color: #f4f4f4;
  color: black;
  font-size: 12px;
  width: 100%;
  text-align: center;
  padding: 5px 0px;
`;

const TopReviewsWrapper = styled.div`
  margin: 10px 0;
`;
const TopReview = styled.text`
  font-size: 36px;
  font-weight: 400;
`;
const ChooseReviewWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin: 20px 0;
`;
const ChooseReview = styled.text`
  font-size: 18px;
  font-weight: 400;
  font-family: sans-serif;
  margin: 0 15px 0 0;
`;

const ReviewButtonWrapper = styled.div`
  margin: 15px 0 20px 0;
`;
const ReviewButton = styled(Button)`
  background-color: #3b60d0;
  color: white;
  @media (max-width: 768px) {
    margin: 4px 0;
    font-size: 14px;
    padding: 10px 10px 10px 32px;
    width: auto;
  }
  @media (max-width: 425px) {
    font-size: 14px;
  }
`;
const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
`;

const CarouselStyle = styled(Carousel)`
@media(max-width: 1400px) {
  width= 80%;

}
`;
const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:hover {
    border-color: #666;
  }

  &:focus {
    outline: none;
    border-color: #3399ff;
    box-shadow: 0 0 5px rgba(51, 153, 255, 0.7);
  }
`;

const Caption = styled.text``;
const HeroSection = ({ loggedIn, setLoggedIn }) => {
  const { id } = useParams();
  const { authData } = useAuth();

  const country_code =
    authData?.country == "India"
      ? "IN"
      : authData?.country == "United Arab Emirates"
      ? "AE"
      : localStorage?.getItem("countryCodeForPricing");
  const [loader, setLoader] = useState(false);
  const [resData, setResData] = useState();
  const { state } = useLocation();
  const baseUrl = environmentVariables.apiUrl;
  const [rooms, setRooms] = useState(state?.rooms || 1);

  const [data, setData] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [reviewShow, setReviewShow] = useState(false);
  const [arr, setArr] = useState([]);
  const [review, setReview] = useState("");
  const [totalRating, setTotalRating] = useState("");
  const [resRating, setResRating] = useState([]);
  const [averagecRating, setAverageRating] = useState();
  const location = useLocation();
  const [ratingError, setRatingError] = useState("");
  const [reviewError, setReviewError] = useState("");
  // const { authData, setAuthData } = useContext(AuthContext);
  Radar.initialize("prj_live_pk_8cef6dee5f31e220f09eba68a33c1556053a8e3b");
  const [rating, setRating] = useState(0);

  const getDataById = async (showLoader = true) => {
    try {
      if (showLoader) setLoader(true); // Only show loader if page is refreshed
      const getData = await axios.get(
        `${baseUrl}auth/gethotelbyid?id=${id}&country_code=${country_code}&roomQty=${rooms}`
      );
      setResData(getData?.data?.data);
      if (showLoader) setLoader(false);
    } catch (error) {
      setResData();
      if (showLoader) setLoader(false);
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Show loader only on initial page load
    getDataById(true);
  }, [id, authData]); // Only run on ID change or initial load

  useEffect(() => {
    // Do not show loader when rooms change, just update prices
    if (rooms !== 1) {
      getDataById(false);
    }
  }, [rooms, authData]); // Only run when room quantity changes

  document.title =
    resData !== undefined && resData.hotelname !== undefined
      ? resData.hotelname
      : "";
  const handleRatingChange = (selectedRating) => {
    setRating(selectedRating);
  };

  const galleryRef = useRef();
  const facilitiesRef = useRef();
  const locationRef = useRef();
  const ratingRef = useRef();
  const handleScroll = (e) => {
    if (e === 1) {
      galleryRef.current.scrollIntoView();
    } else if (e === 2) {
      const scrollPosition = facilitiesRef.current.offsetTop - "100";
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    } else if (e === 3) {
      const scrollPosition = locationRef.current.offsetTop - "100";
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    } else if (e === 4) {
      const scrollPosition = ratingRef.current.offsetTop - "100";
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  const handleReviewChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^A-Za-z\s,./\t]+/g, "");
    setReview(sanitizedValue);
  };

  const getRating = () => {
    const config = {
      method: "get",
      url: `${baseUrl}auth/getratingofhotel/${resData?._id}`,
    };

    axios(config)
      .then(function (response) {
        // const responseData = response.data.data.sort(
        //   (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        // );
        setResRating(response.data.data);
      })
      .catch(function (error) {
        setResRating([]);
      });
  };
  const showReviewInputOrNot = () => {
    if (authData !== undefined) {
      const config = {
        method: "get",
        url: `${baseUrl}auth/is_showreviewinputornot?hotelid=${id}`,
        headers: { _token: authData?.token },
      };

      axios(config)
        .then(function (response) {
          if (response.data.message === "User can review") {
            setReviewShow(true);
          } else {
            setReviewShow(false);
          }
        })
        .catch(function (error) {
          setReviewShow(false);
        });
    }
  };
  const ReviewClick = (e) => {
    e.preventDefault();
    if (!rating) {
      setRatingError("Rating is required");
    } else {
      setRatingError("");
    }

    if (!review) {
      setReviewError("Review is required");
    } else {
      setReviewError("");
    }

    const reviewdata = JSON.stringify({
      rating: rating,
      review: review,
      hotelId: id,
    });

    var config = {
      method: "post",
      url: `${baseUrl}auth/addratingreview`,
      headers: {
        _token: authData?.token,
        "Content-Type": "application/json",
      },
      data: reviewdata,
    };

    if (rating && review) {
      axios(config)
        .then(function (response) {
          if (response.data.success === true) {
            setReview("");
            setRating(0);
            // Swal.fire({
            //   icon: "success",
            //   title: "Thank You for review!",
            // });
            toast.success("Thank You for review!", { autoClose: 3000 });
            getAverageRating();
            getRating();
            setReviewShow(false);
          } else {
            // Swal.fire({
            //   icon: "error",
            //   title: response.data.response.data.message,
            // });
            toast.error("Something went wrong", { autoClose: 3000 });
          }
        })
        .catch(function (error) {
          toast.error(error.response.data.message, { autoClose: 3000 });
          // Swal.fire({
          //   icon: "error",
          //   title: error.response.data.message,
          // });
        });
    }
  };

  const callForLoop = () => {
    setArr([]);
    const dataFromThePage = resData?.image || state?.image;
    for (let i = 1; i < dataFromThePage?.length; i++) {
      const item = dataFromThePage[i];
      setArr((oldItems) => [
        ...oldItems,
        { image: `https://bastionex-travels.b-cdn.net/uploads/${item}` },
      ]);
    }
  };

  const getAverageRating = () => {
    if (resData?._id) {
      const config = {
        method: "get",
        url: `${baseUrl}auth/findavgrating/${resData._id}`,
      };

      axios(config)
        .then(function (response) {
          setAverageRating(response.data.data[0]);
          setTotalRating(response.data.totalrating);
        })
        .catch(function (error) {
          setTotalRating("");
          setAverageRating();
        });
    }
  };
  useEffect(() => {
    if (window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, [isUpdated]);

  useEffect(() => {
    // setData(state?.filter || state);
    callForLoop();
  }, [resData, authData]);

  useEffect(() => {
    getRating();
    // showReviewInputOrNot();
    getAverageRating();
  }, [data, authData, state, totalRating, resData]);
  useEffect(() => {
    showReviewInputOrNot();
  }, [authData]);

  return (
    <Root>
      {loader ? (
        <Loader />
      ) : (
        <Container>
          <ImageSection>
            <MainWrapper>
              <TextWrapper>
                <Text className="hname">{resData?.hotelname}</Text>
                <LocationiconWrapper>
                  <IconWrapper className="icon-p">
                    <i
                      style={{ color: "#3554d1" }}
                      className="fa-solid fa-location-dot"
                    ></i>
                  </IconWrapper>
                  <Locationwrapper className="city-state">
                    {resData?.city} , {resData?.state}
                  </Locationwrapper>
                </LocationiconWrapper>
              </TextWrapper>
              {averagecRating !== undefined && totalRating !== "" ? (
                <RatingBox>
                  <RatingBox1>
                    {averagecRating === undefined ? (
                      "NA"
                    ) : (
                      <>
                        {averagecRating?.rating?.toFixed(1)}
                        <FaStar
                          style={{ color: "white", paddingBottom: "4px" }}
                        />
                      </>
                    )}
                  </RatingBox1>
                  <RatingBox2>
                    {totalRating === ""
                      ? `0 reviews`
                      : `${totalRating} reviews`}
                  </RatingBox2>
                </RatingBox>
              ) : null}
            </MainWrapper>
            <ButtonWrapper className="">
              <PhotoWrapper>
                <Icon Facilities>
                  <i className="fa-solid fa-dungeon"></i>
                </Icon>
                <div
                  onClick={(e) => handleScroll(2)}
                  style={{ textDecoration: "none" }}
                >
                  <ScrollWrapper>
                    <Button>Facilities</Button>
                  </ScrollWrapper>
                </div>
              </PhotoWrapper>

              <PhotoWrapper>
                <Icon Location>
                  <i className="fa-solid fa-location-dot"></i>
                </Icon>
                <div
                  onClick={(e) => handleScroll(3)}
                  style={{ textDecoration: "none" }}
                >
                  {" "}
                  <ScrollWrapper>
                    <Button>Location</Button>
                  </ScrollWrapper>
                </div>
              </PhotoWrapper>
              <PhotoWrapper>
                <Icon>
                  <i className="fa-solid fa-star"></i>
                </Icon>
                <div
                  onClick={(e) => handleScroll(4)}
                  style={{ textDecoration: "none" }}
                >
                  {" "}
                  <ScrollWrapper>
                    <Button>Rating & Review </Button>
                  </ScrollWrapper>
                </div>
              </PhotoWrapper>
            </ButtonWrapper>

            <ImageWrapper ref={galleryRef} id="GalleryDetails">
              <div style={{ textAlign: "center" }}>
                <ImageCarousel>
                  {arr.length !== 0 && (
                    <CarouselStyle
                      className="mobdisplay"
                      data={arr}
                      time={1000}
                      width="100%"
                      radius="5px"
                      slideNumber={false}
                      captionPosition="top"
                      automatic={true}
                      dots={false}
                      pauseIconColor="white"
                      pauseIconSize="40px"
                      slideImageFit="cover"
                      thumbnails={true}
                      thumbnailWidth="90%"
                      style={{
                        textAlign: "center",
                        maxHeight: "400px",
                        margin: "10px auto",
                        display: "flex",
                        justifyContent: "start",
                      }}
                    />
                  )}
                </ImageCarousel>
              </div>
            </ImageWrapper>
          </ImageSection>
          <SidewrapperContainer>
            <LeftsideSection>
              <LocationiconWrapper>
                <IconWrapper>
                  <i className="fa-solid fa-calendar-week"></i>
                </IconWrapper>
                <Locationwrapper details>Details</Locationwrapper>
              </LocationiconWrapper>

              <Horizontalwrapper>
                <hr />
              </Horizontalwrapper>

              {resData?.overview && (
                <>
                  {" "}
                  <Headingwrapper>
                    <Text heading>Overview</Text>
                  </Headingwrapper>
                  <ParagraphWrapper>
                    <Paragraph id="ActivitiesDetails">
                      <div
                        dangerouslySetInnerHTML={{ __html: resData?.overview }}
                      />
                    </Paragraph>
                  </ParagraphWrapper>
                </>
              )}

              <Headingwrapper ref={facilitiesRef}>
                <Text heading>Facilities</Text>
              </Headingwrapper>

              <FacilitiesNameWrapper>
                <ParagraphWrapper>
                  <Paragraph>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          resData?.facilities !== undefined &&
                          resData?.facilities[0].services,
                      }}
                    />
                  </Paragraph>
                </ParagraphWrapper>
              </FacilitiesNameWrapper>

              {resData?.facilities?.[0]?.general && (
                <>
                  {" "}
                  <FacilitiesNameWrapper>
                    <Facilitiesname>General</Facilitiesname>
                    <ParagraphWrapper>
                      <Paragraph>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              resData?.facilities !== undefined &&
                              resData?.facilities[0].general,
                          }}
                        />
                      </Paragraph>
                    </ParagraphWrapper>
                  </FacilitiesNameWrapper>
                </>
              )}

              {resData?.facilities !== undefined &&
                resData?.facilities[0].internet !== undefined &&
                resData?.facilities[0].internet !== "" && (
                  <FacilitiesNameWrapper>
                    <Facilitiesname>Internet</Facilitiesname>
                    <ParagraphWrapper>
                      <Paragraph>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              resData?.facilities !== undefined &&
                              resData?.facilities[0].internet,
                          }}
                        />
                      </Paragraph>
                    </ParagraphWrapper>
                  </FacilitiesNameWrapper>
                )}

              {resData?.facilities !== undefined &&
                resData?.facilities[0].parking !== undefined &&
                resData?.facilities[0].parking !== "" && (
                  <FacilitiesNameWrapper>
                    <Facilitiesname>Parking</Facilitiesname>
                    <ParagraphWrapper>
                      <Paragraph>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              resData?.facilities !== undefined &&
                              resData?.facilities[0].parking,
                          }}
                        />
                      </Paragraph>
                    </ParagraphWrapper>
                  </FacilitiesNameWrapper>
                )}

              <Headingwrapper ref={locationRef}>
                <Text heading>Location Details</Text>
              </Headingwrapper>

              <LocationiconWrapper>
                <IconWrapper>
                  <i className="fa-solid fa-location-dot"></i>
                </IconWrapper>
                <Locationwrapper LocationDetail id="LocationDetails">
                  {resData?.address}
                </Locationwrapper>
              </LocationiconWrapper>

              <Headingwrapper ref={ratingRef}>
                <Text heading>User Rating and Review</Text>
              </Headingwrapper>
              <RatingWrapper>
                {reviewShow && (
                  <div>
                    <ChooseReviewWrapper
                      onClick={() => {
                        setRatingError("");
                        setReviewError("");
                      }}
                    >
                      <ChooseReview>Rating*</ChooseReview>
                      <Rating
                        rating={rating}
                        setRating={setRating}
                        handleRatingChange={handleRatingChange}
                        style={{
                          margin: "0 20px",
                        }}
                      />
                      {ratingError && (
                        <ErrorMessage>{ratingError}</ErrorMessage>
                      )}
                    </ChooseReviewWrapper>
                    <StyledTextarea
                      className="form-control"
                      aria-label="With textarea"
                      value={review}
                      onChange={handleReviewChange}
                      rows="2"
                      cols="200"
                      placeholder="Please write your review"
                      onClick={() => {
                        setRatingError("");
                        setReviewError("");
                      }}
                    ></StyledTextarea>
                    {reviewError && <ErrorMessage>{reviewError}</ErrorMessage>}
                    <ReviewButtonWrapper>
                      <ReviewButton onClick={ReviewClick}>Submit</ReviewButton>
                    </ReviewButtonWrapper>
                  </div>
                )}
              </RatingWrapper>

              <GetRatingWrapper>
                {resRating && resRating.length ? (
                  resRating.map((item, key) => {
                    return (
                      <ViewRating
                        value={item}
                        key={key}
                        getRating={getRating}
                        setReviewShow={setReviewShow}
                        getAverageRating={getAverageRating}
                        setLoggedIn={setLoggedIn}
                      />
                    );
                  })
                ) : (
                  <>
                    <span className="text-danger">No rating and reviews</span>
                  </>
                )}
              </GetRatingWrapper>

              <LastHeadingwrapper></LastHeadingwrapper>
            </LeftsideSection>
            <RightsideWrapper>
              <Deals
                data={resData}
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                rooms={rooms}
                setRooms={setRooms}
              />
            </RightsideWrapper>
          </SidewrapperContainer>
          <ToastContainer />
        </Container>
      )}
    </Root>
  );
};

export default HeroSection;
