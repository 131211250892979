import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SideBar from "../../SideBar/SideBar";
import axios from "axios";
import { useFormik } from "formik";
import { ProfileSettingSchema } from "./Schemas/ProfileSettingSchema";
import { environmentVariables } from "../../../config/config";
import CircularLoader from "../../CircularLoader/CircularLoader";
import { useAuth } from "../../../ContextApi/Contextapi";

const Root = styled.div`
  background-color: #e3e3e3;
  width: 100%;
  padding-top: 85px;
`;
const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  display: flex;
  justify-content: space-around;
  @media (max-width: 768px) {
    width: 100%;
    overflow: scroll;
    padding: 15px;
  }

  @media (max-width: 768px) {
    width: 100vw;
  }
`;
const Leftsidebar = styled.div`
  padding: 0 20px;
  justify-content: center;
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Rightsidebar = styled.div`
  width: 80%;
  @media (max-width: 768px) {
    width: 95vw;
  }
`;

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 0px 10px 3rem 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
`;
const HeadWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: start;
  text-align: center;
  padding-top: 20px;
`;
const HeadText = styled.text`
  font-size: 24px;
  font-weight: 300px;
  color: #0098d4;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  padding-bottom: 0px;
`;
const Text = styled.text`
  font-size: 15px;
  text-align: left;
  font-weight: 500;
  @media (max-width: 768px) {
    text-align: left;
    font-size: 15px;
    width: 100px;
  }
`;
const Inputfield = styled.input`
  border-radius: 5px;
  border: 1px solid #c9c9c9;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 10px;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const InnerTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: start;
  justify-content: flex-start;
  @media (max-width: 768px) {
    width: 100px;
    display: block;
  }
`;

const SecectField = styled.select`
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #c9c9c9;
  padding: 10px 10px;
  width: 600px;
  cursor: pointer;
  @media (max-width: 1024px) {
    margin-left: 0;
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Option = styled.option`
  background-color: #fff;
  padding: 15px;
  cursor: pointer;
`;
const Button = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  cursor: pointer;
  width: 100%;
  background-color: #2196f3;
  color: #fff;
  font-size: 20px;
  margin: 0;
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #cbcbcb;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 15px 0;
`;
const InpputFieldWrapper = styled.div`
  margin-left: 0rem;
  width: 100%;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`;
const LineWrapper = styled.div`
  width: 100%;
  margin: 1rem 0;
`;
const Line = styled.div`
  height: 1px;
  border: 0.2px solid #e3e3e3;
`;
const NavBarBackground = styled.div`
  width: 100vw;
  height: 84.8px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;
const ProfileForm = styled.div`
  margin-left: 0rem;
  width: 600px;
  @media (max-width: 1024px) {
    margin-left: 0;
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`;
const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 0px;
`;
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ProfileSetting = ({
  title,
  userData,
  setUserData,
}) => {
  document.title = title === undefined ? "" : title;
  const baseUrl = environmentVariables.apiUrl;

  // const { authData, setAuthData } = useContext(AuthContext);
  const [email, setEmail] = useState();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const { authData } = useAuth();
  useEffect(() => {
    setEmail(authData?.email)
  }, [email,authData]);
  
  const getUserData = () => {
    setDataLoading(true);
    if (authData !== undefined) {
      axios({
        method: "get",
        url: `${baseUrl}auth/getuserprofile`,
        headers: {
          _token: authData?.token,
        },
      })
        .then((response) => {
          setUserData(response.data.message);
          setDataLoading(false);
        })
        .catch((error) => {
          setError(error.response.data);
          setDataLoading(false);
        });
    }
  };
  const getAllCountries = () => {
    let config = {
      method: "get",
      url: `${baseUrl}auth/getallcountries`,
    };
    axios
      .request(config)
      .then((response) => {
        setAllCountries(response.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    getAllCountries();
    getUserData();
  }, []);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: {
      name: "",
      address: "",
      country: "",
      contact: "",
    },
    validationSchema: ProfileSettingSchema,
    onSubmit: async (values, action) => {
      setIsLoading(true);
      axios({
        method: "put",
        url: `${baseUrl}auth/updateuser`,
        data: {
          username: values.name,
          address: values.address,
          telephone: values.contact,
          country: values.country,
          // gender: values.gender,
          email,
        },
        headers: {
          _token: authData?.token,
        },
      })
        .then((response) => {
          getUserData();
          // const userInfo = JSON.parse(localStorage.getItem("authdata"));
          setIsLoading(false);
          // setValues({
          //   ...values,
          //   name: response.data.data.name || userInfo.data.data.name,
          //   address: response.data.data.address || userInfo.data.data.address,
          //   country: response.data.data.country || userInfo.data.data.country,
          //   gender: response.data.data.gender || userInfo.data.data.gender,
          //   contact: response.data.data.mobile || userInfo.data.data.mobile,
          // });

          // const newUpdatedUserInfo = {
          //   data: {
          //     message: "user login",
          //     data: {
          //       name: response.data.data.name || userInfo.data.data.name,
          //       address:
          //         response.data.data.address || userInfo.data.data.address,
          //       cPassword: userInfo.data.data.cPassword,
          //       country:
          //         response.data.data.country || userInfo.data.data.country,
          //       email: userInfo.data.data.email,
          //       gender: response.data.data.gender || userInfo.data.data.gender,
          //       mobile: response.data.data.mobile || userInfo.data.data.mobile,
          //       password: userInfo.data.data.password,
          //       profile:
          //         response.data.data.profile || userInfo.data.data.profile,
          //       token: userInfo.data.data.token,
          //       id: userInfo.data.data.id,
          //     },
          //   },
          //   expirationTimestamp: userInfo.expirationTimestamp,
          // };
          // localStorage.setItem("authdata", JSON.stringify(newUpdatedUserInfo));
          // setResData(response.data.data);
          // Swal.fire({
          //   icon: "success",
          //   title: response.data.message,
          //   timer: "1000",
          // });
        })
        .catch((error) => {
          setIsLoading(false);
          setError(error.response.data.message);
          // setError('Details are not valid');
        });

      action.resetForm();
    },
  });
  useEffect(() => {
    if (userData || authData) {
      setValues({
        name: userData?.username || authData?.username || "",
        address: userData?.address || authData?.address || "",
        country: userData?.country || authData?.country || "",
        contact: userData?.mobileNumber || authData?.mobileNumber || "",
      });
    }
  }, [userData, authData, setValues]);

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isCtrlPressed = event.ctrlKey || event.metaKey; // Check if Ctrl key is pressed

    if (
      keyCode === 8 || // Allow backspace key (key code 8)
      (keyCode >= 48 && keyCode <= 57) || // Allow numeric keys (0-9)
      (isCtrlPressed && keyValue === "r") // Allow Ctrl+R combination
    ) {
      // Check for plus (+) and minus (-) symbols
      if (keyValue === "+" || keyValue === "-") {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  };
  const handleKeyPressText = (e) => {
    const onlyLettersRegex = /^[A-Za-z\s]+$/;

    if (!onlyLettersRegex.test(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <Root>
      <NavBarBackground></NavBarBackground>
      <Container>
        <Leftsidebar>
          <SideBar />
        </Leftsidebar>
        <Rightsidebar>
          {/* {userData && ( */}
          <Wrapper>
            <HeadWrapper>
              <HeadText>User Profile</HeadText>
            </HeadWrapper>
            <LineWrapper>
              <Line />
            </LineWrapper>
            <ProfileForm>
              <TextWrapper>
                <InnerTextWrapper>
                  <Text>Full Name* </Text>
                </InnerTextWrapper>
                <InpputFieldWrapper>
                  <Inputfield
                    type="text"
                    placeholder="Full name*"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyPressText}
                  />
                </InpputFieldWrapper>
              </TextWrapper>
              {errors.name && touched.name ? (
                <ErrorMessage>{errors.name}</ErrorMessage>
              ) : null}
              <TextWrapper>
                <Text>Address* </Text>
                <InpputFieldWrapper>
                  <Inputfield
                    type="text"
                    placeholder="Address*"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InpputFieldWrapper>
              </TextWrapper>
              {errors.address && touched.address ? (
                <ErrorMessage>{errors.address}</ErrorMessage>
              ) : null}
              <TextWrapper>
                <Text>Country* </Text>

                <SecectField
                  name="country"
                  id="name"
                  value={values.country}
                  onChange={handleChange}
                  // onChange={handleOptionChange}
                  onBlur={handleBlur}
                >
                  <Option value="">select country</Option>
                  {allCountries.map((row, index) => {
                    return (
                      <Option value={row.name} key={index}>
                        {row?.name}
                      </Option>
                    );
                  })}
                </SecectField>
              </TextWrapper>
              {errors.country && touched.country ? (
                <ErrorMessage>{errors.country}</ErrorMessage>
              ) : null}

              <TextWrapper>
                <Text>Contact* </Text>
                <InpputFieldWrapper>
                  <Inputfield
                    type="number"
                    placeholder="Contact*"
                    name="contact"
                    value={values.contact}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyPress}
                  />
                </InpputFieldWrapper>
              </TextWrapper>
              {errors.contact && touched.contact ? (
                <ErrorMessage>{errors.contact}</ErrorMessage>
              ) : null}

              <TextWrapper>
                <Text>Email Address* </Text>
                <InpputFieldWrapper>
                  <Inputfield
                    type="email"
                    placeholder="Email*"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    readOnly
                  />
                </InpputFieldWrapper>
              </TextWrapper>

              {error && <ErrorMessage>{error}</ErrorMessage>}
              <ButtonWrapper>
                {/* <Button onClick={handleSubmit}>Update</Button> */}

                <Button type="button" onClick={handleSubmit}>
                  {isLoading ? (
                    <LoaderWrapper>
                      <CircularLoader />
                    </LoaderWrapper>
                  ) : (
                    "Update"
                  )}
                </Button>
              </ButtonWrapper>
            </ProfileForm>
          </Wrapper>
          {/* )} */}
        </Rightsidebar>
      </Container>
    </Root>
  );
};

export default ProfileSetting;
