import React, { useState } from "react";
import styled from "styled-components";
import Password from "../../../images/lock.png";
import axios from "axios";

import {
  Form,
  Heading,
  Image,
  Input,
  InputWrapper,
} from "../../styledcomponents/popupStyledcomponents";
import { VerifyPasswordSchema } from "./Schemas/VerifyPasswordSchema";
import { useFormik } from "formik";
import Timer from "../../Booking/Timer";
import Swal from "sweetalert2";
import { environmentVariables } from "../../../config/config";
import { LoadingButton } from "@mui/lab";

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Container = styled.div`
  text-align: start;
`;
const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 0px;
`;

const NavBarBackground = styled.div`
  width: 100vw;
  height: 84.8px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;
function VerifyPassword({ setLoginState, setShowPopup }) {
  const baseUrl = environmentVariables.apiUrl;

  const [isApiResponse, setIsApiResponse] = useState(false);
  const [email, setEmail] = useState(localStorage.getItem("emailvalue"));
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(120);

  const initialValues = {
    otp: "",
    newPassword: "",
    confirmPassword: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: VerifyPasswordSchema,
      onSubmit: async (values, action) => {
        setIsApiResponse(true);
        await axios({
          method: "post",
          url: `${baseUrl}auth/verifyotp`,
          data: {
            email: email,
            otp: values.otp.toString(),
            password: values.newPassword,
            confirmPassword: values.confirmPassword,
          },
        })
          .then((response) => {
            setIsApiResponse(false);
            // setData(JSON.stringify(response.data))
            if (response.data.message === "Password Changed Successfully") {
              Swal.fire({
                icon: "success",
                title: "Password Updated Successfully",
                // timer: "800",
              });
              setShowPopup(false);
              localStorage.removeItem("emailvalue");
              localStorage.removeItem("authdata");
              // navigate("/");
            } else {
              Swal.fire({
                icon: "error",
                title: response.data.message,
              });
            }
          })
          .catch((error) => {
            setIsApiResponse(false);
            setError(error.response.data.message);
          });

        action.resetForm();
      },
    });

  const handleSubmit1 = async () => {
    await axios({
      method: "post",
      url: `${baseUrl}auth/emailsend`,
      data: {
        email: email,
      },
    })
      .then((response) => {
        setTimer(120);
      })
      .catch((error) => {
      });
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isCtrlPressed = event.ctrlKey || event.metaKey; // Check if Ctrl key is pressed

    if (
      keyCode === 8 || // Allow backspace key (key code 8)
      (keyCode >= 48 && keyCode <= 57) || // Allow numeric keys (0-9)
      (isCtrlPressed && keyValue === "r") // Allow Ctrl+R combination
    ) {
      // Check for plus (+) and minus (-) symbols
      if (keyValue === "+" || keyValue === "-") {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  };
  return (
    <Root>
      <NavBarBackground></NavBarBackground>
      <Container>
        <Heading>Verify password</Heading>
        <Form>
          <InputWrapper>
            <Image src={Password} />
            <Input
              type="number"
              placeholder="Otp*"
              name="otp"
              value={values.otp}
              onChange={handleChange}
              onBlur={handleBlur}
              onClick={() => setError("")}
              onKeyDown={handleKeyPress}
            />
          </InputWrapper>
          {errors.otp && touched.otp ? (
            <ErrorMessage>{errors.otp}</ErrorMessage>
          ) : null}
          <InputWrapper>
            <Image src={Password} />
            <Input
              type="password"
              placeholder="Your Password*"
              name="newPassword"
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              onClick={() => setError("")}
            />
          </InputWrapper>
          {errors.newPassword && touched.newPassword ? (
            <ErrorMessage>{errors.newPassword}</ErrorMessage>
          ) : null}
          <InputWrapper>
            <Image src={Password} />
            <Input
              type="password"
              placeholder="Repeat Your Password*"
              name="confirmPassword"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              onClick={() => setError("")}
            />
          </InputWrapper>
          {errors.confirmPassword && touched.confirmPassword ? (
            <ErrorMessage>{errors.confirmPassword}</ErrorMessage>
          ) : null}
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <p>
            {timer === 0 ? (
              <p
                onClick={handleSubmit1}
                style={{
                  justifyContent: "center",
                  padding: "10px",
                  borderRadius: "5px",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Resend OTP
              </p>
            ) : (
              <Timer timer={timer} setTimer={setTimer} />
            )}
          </p>
          {/* <Button1 onClick={handleSubmit}>Resend</Button1> */}
          {/* <Button onClick={handleSubmit}>Submit</Button> */}
          <LoadingButton
            onClick={handleSubmit}
            disabled={isApiResponse}
            loading={isApiResponse}
            variant="contained"
            fullWidth={true}
            size="large"
            color="primary"
          >
            Submit
          </LoadingButton>
        </Form>
      </Container>
    </Root>
  );
}

export default VerifyPassword;
