import React from "react";
import styled from "styled-components";

const Root = styled.div`
  padding-top: 85px;
`;
const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media(max-width:1200px){
    width: 100%;
    padding: 0 25px 0 25px;
  }

  @media (max-width: 768px) {
    width: 100vw;
  }
`;
const TextHeading = styled.div`
  margin-top: 50px;
  font-size: 44px;
  font-weight: bolder;
  color: #000000;

  @media (max-width: 1200px) {
    margin-top: 40px;
  }
  @media (max-width: 768px) {
    margin-top: 10px;
  }
  @media (max-width: 425px) {
    font-size: 2rem;
  }
`;

const Heading = styled.div`
  margin-top: 50px;
  font-size: 24px;
  font-weight: 500;
  @media (max-width: 425px) {
    font-size: 18px;
  }
`;

const Para = styled.div`
  margin-top: 20px;
  font-size: 18px;
  text-align: justify;
  // font-weight: bolder;
  li {
    color: #3e3e3e;
    font-size: 16px;
    padding-top: 10px;
    font-weight: 500;
    text-align: justify;
  }
  @media (max-width: 425px) {
    font-size: 18px;
  }
`;
const NavBarBackground = styled.div`
  width: 100vw;
  height: 84.8px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;
const PrivacyPolicy = ({title}) => {
  document.title = title === undefined ? "" : title
  return (
    <Root style={{marginBottom: '2rem'}}>
      <NavBarBackground></NavBarBackground>
      <Container>
        <TextHeading>Privacy Policy</TextHeading>

        <Heading>Privacy Policy Statement</Heading>
        <Para>
          Your privacy is important to us. You place your trust in us by using
          FloxyPay Travels services, and we value that trust. That means we’re
          committed to protecting and safeguarding your personal data. We act in
          our customers’ best interest, and we are transparent about processing
          your personal data.
        </Para>
        <Para>
          This document (‘this Privacy Statement’ or ‘Our Privacy Statement’)
          describes how we use and process your personal data, provided in a
          readable and transparent manner. It also tells you what rights you can
          exercise in relation to your personal data and how you can contact us.
        </Para>
        <Para>
          FloxyPay Travels offers online travel-related services through our own
          websites and mobile apps, as well as other online platforms such as
          partners’ websites and social media. We’d like to point out that all
          the information you are about to read generally applies to not one,
          not two, but all of these platforms.
        </Para>
        <Para>
          In fact, this single privacy statement applies to any kind of customer
          information we collect through all of the above platforms or by any
          other means connected to these platforms (such as when you contact our
          customer service team by email).
        </Para>
        <Para>
          By accessing our Websites and utilising our services, you acknowledge
          that you have read and understood this Privacy Policy and the
          information collection and handling practices outlined in it.
        </Para>
        <Para>
          We might amend this Privacy Statement from time to time, so we
          recommend you visit this page occasionally to make sure you know where
          you stand. If we make any updates to this Privacy Statement that will
          impact you significantly, we’ll notify you about the changes before
          any new activities begin.
        </Para>

        <Heading>Terms we use in this Privacy Statement</Heading>
        <Para>
          'Trip' means the various different travel products and services that
          can be ordered, acquired, purchased, bought, paid for, rented,
          provided, reserved, combined, or consummated by you from the Trip
          Provider.
        </Para>
        <Para>
          'Trip Provider' means the provider of accommodation (e.g., hotel,
          motel, apartment, bed & breakfast), attractions (e.g. (theme) parks,
          museums, sightseeing tours), transportation provider (e.g., car
          rentals, cruises, rail, airport rides, coach tours, transfers), tour
          operators, travel insurances and any other travel or related product
          or service as from time to time available for Trip Reservation on the
          platform.
        </Para>
        <Para>
          'Trip Service' means the online purchase, order, (facilitated)
          payment, or reservation service as offered or enabled by FloxyPay
          Travels in respect of various products and services as from time to
          time made available by Trip Providers on the platform.
        </Para>
        <Para>
          'Trip Reservation' means the order, purchase, payment, booking, or
          reservation of a Trip.
        </Para>

        <Heading>
          What kind of personal data does FloxyPay Travels collect?
        </Heading>
        <Para>
          We can’t help you book the perfect Trip without information, so when
          you use our services, there are certain things we ask for. This is
          typically routine information – your name, preferred contact details,
          the names of the people travelling with you, and your payment
          information. You might also decide to submit additional information
          related to your upcoming Trip (for example, your anticipated arrival
          time).
        </Para>
        <Para>
          In addition, we collect information from the computer, phone, tablet,
          or other device you use to access our services. This includes the IP
          address, the browser you’re using, and your language settings. There
          are also situations in which we receive information about you from
          others or when we automatically collect other information.
        </Para>
        <Para>Here’s a closer look at what we collect.</Para>

        <Heading>Personal data you give to us.</Heading>
        <Para>
          FloxyPay Travels collects and uses the information you provide to us.
          When you make a Trip Reservation, you are (at a minimum) asked for
          your name and email address.
        </Para>
        <Para>
          Depending on the Trip Reservation, we may also ask for your home
          address, telephone number, payment information, date of birth, current
          location (in the case of on-demand services), the names of the people
          travelling with you, and any preferences you might have for your Trip
          (such as dietary or accessibility requirements). In some cases, you
          may also be able to check-in online with the Trip Provider, for which
          we will ask you to share passport information or a driving licence and
          signatures.
        </Para>
        <Para>
          If you need to get in touch with our customer service team, contact
          your Trip Provider through us, or reach out to us in a different way
          (such as social media or via a chatbot) we’ll collect information from
          you there, too. This applies whether you are contacting us with
          feedback or asking for help using our services.
        </Para>
        <Para>
          You might also be invited to write reviews to help inform others about
          the experiences you had on your Trip. When you write a review on the
          FloxyPay Travels platform, we’ll collect any information you’ve
          included, along with your display name and avatar (if you choose one).
        </Para>
        <Para>
          There are other instances where you’ll provide us with information, as
          well. For example, if you’re browsing with your mobile device, you can
          decide to allow FloxyPay Travels to see your current location or grant
          us access to some contact details. This helps us to give you the best
          possible service and experience by, for example, showing you our city
          guides, suggesting the nearest restaurants or attractions to your
          location, or making other recommendations.
        </Para>
        <Para>
          If you create a user account, we’ll also store your personal settings,
          uploaded photos, and reviews of previous bookings there. This saved
          data can be used to help you plan and manage future Trip Reservations
          or benefit from other features only available to account holders (such
          as incentives or other benefits).
        </Para>
        <Para>
          You can also choose to add details from your identification documents
          to your user accounts so that you don’t have to submit this
          information for each individual Trip Reservation.
        </Para>
        <Para>
          We may offer you referral programs or sweepstakes, and participating
          in these will mean providing us with relevant personal data.
        </Para>

        <Heading>Personal data you give us about others.</Heading>
        <Para>
          Whether or not you end up making a Trip Reservation, when you visit
          our websites or apps we automatically collect certain information.
          This includes your IP address, the date and time you accessed our
          services, and information about your computer’s hardware and software
          (such as the operating system, the internet browser used,
          software/application version data, and your language settings). We
          also collect information about clicks and which pages have been shown
          to you.
        </Para>
        <Para>
          If you’re using a mobile device, we collect data that identifies the
          device, as well as data about your device-specific settings and
          characteristics, app crashes, and other system activity. When you make
          a Trip Reservation using this kind of device, our system registers how
          you made your reservation (on which website), and/or which site you
          came from when you entered the FloxyPay Travels website or app.
        </Para>

        <Heading>Personal data we receive from other sources.</Heading>
        <Para>
          It’s not just the things you tell us, though – we may also receive
          information about you from other sources. These include business
          partners, such as affiliate partners, subsidiaries of the FloxyPay
          Travels corporate group, other companies in the FloxyPay Services
          corporate group, and other independent third parties.
        </Para>
        <Para>
          Anything we receive from these partners may be combined with
          information provided by you. For example, FloxyPay Travels Trip
          Reservation services are not only made available via FloxyPay Travels
          and the FloxyPay Travels apps, but are also integrated into services
          of affiliate partners you can find online. When you use any of these
          services, you provide the reservation details to our business
          partners, who then forward your details to us.
        </Para>
        <Para>
          We also integrate with third-party service providers to facilitate
          payments between you and Trip Providers. These service providers share
          payment information so we can administer and handle your Trip
          Reservation, making sure everything goes as smoothly as possible for
          you.
        </Para>
        <Para>
          Additionally, we collect information in the regrettable case that we
          receive a complaint about you from a Trip Provider, for example, in
          the case of misconduct.
        </Para>
        <Para>
          Another way we might receive data about you is through the
          communication services integrated into our platforms. These
          communication services offer you a way to contact the trip Provider
          you’ve booked with to discuss your stay. In some cases, we receive
          metadata about these communication activities (such as who you are,
          where you called from, and the date and length of the call).
        </Para>
        <Para>
          We may also receive information about you in order to show you more
          relevant ads, such as the additional cookie data FloxyPay Travels
          social media partners make available to us.
        </Para>
        <Para>
          When you link your FloxyPay Travels user account to your social media
          account, you might trigger exchanges of data between FloxyPay Travels
          and that social media provider. You can always choose not to share
          that data.
        </Para>
        <Para>
          Trip Providers may share information about you with FloxyPay Travels,
          too. This could happen if you have support questions about a pending
          Trip Reservation or if disputes or other issues arise about a Trip
          Reservation.
        </Para>

        <Heading>
          How does FloxyPay Travels share your data with third parties?
        </Heading>
        <Para>
          There are different parties integrated into FloxyPay Travels’s
          services in various ways and for various reasons. The primary reason
          we share your data is to supply the Trip Provider with the relevant
          information to complete your Trip Reservation.
        </Para>
        <Para>
          We also involve other parties to provide you with the FloxyPay Travels
          services. This includes, for example, financial institutions,
          advertisers, subsidiaries of the FloxyPay Travels corporate group, and
          the other companies that form the FloxyPay Service Provider Ltd. Or,
          in some cases, if we’re required to by law, we might share your data
          with governmental or other authorities.
        </Para>
        <Para>
          In certain circumstances, we’ll share your personal data with third
          parties. These third parties include:
        </Para>

        <Heading>The Trip Provider you booked:</Heading>
        <Para>
          In order to complete your Trip Reservation, we transfer relevant
          reservation details to the Trip Provider you have booked. This is one
          of the most essential things we do for you.
        </Para>
        <Para>
          Depending on the Trip Reservation and the Trip Provider, the details
          we share can include your name, contact, and payment details, the
          names of the people accompanying you, and any other information or
          preferences you specified when you made your Trip Reservation.
        </Para>
        <Para>
          In certain cases, we also provide some additional historical
          information about you to the Trip Provider. This includes whether
          you’ve already booked with them in the past, the number of completed
          bookings you’ve made with FloxyPay Travels, a confirmation that no
          misconduct has been reported about you, the percentage of bookings
          you’ve cancelled in the past, or whether you’ve given reviews about
          past bookings.
        </Para>
        <Para>
          If you have a query about your Trip, we may contact the Trip Provider
          to handle your request. Unless payment is made during the booking
          process via the FloxyPay Travels website, we will forward your credit
          card details to the booked Trip Provider for further handling
          (assuming you’ve provided us with those details).
        </Para>
        <Para>
          In cases of Trip Reservation-related claims or disputes, we may
          provide the Trip Provider with your contact details and other
          information about the booking process, as needed to resolve the
          situation. This can include, but might not be limited to, your email
          address and a copy of your reservation confirmation as proof that a
          Trip Reservation was made or to confirm reasons for cancellation.
        </Para>
        <Para>
          For completeness, Trip Providers will further process your personal
          data outside of the control of FloxyPay Travels. Trip Providers may
          also ask for additional personal data, for instance, to provide
          additional services or to comply with local restrictions. If
          available, please read the Privacy Statement of the Trip Provider to
          understand how they process your personal data.
        </Para>

        <Heading>Connectivity Providers:</Heading>
        <Para>
          Please note that certain Trip Providers may need us to share your
          personal data with a contracted Connectivity Provider in order to be
          able to finalise and administer your reservation. Connectivity
          providers act on Trip Providers' behalf and help them manage their
          reservations.
        </Para>

        <Heading>Your local FloxyPay Travels office:</Heading>
        <Para>
          To support the use of FloxyPay Travels services, your details may be
          shared with subsidiaries of the FloxyPay Travels corporate group,
          including for customer service. To find out more about the FloxyPay
          Travels corporate group, visit About FloxyPay Travels.
        </Para>
        <Para>
          Third-party service providers: We use service providers from outside
          of the FloxyPay Travels corporate group to support us in providing our
          services. These include:
          <ul>
            <li>Customer support</li>
            <li>Market research</li>
            <li>
              Fraud detection and prevention (including anti-fraud screening)
            </li>
            <li>Insurance claims</li>
            <li>Payment</li>
          </ul>
        </Para>
        <Para>
          We use third parties to process payments, handle chargebacks or
          provide billing collection services. When a chargeback is requested
          for your Trip Reservation, either by you or by the holder of the
          credit card used to make your reservation, we need to share certain
          reservation details with the payment service provider and the relevant
          financial institution so they can handle the chargeback. This may also
          include a copy of your reservation confirmation or the IP address used
          to make your reservation. We may share information with relevant
          financial institutions, if we consider it strictly necessary for fraud
          detection and prevention purposes.
        </Para>
        <Para>
          When a chargeback is requested for your Trip Reservation, either by
          you or by the holder of the credit card used to make your reservation,
          we need to share certain reservation details with the payment service
          provider and the relevant financial institution so they can handle the
          chargeback. This may also include a copy of your reservation
          confirmation or the IP address used to make your reservation.
        </Para>
        <Para>
          We may also share information with relevant financial institutions if
          we consider it strictly necessary for fraud detection and prevention
          purposes.
        </Para>

        <Heading>Marketing services</Heading>
        <Para>
          We share personal data with advertising partners, including your email
          address, IP address, and telephone number, as part of marketing
          FloxyPay Travels services via third parties (to ensure that relevant
          advertisements are shown to the right audience). We use techniques
          such as hashing to enable the matching of your email address, IP
          address, and/or telephone number with an existing customer database to
          prevent this data from being used for other purposes.
        </Para>

        <Heading>Advertising partners</Heading>
        <Para>
          We use advertising partners to allow you to compare our offers with
          offers from other Online Travel Agencies (OTAs). When you make a
          reservation on FloxyPay Travels after using an advertising partner, we
          will send the details of the reservation that you made on FloxyPay
          Travels to that partner.
        </Para>

        <Heading>Other professional third parties:</Heading>
        <Para>
          In certain cases (such as disputes or legal claims or as part of
          auditing activities), we may need to share your personal data with
          professional advisors. These advisors include parties such as law
          firms or auditors. We only share your personal data to the extent that
          is necessary, and such third parties process this data in line with
          their own professional obligations.
        </Para>

        <Heading>Competent authorities:</Heading>
        <Para>
          We disclose personal data to law enforcement to the extent that it is
          required by law or is strictly necessary for the prevention,
          detection, or prosecution of criminal acts and fraud or if we are
          otherwise legally obligated to do so. We may need to further disclose
          personal data to competent authorities to comply with a legal
          obligation (for instance, under short-term rental laws) to protect and
          defend our rights or properties or the rights and properties of our
          business partners.
        </Para>

        <Heading>Business partners:</Heading>
        <Para>
          We work with many business partners around the world. These business
          partners distribute and advertise FloxyPay Travels services, including
          the services and products of our Trip Providers.
        </Para>
        <Para>
          When you make a reservation on one of our business partners’ websites
          or apps, certain personal data that you give them, such as your name
          and email address, your address, payment details, and other relevant
          information, will be forwarded to us to finalise and manage your Trip
          Reservation.
        </Para>
        <Para>
          If the business partner provides customer service, FloxyPay Travels
          will share relevant reservation details with them (as and when needed)
          in order to provide you with appropriate and efficient support.
        </Para>
        <Para>
          When you make a reservation through one of our business partner’s
          websites, the business partners can receive certain parts of your
          personal data related to the specific reservation and your
          interactions on these partner websites. This is for their commercial
          purposes.
        </Para>
        <Para>
          When you make a reservation on a business partner’s website, please
          also take the time to read their privacy notice if you’d like to
          understand how they process your personal data.
        </Para>
        <Para>
          For fraud detection and prevention purposes, we may also exchange
          information about our users with business partners – but only when
          strictly necessary.
        </Para>
        <Para>
          If an insurance claim is made concerning you and a Trip Provider, we
          may provide the necessary data (including personal data) to the
          insurance company for further processing.
        </Para>

        <Heading>Partner Offer:</Heading>
        <Para>
          We may present you with a ‘Partner Offer’. When you book a stay marked
          ‘Partner Offer’, your reservation will be facilitated by a Trip
          Provider who is separate from the accommodation you’re booking. As
          part of the reservation process, we’ll need to share some relevant
          personal data with this business partner.
        </Para>
        <Para>
          If you book a Partner Offer, please review the information provided in
          the booking process or check your reservation confirmation for more
          information about the Trip Provider and how they will further process
          your personal data.
        </Para>
        <Para>
          FloxyPay Travels is a global business. The data that we collect from
          you, as described in this Privacy Statement, could be made accessible
          from, transferred to, or stored in countries that may not have the
          same data protection laws as the country in which you initially
          provided the information. In such cases, we will protect your data as
          described in this Privacy Statement.
        </Para>
        <Para>
          This may also be applicable if you are in the European Economic Area
          (EEA). Countries your data may be transferred to may not have laws
          that provide the same level of protection for your personal data as
          laws within the EEA. Where this is the case, we will put appropriate
          safeguards in place to make sure that these transfers comply with
          European privacy law.
        </Para>
        <Para>
          In particular, when your data is transferred to third-party service
          providers, we establish and implement appropriate contractual,
          organisational, and technical measures with them. This is done by
          putting in place Standard Contractual Clauses as approved by the
          European Commission, examining the countries to which the data may be
          transferred, and imposing specific technical and organisational
          security measures.
        </Para>
        <Para>
          In certain specific cases, we transfer your data outside the EEA
          because it is in your interest or is necessary to conclude or perform
          the contract we have with you. For instance, when you make a
          reservation on FloxyPay Travels or through a business partner, we
          might need to transfer your data to a Trip Provider or business
          partner who is located outside the EEA.
        </Para>
        <Para>
          You can ask us to see a copy of our implemented safeguards (where
          possible) by contacting us at @floxyay.com.
        </Para>

        <Heading>
          How is your personal data shared within the FloxyPay Services
          corporate group?
        </Heading>
        <Para>
          FloxyPay Travels is part of the Floxypay Services Provider Ltd., and
          we may receive personal data about you from other companies in the
          FloxyPay Services corporate group or share your personal data with
          them for the following purposes:
          <ul>
            <li>
              To provide services (including making, administering, and managing
              reservations or handling payments)
            </li>
            <li>To provide customer service</li>
            <li>
              To detect, prevent and investigate fraudulent, other illegal
              activities and data breaches
            </li>
            <li>For analytical and product improvement purposes</li>
            <li>
              To provide personalised offers or send you marketing with your
              consent or as otherwise permitted by applicable law
            </li>
            <li>
              For hosting, technical support, overall maintenance, and
              maintaining the security of such shared data
            </li>
            <li>To ensure compliance with applicable laws.</li>
          </ul>
        </Para>
        <Para>
          As applicable and unless indicated otherwise, for purposes A to F,
          FloxyPay Travels relies on its legitimate interests to share and
          receive personal data. For purpose G, FloxyPay Travels relies, where
          applicable, on compliance with legal obligations (such as lawful law
          enforcement requests).
        </Para>

        <Heading>
          How does FloxyPay Travels process communications that you and your
          Trip Provider may send via FloxyPay Travels?
        </Heading>
        <Para>
          How does FloxyPay Travels process communications that you and your
          Trip Provider may send via FloxyPay Travels?
        </Para>
        <Para>
          FloxyPay Travels can offer you and Trip Providers various ways to
          communicate about the Trip Services and existing Trip Reservations,
          directing the communications via FloxyPay Travels. This also allows
          you and your Trip Provider to contact FloxyPay Travels with questions
          about your Trip Reservation through the website, our apps, and the
          other channels that we make available.
        </Para>
        <Para>
          FloxyPay Travels accesses communications and may use automated systems
          to review, scan, and analyse communications for the following reasons:
          <ul>
            <li>Security purposes</li>
            <li>Fraud prevention</li>
            <li>Compliance with legal and regulatory requirements</li>
            <li>Investigations of potential misconduct</li>
            <li>Product development and improvement</li>
            <li>Research</li>
            <li>
              Customer engagement (includes providing you with information and
              offers that we believe may be of interest to you)
            </li>
            <li>Customer or technical support</li>
          </ul>
        </Para>
        <Para>
          We reserve the right to review or block the delivery of communications
          that we, in our sole discretion, believe might contain malicious
          content or spam, or pose a risk to you, Trip Providers, FloxyPay
          Travels, or others.
        </Para>
        <Para>
          All communications sent or received using FloxyPay Travels
          communication tools will be received and stored by FloxyPay Travels.
          Trip Providers and Business partners you’ve booked a Trip Reservation
          through might also choose to communicate with you directly by email or
          through other channels that FloxyPay Travels does not control.
        </Para>

        <Heading>How does FloxyPay Travels make use of mobile devices?</Heading>
        <Para>
          We offer free apps, through which we also collect and process personal
          data. This works in much the same way as our website, but they also
          allow you to benefit from the location services available on your
          mobile device(s).
        </Para>
        <Para>
          We offer free apps for a range of different mobile devices, as well as
          versions of our regular website that have been optimised for browsing
          on a mobile and tablet.
        </Para>
        <Para>
          These apps and mobile websites process the personal details you give
          us in much the same way as our website does. They also allow you to
          use location services to find Trip Services nearby, if you want to.
        </Para>
        <Para>
          With your consent, we may send you push notifications with information
          about your Trip Reservation. You can also choose to grant us access to
          your location data or contact details in order to provide the services
          you’ve requested. If you upload pictures to our platform, these
          pictures may also include location information (known as metadata).
          Please read your mobile device’s instructions to understand how to
          change your settings and control the sharing of this category of data.
        </Para>
        <Para>
          When you choose to use our ‘Voice Assistant’ to search for our
          services or manage your bookings, your speech will be anonymously
          translated into text by a third-party service provider. You will need
          to give us access to your device's microphone to use this feature.
        </Para>
        <Para>
          In order to optimise our services and marketing activities and to make
          sure we give you a consistent user experience, we use something known
          as ‘cross-device tracking’. This can be done with or without the use
          of cookies. For more general information about cookies and other
          similar technologies, please see our Cookie statement.
        </Para>
        <Para>
          With cross-device tracking, FloxyPay Travels is able to track user
          activity across multiple devices. As part of cross-device tracking, we
          may combine data collected from a particular browser or mobile device
          with data from another computer or device used by the same customer.
        </Para>
        <Para>
          Personalised ads shown to you on other websites or in apps can be
          offered based on your activities on linked computers and devices. By
          changing the cookie settings on your device, you can change your
          cross-device tracking settings for advertisement purposes. You should
          know that logging out of your FloxyPay Travels account doesn’t mean
          that you will no longer receive personalised ads.
        </Para>

        <Heading>How does FloxyPay Travels make use of social media?</Heading>
        <Para>
          The use of social media may be integrated into FloxyPay Travels
          services in various ways. These will involve us collecting some of
          your personal data or the social media provider receiving some of your
          information. If you’d like to learn more about how this information is
          used and exchanged, read on.
        </Para>
        <Para>
          At FloxyPay Travels, we use social media to facilitate the use of
          online reservation services but also to promote our Trip Providers’
          travel-related products and services and to advertise, improve and
          facilitate our own services.
        </Para>
        <Para>
          We may integrate other social media services (like social media
          messaging) for you to interact with FloxyPay Travels or with your
          contacts about our services.
        </Para>
        <Para>
          We may maintain social media accounts and offer apps on several social
          media sites. Whenever you connect with FloxyPay through social media,
          your social media service provider may allow you to share information
          with us.
        </Para>
        <Para>
          If you choose to share, your social media provider will generally
          inform you about which information will be shared. For example, when
          you sign into a FloxyPay user account using your social media account,
          certain information from that account may be shared with FloxyPay
          Travels. This includes your email address, your age, and the profile
          pictures you’ve saved – depending on what you authorise in your social
          media account.
        </Para>
        <Para>
          When you register with a FloxyPay social media app or connect to a
          social media messaging service without a FloxyPay user account, the
          information you choose to share with us may include the basic
          information available in your social media profile (including your
          email address, status updates and a list of your contacts).
        </Para>
        <Para>
          We’ll use this information to help provide you with the service you
          requested – for example, to forward a message you want to send to your
          contacts or to create a personalised user experience in the app or on
          our websites. It means that if you want us to, we can tailor our
          services to suit your needs, connecting you and your friends with the
          best travel destinations and analysing and improving our
          travel-related services.
        </Para>
        <Para>
          Your social media provider will be able to tell you more about how
          they use and process your data when you connect to FloxyPay Travels
          through them. This can include combining the personal data they
          collect when you use FloxyPay through them with the information they
          collect when you use other online platforms you have also linked to
          your social media account.
        </Para>

        <Heading>
          What security and retention procedures does FloxyPay Travels put in
          place to safeguard your personal data?
        </Heading>
        <Para>
          We have implemented a range of procedures to prevent unauthorised
          access to, and the misuse of, personal data that we process.
        </Para>
        <Para>
          We have procedures in place to prevent unauthorised access to, and the
          misuse of, personal data.
        </Para>
        <Para>
          We use appropriate business systems and procedures to protect and
          safeguard your personal data. We also use security procedures and
          technical and physical restrictions for accessing and using personal
          data on our servers. Only authorised personnel are permitted to access
          personal data in the course of their work.
        </Para>
        <Para>
          We’ll keep your personal data for as long as is necessary to enable
          you to use our services or to provide our services to you, to comply
          with applicable laws, resolve any disputes, and otherwise allow us to
          conduct our business, including to detect and prevent fraud and/or
          other illegal activities. All personal data we keep about you as a
          FloxyPay Travels customer is covered by this Privacy Statement.
        </Para>
        <Para>
          For added protection, we strongly recommend that you set up two-factor
          authentication for your FloxyPay user account. This adds an extra
          authentication step, to make sure that anyone who gets hold of your
          username and password (e.g. through phishing or social engineering)
          won’t be able to get into your account. You can set this up in the
          Security section of your account settings.
        </Para>

        <Heading>
          How does FloxyPay Travels treat personal data belonging to children?
        </Heading>
        <Para>
          Unless indicated otherwise, FloxyPay Travels is a service you are only
          allowed to use if you are over 16 years of age. We only process
          information about children with the consent of their parents or legal
          guardians, or when the information is shared with us by the parents or
          legal guardians themselves.
        </Para>
        <Para>
          Our services aren’t intended for children under 16 years old, and
          we’ll never collect their data unless it’s provided by (and with the
          consent of) a parent or guardian. The limited circumstances we might
          need to collect the personal data of children under 16 years old
          include: as part of a reservation, the purchase of other
          travel-related services, or other exceptional circumstances (such as
          features addressed to families). Again, this will only be used and
          collected as provided by a parent or guardian and with their consent.
        </Para>
        <Para>
          If we become aware that we’ve processed the information of a child
          under 16 years old without the valid consent of a parent or guardian,
          we will delete it.
        </Para>

        <Heading>
          How can you control the personal data you’ve given to FloxyPay
          Travels?
        </Heading>
        <Para>
          Among others, you have the right to review the personal data we keep
          about you at any time and request access to or deletion of your
          personal data by submitting this form. If you want to find out more
          about your rights to control your personal data, read on.
        </Para>
        <Para>
          We want you to be in control of how your personal data is used by us.
          You can do this in the following ways:
          <ul>
            <li>
              You can ask us for a copy of the personal data we hold about you,
            </li>
            <li>
              You can inform us of any changes to your personal data, or you can
              ask us to correct any of the personal data we hold about you. As
              explained below, you can make some of these changes yourself,
              online, when you have a user account.
            </li>
            <li>
              In certain situations, you can ask us to erase, block, or restrict
              the processing of the personal data we hold about you, or object
              to particular ways in which we are using your personal data.
            </li>
            <li>
              In certain situations, you can also ask us to send the personal
              data you have given us to a third party.
            </li>
          </ul>
        </Para>
        <Para>
          Where we are using your personal data on the basis of your consent,
          you are entitled to withdraw that consent at any time subject to
          applicable law, and
        </Para>
        <Para>
          Where we process your personal data based on legitimate interest or
          the public interest, you have the right to object to that use of your
          personal data at any time, subject to applicable law.
        </Para>
        <Para>
          We rely on you to make sure that your personal information is
          complete, accurate, and current. Please do let us know about any
          changes to, or inaccuracies in, your personal information as soon as
          possible.
        </Para>
        <Para>
          If you have a FloxyPay user account, you can access a lot of your
          personal data through our website or apps. You’ll generally find the
          option to add, update or remove information we have about you in your
          account settings.
        </Para>
        <Para>
          If any of the personal data we have about you isn’t accessible through
          our website or apps, you can send us a request.
        </Para>
        <Para>
          If you want to exercise your right of access or erasure, all you need
          to do is complete and submit the Data Subject Request for FloxyPay
          Travels Customers form. For any requests relating to this Privacy
          Statement, to exercise any of your other rights, or if you have a
          complaint, please contact our Data Protection Officer at @floxyay.com.
          You can also contact your local data protection authority.
        </Para>
        <Para>
          If you’d like to object to your personal data being processed on the
          basis of legitimate interest and there’s no way to opt out directly,
          please contact us at @floxyay.com.
        </Para>
        <Para>
          If you’d like to contact us by post, please address it to the Data
          Protection Officer and use the following postal address:
          __________________
        </Para>

        <Heading>
          Who is responsible for the processing of personal data on the FloxyPay
          Travels website and apps?
        </Heading>
        <Para>
          FloxyPayServices Provider Ltd. controls the processing of personal
          data for the provision of its services. That includes its websites and
          mobile apps, except for some exceptions that are clarified in this
          privacy statement.
        </Para>
        <Para>
          FloxyPay Travels controls the processing of personal data, as
          described in this Privacy Statement, except where explicitly stated
          otherwise. FloxyPay Travels is a private limited liability company,
          incorporated under the laws of the Netherlands, and has its offices at
          Herengracht 597, 1017 CE Amsterdam, the Netherlands.
        </Para>
        <Para>
          If you have any questions about this Privacy Statement or about our
          processing of your personal data, please contact our Data Protection
          Officer at @floxyay.com, and we’ll get back to you as soon as
          possible.
        </Para>
        <Para>
          For questions about a reservation, please contact our customer service
          team through the customer service contact page.
        </Para>
        <Para>
          Requests from law enforcement should be submitted using the Law
          Enforcement process.
        </Para>

        <Heading>Country-specific provisions</Heading>
        <Para>
          Depending on the law that applies to you, we may be required to
          provide some additional information. If applicable, you will find
          additional information for your country or region below.
        </Para>

        <Heading>
          Why does FloxyPay Travels collect and use your personal data?
        </Heading>
        <Para>
          We ask you for personal details to help you organise your online Trip
          Reservations and ensure you get the best service possible.
        </Para>
        <Para>
          We also use your personal data to contact you about the latest deals,
          special offers, and other products or services you might be interested
          in. There are other uses, too. If you’d like to find out what they
          are, read on for a more detailed explanation.
        </Para>
        <Para>
          We use the information collected about you for a variety of purposes.
          Your personal data may be used in the following ways:
        </Para>
        <Para>
          Trip Reservations: First and foremost, we use your personal data to
          complete and administer your online Trip Reservation – which is
          essential for us to provide this service for you. This includes
          sending you communications that relate to your Trip Reservation, such
          as confirmations (including, where applicable, providing you with
          proof of purchase and/or payment), modifications, and reminders. In
          some cases, this may also include processing your personal data to
          enable online check-in with the Trip Provider or processing personal
          data in relation to possible damage deposits.
        </Para>
        <Para>
          Customer service: We provide international customer service from our
          local offices in more than 20 languages, and we’re here to help 24
          hours a day, 7 days a week. Sharing relevant details, such as
          reservation information or information about your user account, with
          our global customer service staff allows us to respond when you need
          us. This includes helping you to contact the right Trip Provider and
          responding to any questions you might have about your Trip Reservation
          (or any other queries, for that matter).
        </Para>

        <Heading>Account facilities:</Heading>
        <Para>
          FloxyPay Travels users can create an account on our website or apps.
          We use the information you give us to administer this account,
          allowing you to do a number of useful things. You can manage your Trip
          Reservations, take advantage of special offers, make future Trip
          Reservations easily, and manage your personal settings.
        </Para>
        <Para>
          Managing personal settings gives you the ability to keep and share
          lists, share photos, easily see Trip Services you’ve searched for, and
          check travel-related information you’ve provided. You can also see any
          reviews you’ve written.
        </Para>
        <Para>
          If you want to, you can share certain information as part of your user
          account, by creating a public profile under your own first name or a
          screen name you choose.
        </Para>
        <Para>
          If you’re a FloxyPay Travels for Business account holder, you can also
          save contact details under that account, manage business reservations
          and link other account holders to the same FloxyPay Travels for
          Business account.
        </Para>

        <Heading>Online groups:</Heading>
        <Para>
          We give account holders a chance to connect and interact with each
          other through online groups or forums, such as travel communities.
        </Para>

        <Heading>Marketing activities:</Heading>
        <Para>
          We use your information for marketing activities. These activities
          include:
        </Para>
        <Para>
          Using your contact information to send you regular news about
          travel-related products and services. You can unsubscribe from email
          marketing communications quickly, easily, and at any time. All you
          need to do is click on the ‘Unsubscribe’ link included in each
          newsletter or other communication or manage your preferences via your
          account settings.
        </Para>
        <Para>
          Based on your information, individualised offers might be shown to you
          on the FloxyPay Travels website, in mobile apps, or on third-party
          websites/apps (including social media sites), and the content of the
          site displayed to you might be personalised. These could be offers you
          can book directly on the FloxyPay Travels website, co-branded sites,
          or other third-party offers or products we think you might find
          interesting.
        </Para>
        <Para>
          When you participate in other promotional activities (such as
          sweepstakes, referral programmes or competitions), only relevant
          information will be used to administer these promotions.
        </Para>

        <Heading>Communicating with you:</Heading>
        <Para>
          There might be other times when we get in touch, including by email,
          by chatbot, by post, by phone, or by texting you. Which method we
          choose depends on the contact information you’ve previously shared.
        </Para>
        <Para>
          We process the communications you send to us. There could be a number
          of reasons for this, including:
          <ul>
            <li>
              Responding to and handling any requests you or your booked Trip
              Provider have made. FloxyPay Travels also offers customers and
              Trip Providers various ways to exchange information, requests, and
              comments about Trip Providers and existing Trip Reservations via
              FloxyPay Travels. For more information, read the section titled
              ‘How does FloxyPay Travels process communications that you and
              your Trip Provider send through FloxyPay Travels?’.
            </li>
            <li>
              If you have started but not finished a Trip Reservation online, we
              might contact you to invite you to continue with your reservation.
              We believe that this additional service benefits you as it allows
              you to pick up the process where you left off without having to
              search for a Trip Provider or fill in your reservation details
              again.
            </li>
            <li>
              3. When you use our services, we might send you a questionnaire or
              invite you to provide a review about your experience with FloxyPay
              Travels or the Trip Provider.
            </li>
            <li>
              4. We also send you other material related to your Trip
              Reservations, such as how to contact FloxyPay Travels if you need
              assistance while you’re away, and information that we feel might
              be useful to you in planning or getting the best out of your Trip.
              We might also send you material related to upcoming Trip
              Reservations or a summary of previous Trip Reservations you made
              through FloxyPay Travels.
            </li>
            <li>
              5. Even if you don’t have an upcoming Trip Reservation, we may
              still need to send you other administrative messages, which could
              include security alerts.
            </li>
            <li>
              6. In case of misconduct, we may send you a notice and/or warning.
            </li>
          </ul>
        </Para>

        <Heading>Market research:</Heading>
        <Para>
          We sometimes invite our customers to take part in market research.
          Please see the information that accompanies this kind of invitation to
          understand what personal data will be collected and how that data will
          be used.
        </Para>

        <Heading>Improving our services:</Heading>
        <Para>
          We also use personal data for analytical purposes and product
          improvement. This is part of our commitment to making our services
          better and enhancing the user experience.
        </Para>
        <Para>
          In this case, we use data for testing and troubleshooting purposes, as
          well as to generate statistics about our business. The main goal here
          is to get insights into how our services perform, and how they are
          used, and ultimately to optimise and customise our website and apps,
          making them easier and more meaningful to use. As much as possible, we
          strive to use anonymised and de-identified personal data for this
          analytical work.
        </Para>
        <Para>
          Providing the best price applicable to you, depending on where you are
          based: When you search our apps or website, for example, to find
          accommodation or a flight, we process your IP address to confirm
          whether you are in the European Economic Area (EEA) or in another
          country. We do this to offer you the best price for the region (EEA)
          or country (non-EEA) where you are based.
        </Para>
        <Para>
          Customer reviews and other destination-related information: During and
          after your Trip, we might invite you to submit a review. We can also
          make it possible for the people you’re travelling with or whom you’ve
          booked a reservation for to do this instead. This invite asks for
          information about the Trip Provider or the destination.
        </Para>
        <Para>
          If you have a FloxyPay Travels account, you can choose to display a
          screen name next to your review instead of your real name. If you’d
          like to set a screen name, you can do that in your account settings.
          Adding an avatar is also possible.
        </Para>
        <Para>
          By completing a review, you agree that it can be displayed (as
          described in detail in our Terms and Conditions) on, for example, the
          relevant Trip Provider information page on our websites, on our mobile
          apps, on our social media accounts, and social media apps, or on the
          online platform of the relevant Trip Provider or business partner’s
          website. This is to inform other travellers about the quality of the
          Trip Service you used, the destination you have chosen, or any other
          experiences you choose to share.
        </Para>

        <Heading>Call monitoring:</Heading>
        <Para>
          When you make calls to our customer service team, FloxyPay Travels
          uses an automated telephone number detection system to match your
          telephone number to your existing reservations. This can help save
          time for both you and our customer service staff. However, our
          customer service staff may still ask for authentication, which helps
          to keep your reservation details confidential.
        </Para>
        <Para>
          During calls with our customer service team, live listening might be
          carried out, or calls might be recorded for quality control and
          training purposes. This includes the usage of the recordings for the
          handling of complaints, legal claims, and for fraud detection.
        </Para>
        <Para>
          We do not record all calls. In the case that a call is recorded, each
          recording is kept for a limited amount of time before being
          automatically deleted. This is unless we have determined that it’s
          necessary to keep the recording for fraud investigation or legal
          purposes. You can read more about this below.
        </Para>

        <Heading>Promotion of a safe and trustworthy service:</Heading>
        <Para>
          To create a trustworthy environment for you, the people you bring with
          you on your Trip, FloxyPay Travels’s business partners, and our Trip
          Providers, we continuously analyse and use certain personal data to
          detect and prevent fraud and other illegal or unwanted activities.
        </Para>
        <Para>
          Similarly, we use personal data for risk assessment and security
          purposes, including when you report a safety concern, or for the
          authentication of users and reservations. When we do this, we may have
          to stop or put certain Trip Reservations on hold until we’ve finished
          our assessment.
        </Para>

        <Heading>Legal purposes:</Heading>
        <Para>
          Finally, in certain cases, we may need to use your information to
          handle and resolve legal claims and disputes, for regulatory
          investigations and compliance, to enforce the FloxyPay Travels online
          reservation service terms of use, or to comply with lawful requests
          from law enforcement.
        </Para>
        <Para>
          Providing your personal data to FloxyPay Travels is voluntary.
          However, we may only be able to provide you with certain services if
          we can collect some personal data. For instance, we can’t process your
          Trip Reservation if we don’t collect your name and contact details.
        </Para>
        <Para>
          If we use automation to process personal data which produces legal
          effects or similarly significantly affects you, we’ll always implement
          the necessary measures to safeguard your rights and freedoms. This
          includes the right to obtain human intervention.
        </Para>
        <Para>
          To process your personal data as described above, we rely on the
          following legal bases: As applicable, for purposes A and B, FloxyPay
          Travels relies on the legal basis that the processing of personal data
          is necessary for the performance of a contract, specifically to
          finalise and administer your Trip Reservation.
        </Para>
        <Para>
          If the required personal data is not provided, FloxyPay Travels cannot
          finalise the Trip Reservation, nor can we provide customer service. In
          view of purposes C to L, FloxyPay Travels relies on its (or third
          parties’) legitimate interest to provide and improve services and to
          prevent fraud and other illegal acts (as set out more specifically
          under C to L).
        </Para>
        <Para>
          When using personal data to serve FloxyPay Travels’s or a third
          party's legitimate interest, FloxyPay Travels will always balance your
          rights and interests in the protection of your personal data against
          FloxyPay Travels’s rights and interests or those of the third party.
          For purposes M, FloxyPay Travels also relies, where applicable, on
          compliance with legal obligations (such as lawful law enforcement
          requests).
        </Para>
        <Para>
          Finally, where needed under applicable law, FloxyPay Travels will
          obtain your consent prior to processing your personal data, including
          for email marketing purposes or as otherwise required by law.
        </Para>
        <Para>
          If you wish to object to the processing set out under C to L and no
          opt-out mechanism is available to you directly (for example, in your
          account settings), please contact us at @floxyay.com
        </Para>

        <Heading>Cookie statement</Heading>
        <Para>
          Whenever you use our online services or apps, we use cookies and other
          online tracking technologies (which we’ll also refer to as 'cookies'
          for the purpose of this Cookie Statement).
        </Para>
        <Para>
          Cookies can be used in various ways, including to make the FloxyPay
          Travels website work, to analyse traffic, or for advertising purposes.
        </Para>
        <Para>
          Read on below to learn more about what a ‘cookie’ is, how they’re
          used, and what your choices are.
        </Para>

        <Heading>What are cookies and online tracking technologies?</Heading>
        <Para>
          A web browser cookie is a small text file that websites place on your
          computer’s or mobile device’s web browser.
        </Para>
        <Para>
          These cookies store information about the content you view and
          interact with, in order to remember your preferences and settings or
          analyse how you use online services.
        </Para>
        <Para>
          Cookies are divided into ‘first party’ and ‘third party’:
          <ul>
            <li>
              First party cookies are the cookies served by the owner of the
              domain – in our case, that’s FloxyPay Travels. Any cookie we place
              ourselves in is a ‘first party cookie’.
            </li>
            <li>
              Third-party cookies are cookies placed on our domains by trusted
              partners that we’ve chosen to allow to do so. These can be social
              media partners, advertising partners, security providers, and
              more.
            </li>
          </ul>
        </Para>
        <Para>
          And they can be either ‘session cookies’ or ‘permanent cookies’:
        </Para>
        <Para>
          Session cookies only exist until you close your browser, ending what
          is called your ‘session’. They are then deleted.
        </Para>
        <Para>
          Permanent cookies have a range of different lifespans and stay on your
          device after the browser is closed. On the FloxyPay Travels platform,
          we try to only serve permanent cookies (or allow permanent cookies to
          be served by third parties) that have a limited lifespan. However, for
          security reasons, or in other exceptional circumstances, we might
          sometimes need to give a cookie a longer lifespan.
        </Para>
        <Para>
          Web browser cookies may store information such as your IP address or
          another identifier, your browser type, and information about the
          content you view and interact with on digital services. By storing
          this information, web browser cookies can remember your preferences
          and settings for online services and analyse how you use them.
        </Para>
        <Para>
          Alongside cookies, we also use tracking technologies that are very
          similar. Our website, emails, and mobile apps may contain small
          transparent image files or lines of code that record how you interact
          with them. These include ‘web beacons’, ‘scripts’, ‘tracking URLs’, or
          ‘software development kits’ (known as SDKs). These beacons are tiny
          graphic images of just one pixel that can be delivered to your device
          as part of a web page request, in an app, an advertisement, or an HTML
          email message.
        </Para>
        <Para>
          They can be used to retrieve information from your device, such as
          your device type or operating system, your IP address, and the time of
          your visit. They are also used to serve and read cookies in your
          browser or to trigger the placement of a cookie.
        </Para>
        <Para>
          Scripts are small computer programs embedded within our web pages that
          give those pages a wide variety of extra functionality. Scripts make
          it possible for the website to function properly. For example, scripts
          power certain security features and enable basic interactive features
          on our website.
        </Para>
        <Para>
          Scripts can also be used for analytical or advertising purposes. For
          example, a script can collect information about how you use our
          website, such as which pages you visit or what you search for.
        </Para>
        <Para>
          Tracking URLs are links with a unique identifier in them. These are
          used to track which website brought you to the FloxyPay Travels
          website or app you’re using. An example would be if you click through
          from a social media page, search engine, or one of our affiliate
          partners’ websites.
        </Para>
        <Para>
          Software Development Kits (SDKs) are part of our apps’ source code,
          and unlike browser cookies, SDK data is stored in the app storage.
        </Para>
        <Para>
          They’re used to analyse how the apps are being used or to send
          personalised push notifications. To do this, they record unique
          identifiers associated with your device, like device ID and IP
          address, as well as your in-app activity and your network location.
        </Para>
        <Para>
          All these tracking technologies are referred to as ‘cookies’ here in
          this Cookie Statement.
        </Para>

        <Heading>How are cookies used?</Heading>
        <Para>
          Cookies are used to collect information, including:
          <ul>
            <li>IP address</li>
            <li>Device ID</li>
            <li>Viewed pages</li>
            <li>Browser type</li>
            <li>Browsing information</li>
            <li>Operating system</li>
            <li>Internet service provider</li>
            <li>Timestamp</li>
            <li>Whether you have responded to an advertisement</li>
            <li>A referring URL</li>
          </ul>
        </Para>
        <Para>Features used or activities engaged in on the website/apps</Para>
        <Para>
          They allow you to be recognised as the same user across the pages of a
          website, across devices, between websites or when you use our apps.
          When it comes to purpose, they are divided into three categories –
          functional cookies, analytical cookies, and marketing cookies.
        </Para>

        <Heading>Functional cookies</Heading>
        <Para>
          These are cookies required for our websites and apps to function, and
          they must be enabled in order for you to use our services.
        </Para>
        <Para>
          Functional cookies are used to create technologically advanced,
          user-friendly websites and apps that adapt automatically to your needs
          and preferences, so you can browse and book easily. This also includes
          enabling essential security and accessibility features.
        </Para>
        <Para>More specifically, these cookies:</Para>
        <Para>
          Enable our website and apps to work properly, so you can create an
          account, sign in, and manage your bookings.
        </Para>
        <Para>
          Remember your selected currency and language settings, your past
          searches, and other preferences to help you use our website and apps
          efficiently and effectively.
        </Para>
        <Para>
          Remember your registration information, so that you don’t have to
          retype your login credentials each time you visit our website or app.
          (Don’t worry, passwords will always be encrypted.)
        </Para>

        <Heading>Analytical cookies</Heading>
        <Para>
          These cookies measure and track how our website and apps are used. We
          use this information to improve our website, apps, and services.
        </Para>
        <Para>More specifically, these cookies:</Para>
        <Para>
          Help us understand how visitors and customers like you use FloxyPay
          Travels and our apps.
        </Para>
        <Para>
          Help improve our website, apps, and communications to make sure we're
          interesting and relevant.
        </Para>
        <Para>
          Allow us to find out what works and what doesn't on our website and
          apps.
        </Para>
        <Para>
          Help us understand the effectiveness of advertisements and
          communications.
        </Para>
        <Para>
          Teach us how users interact with our website or apps after they have
          been shown an online advertisement, including advertisements on
          third-party websites.
        </Para>
        <Para>
          Enable our business partners to learn whether or not their customers
          make use of any accommodation offers integrated into their websites.
        </Para>
        <Para>
          The data we gather through these cookies can include which web pages
          you have viewed, which referring/exit pages you have entered and left
          from, which platform type you have used, which emails you have opened
          and acted upon, and date and time stamp information. It also means we
          can use details about how you’ve interacted with the site or app, such
          as the number of clicks you make on a given screen, your mouse
          movements and scrolling activity, the search words you use, and the
          text you enter into various fields.
        </Para>

        <Heading>Marketing cookies</Heading>
        <Para>
          These cookies are used by FloxyPay Travels and our trusted partners to
          gather information about you over time, across multiple websites,
          applications, or other platforms.
        </Para>
        <Para>
          Marketing cookies help us to decide which products, services, and
          interest-based advertisements to show you, both on and off our website
          and apps.
        </Para>
        <Para>More specifically, these cookies:</Para>
        <Para>
          Categorise you into a certain interest profile, for instance, on the
          basis of the websites you visit and your click behaviour. We use these
          profiles to display personalised content (such as travel ideas or
          specific accommodations) on FloxyPay Travels and other websites.
        </Para>
        <Para>
          Display personalised and interest-based advertisements both on the
          FloxyPay Travels website, our apps, and other websites. This is called
          ‘retargeting’ and is based on your browsing activities, such as the
          destinations you’ve been searching for, the accommodation you’ve
          viewed, and the prices you’ve been shown. It can also be based on your
          shopping habits or other online activities.
        </Para>
        <Para>
          Retargeting ads can be shown to you both before and after you leave
          FloxyPay Travels, as their purpose is to encourage you to browse or
          return to our website. You might see these ads on websites, in apps,
          or in emails.
        </Para>
        <Para>
          Integrate social media into our website and apps. This allows you to
          like or share content or products on social media such as Facebook,
          Instagram, YouTube, Twitter, Pinterest, Snapchat, and LinkedIn.
        </Para>
        <Para>
          These ‘like’ and ‘share’ buttons work using pieces of code from
          individual social media providers, allowing third-party cookies to be
          placed on your device.
        </Para>
        <Para>
          These cookies can be purely functional, but they can also be used to
          keep track of which websites you visit from their network, to build a
          profile of your online browsing behaviour, and to show you
          personalised ads. This profile will be partly built using comparable
          information the providers receive from your visits to other websites
          in their network.
        </Para>
        <Para>
          To read more about what social media providers do with your personal
          data, take a look at their cookie and/or privacy statements: Facebook
          (includes Instagram, Messenger, and Audience Network), Snapchat,
          Pinterest, and Twitter. Be aware that these statements may be updated
          from time to time.
        </Para>
        <Para>
          We work with trusted third parties to collect data. We may also
          sometimes share information with these third parties, such as your
          email address or phone number. These third parties might link your
          data to other information they collect to create custom audiences or
          deliver targeted ads
        </Para>

        <Heading>Non-cookie techniques – email pixels</Heading>
        <Para>
          We may also use techniques, such as pixels, which we don’t mark as
          cookies because they do not store any information on your device.
        </Para>
        <Para>
          We sometimes place pixels in emails, such as newsletters. A ‘pixel’ is
          an electronic file the size of a single pixel, that is placed in the
          email and loaded when you open it. By using email pixels, we can see
          if the message was delivered, if and when you read the message and
          what you click on.
        </Para>
        <Para>
          We also receive this information about the push notifications we send
          you. These statistics provide us with feedback about your reading
          behaviour, which we use to optimise our messages and make our
          communication more relevant to you.
        </Para>

        <Heading>What are your choices?</Heading>
        <Para>
          In the settings for browsers like Internet Explorer, Safari, Firefox,
          or Chrome, you can choose which cookies to accept and which to reject.
          Where you find these settings depends on which browser you use:
          <ul>
            <li>Cookie settings in Chrome</li>
            <li>Cookie settings in Firefox</li>
            <li>Cookie settings in Internet Explorer</li>
            <li>Cookie settings in Safari</li>
          </ul>
        </Para>
        <Para>
          If you choose to block certain functional cookies, you may not be able
          to use some features of our services.
        </Para>
        <Para>
          In addition to specific settings that we may offer on the FloxyPay
          Travels and apps, you can also opt-out of certain cookies:
        </Para>

        <Heading>Analytics</Heading>
        <Para>
          To prevent Google Analytics from collecting analytical data on certain
          browser types visit the following link: Google Analytics Opt-out
          Browser Add-on (only available on desktop).
        </Para>
        <Para>
          It’s possible that your mobile device allows you to limit information
          sharing for retargeting purposes through its settings. If you choose
          to do so, it’s good to be aware that opting out of an online
          advertising network doesn't mean you’ll no longer see or be subject to
          online advertising or marketing analysis. It just means that the
          network you've opted out from will stop delivering ads that are
          tailored to your web preferences and browsing patterns.
        </Para>
        <Para>
          Some websites have ‘Do Not Track’ features that allow you to tell a
          website not to track you. We’re currently unable to support ‘Do Not
          Track’ browser settings.
        </Para>

        <Heading>How to contact us</Heading>
        <Para>
          If you have any questions about this cookie statement, please send an
          email to @floxyay.com
        </Para>
        <Para>
          Our cookie statement may also be updated from time to time. If these
          updates are substantial, particularly relevant to you or impact your
          data protection rights, we’ll get in touch with you about them.
          However, we recommend that you visit this page regularly to stay up to
          date with any other (less substantial or relevant) updates.
        </Para>
      </Container>
    </Root>
  );
};

export default PrivacyPolicy;
