import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import "./CheckAvilable.css";
import { useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { environmentVariables } from "../../../config/config";
import LoginPopup from "../../Pages/Login/LoginPopup";
import { styled as styleMat } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { AuthContext } from "../../../ContextApi/Contextapi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 950,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  "@media (max-width: 600px)": {
    width: 320,
    flexDirection: "column",
    height: "45%!important",
    alignItems: "flex-start",
  },
};

const Wrapper = styled.div``;
const BooknowButton = styled.button``;
const Headingwrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const ErrorMessage = styled.div`
  font-size: 12px;
  color: red;
  margin-bottom: 20px;
`;
const useStyles = makeStyles((theme) => ({
  Hotelstyle: {
    fontSize: "22px !important",
    fontWeight: "800",
    margin: "10px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  HotelWrapper: {},
  TextStyle: {
    fontSize: "18px !important",
    fontWeight: "300px",
    display: "flex",
    justifyContent: "center",
    padding: "15px 0",
  },
  Buttonstyle: {
    border: "navajowhite",
    padding: "10px 25px",
    borderRadius: "4px",
    cursor: "pointer",
    backgroundColor: "#007a87",
    color: "white",
    fontWeight: "600",
  },
}));

const BootstrapDialog = styleMat(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const CustomBootstrapDialog = styled(BootstrapDialog)`
  & .MuiPaper-root {
    min-width: 500px;

    @media (max-width: 768px) {
      min-width: 425px;
    }

    @media (max-width: 425px) {
      min-width: 320px;
    }
  }
`;

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
const CheckAvilable = ({
  hotelData,
  state,
  error,
  setError,
  loggedIn,
  setLoggedIn,
}) => {
  const classes = useStyles();
  const [showPopup, setShowPopup] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [mainDisabled, setMainDisabled] = React.useState(true);
  const [responseData, setResponseData] = React.useState("");

  const getCheckedData = async () => {
    if (loggedIn === false) {
      setOpenAlert(false);
      setShowPopup(!showPopup);
    } else {
      if (state.isCombined != undefined && state.isCombined) {
        let newsateData = {
          ...state.hotelDetailSate,
          isCombined: true,
          selectedActivities: state.selectedActivities,
          currentActivity: {
            ...state.currentActivity,
            date: state.checkIn,
            adults: state.adult,
            child: state.children,
            infants: state.infants,
            changedPricingForActivities: state?.priceRange,
          },
        };
        navigation(state.redirectTo, {
          state: newsateData,
        });
      } else {
        let redirection =
          state.redirectTo != undefined && state.redirectTo
            ? state.redirectTo
            : "/hotelbooking";
        navigation(redirection, {
          state: {
            hotelData: hotelData,
            selectedActivities: state.selectedActivities,
            formData: state,
            price: hotelData.rooms.length === 0 || 0,
            loggedIn: loggedIn,
          },
        });
      }
    }
  };
  const handleOpen = () => {
    getCheckedData();
  };
  const handleClose1 = () => setOpen1(false);
  const navigation = useNavigate();

  useEffect(() => {
    const hasInvalidActivity = !state.isCombined
      ? state.selectedActivities.some(
          (activity) => !activity.changedPricingForActivities
        )
      : false;

    const isDisabled =
      state.adult === "0" ||
      state.rooms === "0" ||
      state.checkIn === null ||
      state.checkOut === null ||
      state.checkIn === "01/01/1970" ||
      !state?.priceRange ||
      hasInvalidActivity;

    setMainDisabled(isDisabled);
  }, [state]);

  // console.log("0988888", state, hotelData);
  return (
    <div>
      <Button
        onClick={handleOpen}
        style={{
          width: "100%",
          color: "white",
          opacity: mainDisabled ? 0.5 : 1,
        }}
        disabled={mainDisabled}
      >
        {state.isCombined != undefined && state.isCombined
          ? "+ Add Activity"
          : hotelData?.type == "hotel"
          ? "Book Now"
          : "Book Ticket"}
      </Button>

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ border: "none", display: "flex", flexDirection: "column" }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            border: "none",
            height: "1%",
            alignItems: "center",
            borderRadius: "10px",
          }}
        >
          <Wrapper className={classes.HotelWrapper}>
            <Typography
              style={{
                color: "red",
                fontSize: "18px",
                fontWeight: "400",
              }}
            >
              {" "}
              {status === true
                ? `Rooms are not available`
                : `Rooms are not available on ${responseData}`}{" "}
            </Typography>
          </Wrapper>
        </Box>
      </Modal>
      {/* <CustomBootstrapDialog
        onClose={handleCloseCrossIcon}
        aria-labelledby="customized-dialog-title"
        open={openAlert}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseCrossIcon}
        >
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Please Login to continue ....</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose2}
            style={{
              width: "140px",
              border: "none",
              borderColor: "rgb(231,231,231)",
              backgroundColor: "#008000",
              color: "white",
              padding: "10px 0",
              margin: "10px",
              cursor: "pointer",
              fontSize: "16px",
              borderRadius: "8px",
            }}
          >
            Login
          </Button>
        </DialogActions>
      </CustomBootstrapDialog> */}
      {showPopup && (
        <LoginPopup
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          setLoggedIn={setLoggedIn}
        />
      )}
      {/* } */}
    </div>
  );
};

export default CheckAvilable;
