export const HOME = "/";
export const HOTELPAGE = "/hotel";
export const SERVICES = "/services";
export const ABOUT_US = "/aboutus";
export const CONTACT_US = "/contactus";
export const DETAILPAGE = "/hoteldetails";
export const ACTIVITIES = "/activities";
export const ACTIVITYDETAILPAGE = "/activityDetail/:id";
export const VERIFYPASSWORD = "/verifypassword";
export const PROFILESIDEBAR = "/userprofile";
export const PROFILESETTING = "/profilesetting";
export const SHORTLISTEDPLACE = "/shortlistedplace";
export const GIFTTOPUPHISTORY = "/gift-topup-history";
export const SHORTWITHSTATE = "/shortwithstate/:id";
export const HOTELBOOKING = "/hotelbooking";
export const SHORTWITHCITY = "/shortwithcity";
export const COMPLETEBOOKING = "/bookinghistory";
export const DEFAULT = "*";
export const BOOKINHOTELDETAILS = "/bookinghoteldetails/:id";
export const CITYPOPUP = "/citypopup";
export const FILTEREDBYTHEME = "/filter";
export const HOTELDETAILPAGE = "/hoteldetails/:id";
export const UPDATEPASSWORD = "/updatepassword";
export const CancelPolicy = "/cancellationpolicy";
export const PrivacyPolicy = "/privacypolicy";
export const TermsAndCondition = "/termsandcondition";
export const GIFTCARD = "/gifts";
export const TOPUPCARD = "/topups";
// export const CardDetail = "/card/:id";
export const AppName = "Bastionex Travel";
