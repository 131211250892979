import styled from "styled-components";

export const Root = styled.div`
  background-color: #e3e3e3;
  // height: 100vh;
  @media (max-width: 768px) {
    height: 100%;
  }
`;
export const Image = styled.img`

`;
export const Container = styled.div`
  position: relative;
  /* width:1200px;
  margin:0 auto;
  display: flex; */
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0px;
  margin-top: 0px;
  // padding-top: 385px;
  @media (max-width: 1024px) {
    padding-top: 0px;
    
  }
  @media (max-width: 768px) {
    padding-top: 0px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  /* position: absolute; */
  /* top: 32%; */
  z-index: 3;
  /* font-family: "Playfair Display"; */
  @media (max-width: 768px) {
    // padding: 100px 3px;
    position: static;
    text-align: center;
  }
`;
export const Text = styled.text`
  width: 100%;
  font-size: 56px;
  line-height: 60px;
  width: 60%;
  text-align: center;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0;
  color: #fff;
  // text-shadow: 0 0 12px rgb(0 0 0 / 70%);
  /* font-family: "Playfair Display", serif; */
  ${(p) =>
    p.smalltext &&
    `
font-size: 18px;
padding:10px 0px 20px 0px;
line-height: 25px;
`}
@media (max-width:1024px) {
  width: 80%;
  // padding: 0;
  // margin: 0;
  text-align: center;
  font-size: 40px;
  padding: 0 20px 20px;
  line-height: 40px;
  ${(p) =>
    p.smalltext &&
    `
   font-size: 18px;
   line-height: 22px;
`}
  @media (max-width:768px) {
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 36px;
    padding: 0 20px 20px;
    line-height: 36px;
    ${(p) =>
      p.smalltext &&
      `
     font-size: 18px;
     line-height: 22px;
`}
  }
`;

export const FormWrapper = styled.div`
  /* background: rgba(0, 0, 0, 0.35) none repeat scroll 0 0; */
  top: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 3px;
  border-radius: 16px;
  background-color: rgb(248, 247, 249);
  border-radius: 5px;
  width: 1060px;
  padding: 0;
  z-index: 3;
  /* position: absolute; */
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    width: 90%;
    top: 50%;
    flex-direction: column;
  }
  @media (max-width: 768px) {
    width: 90%;
    top: 50%;
    flex-direction: column;
  }
`;

export const DatepickerSectionWrapper = styled.div`
  display: flex;
  padding: 20px 0;
`;
export const DestinationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 15px;
  position: relative;
  width: 95%;
  /* ${(p) =>
    p.CheckOut &&
    `
margin-bottom:"10px"
`} */
  :focus {
    border: none;
    outline: none;
  }
`;

export const DateAdultWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  margin-left: 15px 15px 15px 0;
  padding: 0;
  gap: 15px;
  /* border: 1px solid #dddfe2; */
  @media (max-width: 1024px) {
    display: flex;
    /* flex-direction: column; */
    width: 95%;
    padding: 0;
    margin-bottom: 10px;
    height: auto;
  }
  @media (max-width: 768px) {
    display: flex;
    /* flex-direction: column; */
    width: 95%;
    padding: 0;
    margin-bottom: 10px;
    height: auto;
  }
`;
export const VerticalLine = styled.div`
  height: 30px;
  width: 2px;
  background-color: #8f8f8f5e;
  align-items: center;
  @media (max-width: 768px) {
    height: 4px;
    width: 0%;
    border: 1px solid #e9e9e9;
  }
`;
export const DatePickerWrapper = styled.div`
  width: 300px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  border: 1px solid #dddfe2;
  position: relative;
  @media (max-width: 1024px) {
    display: flex;
    /* flex-direction: column; */
    width: 100%;
  }
  @media (max-width: 768px) {
    display: flex;
    /* flex-direction: column; */
    width: 100%;
  }
`;
export const DateCheckInInput = styled.div`
  position: absolute;
  top: 15%;
  left: 39%;
  font-size: 20px;
  cursor: pointer;
  @media (max-width: 1024px) {
    top: 14%;
    right: 40%;
    z-index: 1;
  }
  @media (max-width: 768px) {
    top: 14%;
    left: 40%;
    z-index: 1;
  }
`;
export const DateCheckOutInput = styled.div`
  position: absolute;
  top: 15%;
  left: 89%;
  font-size: 20px;
  cursor: pointer;
  @media (max-width: 1024px) {
    top: 14%;
    left: 90%;
    z-index: 1;
  }
  @media (max-width: 768px) {
    top: 14%;
    left: 90%;
    z-index: 1;
  }
`;
export const CheckIn = styled.input`
  border: none;
  padding: 20px 25px;
  width: 100%;
  border-radius: 5px;
  :focus {
    border: none;
    outline: none;
  }
`;

export const CheckOut = styled.input`
  border: none;
  padding: 20px 25px;
  width: 100%;
  border-radius: 5px;
  :focus {
    border: none;
    outline: none;
  }
`;
export const DestinationText = styled.text`
  margin: 10px 0;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
`;
export const SearchIconWrapper = styled.div`
  position: absolute;
  // top: 38px;
  left: 21px;
  font-size: 20px;
`;
export const DestinatioInput = styled.input`
  position: relative;
  width: 275px;
  border: 1px solid #dddfe2;
  border-radius: 5px;
  font-size: 16px;
  height: 50px;
  padding: 10px 10px 10px 55px;
  background-color: #fff;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border linear 0.2s, box-shadow linear 0.2s;
  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
  :focus {
    outline: none;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 50%;
  margin-left: 15px;
  @media (max-width: 1024px) {
    width: 95%;
    margin-left: 0px;
  }
  @media (max-width: 768px) {
    width: 95%;
    margin-left: 0px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  align-items: center;
  border: none;
  /* position: absolute; */
  width: 100%;
  /* top: 90%; */

  cursor: pointer;
`;
export const PopupWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;
export const Button = styled.div`
  /* border: none;
  padding: 12px 30px;
  margin: 0 20px;
  width: 35%;
  font-weight: bold;
  font-size: 20px;
  border-radius: 50px;
  background-color: #007a87;
  color: white; */
  border: none;
  padding: 12px 40px;
  // margin: 0 20px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 50px;
  cursor: ${(props) => (props.isSearchActive ? "pointer" : "default")};
  background-color: ${(props) =>
    props.isSearchActive ? "#3554d1" : "#444f78"};
  color: ${(props) => (props.isSearchActive ? "#fff" : "#fff")};
  /* cursor: pointer; */
  /* :focus {
    border: none;
    outline: none;
  } */
  /* :disabled {
    background-color: #3554d1;
    color: #ffffff;
  } */
  :hover {
    cursor: ${(props) => (props.isSearchActive ? "pointer" : "default")};
    background-color: #051036;
    color: #fff;
  }
  @media (max-width: 1024px) {
    margin: 10px 10px;
    width: 95%;
    border-radius: 5px;
    text-align: center;
  }
  @media (max-width: 768px) {
    margin: 10px 10px;
    width: 100%;
    border-radius: 5px;
    text-align: center;
  }
`;

export const Icon = styled.div`
  cursor: pointer;
`;
export const ModelWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  /* margin: 0 20px; */
  padding: 10px 0;
`;
export const ModelTextWrapper = styled.div``;
export const Modeltext = styled.text`
  font-size: 16px;
  font-weight: 500;
`;
export const ModelQuantity = styled.div`
  display: flex;
`;
export const Number = styled.div`
  padding: 0 20px;
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 15px 0;
`;
export const SubmitButton = styled.button`
  width: 31%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #dddfe2;
  background-color: #007a87;
  color: white;

  :focus {
    /* border: none; */
    outline: none;
  }
`;

export const CheckInDatePickerWrapper = styled.div`
width: 50%;
`;

export const CheckOutDatePickerWrapper = styled.div`
width: 50%;
`;