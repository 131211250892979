import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import Banner from "../../images/bannerimg.jpg";

const Root = styled.div`
  padding-top: 85px;
`;

const ImageWrapper = styled.div`
  background-image: url(${Banner});
  width: 100%;
  height: 25rem;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  @media (max-width: 768px) {
    background-size: cover;
    background-position: center;
  }
`;

const ContainerStyledUpdate = styled(Container)`
@media (max-width:768px){
  margin-top: -45px;
}
`;

const TextHeading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: #fff;
  @media (max-width: 425px) {
    font-size: 2rem;
  }
`;

const RowStyledUpdate = styled(Row)`
@media (max-width: 768px) {
  display: flex;
  flex-direction: column;
  width:100%;
}
`;

const ColStyledUpdate = styled(Col)`
@media (max-width: 768px) {
  width:100%;
}
`;

const TextHeadingOne = styled.div`
  font-size: 20px;
  color: #6e6666;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
`;
const TextParaOne = styled.div`
  font-size: 14px;
  color: #6e6666;
  font-family: "Roboto";
  margin-top: 0px;
`;
const TextHeadingTwo = styled.div`
  font-size: 14px;
  color: #6e6666;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  margin-top: 10px;
`;
const TextParaTwo = styled.div`
  font-size: 14px;
  color: #6e6666;
  font-family: "Roboto";
`;

const TextParaDiv = styled.div`
  margin-top: 10px;
`;

const TextHeadingThree = styled.div`
  font-size: 20px;
  color: #6e6666;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  margin-top: 20px;
`;

const TextParaThree = styled.div`
  font-size: 14px;
  color: #6e6666;
  font-family: "Roboto";
  margin-top: 0px;
`;

const TextIframe = styled.div`
  margin-top: 10px;
  @media (max-width: 425px) {
    margin-top: 20px;
  }
`;

const TextHeadingFour = styled.div`
  font-size: 20px;
  color: #6e6666;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  margin-top: 10px;
`;

const TextParaFour = styled.div``;

const TextList = styled.div`
  font-size: 14px;
  color: #6e6666;
  font-family: "Roboto";
`;
const NavBarBackground = styled.div`
  width: 100vw;
  height: 77px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;

const ContactUpdate = styled.div`
font-size: 32px;
font-weight: 500;
margin-bottom: 20px;
`;

const Contactus = ({ title }) => {
  document.title = title === undefined ? "" : title;
  return (
    <Root>
      <NavBarBackground></NavBarBackground>
      {/* <Container fluid>
        <Row>
          <ImageWrapper>
            <TextHeading>Contact Us</TextHeading>
          </ImageWrapper>
        </Row>
      </Container> */}
      <ContainerStyledUpdate fluid style={{ backgroundColor: "#e9e9e9" }}>
        
        <Container style={{ padding: "70px 0px" }}>
          <ContactUpdate> Contact Us</ContactUpdate>
          <RowStyledUpdate>
            <ColStyledUpdate sm={8}>
              <TextHeadingOne>Customer Service</TextHeadingOne>
              <TextParaOne>
                To book your vacations, call us at 8880036677
              </TextParaOne>
              <TextHeadingTwo>Operational Timings</TextHeadingTwo>
              <TextParaDiv>
                <TextParaTwo>Mon to Fri - 9:30 am to 6.30 pm</TextParaTwo>
                <TextParaTwo>
                  You can also email us at bookings@bastionextravel.net
                </TextParaTwo>
              </TextParaDiv>
              <TextHeadingThree>Sales:</TextHeadingThree>
              <TextParaThree>
                Have a getaway you would like to see on bastionextravel? Mail us
                at sales@bastionextravel.net
              </TextParaThree>
              <TextHeadingThree>Advertise with us:</TextHeadingThree>
              <TextParaThree>
                Want to boost your business by advertising with us? Mail us at
                advertise@bastionextravel.net
              </TextParaThree>
              <TextHeadingThree>Partner with us:</TextHeadingThree>
              <TextParaThree>
                Want to explore a mutually beneficial partnership? Mail us at
                partners@bastionextravel.net
              </TextParaThree>
              <TextHeadingThree>Careers:</TextHeadingThree>
              <TextParaThree>
                Are you as passionate about travel as we are and would like to
                be a part of our team? Mail us at careers@bastionextravel.net
              </TextParaThree>
              <TextHeadingThree>Feedback:</TextHeadingThree>
              <TextParaThree>
                Bouquets? Brickbats? Mail us at feedback@bastionextravel.net
              </TextParaThree>
            </ColStyledUpdate>
            <ColStyledUpdate sm={4}>
              <TextIframe>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d56059.90309030851!2d77.27947393974074!3d28.577450779990446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x390ce45b7e24b733%3A0xb8dd905f45fbfcd5!2sSector%2016%2C%20Noida%2C%20Uttar%20Pradesh!3m2!1d28.5773799!2d77.31449359999999!5e0!3m2!1sen!2sin!4v1674803824108!5m2!1sen!2sin"
                  width="100%"
                  height="350"
                  style={{ border: "0" }}
                  loading="lazy"
                  // referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </TextIframe>
              <TextHeadingFour>Contact Address</TextHeadingFour>
              <TextParaFour>
                <TextList>FloxyPay Travels</TextList>
                <TextList>B2 Sec-4, Noida,</TextList>
                <TextList>Uttar Pradesh 201301, India</TextList>
              </TextParaFour>
            </ColStyledUpdate>
          </RowStyledUpdate>
        </Container>
      </ContainerStyledUpdate>
    </Root>
  );
};

export default Contactus;
