import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import styled from "styled-components";
import CircularLoader from "../CircularLoader/CircularLoader";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { environmentVariables } from "../../config/config";
import { useContext } from "react";
import { AuthContext } from "../../ContextApi/Contextapi";

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default function Model({
  open,
  setOpen,
  amount,
  productId,
  quantity,
  unitPrice,
  senderName,
  senderEmail,
  recieverMobile,
  recieverName,
  recieverCountryCode,
  recieverEmail,
  message,
  token,
  loggedIn,
  currencyCode,
  originalJSONText,
}) {
  const baseUrl = environmentVariables.apiUrl;
  const [loading, setLoading] = useState(false);
  // const { authData, setAuthData } = useContext(AuthContext);

  const HandleClick = () => {
    setLoading(true);
    const data = {
      productId,
      senderEmail,
      senderName,
      recieverName,
      recieverEmail,
      recieverMobile,
      recieverCountryCode,
      message,
      quantity,
      unitPrice,
      email: originalJSONText?.email,
      currencyCode,
    };

    const config = {
      method: "post",
      url: `${baseUrl}auth/makeconnectionforpg_giftcard?type=giftcard`,
      headers: {
        _token: originalJSONText?.token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        window.location.href = response.data.message.url;
        setOpen(false);
        setLoading(false);
      })
      .catch(function (error) {
        setOpen(false);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });

    // const config = {
    //   method: "post",
    //   url: `${baseUrl}auth/createtopuporder`,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: data,
    // };

    // axios(config)
    //   .then(function (response) {
    //     setLoading(false);
    //     setOpen(false);
    //     Swal.fire(
    //       `Successfully Recharge for ${response.data.data.recipientPhone}!`,
    //       `Your tansaction id is ${response.data.data.transactionId}`,
    //       "success"
    //     );
    //   })
    //   .catch(function (error) {
    //     setLoading(false);
    //     setOpen(false);
    //     Swal.fire({
    //       icon: "error",
    //       title: "Oops...",
    //       text: "Something went wrong!",
    //     });
    //   });
  };
  return (
    <React.Fragment>
      {/* <Button variant="outlined" color="neutral" onClick={() => setOpen(true)}>
        Open modal
      </Button> */}
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
            },
          })}
        >
          <Typography id="nested-modal-title" level="h2">
            Are you sure?
          </Typography>
          <Typography id="nested-modal-description" textColor="text.tertiary">
            This action cannot be undone. Proceeding further will cause
            deduction from your account
          </Typography>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", sm: "row-reverse" },
            }}
          >
            <Button
              variant="solid"
              color="neutral"
              onClick={() => HandleClick()}
            >
              {loading ? (
                <LoaderWrapper>
                  <CircularLoader />
                </LoaderWrapper>
              ) : (
                "Continue"
              )}
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
