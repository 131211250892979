import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LoginPopup from "../Pages/Login/LoginPopup";

import axios from "axios";
import { useLocation } from "react-router-dom";

import { FormControl, Select, MenuItem } from "@mui/material";
import CircularLoader from "../CircularLoader/CircularLoader";
import { useFormik } from "formik";
import { TopupSchema } from "./TopupSchema";
import { environmentVariables } from "../../config/config";
import { debounce } from "lodash";
import { generateTopupSchemaRange } from "./TopupSchemaRange";
import Adbanner4x from "./../../images/Adbanner4x.png";
import TextBackgroundImg from "../../images/Topuptextbg.jpg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Swal from "sweetalert2";
import "./TopupCard.css";
import { useAuth } from "../../ContextApi/Contextapi";

const ErrorMessage = styled.div`
  color: #c88714;
  margin-bottom: 20px;
  line-height: 24px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  padding: 60px;
`;
const ErrorMessage1 = styled.div`
  color: red;
  font-size: 12px;
  // margin-bottom: 20px;
`;
const Root = styled.div`
  padding: 10px 0;
  background-color: #ededed;
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  margin-top: -200px !important;
  display: flex;
  flex-direction: column;
  @media (max-width: 1600px) {
    // width: 1200px;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
`;
const MainDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: 18px 0px;
  width: 100%;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  background-color: white;
  padding: 15px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  min-height: 490px;
  width: 30%;
  @media (max-width: 1024px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 15px;
  margin-left: 25px;
  border-radius: 10px;
  min-height: 490px;
  width: 50%;
  justify-content: space-between;
  @media (max-width: 1260px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 20px 0;
  }
`;
const TopupHeading = styled.div`
  margin-top: 12px;
  font-weight: 400;
  font-size: 18.5px;
  color: #182a2f;
`;
const InputCountryCodeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentWrapper = styled.div`
  font-family: lato;
  font-size: 11px;
  font-weight: 400;
  margin-top: 10px;
  color: #00a154;
`;
const InputWrapper = styled.div`
  margin-top: 12px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
const MainInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
const Label = styled.text`
  font-size: 12px;
  color: #707070;
`;

const BrowsePerPlan = styled.div`
  margin-top: 12px;
  font-weight: 400;
  font-size: 16px;
  color: #182a2f;
`;
const Input = styled.input`
  outline: none;
  padding: 8px 10px 8px 10px;
  // border-radius: 5px;
  // border-color: #d0d0d0;
  border: none;
  border-bottom: 1px solid rgb(152, 152, 152) !important;
  border-radius: 0;
  font-size: 16px;
  // border: 1px solid gray;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }

  ${(p) =>
    p.fullWidthInput &&
    `width:100%;
  `};
  ${(p) =>
    p.mobileinput &&
    `border: none;
    border-bottom: 1px solid rgb(152,152,152) !important;
    border-radius: 0;
    
    height: 50px;

  `};
`;
const InputSmall = styled.input`
  outline: none;

  padding: 8px 10px 8px 10px;
  border-radius: 5px;
  // border-color: #d0d0d0;
  font-size: 16px;
  // border: 1px solid gray;
  border: none;
  border-bottom: 1px solid rgb(152, 152, 152) !important;
  border-radius: 0;
  width: 20%;
  margin-right: 7px;
`;

const InputInsideWapper = styled.div`
  display: flex;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
  margin-bottom: 20px;
`;
const StyledButton = styled.button`
  background: linear-gradient(to left, #0059a7 0%, #4297e2 100%);
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 18px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: #0056b3;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
`;

const DisclaimerWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #efefef;
  border-radius: 10px;
  margin-top: 20px;
  color: #7e7e7e;
`;
const DisclaimerHead = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #182a2f;
`;
const DisclaimerContent = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #4d4d4d;
  width: 100%;
  margin-left: 5px;
`;
const CircularLoaderWrapper = styled.div`
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainCombinedArrayHeading = styled.div`
  // background-color: #f1f9ff;
  border-bottom: 1px solid #d0d0d0;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-top: 20px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;
const MainCombinedArray = styled.div`
  margin-top: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: flex-start;
  // align-items: center;
  // border: 1px solid rgba(0, 0, 0, 0.1);
  border: none;
  padding: 10px 20px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;
const AmountDiv = styled.div`
  width: 20%;
  font-weight: 400;
  font-size: 14px;
  color: #989898;
  @media (max-width: 768px) {
    width: 60%;
    font-size: 13px;
    line-height: 16px;
  }
`;
const AmountDivInner = styled.div`
  width: 20%;
  line-height: 50px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  padding: 0px 0px 0px 10px;
  font-weight: 500;
  font-size: 16px;
  color: #231f20;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 18px;
    width: 30%;
  }
`;
const DescriptionDiv = styled.div`
  width: 65%;
  font-weight: 500;
  font-size: 16px;
  color: #231f20;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 16px;
    width: 75%;
  }
`;
const TextDescriptionDiv = styled.div`
  width: 65%;
  font-weight: 400;
  font-size: 14px;
  color: #989898;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 16px;
    width: 75%;
  }
`;
const CombinedArrayButton = styled.button`
  width: 18%;
  // padding
  // margin-left: 4%;
  border-radius: 30px;
  border: 1px solid #2196f3;
  padding: 8px 10px;
  background-color: rgb(255 255 255);
  color: #2196f3;
  font-weight: 500;
  @media (max-width: 768px) {
    width: 25%;
    padding: 3px;
    margin-left: 0;
  }
`;

const OptionImage = styled.img`
  width: 25px;
  height: 25px;
`;
const InputSenderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;

  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 15px;
  }
`;

const SelectStyledUpdate = styled(Select)`
  width: 100% !important;
  border: none !important;
  outline: none !important;
`;

const FormControlStyledUpdate = styled(FormControl)`
  width: 95px !important;
  @media (max-width: 950px) {
    width: 100px !important;
  }
  @media (max-width: 750px) {
    width: 110px !important;
  }
  @media (max-width: 550px) {
    width: 130px !important;
  }
`;
const WarningDiv = styled.div`
  display: flex;
  font-size: 13px;
  color: #ff9966;
`;
const WarningAmountDiv = styled.div`
  display: flex;

  ${(p) =>
    p.select &&
    `margin-left:30px;
  `};
`;

const KeyDiv = styled.div``;
const ValueDiv = styled.div`
  margin-left: 10px;
`;

const GiftCardHeadingWrapper = styled.div`
display: flex;
justify-content: center;
text-align: center;
// align-items: center;
background-image: url(${TextBackgroundImg});
background-repeat: no-repeat;
background-size: cover
background-position: center;
margin-bottom:20px ;
height: 300px
`;
const GiftCardHeadText = styled.div`
  font-weight: 300;
  font-size: 30px;
  padding: 40px 0;
  color: white;
`;

const AddBannerWrapper = styled.div`
  width: 100%;
`;
const AddBannerimg = styled.img`
  width: 100%;
`;

const Topuplist = ({ loggedIn, setLoggedIn }) => {
  const location = useLocation();
  const baseUrl = environmentVariables.apiUrl;
  const [selected, setselected] = useState();
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [responseFromMobileAndCode, setResponseFromMobileAndCode] = useState();
  const [isCircularLoading, setIsCircularLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(
    location?.state === null
      ? responseFromMobileAndCode?.supportsLocalAmounts
        ? "popularplan"
        : "fixedplan"
      : location?.state?.PlanType
  );
  const { authData } = useAuth();
  const [description, setDescription] = useState("");
  const [combinedArray, setCombinedArray] = useState();
  const [countries, setCountries] = useState([]);

  const [responseError, setResponseError] = useState();

  const [name, setName] = useState(
    location?.state === null ? "" : location?.state?.name
  );
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const countryName = localStorage.getItem("countryISOCode");

  const initialValues = {
    amount: location?.state === null ? "" : location?.state?.localAmount,
    selectedOptions:
      location?.state === null
        ? countryName
          ? countryName
          : "AE"
        : location?.state?.recieverCountryCode,
    number: location?.state === null ? "" : location?.state?.recieverMobile,
  };
  function formatDate(timestamp) {
    const options = {
      timeZone: "Asia/Kolkata",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const formattedDate = new Date(timestamp).toLocaleString("en-IN", options);
    return formattedDate;
  }

  const TopupCardSchema = generateTopupSchemaRange(
    responseFromMobileAndCode?.localMinAmount,
    responseFromMobileAndCode?.localMaxAmount
  );
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema:
      responseFromMobileAndCode?.denominationType === "RANGE"
        ? TopupCardSchema
        : TopupSchema,
    onSubmit: async () => {
      if (loggedIn === false) {
        setOpenAlert(false);
        setShowPopup(!showPopup);
      } else {
        setIsCircularLoading(true);
        console.log("responseFromMobileAndCode",responseFromMobileAndCode)
        if(responseFromMobileAndCode?.status == "ACTIVE"){
        const data = {
          amount: values?.amount.toString(),
          recieverMobile: values.number.toString(),
          recieverCountryCode: values.selectedOptions,
          operatorId: responseFromMobileAndCode?.operatorId,
          operatorName: responseFromMobileAndCode?.name,
          email: authData?.email,
          currencyCode: responseFromMobileAndCode?.destinationCurrencyCode,
          name,
          PlanType: activeTab,
        };

        const config = {
          method: "post",
          url: `${baseUrl}auth/makeconnectionforpg?type=topup`,
          headers: {
            _token: authData.token,
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            if(response.data.message.url){
              window.location.href = response.data.message.url;
            }
           
            setIsCircularLoading(false);
            setOpen(false);
            setLoading(false);
          })
          .catch(function (error) {
            setOpen(false);
            setIsCircularLoading(false);
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
        }else{
          setIsCircularLoading(false);
          Swal.fire({
            icon: "warning",
            title: "Oops",
            text: "Currently services are not available for respective operator. Please Try again after sometimes."
          });
        }
       
      }
    },
  });
  // const handleKeyPress = (event) => {
  //   const keyCode = event.keyCode || event.which;
  //   const keyValue = String.fromCharCode(keyCode);
  //   const isCtrlPressed = event.ctrlKey || event.metaKey;

  //   if (
  //     keyCode === 8 ||
  //     keyCode === 46 ||
  //     (keyCode >= 48 && keyCode <= 57) ||
  //     (isCtrlPressed && keyValue === "r") ||
  //     keyCode === 37 ||
  //     keyCode === 39
  //   ) {
  //     if (keyValue === "+" || keyValue === "-") {
  //       event.preventDefault();
  //     }
  //   } else {
  //     event.preventDefault();
  //   }
  // };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const isCtrlPressed = event.ctrlKey || event.metaKey;
  
    // Allow copy (Ctrl+C) and paste (Ctrl+V) actions
    if (isCtrlPressed && (keyCode === 67 || keyCode === 86)) {
      return; // Allow the default browser behavior for copy-paste
    }
  
    // Restrict other keystrokes based on your conditions
    if (
      keyCode === 8 || // Backspace
      keyCode === 46 || // Delete
      (keyCode >= 48 && keyCode <= 57) || // Numbers 0-9
      (isCtrlPressed && keyCode === 82) || // Ctrl+R
      keyCode === 37 || // Left arrow
      keyCode === 39 // Right arrow
    ) {
      if (event.key === "+" || event.key === "-") {
        event.preventDefault(); // Prevent typing '+' or '-'
      }
    } else {
      event.preventDefault(); // Prevent default for all other keystrokes
    }
  };

  const loadAllcountries = () => {
    // setLoading(true);
    const config = {
      method: "post",
      url: `${baseUrl}auth/getallcountriestopup`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        // setLoading(false);
        setCountries(response.data.data);
      })
      .catch(function (error) {
        // setLoading(false);
      });
  };

  const SomeFunction = () => {
    const data = {
      number: values.number?.toString(),
    };

    const selectedCountry = countries.find(
      (country) => country.isoName === values.selectedOptions
    );
    setLoading1(true);
    if(countries.length > 0){
      if (selectedCountry) {
        const isValidNumber = validateMobileNumber(
          values.number,
          selectedCountry.isoName
        );
  
        if (!isValidNumber) {
          setLoading1(false);
          setResponseError(
            "Please enter a valid mobile number for the selected country"
          );
  
          setResponseFromMobileAndCode();
          return;
        } else {
          setLoading1(true);
          const config = {
            method: "post",
            url: `${baseUrl}auth/getoperatorbynumberandisocode/${values.selectedOptions}`,
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
            signal: abortController.signal,
          };
  
          axios(config)
            .then(function (response) {
  
              setLoading1(false);
              if (response.data.success === true) {
                if (
                  response.data.data.denominationType === "RANGE" &&
                  response.data.data.supportsLocalAmounts
                ) {
                  setResponseFromMobileAndCode(response.data.data);
                  setResponseError("Please Insert Amount For Recharge");
                } else {
                  setResponseFromMobileAndCode(response.data.data);
                  setCombinedArray(response.data.combinedArray);
                  setResponseError();
                }
              } else {
                setResponseError(
                  "Your request could not be processed at the moment, please try again later or contact support"
                );
                setResponseFromMobileAndCode();
              }
            })
            .catch(function (error) {
              setLoading1(false);
              if (error?.response?.data?.success === false) {
                setResponseError(
                  "Your request could not be processed at the moment, please try again later or contact support"
                );
                setResponseFromMobileAndCode();
              }
            });
        }
      } else {
        setResponseError(
          "Your request could not be processed at the moment, please try again later or contact support"
        );
        setResponseFromMobileAndCode();
      }
    }
  };
  const mobileNumberRegex = {
    // India
    IN: /^[6-9]\d{9}$/,
    // United Arab Emirates
    AE: /^((\+|00)?971[\s\.-]?(\(0\)[\s\.-]?)?|0)?[1-9]\d{8}$/,
    // Afghanistan
    AF: /^(0)?[7]\d{8}$/,
    // United States
    US: /^(\+1)?[2-9]\d{9}$/,
    // Australia
    AU: /^(0)?[2|3|4|7|8][0-9]{8}$/,
    // France
    FR: /^(\+|00)?33[\s\.-]?[1-9](\d{2}){4}$/,
  };

  // Function to validate mobile number based on country code
  const validateMobileNumber = (number, countryCode) => {
    const regex = mobileNumberRegex[countryCode];
    return regex ? regex.test(number) : false;
  };

  const abortController = new AbortController();
  const getTopupDetailsByNumberandCode = () => {
    SomeFunction();
  };

  // const handleTabClick = (tab) => {
  //   setActiveTab(tab); // Update the active tab when a tab is clicked
  // };
  // const handleChangeSelectOptions = (event) => {
  //   setSelectedOption(event.target.value);
  // };
  // const getTransactionHistory = () => {
  //   // setLoading1(true);
  //   const data = {
  //     token: topupToken,
  //   };

  //   const config = {
  //     method: "post",
  //     url: `${baseUrl}auth/gettopuphistory`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       // setLoading1(false);
  //       setTransactionData(response.data.data);
  //     })
  //     .catch(function (error) {
  //       // setLoading1(false);
  //     });
  // };


  useEffect(() => {
    loadAllcountries();
  }, []);
  useEffect(() => {

      getTopupDetailsByNumberandCode();



    return () => {
      abortController.abort();
    };
  }, [values.number, values.selectedOptions, countries]);


  // const handleClose2 = () => {
  //   setOpenAlert(false);
  //   setShowPopup(!showPopup);
  // };
  const handleKeyPressText = (e) => {
    const onlyLettersRegex = /^[A-Za-z\s]+$/;

    if (!onlyLettersRegex.test(e.key)) {
      e.preventDefault();
    }
  };

  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
    },
    blurredRoot: {
      filter: "blur(5px)",
    },
  };

  return (
    <>
      <Root style={isCircularLoading ? styles.blurredRoot : {}}>
        <GiftCardHeadingWrapper>
          <GiftCardHeadText>TOP-UP</GiftCardHeadText>
        </GiftCardHeadingWrapper>
        <Container>
          <MainDiv>
            <LeftContainer>
              <TopupHeading>
                {responseFromMobileAndCode?.name}Top Up
              </TopupHeading>
              <MainInputWrapper>
                <Label>Mobile Number*</Label>
                <InputCountryCodeWrapper
                  onClick={() => {
                    values.amount = "";
                    setDescription("");
                  }}
                >
                  <FormControlStyledUpdate
                    className="formcontrolselect"
                    sx={{ position: "initial" }}
                    onClick={() => {
                      values.amount = "";
                      setDescription("");
                    }}
                  >
                    <SelectStyledUpdate
                      value={values.selectedOptions}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="selectoption"
                      name="selectedOptions"
                      autoFocus={false}
                      variant="standard"
                    >
                      {countries.map((row, index) => {
                        const imageUrl = row.flag;
                        return (
                          (row.isoName === "IN" ||
                            row.isoName === "AE" ||
                            row.isoName === "AF" ||
                            row.isoName === "US" ||
                            row.isoName === "AU" ||
                            row.isoName === "FR") && (
                            <MenuItem
                              key={index}
                              value={row.isoName}
                              className="menulist"
                            >
                              <OptionImage src={`${imageUrl}`} alt="img" />
                              <span
                                style={{
                                  padding: "0 0 0 5px",
                                  fontSize: "14px",
                                }}
                              >
                                {row.callingCodes[0]}
                              </span>
                            </MenuItem>
                          )
                        );
                      })}
                    </SelectStyledUpdate>
                    {errors.selectedOptions && touched.selectedOptions ? (
                      <ErrorMessage1>{errors.selectedOptions}</ErrorMessage1>
                    ) : null}
                  </FormControlStyledUpdate>
                  <InputSenderWrapper>
                    <Input
                      mobileinput
                      className="form-control addonInputopuplist"
                      type="number"
                      placeholder="Mobile Number*"
                      style={{ outline: "none", border: "none" }}
                      name="number"
                      value={values.number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // onClick={() => setError("")}
                      onKeyDown={handleKeyPress}
                    
                      onClick={() => {
                        values.amount = "";
                        setDescription("");
                      }}
                    />
                    {errors.number && touched.number ? (
                      <ErrorMessage1>{errors.number}</ErrorMessage1>
                    ) : null}
                  </InputSenderWrapper>
                </InputCountryCodeWrapper>
              </MainInputWrapper>

              <InputWrapper>
                <Label>Operator*</Label>
                <Input
                  type="text"
                  placeholder="Operator*"
                  value={
                    responseFromMobileAndCode === undefined
                      ? ""
                      : responseFromMobileAndCode.name
                  }
                  onChange={handleChange}
                  readOnly
                />
              </InputWrapper>
              {responseFromMobileAndCode?.denominationType === "RANGE" ? (
                <InputWrapper>
                  <Label>Amount*</Label>
                  <InputInsideWapper>
                    <InputSmall
                      type="text"
                      placeholder="Currency Code"
                      // name="additionalInput"
                      value={responseFromMobileAndCode?.destinationCurrencyCode}
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      readOnly
                    />
                    <Input
                      type="number"
                      placeholder="Please Enter Plan*"
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // readOnly
                    />
                  </InputInsideWapper>

                  <WarningDiv>
                    <WarningAmountDiv>
                      <KeyDiv>min amount:</KeyDiv>
                      <ValueDiv>
                        {responseFromMobileAndCode?.localMinAmount}
                      </ValueDiv>
                    </WarningAmountDiv>
                    <WarningAmountDiv select>
                      <KeyDiv>max amount:</KeyDiv>
                      <ValueDiv>
                        {responseFromMobileAndCode?.localMaxAmount}
                      </ValueDiv>
                    </WarningAmountDiv>
                  </WarningDiv>
                  {errors.amount && touched.amount ? (
                    <ErrorMessage1>{errors.amount}</ErrorMessage1>
                  ) : null}
                </InputWrapper>
              ) : (
                <InputWrapper>
                  <Label>Amount*</Label>
                  <InputInsideWapper>
                    <InputSmall
                      type="text"
                      placeholder="Currency Code"
                      // name="additionalInput"
                      value={
                        responseFromMobileAndCode?.destinationCurrencyCode ||
                        "AED"
                      }
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      readOnly
                    />
                    <Input
                      type="number"
                      placeholder="Please Select Plan*"
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      readOnly
                    />
                  </InputInsideWapper>

                  {errors.amount && touched.amount ? (
                    <ErrorMessage1>{errors.amount}</ErrorMessage1>
                  ) : null}
                </InputWrapper>
              )}

              <InputWrapper>
                {/* <Label>Nickname (Optional)</Label> */}
                <Input
                  type="text"
                  placeholder="Nickname"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  name="name"
                  onKeyDown={handleKeyPressText}
                />
              </InputWrapper>

              <ContentWrapper>{description ? description : ""}</ContentWrapper>
              <ButtonWrapper>
                <StyledButton onClick={handleSubmit}>
                  Proceed to Pay
                </StyledButton>
              </ButtonWrapper>
            </LeftContainer>

            <RightContainer>
              {/* <BrowseAllPlan>Browse Plan</BrowseAllPlan> */}
              <>
                <BrowsePerPlan>
                  {responseFromMobileAndCode === undefined ? (
                    "Browse Plans"
                  ) : (
                    // : `Browse Plans of ${responseFromMobileAndCode.name}`}
                    <>
                      Browse Plans of{" "}
                      <span
                        style={{
                          color: "#182A2F",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {responseFromMobileAndCode.name}
                      </span>
                    </>
                  )}
                </BrowsePerPlan>
                {/* <TabsButton>
                <FirstTab
                  className={`topuptab ${
                    activeTab === "popularplan" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("popularplan")}
                >
                  Popular Plans
                </FirstTab>
                <SecondTab
                  className={`topuptab ${
                    activeTab === "fixedplan" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("fixedplan")}
                >
                  Fixed Plans
                </SecondTab>
              </TabsButton> */}

                {loading1 ? (
                  <CircularLoaderWrapper>
                    <CircularLoader />
                  </CircularLoaderWrapper>
                ) : responseFromMobileAndCode === undefined ? (
                  <ErrorMessage>{responseError}</ErrorMessage>
                ) : responseError === "Please Insert Amount For Recharge" ? (
                  <ErrorMessage>{responseError}</ErrorMessage>
                ) : (
                  <TableWrapper>
                    <MainCombinedArrayHeading>
                      <TextDescriptionDiv>Description</TextDescriptionDiv>
                      <AmountDiv>Amount</AmountDiv>
                    </MainCombinedArrayHeading>
                    {combinedArray !== undefined &&
                      combinedArray.map(
                        (rowData, rowIndex) => {
                          return (
                            <MainCombinedArray key={rowIndex}>
                              <DescriptionDiv>
                                {rowData.description}
                              </DescriptionDiv>
                              <AmountDivInner>
                                {
                                  responseFromMobileAndCode.destinationCurrencySymbol
                                }{" "}
                                {rowData.amount}
                              </AmountDivInner>

                              <CombinedArrayButton
                                select={selected === rowData.amount}
                                onClick={() => {
                                  values.amount = rowData.amount;
                                  setselected(rowData.amount);
                                  setDescription(rowData.description);
                                  setErrors({});
                                }}
                              >
                                Select
                              </CombinedArrayButton>
                            </MainCombinedArray>
                          );
                        }
                        // <tr key={rowIndex}>
                        //   {rowData.map((cellData, cellIndex) => (
                        //     <TableCell key={cellIndex}>{cellData}</TableCell>
                        //   ))}
                        // </tr>
                      )}
                  </TableWrapper>
                )}
              </>
              <DisclaimerWrapper>
                <DisclaimerHead>Disclaimer:</DisclaimerHead>
                <DisclaimerContent>
                  While we support most recharges, we request you to verify with
                  your operator once before proceeding.
                </DisclaimerContent>
              </DisclaimerWrapper>
            </RightContainer>
          </MainDiv>
          <AddBannerWrapper>
            <AddBannerimg src={Adbanner4x} />
          </AddBannerWrapper>
          {/* <Model
            open={open}
            setOpen={setOpen}
            amount={values.amount}
            countryIsoName={values.selectedOptions}
            number={values.number}
            name={name}
            operatorData={responseFromMobileAndCode}
            loggedIn={loggedIn}
            activeTab={activeTab}
            // token={topup_token}
          /> */}
          {/* <BootstrapDialog
            onClose={handleCloseCrossIcon}
            aria-labelledby="customized-dialog-title"
            open={openAlert}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleCloseCrossIcon}
            >
              Alert
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>Please Login to continue ...</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={handleClose2}
                style={{
                  width: "140px",
                  border: "none",
                  borderColor: "rgb(231,231,231)",
                  backgroundColor: "#008000",
                  color: "white",
                  padding: "10px 0",
                  margin: "10px",
                  cursor: "pointer",
                  fontSize: "16px",
                  borderRadius: "8px",
                }}
              >
                Login
              </Button>
            </DialogActions>
          </BootstrapDialog> */}
          {showPopup && (
            <LoginPopup
              showPopup={showPopup}
              setShowPopup={setShowPopup}
              setLoggedIn={setLoggedIn}
            />
          )}
        </Container>
      </Root>
      {isCircularLoading && (
        <div style={styles.overlay}>
          <CircularLoader></CircularLoader>
        </div>
      )}
    </>
  );
};

export default Topuplist;
