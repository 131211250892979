import React from "react";
import styled from "styled-components";

const Root = styled.div`
  padding-top: 85px;
`;
const NavBarBackground = styled.div`
  width: 100vw;
  height: 84.8px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;
const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100vw;
  }
`;
const TextHeading = styled.div`
  margin-top: 50px;
  font-size: 2rem;
  @media (max-width: 425px) {
    font-size: 2rem;
  }
`;

const Heading = styled.div`
  margin-top: 50px;
  font-size: 28px;
  font-weight: bolder;
  @media (max-width: 425px) {
    font-size: 18px;
  }
`;

const HeadingLast = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;
  font-size: 18px;
  font-weight: bolder;
  @media (max-width: 425px) {
    font-size: 18px;
  }
`;

const Para = styled.div`
  margin-top: 20px;
  font-size: 18px;
  // font-weight: bolder;
  @media (max-width: 425px) {
    font-size: 18px;
  }
`;
const CancelPolicy = () => {
  return (
    <Root>
      <NavBarBackground></NavBarBackground>
      <Container>
        <TextHeading>Cancellation Policy</TextHeading>
        <Heading>Life is unpredictable, but cancelling is simple!</Heading>
        <Para>
          We understand that travel plans may change unexpectedly, and
          that&#39;s why we offer flexible cancellation policies for our guests.
        </Para>
        <TextHeading>Standard cancellation policy</TextHeading>
        <Para>
          1. Cancellations made at least 24 hours before the scheduled check-in
          time are eligible for a full refund.
        </Para>
        <Para>
          2. Cancellations made less than 24 hours before the scheduled check-in
          time are subject to a cancellation fee equal to the cost of one
          night&#39;s stay.
        </Para>
        <Para>
          3. No-shows and early departures are also subject to a cancellation
          fee, i.e., equal to the cost of one night&#39;s stay.
        </Para>

        <TextHeading>Cancellation of additional services</TextHeading>
        <Para>
          Cancellation of meals, Special Service Request (assistance, sporting
          material, etc.,) transfers, Premium Club, and other additional
          services. All services mentioned under this point can be cancelled,
          but reimbursement is impossible.
        </Para>
        <Para>
          Please note that this cancellation policy is subject to change based
          on specific circumstances or events.
        </Para>
        <Para>
          Any variations to the policy will be clearly communicated to guests at
          the time of booking.
        </Para>
        <Para>
          Additionally, refunds may take up to 7-10 business days to process, as
          per your bank&#39;s policies.
        </Para>

        <Para>
          If you have any enquiry or need further assistance regarding our
          cancellation policy, please do not hesitate to contact our customer
          support team. We are available to provide guidance and address any
          concerns you may have.
        </Para>

        <HeadingLast>
          Thank you for choosing our hotel for your stay.
        </HeadingLast>
      </Container>
    </Root>
  );
};

export default CancelPolicy;
