import React, { useState } from "react";
import styled from "styled-components";
import Password from "../../../images/lock.png";
import axios from "axios";
// import ReCAPTCHA from "react-google-recaptcha";
import {
  Form,
  Image,
  Input,
  InputWrapper,
} from "../../styledcomponents/popupStyledcomponents";
import { useFormik } from "formik";
import Timer from "../../Booking/Timer";
import Swal from "sweetalert2";
import { environmentVariables } from "../../../config/config";
import { LoadingButton } from "@mui/lab";
import { OtpforMailVerificationSchema } from "./Schemas/OtpforMailVerificationSchema";

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const Heading = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const Container = styled.div`
  text-align: start;
`;
const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 0px;
`;

const NavBarBackground = styled.div`
  width: 100vw;
  height: 84.8px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;
function OtpforMailVerification({ setLoginState, setShowPopup }) {
  const baseUrl = environmentVariables.apiUrl;
  const [isApiResponse, setIsApiResponse] = useState(false);
  const [data, setData] = useState("");
  const email = JSON.parse(localStorage.getItem("email"));
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(120);

  const initialValues = {
    otp: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: OtpforMailVerificationSchema,
      onSubmit: async (values, action) => {
        setIsApiResponse(true);
        await axios({
          method: "post",
          url: `${baseUrl}auth/verifyotpformailverification`,
          data: {
            email: email,
            otp: values.otp.toString(),
          },
        })
          .then((response) => {
            setIsApiResponse(false);
            setData(JSON.stringify(response.data));
            if (response.data.message === "Successfully registered") {
             
              setLoginState("login");
              // setShowPopup(false);

              localStorage.removeItem("email");
              localStorage.removeItem("authdata");
              // navigate("/");
            } else {
              Swal.fire({
                icon: "error",
                title: response.data.message,
              });
            }
          })
          .catch((error) => {
            setIsApiResponse(false);
            setError(error.response.data.message);
          });

        action.resetForm();
      },
    });

  const handleSubmit1 = async () => {
    await axios({
      method: "post",
      url: `${baseUrl}auth/emailsendformailverification`,
      data: {
        email: email,
      },
    })
      .then((response) => {
        setTimer(120);
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isCtrlPressed = event.ctrlKey || event.metaKey; 

    if (
      keyCode === 8 || 
      (keyCode >= 48 && keyCode <= 57) ||
      (isCtrlPressed && keyValue === "r") 
    ) {

      if (keyValue === "+" || keyValue === "-") {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  };
  return (
    <Root>
      <NavBarBackground></NavBarBackground>
      <Container>
        <Heading>OTP has been sent to Email</Heading>
        <Form>
          <InputWrapper>
            <Image src={Password} />
            <Input
              type="number"
              placeholder="Otp*"
              name="otp"
              value={values.otp}
              onChange={handleChange}
              onBlur={handleBlur}
              onClick={() => setError("")}
              onKeyDown={handleKeyPress}
            />
          </InputWrapper>
          {errors.otp && touched.otp ? (
            <ErrorMessage>{errors.otp}</ErrorMessage>
          ) : null}

          {error && <ErrorMessage>{error}</ErrorMessage>}
          <p>
            {timer === 0 ? (
              <p
                onClick={handleSubmit1}
                style={{
                  justifyContent: "center",
                  padding: "10px",
                  borderRadius: "5px",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Resend OTP
              </p>
            ) : (
              <Timer timer={timer} setTimer={setTimer} />
            )}
          </p>
          <LoadingButton
            onClick={handleSubmit}
            disabled={isApiResponse}
            loading={isApiResponse}
            variant="contained"
            fullWidth={true}
            size="large"
            color="primary"
          >
            Submit
          </LoadingButton>
        </Form>
      </Container>
    </Root>
  );
}

export default OtpforMailVerification;
