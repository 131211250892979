import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "react-bootstrap/Card";

import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import LoginPopup from "../Pages/Login/LoginPopup";
import axios from "axios";

import Tooltip from "@mui/material/Tooltip";
import { environmentVariables } from "../../config/config";
import ContentLoader from "react-content-loader";
import Loader from "../Shortlistedloader/Loader";
import { useAuth } from "../../ContextApi/Contextapi";
import { useQuery } from "react-query";

const Root = styled.div`
  /* margin: 20px 0; */
`;
const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1600px) {
    width: 1200px;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    width: 100vw;
    padding: 0;
  }
`;

const Text = styled.text`
  font-size: 26px;
  font-weight: 500;
  padding: 10px 0;
  @media (max-width: 768px) {
    font-size: 20px !important;
    padding: 0;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  justify-content: center;
  padding-left: 10px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    width: 100vw;
    padding-bottom: 10px;
  }
`;

const CardWrapper = styled.div`
  margin: 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  padding: 0 10px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const CityWrapper = styled.div`
  margin: 20px 0;
  @media (max-width: 768px) {
    width: 100vw;
    margin: 20px 0;
  }
`;
const HeartWrapper = styled.div`
  width: 40px;
  border-radius: 3px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  /* left: 88%; */
  right: 15px;
  top: 15px;
  /* right: 1px; */
  background-color: #fff;
  cursor: pointer;
  :hover {
    color: red !important;
  }
`;
const Msgdata = styled.div`
  color: #d63b3b;
  font-size: 20px;
  text-align: center;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 15px;
  }
  /* margin-bottom:5px; */
`;

const HotelSection = ({ filter, setLoggedIn }) => {
  const baseUrl = environmentVariables.apiUrl;
  // const [loading, setLoading] = useState(false);
  const { authData } = useAuth();
  const [showPopup, setShowPopup] = useState(false);

  // const [cityData, setCityData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isHeartLoading, setIsHeartLoading] = useState({});

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          // arrows: false,
        },
      },
    ],
  };

  const navigation = useNavigate();

  const fetchData = async () => {
    const response = await axios.get(`${baseUrl}auth/prioritydata`);
    return response.data.data;
  };

  const { data: data1, isLoading, isError } = useQuery("cityData", fetchData);

  // const getCityData = () => {
  //   setLoading(true);
  //   axios
  //     .get(`${baseUrl}auth/prioritydata`)
  //     .then((res) => {
  //       setLoading(false);
  //       setCityData(res.data.data);
  //       // getCityCarouselData(res.data.data);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  // };

  const getUser = async (key) => {
    if (authData !== undefined) {
      try {
        const data = await axios.get(`${baseUrl}auth/getuserdata`, {
          headers: {
            _token: authData?.token,
          },
        });
        setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
        setUserData(data.data.data.shortlistedArray);
        // return data.data.message;
      } catch (error) {
        setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
        return error?.message;
      }
    }
  };
  useEffect(() => {
    // getCityData();
    getUser();
  }, []);
  useEffect(() => {
    if (!authData) {
      setUserData(null);
    }
  }, [authData]);

  const onClickLike = async (item, key) => {
    const compoundKey = `${item._id}-${key}`;
    if (authData === undefined) {
      setShowPopup(true);
    } else {
      setIsHeartLoading((prevState) => ({ ...prevState, [compoundKey]: true }));
      await axios({
        url: `${baseUrl}auth/getshortlisted/${item._id}`,
        method: "post",
        headers: {
          _token: authData?.token,
        },
      })
        .then((response) => {
          getUser(compoundKey);
        })
        .catch((error) => {
          setIsHeartLoading((prevState) => ({
            ...prevState,
            [compoundKey]: false,
          }));
          setShowPopup(true);

          // console.log("error", error);
        });
    }
  };

  if (isLoading) {
    return (
      <ContentLoader
        width={2500}
        height={575}
        viewBox="0 0 1100 575"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ width: "100%" }}
      >
        <rect x="0" y="58" rx="2" ry="2" width="250" height="300" />
        <rect x="280" y="57" rx="2" ry="3" width="250" height="300" />
        <rect x="560" y="56" rx="2" ry="2" width="250" height="300" />
        <rect x="840" y="56" rx="2" ry="2" width="250" height="300" />
        <rect x="0" y="615" rx="2" ry="2" width="250" height="300" />
        <rect x="280" y="615" rx="2" ry="2" width="250" height="300" />
        <rect x="560" y="615" rx="2" ry="2" width="250" height="300" />
        <rect x="840" y="615" rx="2" ry="2" width="250" height="300" />
      </ContentLoader>
    );
  }

  if (isError) {
    return <Msgdata>Data Unavailable: Keep Exploring!</Msgdata>;
  }

  return (
    <Root>
      <Container>
        <div className="row mt-4">
          {data1 &&
            data1.length &&
            data1.map((item, index) => {
              return (
                <CityWrapper key={index}>
                  <TextWrapper>
                    <Text>{item.title}</Text>
                  </TextWrapper>
                  {/* {item.allData.length} */}
                  {item.hotels.length <= 4 && item.hotels.length >= 1 ? (
                    <CardWrapper>
                      {item.hotels.map((item, key) => {
                        return (
                          <Card id="hotel-cards" key={key}>
                            <Card.Img
                              style={{
                                maxHeight: "217px",
                                cursor: "pointer",
                                minHeight: "217px",
                              }}
                              variant="top"
                              src={
                                item.image[0] && item?.image[0] != "medianew"
                                  ? `https://bastionex-travels.b-cdn.net/uploads/${item.image[0]}`
                                  : `https://bastionex-travels.b-cdn.net/uploads/photo_2024-09-06_21-43-47.jpg`
                              }
                              onClick={() =>
                                navigation(`/hoteldetails/${item?._id}`)
                              }
                            />
                            <Tooltip
                              title="Shortlist"
                              styled={{ fontSize: "50px" }}
                            >
                              <HeartWrapper
                                onClick={() => onClickLike(item, key)}
                              >
                                {isHeartLoading[`${item._id}-${key}`] ? (
                                  <Loader />
                                ) : (
                                  <i
                                    style={{
                                      fontSize: "20px",
                                      color:
                                        userData && userData.includes(item._id)
                                          ? "red"
                                          : "#747474",
                                    }}
                                    className="fa fa-thin fa-heart style"
                                  ></i>
                                )}
                              </HeartWrapper>
                            </Tooltip>
                            <Card.Body
                              style={{
                                maxHeight: "100px",
                                marginBottom: "20px",
                                minHeight: "100px",
                              }}
                            >
                              <Card.Title
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                {item.hotelname}
                              </Card.Title>
                              <Card.Title
                                style={{
                                  fontWeight: "300",
                                  fontSize: "14px",
                                }}
                              >
                                {item.city} , {item.state}
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        );
                      })}
                    </CardWrapper>
                  ) : (
                    <Slider {...settings}>
                      {item.hotels.map((item, key) => {
                        return (
                          <Card key={key}>
                            <Card.Img
                              style={{
                                maxHeight: "217px",
                                cursor: "pointer",
                                minHeight: "217px",
                              }}
                              variant="top"
                              src={
                                item.image[0] && item?.image[0] != "medianew"
                                  ? `https://bastionex-travels.b-cdn.net/uploads/${item.image[0]}`
                                  : `https://bastionex-travels.b-cdn.net/uploads/photo_2024-09-06_21-43-47.jpg`
                              }
                              onClick={() =>
                                navigation(`/hoteldetails/${item?._id}`)
                              }
                            />
                            <Tooltip
                              title="Shortlist"
                              styled={{ fontSize: "50px" }}
                            >
                              <HeartWrapper
                                onClick={() => onClickLike(item, key)}
                              >
                                {isHeartLoading[`${item._id}-${key}`] ? (
                                  <Loader />
                                ) : (
                                  <i
                                    style={{
                                      fontSize: "20px",
                                      color:
                                        userData && userData.includes(item._id)
                                          ? "red"
                                          : "#747474",
                                    }}
                                    className="fa fa-thin fa-heart style"
                                  ></i>
                                )}
                              </HeartWrapper>
                            </Tooltip>
                            <Card.Body
                              style={{
                                maxHeight: "100px",
                                marginBottom: "20px",
                                minHeight: "100px",
                              }}
                            >
                              <Card.Title
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                {item.hotelname}
                              </Card.Title>
                              <Card.Title
                                style={{
                                  fontWeight: "300",
                                  fontSize: "14px",
                                }}
                              >
                                {item.city} , {item.state}
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        );
                      })}
                    </Slider>
                  )}
                </CityWrapper>
              );
            })}
        </div>
      </Container>
      {showPopup && (
        <LoginPopup
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          setLoggedIn={setLoggedIn}
        />
      )}
    </Root>
  );
};

export default HotelSection;
