import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import hill_black from "../../images/hill_black.png";
import hearts_black from "../../images/hearts-black.png";
import heritage_black from "../../images/heritage_black.png";
import sandals_black from "../../images/sandals_black.png";
import paw_black from "../../images/paw_black.png";
import Slider from "@mui/material/Slider";
import styled from "styled-components";
import "../Home/Home.css";
import {
  FilterWrapper,
  Root,
  FilterElements,
  FilterElements1,
  SliderLabels,
  FilterIcon,
  FilterName,
  SliderWrapper,
  DistanceRange,
  FilterStayType,
  FilterTravelPreferences,
  ClearAllButton,
  FilterTravelPreferencesHeading,
  DistanceRangeNum1,
  DistanceRangeNum2,
  FilterStayTypeMenu,
  FilterStayTypeOption,
  FilterElementsWrapper,
  FilterButtonsWrapper,
  FilterAllOptions,
} from "./RangeForm.styles";

const minDistance = 0;

const Image = styled.img``;

const RangeForm1 = ({
  data,
  setData,
  category,
  setCategory,
  mindistance,
  setmindistance,
  setDefaultDataStatus,
  showDistanceSlider,
  setShowDistanceSlider,
}) => {
  const navigation = useNavigate(null);
  const getCity = localStorage.getItem("city");
  const getInnerCity = localStorage.getItem("cityname");
  const [value1, setValue1] = useState([0, 50]);
  const [stayType, setStayType] = useState("all");
  const [interested, setInterested] = useState("all");
  const [stayTypeText, setStayTypeText] = useState("Stay Type");
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [showStayType, setShowStayType] = useState(false);
  const [isApplyActive, setIsApplyActive] = useState(false);
  const stayTypeRef = useRef();

  const handleClear = () => {
    normalFunc("all");
    categoryFunc("all");
    setStayTypeText("Stay Type");
    setValue1([0, 50]);
    setmindistance([0, 50]);
    setDefaultDataStatus(true);
  };
  const navigateToFilter = (interested, stayType) => {
    navigation("/filter", {
      state: {
        theme: interested,
        category: stayType,
        city: getInnerCity === null ? "all" : getInnerCity,
        state: getCity === null ? "all" : getCity,
        minDistance: value1[0] * 1000,
        maxDistance: value1[1] * 1000,
        lat: lat,
        long: long,
      },
    });
  };
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      setLat(null);
      setLong(null);
    }
  };
  const showPosition = (position) => {
    setLat(position.coords.latitude);
    setLong(position.coords.longitude);
    setShowDistanceSlider(true);
  };
  useEffect(() => {
    if (
      interested === "all" &&
      stayType === "all" &&
      value1[0] === 0 &&
      value1[1] === 50
    ) {
      setIsApplyActive(false);
    } else {
      setIsApplyActive(true);
    }
  }, [interested, stayType, value1]);
  useEffect(() => {
    getLocation();
  }, []);
  const handleChange1 = (event, newValue, activeThumb) => {
    setDefaultDataStatus(false);
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
      setmindistance([
        Math.min(newValue[0], value1[1] - minDistance),
        value1[1],
      ]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
      setmindistance([
        value1[0],
        Math.max(newValue[1], value1[0] + minDistance),
      ]);
    }
  };
  const normalFunc = (x) => {
    setDefaultDataStatus(false);
    setData(x);
  };
  const categoryFunc = (category) => {
    setDefaultDataStatus(false);
    setCategory(category);
    // console('............', category);
  };
  // const distanceFunc = (minDistance,maxDistance) =>{
  //   setMinDistance(minDistance);
  //   setMaxDistance(maxDistance);
  // }
  return (
    <Root>
      {" "}
      <FilterWrapper>
        <FilterElementsWrapper>
          <FilterElements
            isActive={data === "beach"}
            // onClick={() => setInterested("beach")}
            onClick={() => {
              normalFunc("beach");
              localStorage.setItem("theme", "beach");
            }}
          >
            <FilterIcon>
              <Image src={sandals_black} alt="img" />
            </FilterIcon>
            <FilterName>Beach</FilterName>
          </FilterElements>
          <FilterElements
            isActive={data === "wildlife"}
            onClick={() => {
              normalFunc("wildlife");
              localStorage.setItem("theme", "wildlife");
            }}
          >
            <FilterIcon>
              <Image src={paw_black} alt="img" />
            </FilterIcon>
            <FilterName>Wildlife</FilterName>
          </FilterElements>
          <FilterElements
            isActive={data === "romantic"}
            onClick={() => {
              normalFunc("romantic");
              localStorage.setItem("theme", "romantic");
            }}
          >
            <FilterIcon>
              <Image src={hearts_black} alt="img" />
            </FilterIcon>
            <FilterName>Romantic</FilterName>
          </FilterElements>
          <FilterElements
            isActive={data === "hill"}
            onClick={() => {
              normalFunc("hill");
              localStorage.setItem("theme", "hill");
            }}
          >
            <FilterIcon>
              <Image src={hill_black} alt="img" />
            </FilterIcon>
            <FilterName>Hill</FilterName>
          </FilterElements>
          <FilterElements
            isActive={data === "heritage"}
            onClick={() => {
              normalFunc("heritage");
              localStorage.setItem("theme", "heritage");
            }}
          >
            <FilterIcon>
              <Image src={heritage_black} alt="img" />
            </FilterIcon>
            <FilterName>Heritage</FilterName>
          </FilterElements>
        </FilterElementsWrapper>
        {/* <FilterElements1 ref={stayTypeRef}>
          <FilterStayType>
            <FilterAllOptions onClick={() => setShowStayType(!showStayType)}>
              <FilterName>{stayTypeText}</FilterName>
              <i className="fa fa-light fa-angle-down"></i>
            </FilterAllOptions>

            {showStayType && (
              <FilterStayTypeMenu>
                <FilterStayTypeOption
                  onClick={() => {
                    categoryFunc("economy");
                    setStayTypeText("Economy");
                    localStorage.setItem("category", "economy");
                    setShowStayType(false);
                  }}
                >
                  Economy
                </FilterStayTypeOption>
                <FilterStayTypeOption
                  onClick={() => {
                    categoryFunc("midrange");
                    setStayTypeText("Mid Range");
                    localStorage.setItem("category", "midrange");
                    setShowStayType(false);
                  }}
                >
                  Mid Range
                </FilterStayTypeOption>
                <FilterStayTypeOption
                  onClick={() => {
                    categoryFunc("luxury");
                    setStayTypeText("Luxury");
                    localStorage.setItem("category", "luxury");
                    setShowStayType(false);
                  }}
                >
                  Luxury
                </FilterStayTypeOption>
                <FilterStayTypeOption
                  onClick={() => {
                    categoryFunc("all");
                    setStayTypeText("All");
                    localStorage.setItem("category", "all");
                    setShowStayType(false);
                  }}
                >
                  All
                </FilterStayTypeOption>
              </FilterStayTypeMenu>
            )}
          </FilterStayType>
        </FilterElements1> */}
        {lat === null || showDistanceSlider === false ? (
          <></>
        ) : (
          <FilterElements1>
            <FilterTravelPreferences>
              <FilterTravelPreferencesHeading>
                Travel Preferences
              </FilterTravelPreferencesHeading>
              <SliderWrapper>
                <DistanceRange>
                  <SliderLabels
                    style={{
                      marginBottom: "40px",
                      fontSize: "10px",
                      fontWeight: "500",
                    }}
                  ></SliderLabels>
                  <Slider
                    value={value1}
                    onChange={handleChange1}
                    valueLabelDisplay="off"
                    min={0}
                    max={50}
                    step={5}
                    disableSwap
                    valueLabelFor4mat={(val) => <div>{`${val} km`}</div>}
                    sx={{
                      color: "#000", // Change the color to purple
                      "& .MuiSlider-rail": {
                        backgroundColor: "gray", // Change the color of the rail
                      },
                      "& .MuiSlider-track": {
                        backgroundColor: "#000", // Change the color of the track
                      },
                      "& .MuiSlider-thumb": {
                        backgroundColor: "#000", // Change the color of the thumb
                      },
                    }}
                  />
                </DistanceRange>
                <DistanceRangeNum1>{`${value1[0]} km`}</DistanceRangeNum1>
                <DistanceRangeNum2>{`${value1[1]} km`}</DistanceRangeNum2>
              </SliderWrapper>
            </FilterTravelPreferences>
          </FilterElements1>
        )}
        <FilterButtonsWrapper>
          <ClearAllButton className="clrallbtn" onClick={handleClear}>
            Clear All
          </ClearAllButton>
          {/* <ApplyButton
            isApplyActive={isApplyActive}
            onClick={() =>
              isApplyActive && navigateToFilter(interested, stayType)
            }
          >
            Apply
          </ApplyButton> */}
        </FilterButtonsWrapper>
        {/* <FilterButtonWrapper>
          <FilterButtonIcon>
            <Image style={{ width: "20px" }} src={filter_icon} alt="img"/>
          </FilterButtonIcon>
          <FilterButton>Filter</FilterButton>
        </FilterButtonWrapper> */}
      </FilterWrapper>
    </Root>
  );
};

export default RangeForm1;
