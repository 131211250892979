import * as Yup from "yup";

export const GiftCardSchemaRange = Yup.object({
  cardValue: Yup.number().required("Please Select Amount"),
  quantity: Yup.number()
    .positive("Please select a valid quantity")
    .required("Quantity is required"),
  selectedOptions: Yup.string().required("Please Select Country Code"),
  selectedGender: Yup.string().required("Please Select Delivery Method"),
  recieverName: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter Receiver’s name"),
  receiverEmail: Yup.string().email().required("Please enter Receiver’s Email"),
  receiverMobile: Yup.string().required("Please enter Receiver’s Mobile No."),

  senderName: Yup.string().min(2).max(25).required("Please enter Your name"),
  senderEmail: Yup.string().email().required("Please enter Your email"),
});
