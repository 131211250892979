import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import styled from "styled-components";
import Card from "react-bootstrap/Card";
import Hotel from "../../../../images/hotelimg1.jpg";
import { FaComments, FaPlusCircle, FaRupeeSign } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useContext } from "react";
import { AuthContext, useAuth } from "../../../../ContextApi/Contextapi";
import LoginPopup from "../../../Pages/Login/LoginPopup";
import { environmentVariables } from "../../../../config/config";
import Loader from "../../../Shortlistedloader/Loader";

const TextHeading = styled.div`
  color: #34495e;
  font-size: 17px;
  font-weight: 700;
  line-height: 50px;
  margin-left: 20px;
`;
const TextWrapper = styled.div`
  display: flex;
  margin-left: 15px;
  font-size: 16px;
  padding-bottom: 10px;
`;

const HeadingWrapper = styled.text`
  color: #131313;
  font-size: 32px;
  font-weight: bold;
`;

const IconLocation = styled.div`
  /* margin-top: 3px; */
  margin-left: 2px;
`;
const TextLocation = styled.div`
  margin-left: 10px;
  color: #6e6666;
`;

const TextPara = styled.div`
  width: auto;
  border-top: 1px solid #0000001c;
  border-bottom: 1px solid #0000001c;
  overflow: auto;
  height: 80px;
  line-height: 25px;
  padding: 1px 15px 0 20px;
  overflow: auto;
  color: rgb(102, 102, 102);

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #fff;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
    height: 30px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }
  @media (max-width: 768px) {
    width: 90vw;
  }
`;

const Button = styled.button`
  background-color: #3554d1;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-bottom: 1px solid #008d35;
  box-sizing: border-box;
  height: 40px;
  border: none;
`;

const TextIconOne = styled.div`
  font-size: 14px;
  color: #34495e;
  cursor: pointer;
`;
const TextIconTwo = styled.div`
  font-size: 14px;
  margin-left: 50px;
  color: #34495e;
  cursor: pointer;
`;

const TextIconThree = styled.div`
  display: flex;
  margin: 5px auto;
`;

const Li = styled.text``;

const HeartWrapper = styled.div`
  width: 40px;
  border-radius: 3px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  /* left: 88%; */
  right: 15px;
  top: 15px;
  /* right: 1px; */
  background-color: #fff;
  cursor: pointer;
  :hover {
    color: red !important;
  }
`;
const CityWrapper = styled.div`
  margin: 20px 0;
`;
const CardWrapper = styled.div`
  margin: 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 0 10px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const ResortsCard = () => {
  const baseUrl = environmentVariables.apiUrl;
  const { state } = useLocation();
  const navigation = useNavigate();
  const [data, setData] = useState();
  // const { authData, setAuthData } = useContext(AuthContext);
  const [userData, setUserData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isHeartLoading, setIsHeartLoading] = useState({});
  const { authData } = useAuth();
  const getCity = async () => {
    await axios({
      method: "post",
      url: `${baseUrl}auth/gethotel`,
      data: {
        city: state.cityName,
      },
    })
      .then((response) => {
        setData(response.data.message);
      })
      .catch((error) => {
        setData();
      });
  };

  useEffect(() => {
    getCity();
  }, [state]);

  const onClickLike = async (item, key) => {
    const compoundKey = `${item._id}-${key}`;
    if (authData === undefined) {
      setShowPopup(true);
    } else {
      setIsHeartLoading((prevState) => ({ ...prevState, [compoundKey]: true }));
      await axios({
        url: `${baseUrl}auth/getshortlisted/${item._id}`,
        method: "post",
        headers: {
          _token: authData?.token,
        },
      })
        .then((response) => {
          getUser(compoundKey);
        })
        .catch((error) => {});
    }
  };

  const getUser = async (key) => {
    try {
      const data = await axios.get(`${baseUrl}auth/getuserdata`, {
        headers: {
          _token: authData?.token,
        },
      });
      setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
      setUserData(data.data.data.shortlistedArray);
      // return data.data.message;
    } catch (error) {
      setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
      return error?.message;
    }
  };

  useEffect(() => {
    getUser();
  }, [authData]);
  return (
    <>
      <Container style={{ marginTop: "50px" }}>
        <HeadingWrapper> Similar Hotel... </HeadingWrapper>
        <CityWrapper>
          {
            <CardWrapper>
              {data &&
                data.map((item, key) => {
                  console.log("item.image[0]",item?.image[0])
                  return (
                    <Card id="hotel-cards">
                      <Card.Img
                        style={{
                          maxHeight: "217px",
                          cursor: "pointer",
                          minHeight: "217px",
                        }}
                        variant="top"
                        src={
                          item.image[0] && item?.image[0] != "medianew"
                            ? `https://bastionex-travels.b-cdn.net/uploads/${item.image[0]}`
                            : `https://bastionex-travels.b-cdn.net/uploads/photo_2024-09-06_21-43-47.jpg`
                        }
                        onClick={() =>
                          navigation(`/hoteldetails/${item?._id}`)
                        }
                      />
                      <Tooltip title="Shortlist" styled={{ fontSize: "50px" }}>
                        <HeartWrapper onClick={() => onClickLike(item, key)}>
                          {isHeartLoading[`${item._id}-${key}`] ? (
                            <Loader />
                          ) : (
                            <i
                              style={{
                                fontSize: "20px",
                                color:
                                  userData && userData.includes(item._id)
                                    ? "red"
                                    : "#747474",
                              }}
                              className="fa fa-thin fa-heart style"
                            ></i>
                          )}
                        </HeartWrapper>
                      </Tooltip>
                      <Card.Body
                        style={{
                          maxHeight: "100px",
                          marginBottom: "20px",
                          minHeight: "100px",
                        }}
                      >
                        <Card.Title
                          style={{ fontWeight: "500", fontSize: "16px" }}
                        >
                          {item.hotelname}
                        </Card.Title>
                        <Card.Title
                          style={{ fontWeight: "300", fontSize: "14px" }}
                        >
                          {item.city} , {item.state}
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  );
                })}
              {showPopup && (
                <LoginPopup showPopup={showPopup} setShowPopup={setShowPopup} />
              )}
            </CardWrapper>
          }
        </CityWrapper>
      </Container>
    </>
  );
};

export default ResortsCard;
