import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Holiday from "../../images/holiday.png";
import CardImage from "../../images/Group.png";
import CardLogoIcon from "../../images/card-logo.png";
import GiftCardBG from "../../images/GiftBannercard.png";
import BackArrowIconImg from "../../images/LeftArroww.png";
// import BackIconBtn from "../../images/LeftArroww.png";
import GiftCardTabs from "./GiftCardTabs";
import axios from "axios";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import "./GiftCard.css";
import ContentLoader from "react-content-loader";
import { useFormik } from "formik";
import { generateGiftCardSchema } from "./GiftCardFormSchema";
import CircularLoader from "../CircularLoader/CircularLoader";
import Swal from "sweetalert2";
import { environmentVariables } from "../../config/config";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled as styleMat } from "@mui/material/styles";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import LoginPopup from "../Pages/Login/LoginPopup";
import Model from "./Model";
import { GiftCardSchemaRange } from "./GiftCardSchemaRange";
import GiftCardDescription from "./GiftCardDesciption";
import CryptoJS from "crypto-js";
import TextBackgroundImg from "../../images/giftcardtextbg.png";
import { useAuth } from "../../ContextApi/Contextapi";

const Root = styled.div`
  padding: 0 0 10px 0;
  background-color: #fff;
`;
const CardLogo = styled.div``;
const CardLogoImg = styled.img`
  // width: 250px;
  width: 160px;
`;

const BackButtonWrapper = styled.div`
  width: 100px;
  background-color: white;
  border-radius: 5px;
`;
const BackButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 32px;
  color: #007bff;
  transition: color 0.3s ease;
  width: 100%;
  &:hover {
    color: #0056b3;
  }
`;

const Container = styled.div`
  width: 1200px;
  margin: 10px auto 0px auto;
  @media (max-width: 768px) {
    // display: none;
  }
  @media (max-width: 1600px) {
    width: 1200px;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
`;
const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 18px 0px;
  width: 100%;
`;
const MainDiv1 = styled.div`
  display: flex;
  margin: 18px 0px;
  width: 100%;
  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const LeftSideContainerWithText = styled.div`
  width: 55%;
  @media (max-width: 900px) {
    width: 75%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const HowTouseWrapper = styled.div`
  width: 100%;
`;

const LeftContainer = styled.div`
  background-color: #e1e1e1;
  position: relative;
  overflow: hidden;
  z-index: 0;

  padding: 15px;
  width: 100%;
  // border: 1.5px dashed #05103680;
  // box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  // background-image: url(${GiftCardBG});
  // background-repeat: no-repeat;
  // background-size: contain;
  // background-position: center;
  // width:100%;
  height: fit-content;
`;
const ContainerOne = styled.div`
  width: 80px;
  height: 70px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  // box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
  bottom: 100px;
  left: -50px;
  @media (max-width: 1024px) {
    bottom: 125px;
  }
  @media (max-width: 900px) {
    bottom: 115px;
  }
  @media (max-width: 590px) {
    bottom: 155px;
  }
  @media (max-width: 520px) {
    bottom: 165px;
  }
`;
const ContainerTwo = styled.div`
  width: 80px;
  height: 70px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  // box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
  bottom: 100px;
  right: -50px;
  @media (max-width: 1024px) {
    bottom: 125px;
  }
  @media (max-width: 900px) {
    bottom: 115px;
  }
  @media (max-width: 590px) {
    bottom: 155px;
  }
  @media (max-width: 520px) {
    bottom: 165px;
  }
`;

const DashedLineWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  bottom: 135px;
  z-index: 1;
  left: 0px;
  width: 100%;
  @media (max-width: 1024px) {
    bottom: 157px;
  }
  @media (max-width: 900px) {
    bottom: 145px;
  }
  @media (max-width: 590px) {
    bottom: 188px;
  }
  @media (max-width: 520px) {
    bottom: 200px;
  }
`;

const DashedLine = styled.div`
  border: 2px dashed #6b6b6b45;
  width: calc(100% - 70px);
`;
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  // background-color: white;
  padding: 0 15px 15px 15px;
  margin-left: 25px;
  width: 45%;
  border-radius: 10px;
  @media (max-width: 1230px) {
    width: 50%;
  }
  @media (max-width: 900px) {
    margin-left: 0px;
    margin-top: 20px;
    width: 75%;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-top: 20px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const Image = styled.img`
  border-radius: 20px;
  width: 96%;
  margin-top: 10px;
`;
const BoldText = styled.text`
  color: #000000;
  font-weight: 500;
`;
const ParaText = styled.text`
  color: #6d6e71;
  font-size: 14px;
  font-weight: 400;
`;

const TravelCardWrapper = styled.div`
  display: flex;
  // margin: 11px 0px;
`;

const PriceContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  // padding-left: 50px;
`;
const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AmountMoney = styled.text`
  color: rgb(92 92 92);
  font-size: 30px;
  font-weight: 600;
  // margin-left: 50px;
  // border-left: 1px solid #d5d5d5;
  // padding-left: 20px;
  @media (max-width: 768px) {
    font-size: 26px;
    border-left: none;
    margin-left: 0px;
    padding-left: 0px;
  }
`;

const MiddleContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px 16px;
`;
const HeadingTxt = styled.div`
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 400;
`;
const Line = styled.div`
  width: 100%;
  border: 1px solid #00000075;
  opacity: 0.2;
  margin: 6px 0;
`;
const ImageCorosel = styled.div``;
const ImageCoroselFirstContainer = styled.div`
  display: flex;
  margin: 2px 0px 2px 0px;
`;

const Lowercontent = styled.div`
  margin: 0px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 12px;
  @media (max-width: 590px) {
    display: flex;
    flex-direction: column;
  }
`;
const ValueQuantityRangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
const ValueQuantityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  @media (max-width: 590px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;
const RangeWrapper = styled.div`
  // display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px 10px;
  // margin-top: 10px;
  margin: 22px 0;
  // margin: px 0

  justify-content: flex-start;
  /* justify-content: space-between; */
  @media (max-width: 1175px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 425px) {
    grid-template-columns: 1fr 1fr;
  }
`;
const ValueWrapper = styled.div`
  width: 100%;
  // margin: 0 8px 0 0;

  ${(p) =>
    p.SelectMargin &&
    `
margin-right: 10px;

`}

  @media(max-width:1024px) {
    ${(p) =>
      p.QuantityWrapper &&
      `
margin-top: 10px;
`}
  }
  @media (max-width: 590px) {
    width: 100%;
  }
`;
const TextHead = styled.div`
  opacity: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #707070;
`;
const Input = styled.input`
  font-size: 16px;
  color: #231f20;
  // font-weight: 700;
  padding: 0px 0 2px 0;
  background-color: transparent;
  outline: none;
  // padding: 0px 10px;
  border: none;
  border-bottom: 1px solid #989898;
  border-radius: 0;
  // border: 1px solid gray;
  // width: 250px;
  // height: 50px;
  // font-size: 16px;
  // color: #727272;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }

  &::placeholder {
    color: #707070 !important;
    font-size: 14px !important;
    fone-weight: 300 !important;
  }

  ${(p) =>
    p.fullWidthInput &&
    `width:100%;
  `};
  ${(p) =>
    p.ReceiverInput &&
    `
  @media (max-width: 768px){
    width: 100%;
  }
`}
`;
const InputTextArea = styled.textarea`
  background-color: transparent;
  outline: none;
  padding: 8px 10px 8px 10px;
  width: 100%;
  height: 95px;
  // border-color: #d0d0d0;
  border: none !important;
  border-bottom: 1px solid #989898 !important;
  border-radius: 0;
  font-size: 12px;
  border: 1px solid gray;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const SelectOptions = styled.select`
  outline: none;
  // padding: 8px 10px;
  padding: 0px 0px 2px;
  // border-radius: 5px;

  // border: 1px solid gray;
  border: none;
  border-bottom: 1px solid #989898;
  border-radius: 0;
  width: 100%;
  // width: 250px;
  // height: 50px;
  font-size: 20px;
  background-color: transparent;
  color: #727272;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const MoneyValueWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: center;
`;

const MoneyValue = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 10px 26px;
  // margin: 0px 5px;
  border-radius: 5px;
  display: flex;
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px 20px;
  background-color: rgb(255 255 255, 0.1);
  color: #3a3a3a;
  // border: 1px solid #d1d1d1;
  border: none;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  // opacity:10%;
  justify-content: center;
  ${(p) =>
    p.select &&
    `color:white;
    width:100%;
    margin: 0px 5px;
    border: 1px solid rgb(0 54 128);
    outline: none;
    border:none;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    padding:8px 18px;
    border-radius: 5px;
    // background-color: #003680eb;
    background: linear-gradient(to left, #0059A7 0%, #4297E2 100%);
    border-radius: 5px;
    justify-content: center;
    transform: scale(1.1);
  `};
`;
const DeliverWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
`;
const DeliveryText = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: #231f20;
`;
const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const ToWrapper = styled.div`
  margin-top: 11px;
`;
const ToInside = styled.div`
  opacity: 60%;
`;
const InputInToWrapper = styled.div`
  margin-right: 25px;
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const InputSecondWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
    margin-top: 0px;
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const MessageWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
  margin-bottom: 20px;
`;
const StyledButton = styled.button`
  // background-color: #3554d1;
  // background-image:linear-gradient()
  background: linear-gradient(to left, #0059a7 0%, #4297e2 100%);
  color: #fff;
  border: none;
  font-weight: 400;
  border-radius: 50px;
  padding: 18px 20px;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  // mrgin:10px 0;
  margin: 10px 0;

  &:hover {
    background-color: #0056b3;
  }
`;

const InputCountryCodeWrapper = styled.div`
  display: flex;
  // width: 100px;
  margin: 20px 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const OptionImage = styled.img`
  width: 25px;
  height: 25px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 20px;
`;
const InputSenderWrapper = styled.div`
  display: flex;
  // margin: 0 8px 0 0;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    // width: 50%;
  }
  // ${(p) =>
    p.InputSenderWrapperSelectMar &&
    `
// @media(max-width: 1024px){
//   margin-top: 20px;
// }
// `}

  ${(p) =>
    p.SelectMarginInputSenderWrapper &&
    `
margin-right: 10px;
width: 50%;
@media (max-width: 1024px) {
width: 100% !important;
margin-top:20px !important;
  `}
  ${(p) =>
    p.SelectMargin &&
    `
margin-right: 10px;
width: 100%;
`}
  ${(p) =>
    p.SenderFrom &&
    `
  width: 50%;
  `}
`;
const WarningDiv = styled.div`
  display: flex;
  font-size: 12px;
  justify-content: flex-start;
  color: #ff9966;
`;
const WarningAmountDiv = styled.div`
  display: flex;

  ${(p) =>
    p.select &&
    `margin-left:30px;
  `};
`;
const KeyDiv = styled.div``;
const ValueDiv = styled.div`
  margin-left: 10px;
`;
const CardNameHeader = styled.div`
  width: 50%;
  margin: 20px 0px 0px;
  font-weight: 500;
  @media (max-width: 900px) {
    width: 42%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 768px) {
    width: 48%;
  }
  @media (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
`;
const InsideCardNameHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
`;

const SelectStyled = styled(Select)`
  height: auto !important;
  border: none !important;
  @media (max-width: 1024px) {
    // width: 25%;
    margin-top: 10px;
  }
  @media (max-width: : 768px) {
    width: 25%;
  }
`;

const GiftCardHeadingWrapper = styled.div`
display: flex;
justify-content: center;
text-align: center;
align-items: center;
background-image: url(${TextBackgroundImg});
background-repeat: no-repeat;
background-size: cover
background-position: center;
margin-bottom:20px ;
`;

const GiftCardHeadText = styled.div`
  font-weight: 300;
  font-size: 30px;
  padding: 25px 0;
  color: white;
`;

const BackIconBtn = styled.div`
  padding-right: 15px;
  cursor: pointer;
  color: red !important;
`;
const BackArrowIcon = styled.img``;

const VarticalLineWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const VirticalLine = styled.div`
  width: 4px;
  background-color: #666666;
  opacity: 25%;
  height: 75%;
  border-radius: 10px;
  margin: 0 20px;
`;

const BootstrapDialog = styleMat(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    // boxShadow: "0px 0px 30px 10px #00000042",
    borderRadius: "15px",
    boxShadow: "0px 0px 30px 10px #00000042",
    fontSize: "18px",
    display: "flex",
    color: "yellow",
    justifyContent: "center",
    textAlign: "-webkit-right",
    width: "390px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    fontSize: "18px",
    display: "flex",
    color: "red",
    justifyContent: "center",
    textAlign: "-webkit-right",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },

  [theme.breakpoints.down("1440")]: {
    "& .MuiPaper-root": {
      minWidth: "470px", // Set a different maximum width for screens <= 1023px
    },
  },
  [theme.breakpoints.down("550")]: {
    "& .MuiPaper-root": {
      minWidth: "380px", // Set a different maximum width for screens <= 1023px
    },
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, color: "#fff" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
const CardDetail = ({
  active,
  setActive,
  productId,
  setProductId,
  loggedIn,
  setLoggedIn,
  againData,
  setAgainData,
  // giftCard_token,
}) => {
  const baseUrl = environmentVariables.apiUrl;
  // const giftToken = JSON.parse(localStorage.getItem("giftcard_token"));
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [selected, setselected] = useState();
  const [responseData, setResponseData] = useState();
  const countryName = localStorage.getItem("countryISOCode");
  const [isCircularLoading, setIsCircularLoading] = useState(false);
  const [receiverMessage, setRecieverMessage] = useState(
    againData === null ? "" : againData?.message
  );
  const { authData } = useAuth();

  const [countries, setCountries] = useState([]);
  const [productFromApi, setProductFromApi] = useState();

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleChangeRecieverMessage = (event) => {
    setRecieverMessage(event.target.value);
  };

  const initialValues = {
    cardValue:
      againData === null
        ? productFromApi?.fixedRecipientDenominations?.[0] || 0
        : againData?.unitPrice,
    quantity: againData === null ? 1 : againData?.quantity,
    selectedGender: "email",
    recieverName: againData === null ? "" : againData?.recieverName,
    receiverEmail: againData === null ? "" : againData?.recieverEmail,
    selectedOptions: countryName ? countryName : "AE",
    receiverMobile: againData === null ? "" : againData?.recieverMobile,
    senderName: againData === null ? "" : againData?.senderName,
    senderEmail: againData === null ? "" : againData?.senderEmail,
  };

  const GiftCardSchema = generateGiftCardSchema(
    productFromApi !== undefined && productFromApi.minRecipientDenomination,
    productFromApi !== undefined && productFromApi.maxRecipientDenomination
  );
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema:
      productFromApi !== undefined &&
      (productFromApi.fixedRecipientDenominations.length >= 1
        ? GiftCardSchemaRange
        : GiftCardSchema),
    onSubmit: async (values, action) => {
      if (loggedIn === false) {
        setOpenAlert(false);
        setShowPopup(!showPopup);
      } else {
        setIsCircularLoading(true);

        const data = {
          productId: productFromApi !== undefined && productFromApi.productId,
          senderEmail: values.senderEmail,
          senderName: values.senderName,
          recieverName: values.recieverName,
          recieverEmail: values.receiverEmail,
          recieverMobile: values.receiverMobile,
          recieverCountryCode: values.selectedOptions,
          message: receiverMessage,
          quantity: Number(values.quantity),
          unitPrice:
            againData === null
              ? productFromApi?.fixedRecipientDenominations?.length >= 1
                ? productFromApi?.fixedRecipientDenominations[0]
                : values.cardValue
              : values.cardValue || againData?.unitPrice,
          email: authData?.email,
          currencyCode:
            productFromApi !== undefined &&
            productFromApi.recipientCurrencyCode,
          productName : productFromApi !== undefined && productFromApi.productName
        };
        // data.currency_name = "matic";
        // data.userAddress = "A99oooooooooooooozxx"
        const config = {
          method: "post",
          url: `${baseUrl}auth/makeconnectionforpg_giftcard?type=giftcard`,
          headers: {
            _token: authData.token,
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
           
            if(response.data.message.url){
              window.location.href = response.data.message.url;
            }
            setIsCircularLoading(false);
            setOpen(false);
            setLoading(false);
          })
          .catch(function (error) {
            setOpen(false);
            setIsCircularLoading(false);
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
        // setOpen(true);
      }
    },
  });
  // const handleClose2 = () => {
  //   setOpenAlert(false);
  //   setShowPopup(!showPopup);
  // };
  const handleCloseCrossIcon = () => {
    setOpenAlert(false);
  };

  const handleBackClick = () => {
    setActive("lists");
    setProductId();
    setAgainData(null);
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  const loadAllcountries = () => {
    setLoading(true);

    const config = {
      method: "post",
      url: `${baseUrl}auth/getallcountriesgiftcard`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        setCountries(response.data.data);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };
  const loadProductById = () => {
    setLoading1(true);

    const config = {
      method: "post",
      url: `${baseUrl}auth/getproductbyidgiftcard/${productId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setProductFromApi(response.data.data);
        setLoading1(false);
      })
      .catch(function (error) {
        setProductFromApi();
        setLoading1(false);
      });
  };
  useEffect(() => {
    loadAllcountries();
    loadProductById();
  }, []);
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isCtrlPressed = event.ctrlKey || event.metaKey; // Check if Ctrl key is pressed

    if (
      keyCode === 8 || // Allow backspace key (key code 8)
      keyCode === 46 ||
      (keyCode >= 48 && keyCode <= 57) || // Allow numeric keys (0-9)
      (isCtrlPressed && keyValue === "r") || // Allow Ctrl+R combination
      keyCode === 37 || // Allow left arrow key (key code 37)
      keyCode === 39 // Allow right arrow key (key code 39)
    ) {
      // Check for plus (+) and minus (-) symbols
      if (keyValue === "+" || keyValue === "-") {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  };
  const handleKeyPressText = (e) => {
    const onlyLettersRegex = /^[A-Za-z\s]+$/;

    if (!onlyLettersRegex.test(e.key)) {
      e.preventDefault();
    }
  };
  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)", // semi-transparent black background
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999, // set a high z-index to ensure it's on top
    },
    blurredRoot: {
      filter: "blur(5px)", // adjust the blur amount as needed
    },
  };
  return (
    <>
      <Root style={isCircularLoading ? styles.blurredRoot : {}}>
        {/* <NavBarBackground></NavBarBackground>
      <GiftCardTabs /> */}
        <GiftCardHeadingWrapper>
          <GiftCardHeadText>GIFT CARDS DETAILS</GiftCardHeadText>
        </GiftCardHeadingWrapper>
        <Container>
          {/* <BackButtonWrapper>
            <BackButton onClick={handleBackClick}>←</BackButton>
          </BackButtonWrapper> */}

          <MainDiv>
            {loading1 ? (
              <ContentLoader
                width={1230}
                height={575}
                viewBox="0 0 1100 575"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="58" rx="2" ry="2" width="250" height="300" />
                <rect x="280" y="57" rx="2" ry="3" width="250" height="300" />
                <rect x="560" y="56" rx="2" ry="2" width="250" height="300" />
                <rect x="840" y="56" rx="2" ry="2" width="250" height="300" />
                <rect x="0" y="615" rx="2" ry="2" width="250" height="300" />
                <rect x="280" y="615" rx="2" ry="2" width="250" height="300" />
                <rect x="560" y="615" rx="2" ry="2" width="250" height="300" />
                <rect x="840" y="615" rx="2" ry="2" width="250" height="300" />
              </ContentLoader>
            ) : (
              <>
                <CardNameHeader>
                  <InsideCardNameHeader>
                    <BackIconBtn onClick={handleBackClick}>
                      <BackArrowIcon src={BackArrowIconImg} />
                    </BackIconBtn>
                    <div> {productFromApi?.productName} </div>
                  </InsideCardNameHeader>
                </CardNameHeader>
                <MainDiv1>
                  <LeftSideContainerWithText>
                    <LeftContainer>
                      <ContainerOne></ContainerOne>
                      <ContainerTwo></ContainerTwo>
                      <DashedLineWrapper>
                        <DashedLine></DashedLine>
                      </DashedLineWrapper>
                      <ImageWrapper>
                        <Image
                          src={
                            productFromApi !== undefined &&
                            productFromApi?.logoUrls[0]
                          }
                          alt="image"
                        />
                      </ImageWrapper>
                      <MiddleContent>
                        {/* <BoldText>Hi reciever , </BoldText> */}
                        {/* <ParaText>
                        You've got a {productFromApi?.productName}(
                        {productFromApi?.brand?.brandName}), E-Gift Card(Instant
                        Voucher)
                      </ParaText> */}
                      </MiddleContent>
                      <Lowercontent>
                        <CardLogo>
                          <CardLogoImg src={CardLogoIcon} />
                        </CardLogo>
                        <VarticalLineWrapper>
                          <VirticalLine> </VirticalLine>
                        </VarticalLineWrapper>
                        <TravelCardWrapper>
                          <PriceContentWrapper>
                            <ParaText>
                              You've got a {productFromApi?.productName}(
                              {productFromApi?.brand?.brandName}), E-Gift
                              Card(Instant Voucher)
                            </ParaText>
                            <PriceWrapper>
                              <AmountMoney>
                                {productFromApi?.recipientCurrencyCode}{" "}
                                {productFromApi?.fixedRecipientDenominations
                                  ?.length === 1
                                  ? productFromApi
                                      ?.fixedRecipientDenominations[0] *
                                    values.quantity
                                  : productFromApi !== undefined &&
                                    productFromApi.fixedRecipientDenominations
                                      .length > 1
                                  ? (values.cardValue ||
                                      productFromApi
                                        ?.fixedRecipientDenominations[0]) *
                                    values.quantity
                                  : values.cardValue * values.quantity}
                              </AmountMoney>
                            </PriceWrapper>
                          </PriceContentWrapper>
                        </TravelCardWrapper>
                      </Lowercontent>
                    </LeftContainer>
                    <HowTouseWrapper>
                      <GiftCardDescription
                        poductData={
                          productFromApi !== undefined && productFromApi
                        }
                      />
                    </HowTouseWrapper>
                  </LeftSideContainerWithText>

                  <RightContainer>
                    <HeadingTxt>Enter your Gift Card details</HeadingTxt>
                    {/* <Line /> */}
                    <ValueQuantityRangeWrapper>
                      <ValueQuantityWrapper>
                        {productFromApi?.fixedRecipientDenominations.length >
                        1 ? (
                          <ValueWrapper SelectMargin>
                            <TextHead>Select Card Value*</TextHead>
                            <Input
                              type="number"
                              placeholder="₹500 - ₹50,000"
                              name="cardValue"
                              value={
                                values.cardValue || initialValues.cardValue
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onClick={() => setselected(null)}
                              readOnly
                            />
                            {errors.cardValue && touched.cardValue ? (
                              <ErrorMessage>{errors.cardValue}</ErrorMessage>
                            ) : null}
                          </ValueWrapper>
                        ) : productFromApi?.denominationType === "RANGE" ? (
                          <ValueWrapper SelectMargin>
                            <TextHead>Enter GiftCard Amount*</TextHead>
                            <Input
                              type="number"
                              placeholder="₹500 - ₹50,000"
                              name="cardValue"
                              value={values.cardValue}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onClick={() => setselected(null)}
                              onKeyDown={handleKeyPress}
                            />
                            <WarningDiv>
                              <WarningAmountDiv>
                                <KeyDiv>min amount:</KeyDiv>
                                <ValueDiv>
                                  {productFromApi?.minRecipientDenomination}
                                </ValueDiv>
                              </WarningAmountDiv>
                              <WarningAmountDiv select>
                                <KeyDiv>max amount:</KeyDiv>
                                <ValueDiv>
                                  {productFromApi?.maxRecipientDenomination}
                                </ValueDiv>
                              </WarningAmountDiv>
                            </WarningDiv>

                            {errors.cardValue && touched.cardValue ? (
                              <ErrorMessage>{errors.cardValue}</ErrorMessage>
                            ) : null}
                          </ValueWrapper>
                        ) : (
                          <></>
                        )}

                        {productFromApi?.fixedRecipientDenominations.length ===
                        1 ? (
                          <></>
                        ) : (
                          <RangeWrapper>
                            {productFromApi?.fixedRecipientDenominations
                              ?.slice(0, 5)
                              .map((item, index) => {
                                return (
                                  <MoneyValueWrapper>
                                    <MoneyValue
                                      select={
                                        (selected !== undefined &&
                                          selected === item) ||
                                        (againData !== null &&
                                          (selected === undefined ||
                                            selected === againData.unitPrice) &&
                                          againData.unitPrice === item) ||
                                        (selected === undefined &&
                                          againData === null &&
                                          index === 0)
                                      }
                                      onClick={() => {
                                        values.cardValue = item;
                                        setselected(item);
                                        setErrors({ cardValue: "" });
                                      }}
                                      key={index}
                                    >
                                      {productFromApi?.recipientCurrencyCode}{" "}
                                      {item}
                                    </MoneyValue>
                                  </MoneyValueWrapper>
                                );
                              })}
                          </RangeWrapper>
                        )}

                        <ValueWrapper QuantityWrapper>
                          <TextHead>Quantity*</TextHead>
                          <SelectOptions
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.quantity}
                            name="quantity"
                          >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                          </SelectOptions>
                          {errors.quantity && touched.quantity ? (
                            <ErrorMessage>{errors.quantity}</ErrorMessage>
                          ) : null}
                        </ValueWrapper>
                      </ValueQuantityWrapper>
                      {/* {productFromApi?.fixedRecipientDenominations.length ===
                      1 ? (
                        <></>
                      ) : (
                        <RangeWrapper>
                          {productFromApi?.fixedRecipientDenominations?.slice(0,5).map(
                            (item, index) => {
                              return (
                                <MoneyValue
                                  select={
                                    (selected !== undefined &&
                                      selected === item) ||
                                    (againData !== null &&
                                      (selected === undefined ||
                                        selected === againData.unitPrice) &&
                                      againData.unitPrice === item) ||
                                    (selected === undefined &&
                                      againData === null &&
                                      index === 0)
                                  }
                                  onClick={() => {
                                    values.cardValue = item;
                                    setselected(item);
                                    setErrors({ cardValue: "" });
                                  }}
                                  key={index}
                                >
                                  {productFromApi?.recipientCurrencyCode} {item}
                                </MoneyValue>
                              );
                            }
                          )}
                        </RangeWrapper>
                      )} */}
                    </ValueQuantityRangeWrapper>
                    <DeliverWrapper>
                      <DeliveryText>Delivery To</DeliveryText>
                    </DeliverWrapper>
                    <ToWrapper>
                      {/* <ToInside>To</ToInside> */}
                      <InputSecondWrapper>
                        <InputInToWrapper>
                          <Input
                            type="text"
                            fullWidthInput
                            placeholder="Receiver’s Name*"
                            value={values.recieverName}
                            name="recieverName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyPressText}
                          />
                          {errors.recieverName && touched.recieverName ? (
                            <ErrorMessage>{errors.recieverName}</ErrorMessage>
                          ) : null}
                        </InputInToWrapper>

                        <InputSenderWrapper
                          SelectMarginInputSenderWrapper
                          //                          style={{
                          //   width: "100%",
                          //   '@media(max-width: 1024px)': {
                          //     width: '100% !important',
                          //   },
                          // }}
                        >
                          <Input
                            type="email"
                            placeholder="Receiver’s Email*"
                            value={values.receiverEmail}
                            name="receiverEmail"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.receiverEmail && touched.receiverEmail ? (
                            <ErrorMessage>{errors.receiverEmail}</ErrorMessage>
                          ) : null}
                        </InputSenderWrapper>
                      </InputSecondWrapper>
                      <InputCountryCodeWrapper>
                        <FormControl className="formcontrolselectcarddetail">
                          <SelectStyled
                            value={values.selectedOptions}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="selectedOptions"
                            sx={{ height: "50px" }}
                            variant="standard"
                          >
                            {countries.map((row, index) => {
                              const imageUrl = row.flagUrl;
                              return (
                                (row.isoName === "IN" ||
                                  row.isoName === "AE") && (
                                  <MenuItem
                                    key={index}
                                    value={row.isoName}
                                    className="menulistcarddetail"
                                  >
                                    <OptionImage
                                      src={`${imageUrl}`}
                                      alt="img"
                                    />
                                    <span
                                      style={{
                                        color: "#182A2F",
                                        fontWeight: "bolder",
                                        padding: "0 5px 0 4px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {row.isoName}
                                    </span>
                                  </MenuItem>
                                )
                              );
                            })}
                          </SelectStyled>
                          {errors.selectedOptions && touched.selectedOptions ? (
                            <ErrorMessage>
                              {errors.selectedOptions}
                            </ErrorMessage>
                          ) : null}
                        </FormControl>
                        <InputSenderWrapper
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginLeft: "15px",
                          }}
                        >
                          <Input
                            ReceiverInput
                            type="text"
                            placeholder="Receiver’s Mobile No.*"
                            value={values.receiverMobile}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ outline: "none" }}
                            name="receiverMobile"
                            onKeyDown={handleKeyPress}
                            onPaste={(event) => {
                              const pastedText =
                                event.clipboardData.getData("text/plain");
                              if (!/^\d+$/.test(pastedText)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          {errors.receiverMobile && touched.receiverMobile ? (
                            <ErrorMessage>{errors.receiverMobile}</ErrorMessage>
                          ) : null}
                        </InputSenderWrapper>
                      </InputCountryCodeWrapper>

                      <MessageWrapper>
                        <InputTextArea
                          type="textarea"
                          placeholder="Leave a message for the Receiver?"
                          value={receiverMessage}
                          onChange={handleChangeRecieverMessage}
                        />
                      </MessageWrapper>
                    </ToWrapper>
                    <ToWrapper>
                      <DeliveryText style={{ margin: "20px 0" }}>
                        From
                      </DeliveryText>
                      {/* <InputSecondWrapper> */}
                      <InputSenderWrapper SelectMargin>
                        <Input
                          type="text"
                          placeholder="Your Name*"
                          value={values.senderName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="senderName"
                          onKeyDown={handleKeyPressText}
                        />
                        {errors.senderName && touched.senderName ? (
                          <ErrorMessage>{errors.senderName}</ErrorMessage>
                        ) : null}
                      </InputSenderWrapper>
                      <InputSenderWrapper
                        InputSenderWrapperSelectMar
                        style={{ margin: "30px 0", width: "100%" }}
                      >
                        <Input
                          type="email"
                          placeholder="Your Email*"
                          value={values.senderEmail}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="senderEmail"
                        />
                        {errors.senderEmail && touched.senderEmail ? (
                          <ErrorMessage>{errors.senderEmail}</ErrorMessage>
                        ) : null}
                      </InputSenderWrapper>
                      {/* </InputSecondWrapper> */}
                      <ButtonWrapper>
                        <StyledButton onClick={handleSubmit} type="submit">
                          {loading2 ? (
                            <LoaderWrapper>
                              <CircularLoader />
                            </LoaderWrapper>
                          ) : (
                            "Proceed to Pay"
                          )}
                        </StyledButton>
                      </ButtonWrapper>
                    </ToWrapper>
                  </RightContainer>
                </MainDiv1>
              </>
            )}
          </MainDiv>
        </Container>

        {/* <GiftCardDescription
          poductData={productFromApi !== undefined && productFromApi}
        /> */}
        {/* <Model
        open={open}
        setOpen={setOpen}
        amount={values.cardValue}
        productId={productFromApi !== undefined && productFromApi.productId}
        quantity={Number(values.quantity)}
        unitPrice={
          againData === null
            ? productFromApi?.fixedRecipientDenominations?.length >= 1
              ? productFromApi?.fixedRecipientDenominations[0]
              : values.cardValue
            : values.cardValue || againData?.unitPrice
        }
        senderName={values.senderName}
        senderEmail={values.senderEmail}
        recieverMobile={values.receiverMobile}
        recieverName={values.recieverName}
        recieverCountryCode={values.selectedOptions}
        recieverEmail={values.receiverEmail}
        message={receiverMessage}
        // token={giftCard_token}
        loggedIn={loggedIn}
        currencyCode={
          productFromApi !== undefined && productFromApi.recipientCurrencyCode
        }
      /> */}
        {/* <BootstrapDialog
        onClose={handleCloseCrossIcon}
        aria-labelledby="customized-dialog-title"
        open={openAlert}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseCrossIcon}
         
        >
          Alert
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Please Login to continue ...</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose2}
            style={{
              width: "140px",
              border: "none",
              borderColor: "rgb(231,231,231)",
              backgroundColor: "#008000",
              color: "white",
              padding: "10px 0",
              margin: "10px",
              cursor: "pointer",
              fontSize: "16px",
              borderRadius: "8px",
            }}
          >
            Login
          </Button>
        </DialogActions>
      </BootstrapDialog> */}
        {showPopup && (
          <LoginPopup
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            setLoggedIn={setLoggedIn}
          />
        )}
      </Root>
      {isCircularLoading && (
        <div style={styles.overlay}>
          <CircularLoader></CircularLoader>
        </div>
      )}
    </>
  );
};

export default CardDetail;
