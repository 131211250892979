import React, { useState } from "react";

import styled from "styled-components";
import {
  FooterText,
  Form,
  Heading,
  Image,
  Input,
  InputWrapper,
  Link,
} from "../../styledcomponents/popupStyledcomponents";
import EmailIcon from "../../../images/email-icon.png";
import axios from "axios";
import { ForgetPasswordSchema } from "./Schemas/ForgetPasswordSchema";
import { useFormik } from "formik";
import { environmentVariables } from "../../../config/config";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
const Root = styled.div`
  width: 100%;
`;

const ErrorMessage = styled.div`
  font-size: 12px;
  color: red;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
`;
function ForgotPassword({ setLoginState, setShowPopup }) {
  const baseUrl = environmentVariables.apiUrl;
  const [isApiResponse, setIsApiResponse] = useState(false);
  const [data, setData] = useState();

  const initialValues = {
    email: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: ForgetPasswordSchema,
      onSubmit: async (values, action) => {
        setIsApiResponse(true);
        await axios({
          method: "post",
          url: `${baseUrl}auth/emailsend`,
          data: {
            email: values.email,
          },
        })
          .then((response) => {
            setIsApiResponse(false);
            setData(response.data);
            // setShowPopup(false);
            localStorage.setItem("emailvalue", response.data.data.email);
            // navigate("/verifypassword", { state: response.data });

            Swal.fire({
              icon: "success",
              title: "Please check your mail and verify your otp",
              timer: "800",
            });
            setLoginState("verifyotp");
          })
          .catch((error) => {
            setIsApiResponse(false);
            Swal.fire({
              icon: "error",
              title: "Something went wrong",
            });
          });
        action.resetForm();
      },
    });
  return (
    <Root>
      <Heading>Reset Password</Heading>
      <Form>
        <InputWrapper>
          <Image src={EmailIcon} />
          <Input
            type="email"
            placeholder="Your Email*"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </InputWrapper>
        {errors.email && touched.email ? (
          <ErrorMessage className="form-error">{errors.email}</ErrorMessage>
        ) : null}
        {/* {error && <ErrorMessage>{error}</ErrorMessage>} */}
        <LoadingButton
          onClick={handleSubmit}
          disabled={isApiResponse}
          loading={isApiResponse}
          variant="contained"
          fullWidth={true}
          size="large"
          color="primary"
        >
          Send Email
        </LoadingButton>
        {/* <Button onClick={handleSubmit}>Send Email</Button> */}
        <FooterText>
          Don’t have an account?{" "}
          <Link
            style={{ color: "#0c6068", fontWeight: 600 }}
            onClick={() => {
              setLoginState("signUp");
            }}
          >
            Signup
          </Link>
        </FooterText>
      </Form>
    </Root>
  );
}

export default ForgotPassword;
