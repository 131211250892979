import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Hotel from "../../../images/hotelimg1.jpg";
import styled from "styled-components";
import { FaComments, FaPlusCircle } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import axios from "axios";
import "./SimilarHotels.css";
import "./HeroSection.css";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useContext } from "react";
import { AuthContext, useAuth } from "../../../ContextApi/Contextapi";
import LoginPopup from "../../Pages/Login/LoginPopup";
import { environmentVariables } from "../../../config/config";
import Loader from "../../Shortlistedloader/Loader";
const TextCard = styled.div`
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TextCardOne = styled.div`
  @media (max-width: 768px) {
    width: 90vw;
  }
`;

const TextCardTow = styled.div``;

const TextCardThree = styled.div``;

const TextHeading = styled.div`
  color: #34495e;
  font-size: 18px;
  font-weight: 700;
  line-height: 50px;
  margin-left: 15px;
`;

const Button = styled.button`
  background-color: #3554d1;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-bottom: 1px solid #008d35;
  box-sizing: border-box;
  height: 40px;
  border: none;
`;

const TextPara = styled.div`
  width: auto;
  border-top: 1px solid #0000001c;
  /* border-bottom: 1px solid #0000001c; */
  margin-top: 5px;
  overflow: auto;
  height: 80px;
  line-height: 25px;
  padding: 10px 20px;
  overflow: auto;
  color: rgb(102, 102, 102);

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
    height: 30px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }
`;

const TextIcon = styled.div`
  text-align: center;
  font-size: 14px;
  margin: 10px 0px;
  color: #34495e;
  cursor: pointer;
`;

const Span = styled.span`
  font-size: 14px;
  margin: 10px 0px 10px 50px;
  color: #34495e;
  cursor: pointer;
`;
const TextWrapper = styled.div`
  display: flex;
  padding: 0 15px;
  align-items: center;
`;

const IconLocation = styled.div`
  /* margin-top: 3px; */
  margin-bottom: 3px;
`;
const TextLocation = styled.div`
  margin-left: 10px;
  color: #6e6666;
`;
const HeartWrapper = styled.div`
  width: 40px;
  border-radius: 3px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  /* left: 88%; */
  right: 15px;
  top: 15px;
  /* right: 1px; */
  background-color: #fff;
  cursor: pointer;
  :hover {
    color: red !important;
  }
`;

const SimilarHotels = ({ theme, category, id, setIsUpdated, setArr, city }) => {
  const baseUrl = environmentVariables.apiUrl;
  const [data, setData] = useState();
  const [similarData, setSimilarData] = useState();
  const navigation = useNavigate();
  const { authData } = useAuth();
  const [userData, setUserData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isHeartLoading, setIsHeartLoading] = useState({});

  const getData = async () => {
    await axios({
      method: "get",
      url: `${baseUrl}auth/getsimilar/?theme=${
        theme !== undefined && theme[0]
      }&city=${city !== undefined && city}`,
    })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    getData();
  }, [theme]);

  function removeObjectWithId(data, id) {
    const objWithIdIndex = data.filter((obj) => obj._id !== id);
    return objWithIdIndex;
  }

  useEffect(() => {
    data !== undefined && setSimilarData(removeObjectWithId(data, id));
  }, [data]);
  const onClickLike = async (item, key) => {
    const compoundKey = `${item._id}-${key}`;
    if (authData === undefined) {
      setShowPopup(true);
    } else {
      setIsHeartLoading((prevState) => ({ ...prevState, [compoundKey]: true }));
      await axios({
        url: `${baseUrl}auth/getshortlisted/${item._id}`,
        method: "post",
        headers: {
          _token: authData?.token,
        },
      })
        .then((response) => {
          getUser(compoundKey);
        })
        .catch((error) => {});
    }
  };

  const getUser = async (key) => {
    try {
      const data = await axios.get(`${baseUrl}auth/getuserdata`, {
        headers: {
          _token: authData?.token,
        },
      });
      setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
      setUserData(data.data.data.shortlistedArray);
      // return data.data.message;
    } catch (error) {
      setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
      return error?.message;
    }
  };
  useEffect(() => {
    getUser();
  }, [authData]);

  return (
    <>
      <Card id="similar-getaways-container">
        <Card.Header id="similar-getaways-heading">
          {similarData !== undefined && similarData.length === 0
            ? "No similar Getaways Available"
            : "Similar Getaways"}
        </Card.Header>
        <TextCard>
          <>
            {similarData !== undefined &&
              similarData.slice(0, 3).map((item, key) => {
                return (
                  <TextCardOne key={key}>
                    <Card
                      id="card-one"
                      style={{ width: "25rem", margin: "10px" }}
                    >
                      <Card.Img
                        variant="top"
                        src={
                          item.image[0] && item?.image[0] != "medianew"
                            ? `https://bastionex-travels.b-cdn.net/uploads/${item.image[0]}`
                            : `https://bastionex-travels.b-cdn.net/uploads/photo_2024-09-06_21-43-47.jpg`
                        }
                        height="250px"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIsUpdated((prev) => !prev);
                          navigation(`/hoteldetails/${item._id}`);
                        }}
                      />
                      <Tooltip title="Shortlist" styled={{ fontSize: "50px" }}>
                        <HeartWrapper onClick={() => onClickLike(item, key)}>
                          {isHeartLoading[`${item._id}-${key}`] ? (
                            <Loader />
                          ) : (
                            <i
                              style={{
                                fontSize: "20px",
                                color:
                                  userData && userData.includes(item._id)
                                    ? "red"
                                    : "#747474",
                              }}
                              className="fa fa-thin fa-heart style"
                            ></i>
                          )}
                        </HeartWrapper>
                      </Tooltip>
                      <TextHeading>{item.hotelname}</TextHeading>
                      <TextWrapper>
                        <IconLocation>
                          <MdLocationPin
                            style={{ color: "rgb(12, 176, 155)" }}
                          />
                        </IconLocation>
                        <TextLocation>
                          {" "}
                          {item.city}, {item.country}
                        </TextLocation>
                      </TextWrapper>
                      {/* <TextPara>{item.overview}</TextPara> */}
                      <TextIcon>
                        {/* <FaComments style={{color: "#34495e"}}/> REVIEWS<Span><FaPlusCircle style={{color: "#34495e"}}/> COMPARE</Span> */}
                      </TextIcon>
                      <Button
                        onClick={() => {
                          setIsUpdated((prev) => !prev);
                          navigation(`/hoteldetails/${item._id}`);
                        }}
                      >
                        Enter Dates for Offers
                      </Button>
                    </Card>
                    {showPopup && (
                      <LoginPopup
                        showPopup={showPopup}
                        setShowPopup={setShowPopup}
                      />
                    )}
                  </TextCardOne>
                );
              })}
          </>
          {/* ) : (
            <>No Similar Date Available</>
          )} */}
          {/* <TextCardTow>
    <Card style={{ width: '25rem', margin: "10px"}}>
      <Card.Img variant="top" src={Hotel} />
        <TextHeading>The Resort Mumbai</TextHeading>
      <TextWrapper>
      <IconLocation><MdLocationPin style={{color: "rgb(12, 176, 155)"}}/></IconLocation>
      <TextLocation> Mumbai</TextLocation>
      </TextWrapper>
      <TextPara>
      Located in the city overlooking the Aksa beach, the property grounds are peppered with palm trees. 
      The rooms are stylish and brightly decorated. They contain all the modern luxuries needed for a comfortable stay and some have sea views. 
      Suites have extra living spaces with the addition of balconies or terraces. Villas add dining areas along with saunas, steam rooms and whirlpool baths. 
      For entertainment, there is an exotic outdoor pool, hot tub, gym, sports court and a playground. Other amenities include a restaurant, coffee shop and a poolside bar.
      </TextPara>
        <TextIcon>
          <FaComments style={{color: "#34495e"}}/> REVIEWS<Span><FaPlusCircle style={{color: "#34495e"}}/> COMPARE</Span>
        </TextIcon>
      <Button>Enter Dates for Offers</Button>
    </Card>
    </TextCardTow>

    <TextCardThree>
    <Card style={{ width: '25rem', margin: "10px"}}>
      <Card.Img variant="top" src={Hotel} />
        <TextHeading>The Resort Mumbai</TextHeading>
      <TextWrapper>
      <IconLocation><MdLocationPin style={{color: "rgb(12, 176, 155)"}}/></IconLocation>
      <TextLocation> Mumbai</TextLocation>
      </TextWrapper>
      <TextPara>
      Located in the city overlooking the Aksa beach, the property grounds are peppered with palm trees. 
      The rooms are stylish and brightly decorated. They contain all the modern luxuries needed for a comfortable stay and some have sea views. 
      Suites have extra living spaces with the addition of balconies or terraces. Villas add dining areas along with saunas, steam rooms and whirlpool baths. 
      For entertainment, there is an exotic outdoor pool, hot tub, gym, sports court and a playground. Other amenities include a restaurant, coffee shop and a poolside bar.
      </TextPara>
        <TextIcon>
          <FaComments style={{color: "#34495e"}}/> REVIEWS<Span><FaPlusCircle style={{color: "#34495e"}}/> COMPARE</Span>
        </TextIcon>
      <Button>Enter Dates for Offers</Button>
    </Card>
  </TextCardThree> */}
        </TextCard>
      </Card>
    </>
  );
};

export default SimilarHotels;
