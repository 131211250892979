import React from "react";
import HeroSection from "./HeroSection";
import SimilarHotels from "./SimilarHotels";
import styled from "styled-components";

const NavBarBackground = styled.div`
  width: 100vw;
  height: 77px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;
const HotelDetails = ({ loggedIn, setLoggedIn}) => {
  return (
    <div>
      <NavBarBackground></NavBarBackground>
      <HeroSection loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>
      {/* <SimilarHotels /> */}
    </div>
  );
};

export default HotelDetails;
