import * as Yup from "yup";

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const OTPSchema = Yup.object({
  name: Yup.string().min(2).max(25).required("Please Enter Your Name"),
  email: Yup.string().email().required("Please Enter Your Email"),
  number: Yup.number().required("Please Enter Your Contact"),
  rooms: Yup.number().required("Please Enter Number of Rooms"),
  // activityTime: Yup.string().required("Please Enter Activity Time"),
  adult: Yup.number().test("rooms", "Invalid  Input.", function (value) {
    const minFieldValue = this.resolve(Yup.ref("rooms"));
    if (minFieldValue !== NaN) {
      return value <= minFieldValue * 2;
    }
  }),
  children: Yup.number().test("rooms", "Invalid  Input.", function (value) {
    const minFieldValue = this.resolve(Yup.ref("rooms"));
    if (minFieldValue !== NaN) {
      return value <= minFieldValue * 2;
    }
  }),
  infants: Yup.number().test("rooms", "Invalid  Input.", function (value) {
    const minFieldValue = this.resolve(Yup.ref("rooms"));
    if (minFieldValue !== NaN) {
      return value <= minFieldValue * 2;
    }
  }),
  countryCode: Yup.string().required("Please Select Country Code"),
  title: Yup.string().required("Please Select Title"),
});
