import React from "react";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import { useLocation } from "react-router-dom";
import ShortCityShortlistedCards from "./ShortCityShortlistedCards";

const Root = styled.div`
  /* background-color: #e7e7e7; */
  padding-top: 85px;
`;

const TextWrapperTwo = styled.div`
  padding: 5px;
`;

const TextCardTitle = styled.div`
  font-size: 25px;
  margin-left: 15px;
`;


const NavBarBackground = styled.div`
  width: 100vw;
  height: 84.8px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;
export const ShortCityDetails = () => {
  const location = useLocation();
  
  return (
    <Root>
      <NavBarBackground></NavBarBackground>
      <Container>
        <div
          className="row"
          style={{ padding: "20px 0px 0px", fontWeight: 600 }}
        >
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
            <TextWrapperTwo>
              <TextCardTitle>
                {location.state.theme.themeName.charAt(0).toUpperCase() +
                  location.state.theme.themeName.slice(1)}{" "}
                Resorts in {location.state.city}
              </TextCardTitle>
            </TextWrapperTwo>
          </div>
        </div>
      </Container>
      <ShortCityShortlistedCards
        cityName={location.state}
      />
    </Root>
  );
};
