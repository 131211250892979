import NavSection from "./Component/NavSection/NavSection";
import React, { useContext, useState, useEffect, useRef } from "react";
import { Route, Routes } from "react-router";
import "./App.css";
import Home from "./Component/Home/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./Component/Footer/Footer";
import * as Router from "./Routes";
import styled from "styled-components";
import Aboutus from "./Component/Aboutus/Aboutus";
import Contactus from "./Component/Contactus/Contactus";
import HotelDetails from "./Component/DetailPage/Mumbai/HotelDetails";
import { AuthContext, useAuth } from "./ContextApi/Contextapi";
import VerifyPassword from "./Component/Pages/Login/VerifyPassword";
import Hotelpage from "./Component/Hotelpage/Hotelpage";
import ScrollToTop from "./Component/Pages/Login/ScrollToTop";
import SideBar from "./Component/SideBar/SideBar";
import ProfileSetting from "./Component/Pages/Login/ProfileSetting";
import ShortlistedPlace from "./Component/Pages/Login/ShortlistedPlace";
import About from "./Component/DetailPage/DetailWitState/BangaloreResorts/About";
import { HotelBooking } from "./Component/Booking/HotelBooking";
import { ShortCityDetails } from "./Component/Home/ShortCityDetails";
import Bookinghistory from "./Component/Pages/Login/Bookinghistory";
import BookingHotelDetails from "./Component/Pages/BookingHotelDetails";

import Filterbytheme from "./Component/Pages/FilterByTheme/Filterbytheme";
import UpdatePassword from "./Component/Pages/Login/UpdatePassword";
import { environmentVariables } from "./config/config";
import CancelPolicy from "./Component/CancelPolicy/CancelPolicy";
import PrivacyPolicy from "./Component/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "./Component/TermsandCondition/TermsAndCondition";
import { useLocation } from "react-router-dom";
import axios from "axios";
import ActivityDetail from "./Component/DetailPage/ActivityDetail";
import GiftCard from "./Component/GiftCard/GiftCard";
import TopupCard from "./Component/TopupCard/TopupCard";
import Activities from "./Component/Hotelpage/Activities";
import GiftTopupHistory from "./Component/Pages/Login/GiftTopupHistory";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import Pusher from "pusher-js";
const Root = styled.div``;
const Container = styled.div``;

function App() {
  const baseUrl = environmentVariables.apiUrl;
  const getState = localStorage.getItem("cityname");
  const [scrollAfterSelection, setScrollAfterSelection] = useState(false);
  const [message1, setMessage1] = React.useState("");
  const [message2, setMessage2] = React.useState("");
  const [showSearchData, setShowSearchData] = useState(false);
  const [data, setData] = useState("all");
  const [category, setCategory] = useState("all");
  const [defaultDataStatus, setDefaultDataStatus] = useState("");

  const [showDropDown, setShowDropDown] = useState(false);
  const [profileOptionDrop, setProfileOptionDrop] = useState(false);
  const [showDistanceSlider, setShowDistanceSlider] = useState(false);
  const [userData, setUserData] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userState, setUserState] = useState("");
  const [originalJSONText, setOriginalJSONText] = useState();
  const [originalJSONTextForUser, setOriginalJSONTextForUser] = useState();
  const navigation = useNavigate();

  const [resCity, setResCity] = useState("");
  const [resState, setResState] = useState("");
  const [resCountry, setResCountry] = useState("");

  let giftCard_token, topup_token;
  // const myJsonData = JSON.parse(
  //   localStorage.getItem("authdata")
  // )?.encryptedDataForToken;
  // const userEncryptedData = JSON.parse(localStorage.getItem("userdata"))?.data;

  // const getOrignalText = () => {
  //   if (myJsonData !== undefined || userEncryptedData !== undefined) {
  //     const encryptedData = myJsonData;


  //     const decrypt = (encryptedData, key, iv) => {
  //       const decryptedText = CryptoJS.AES.decrypt(encryptedData, key, {
  //         iv,
  //         mode: CryptoJS.mode.CBC,
  //         padding: CryptoJS.pad.Pkcs7,
  //       });
  //       return decryptedText.toString(CryptoJS.enc.Utf8);
  //     };

  //     const key = CryptoJS.enc.Hex.parse("lifeisoutside!1234567890@$%^&*()");
  //     const iv = CryptoJS.enc.Hex.parse("blkiajnij2345tgh");

  //     const decryptedData = decrypt(encryptedData, key, iv);
  //     const userdecryptedData = decrypt(userEncryptedData, key, iv);

      
  //     setOriginalJSONText(JSON.parse(decryptedData));
  //     setOriginalJSONTextForUser(JSON.parse(userdecryptedData));
  //   }
  // };

  // const [authData, setAuthData] = useState(originalJSONText);
  const chooseMessage = (message) => {
    setMessage1(message);
  };
  const chooseMessage1 = (message) => {
    setMessage2(message);
  };

  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState(false);

  const { authData } = useAuth();
  useEffect(() => {
    if(authData){
      const config = {
        method: "get",
        url: `${baseUrl}auth/isuserlogged`,
        headers: {
          _token: authData?.token,
        },
      };
      axios(config)
        .then(function (response) {
          if (!response.data.success) {
            localStorage.removeItem("authdata");
            setLoggedIn(false);
          } else {
            setLoggedIn(true);
          }
        })
        .catch(function (error) {
          console.log(error, "error...");
        });
    }
     
  }, [baseUrl, loggedIn,authData]);

  // useEffect(() => {
  //   getOrignalText();
  // }, [loggedIn]);

  
  useEffect(() => {
    if (location.pathname === "/") {
      localStorage.removeItem("cityname");
      localStorage.removeItem("city");
    }
  }, [location]);

  return (
    // <AuthContext.Provider>
    <Root
      onClick={() => {
        setShowDropDown(false);
        setProfileOptionDrop(false);
        setShowSearchData(false);
      }}
    >
      <Container>
        <ScrollToTop />
        <NavSection
          showDistanceSlider={showDistanceSlider}
          setShowDistanceSlider={setShowDistanceSlider}
          scrollAfterSelection={scrollAfterSelection}
          setScrollAfterSelection={setScrollAfterSelection}
          chooseMessage={chooseMessage}
          chooseMessage1={chooseMessage1}
          showDropDown={showDropDown}
          setShowDropDown={setShowDropDown}
          profileOptionDrop={profileOptionDrop}
          setProfileOptionDrop={setProfileOptionDrop}
          message={message1}
          message2={message2}
          showSearchData={showSearchData}
          setShowSearchData={setShowSearchData}
          data={data}
          setData={setData}
          category={category}
          setCategory={setCategory}
          setDefaultDataStatus={setDefaultDataStatus}
          loggedIn={loggedIn}
          setLoggedIn={setLoggedIn}
          userData={userData}
          setUserEmail={setUserEmail}
          userState={userState}
          setUserState={setUserState}
        />
        <Routes>
          <Route
            path={Router.HOME}
            index
            element={
              <Home
                message={message1}
                message2={message2}
                showDistanceSlider={showDistanceSlider}
                setShowDistanceSlider={setShowDistanceSlider}
                chooseMessage={chooseMessage}
                chooseMessage1={chooseMessage1}
                title={"FloxyPay Travel"}
                setLoggedIn={setLoggedIn}
                setResCity={setResCity}
                setResState={setResState}
                setResCountry={setResCountry}
                resCity={resCity}
                resState={resState}
                resCountry={resCountry}
              />
            }
          />

          <Route
            path={Router.ABOUT_US}
            element={<Aboutus title={"About Us"} />}
          />
          <Route
            path={Router.CONTACT_US}
            element={<Contactus title={"Contact Us"} />}
          />
          <Route
            path={Router.DETAILPAGE}
            element={
              <HotelDetails
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
              />
            }
          />

          <Route
            path={Router.HOTELDETAILPAGE}
            element={
              <HotelDetails
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
              />
            }
          />
          <Route path={Router.VERIFYPASSWORD} element={<VerifyPassword />} />
          <Route
            path={Router.ACTIVITYDETAILPAGE}
            element={
              <ActivityDetail
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
              />
            }
          />
          <Route
            path={Router.HOTELPAGE}
            element={
              <Hotelpage
                message={message1}
                // statefromapp={resState}
                message2={message2}
                data={data}
                setData={setData}
                category={category}
                setCategory={setCategory}
                showDistanceSlider={showDistanceSlider}
                setShowDistanceSlider={setShowDistanceSlider}
                setScrollAfterSelection={setScrollAfterSelection}
                scrollAfterSelection={scrollAfterSelection}
                defaultDataStatus={defaultDataStatus}
                setDefaultDataStatus={setDefaultDataStatus}
                setLoggedIn={setLoggedIn}
                setResCity={setResCity}
                setResState={setResState}
                setResCountry={setResCountry}
                resCity={resCity}
                resState={resState}
                resCountry={resCountry}
              />
            }
          />
          <Route
            path={Router.ACTIVITIES}
            element={
              <Activities
                message={message1}
                message2={message2}
                setLoggedIn={setLoggedIn}
              />
            }
          />
          <Route path={Router.PROFILESIDEBAR} element={<SideBar />} />
          <Route
            path={Router.PROFILESETTING}
            element={
              <ProfileSetting
                title={"Profile"}
                setUserData={setUserData}
                userData={userData}
              />
            }
          />
          <Route
            path={Router.SHORTLISTEDPLACE}
            element={
              <ShortlistedPlace
                title={"Shortlisted Places"}
              />
            }
          />
          <Route
            path={Router.GIFTTOPUPHISTORY}
            element={
              <GiftTopupHistory
                title={"Gift And Topup History"}
              />
            }
          />
          <Route
            path={Router.SHORTWITHSTATE}
            element={<About />}
          />
          <Route
            path={Router.HOTELBOOKING}
            element={<HotelBooking />}
          />
          <Route
            path={Router.COMPLETEBOOKING}
            element={
              <Bookinghistory
                title={"Booking History"}
              />
            }
          />
          <Route
            path={Router.SHORTWITHCITY}
            element={<ShortCityDetails />}
          />
          <Route
            path={Router.DEFAULT}
            element={
              <Home
                message={message1}
                message2={message2}
                showDistanceSlider={showDistanceSlider}
                setShowDistanceSlider={setShowDistanceSlider}
                chooseMessage={chooseMessage}
                chooseMessage1={chooseMessage1}
                title={"Floxy Travel"}
                setLoggedIn={setLoggedIn}
                
              />
            }
          />
          <Route
            path={Router.BOOKINHOTELDETAILS}
            element={
              <BookingHotelDetails  />
            }
          />
          <Route
            path={Router.FILTEREDBYTHEME}
            element={<Filterbytheme />}
          />
          <Route
            path={Router.UPDATEPASSWORD}
            element={
              <UpdatePassword
                title={"Update Password"}
                setLoggedIn={setLoggedIn}
              />
            }
          />
          <Route path={Router.CancelPolicy} element={<CancelPolicy />} />
          <Route
            path={Router.PrivacyPolicy}
            element={<PrivacyPolicy title={"Privacy Policy"} />}
          />
          <Route
            path={Router.TermsAndCondition}
            element={<TermsAndCondition title={"Terms & Conditions"} />}
          />
          <Route
            path={Router.GIFTCARD}
            element={
              <GiftCard
                title={"Gift Cards"}
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                giftCard_token={giftCard_token}
              />
            }
          />
          <Route
            path={Router.TOPUPCARD}
            element={
              <TopupCard
                title={"Topup Cards"}
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                topup_token={topup_token}
              />
            }
          />
        </Routes>
        <Footer
          scrollAfterSelection={scrollAfterSelection}
          setScrollAfterSelection={setScrollAfterSelection}
          chooseMessage={chooseMessage}
          chooseMessage1={chooseMessage1}
          loggedIn={loggedIn}
        />
      </Container>
    </Root>
    // </AuthContext.Provider>
  );
}

export default App;
