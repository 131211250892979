import React, { useEffect, useRef, useState, useContext } from "react";
import Card from "react-bootstrap/Card";
import styled from "styled-components";
import { FiThumbsUp } from "react-icons/fi";
import { FaArrowCircleUp } from "react-icons/fa";
import Percentage from "../../../images/percentage.png";
import { FaUser } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { Modal, Title } from "react-bootstrap";
import { BsCheckCircleFill } from "react-icons/bs";
import Container from "react-bootstrap/Container";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Deals.css";
import { useLocation, useNavigate } from "react-router-dom";
import CheckAvilable from "./CheckAvilable";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
import { environmentVariables } from "../../../config/config";
import { AuthContext, useAuth } from "../../../ContextApi/Contextapi";

const Root = styled.div`
  width: 100%;
`;
const TextHeading = styled.div`
  color: #000;
  display: flex;
  align-items: center;
  font-weight: 500;
`;

const TextContainerDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  background-color: #3554d1;
  width: 100%;
  color: white;
  margin-top: 10px;
  border: none;
  padding: 10px 0px;
  border-radius: 5px;
`;

const Input = styled.input`
  padding: 2px 10px;
  outline: none;
`;
const Form = styled.div`
  padding-top: 10px;
`;
// const InputWrapper = styled.div`
// display: inline-block;
// margin-left: 43px;
// `;

const InputTextWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: rgba(73, 73, 73, 0.15) 0px 0px 8px 1px;
  padding: 10px 8px;
  border-radius: 5px;
`;

const TextIcon = styled.div`
  text-align: center;
`;

const TextWrapper = styled.div`
  text-align: center;
`;
const TextWrapperChildPolicy = styled.div`
  text-align: left;
  font-size: 12px;
  font-weight: bolder;
  color: #0056b3;
`;

const Image = styled.img`
  display: block;
  margin: 0 auto;
`;

const TextBtnOne = styled.div``;

const TextForm = styled.div`
  text-align: center;
`;
const Label = styled.label`
  color: #fff;
`;

const TextSelect = styled.div`
  padding: 0 10px;
  display: flex;
  justify-content: center;
`;

const TextFormWrapper = styled.div`
  background-color: #fff;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 480px) {
    height: 250px;
  }
`;

const TextSelectInput = styled.div`
  display: flex;
  padding-left: 10px;
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
`;
const Text = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 500;
  text-transform: uppercase;
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const TextOne = styled.div`
  color: #000;
  padding: 10px 30px;
`;

const TextSelectWrapper = styled.div`
  margin: 5px;
  box-shadow: 0px 0px 7px 2px #00000012;
  :focus {
    outline: none;
    border: none;
  }
`;

const TextSelect1 = styled.div`
  // padding: 0 10px;
  display: flex;
  justify-content: center;
`;

const TextSelectInput1 = styled.div`
  display: flex;
  width: 100%;
  // padding-left: 10px;
  flex-direction: column;
`;

const TextSelectWrapper1 = styled.div`
  margin: 10px 0px;
  box-shadow: rgba(73, 73, 73, 0.15) 0px 0px 8px 1px;
  :focus {
    outline: none;
    border: none;
  }
`;

const TextBorderWrapper = styled.div`
  background-color: #1a2a68;
  height: 50px;
`;

const TextBtnDiv = styled.button`
  text-align: center;
  color: #fff;
  background-color: #3554d1;
  cursor: pointer;
  font-size: 17px;
  /* margin-top: 10px; */
  width: 100%;
  padding: 12px;
  margin-top: 0px;
  border: none;
  :disabled {
    display: none;
  }
  :enabled {
    /* display: none; */
  }
`;

const inputStyle = {
  padding: "10px 8px",
  border: "none",
  borderRadius: "5px",
  width: "100%",
};

const inputStyleOne = {
  // padding: "2px 3px",
  border: "none",
  padding: "10px 8px",
  borderRadius: "5px",
  width: "100%",
};

const DateWrapper = styled.div``;

const DateContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  width: "80%";
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 6px;
  box-shadow: 0px 0px 8px 1px #00000026;
  border-radius: 5px;
  background-color: #dbdbdb;
`;
const ErrorMessage = styled.div`
  font-size: 12px;
  color: red;
  margin-bottom: 20px;
`;
const TimeInput = styled.input`
  /* Add your styles here */
  padding: 0.5rem;
  font-size: 1rem;
`;
const FormContainerNameValue = styled.div`
  @media (max-width: 480px) {
    display: flex;
    // flex-direction: "column";
  }
`;
const SelectTicketsWrapper = styled.div`
  display: flex;
  gap: 10px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 3px 12px;
`;

const ActivityDeals = (props) => {
  const baseUrl = environmentVariables.apiUrl;
  const { state } = useLocation();
  const { authData } = useAuth();

  // const { authData, setAuthData } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [priceRange, setPriceRange] = useState();

  const [show, setShow] = useState(false);
  const InputCheckIn = useRef(null);
  const InputCheckOut = useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [checkIn, setCheckIn] = useState();
  const [checkOut, setCheckOut] = useState();
  const [adults, setAdults] = useState();
  const [children, setChildren] = useState();
  const [infants, setInfants] = useState();
  const [currentActivity, setCurrentActivity] = useState(null);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [selectedTime, setSelectedTime] = useState();

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const today = new Date();
  const navigation = useNavigate();

  const handleClose1 = () => {
    // setAdults1(adults);
    // setCheckIn1(checkIn);
    setChildren(children);
    setAdults(adults);
    setCheckIn(checkIn);
    setInfants(infants);
    setShow(false);
  };
  const handleSelectedActivities = async () => {
    let allSelctedActivities = [];
    for (let index = 0; index < selectedActivities.length; index++) {
      const element = selectedActivities[index];
      if (element.activityid == currentActivity.activityid) {
        let updatedValue = {
          ...currentActivity,
          date: checkIn,
          adults: adults,
          child: children,
          activityTime: selectedTime,
          changedPricingForActivities: priceRange,
        };
        setCurrentActivity(updatedValue);
        allSelctedActivities.push(updatedValue);
      } else {
        allSelctedActivities.push(element);
      }
    }
    setSelectedActivities(allSelctedActivities);
  };
  useEffect(() => {
    handleSelectedActivities();
  }, [children, adults, checkIn, selectedTime, priceRange]);

  useEffect(() => {
    setCurrentActivity(
      state?.currentActivity != undefined && state?.currentActivity
        ? state?.currentActivity
        : null
    );
    setSelectedActivities(
      state?.selectedActivities != undefined && state?.selectedActivities
        ? state?.selectedActivities
        : []
    );
    setCheckIn(
      state?.currentActivity != undefined && state?.currentActivity.date
        ? state?.currentActivity?.date
        : new Date()
    );
    setAdults(
      state?.currentActivity != undefined && state?.currentActivity?.adults
        ? state?.currentActivity?.adults
        : 2
    );
    setChildren(
      state?.currentActivity != undefined && state?.currentActivity?.child
        ? state?.currentActivity?.child
        : 0
    );
    setSelectedTime(
      state?.currentActivity !== undefined &&
        state?.currentActivity?.activityTime
        ? state?.currentActivity?.activityTime
        : "11:00"
    );
    setInfants(
      state?.currentActivity !== undefined && state?.currentActivity?.infants
        ? state?.currentActivity?.infants
        : 0
    );
  }, []);

  useEffect(() => {
    if (state?.currentActivity?.changedPricingForActivities) {
      const defaultPricing = props?.data?.pricingData?.find(
        (item) => item._id === state.currentActivity.changedPricingForActivities
      );
      if (defaultPricing) {
        setSelectedOption(defaultPricing); // Set selectedOption for default display
        setPriceRange(defaultPricing._id); // Set priceRange to the default _id
      }
    }
  }, [state, props?.data?.pricingData, authData]);

  const handleCheckInChange = (date) => {
    setCheckIn(date);
    if (checkOut && date > checkOut) {
      setCheckOut(null);
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (item) => {
    setSelectedOption(item);
    setIsOpen(false);
    setPriceRange(item?._id); // setting the value of selected price range
  };

  return (
    <>
      <Root>
        <Container
          style={{
            padding: "0",
          }}
        >
          <TextContainerDiv>
            <Card
              style={{
                width: "97%",
                backgroundColor: "#fff",
                border: "none",
              }}
            >
              <Card.Body>
                <TextHeading>
                  <FiThumbsUp style={{ marginRight: "10px" }} />
                  Find the best deals
                </TextHeading>
                <Form>
                  <FormContainer onClick={() => setError("")}>
                    <DatePicker
                      className=""
                      placeholderText=" Activity Date*"
                      // dateFormat="MM/dd/yyyy"
                      selected={checkIn}
                      onChange={handleCheckInChange}
                      selectsStartcheckIn
                      startDate={checkIn}
                      endDate={checkOut}
                      // maxDate={checkOut}
                      ref={InputCheckIn}
                      minDate={new Date()}
                      style={{ width: "100%" }}
                      dateFormat="dd/MM/yyyy"
                    ></DatePicker>
                    <div
                      onClick={() => {
                        // alert("sadasd")
                        InputCheckIn.current.setOpen(true);
                      }}
                      style={{
                        position: "absolute",
                        // zIndex: "999999",
                        top: "57px",
                        right: "160px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    >
                      <i className="fas fa-calendar-alt"></i>
                    </div>
                    <TimeInput
                      type="time"
                      id="timeInput"
                      name="timeInput"
                      value={selectedTime}
                      onChange={handleTimeChange}
                    />
                  </FormContainer>

                  <InputTextWrapper
                  //  onClick={handleShow}
                  >
                    <div>
                      <FaUser
                        className="userIcon"
                        style={{
                          top: "30%",
                          left: "5%",
                          fontSize: "20px",
                          color: "#2196f3",
                        }}
                      />
                      <div style={{ marginLeft: "30px", marginTop: "4px" }}>
                        {" "}
                        No of Tickets
                      </div>{" "}
                    </div>
                    <SelectTicketsWrapper>
                      {" "}
                      <div
                        onClick={() => {
                          if (adults > 1) {
                            setAdults(adults - 1);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        -
                      </div>{" "}
                      {adults}{" "}
                      <div
                        onClick={() => {
                          setAdults(adults + 1);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        +
                      </div>
                    </SelectTicketsWrapper>
                    {/* <Input
                      type="text"
                      placeholder="2 Adults 1 Children*"
                      style={{
                        width: "100%",
                        // textAlign: "center",
                        outline: "none",
                        border: "none",
                        borderRadius: "5px",
                        padding: "20px 50px",
                        boxShadow: "rgba(73, 73, 73, 0.15) 0px 0px 8px 1px",
                        borderRadius: "5px",
                      }}
                      value={`${
                        adults > 1 ? `${adults} Adults` : `${adults} Adult`
                      }, ${
                        children > 1
                          ? `${children} Children`
                          : `${children} Child`
                      }, ${
                        infants > 1 ? `${infants} Infants` : `${infants} Infant`
                      }`}
                      readOnly
                    />
                    <TextBtnOne
                      onClick={handleShow}
                      style={{ cursor: "pointer" }}
                    >
                      <FaRegEdit
                        className="editIcon"
                        style={{
                          top: "25%",
                          right: "7%",
                          fontSize: "20px",
                          color: "#2196f3",
                        }}
                      />
                    </TextBtnOne> */}
                  </InputTextWrapper>
                  {error !== "" && <ErrorMessage>{error}</ErrorMessage>}
                  {/* <TextSelectWrapper1>
                    <select
                      style={inputStyle}
                      onChange={(e) => setPriceRange(e.target.value)}
                      value={priceRange}
                    >
                      <option selected value="0">
                        Select Price
                      </option>
                      {props?.data?.pricingData?.map((item, index) => {
                        return (
                          <option value={item?._id} key={index}>
                            {`${item?.board_basis_ticket_option} - ${item?.currency} ${item?.price}`}
                          </option>
                        );
                      })}
                    </select>
                  </TextSelectWrapper1> */}
                  <div className="text-select-wrapper">
                    <div
                      className="selected-option"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      {selectedOption ? (
                        <div className="dropdown-option">
                          <div className="dropdown-firstwrapper">
                            <span className="item-title">
                              {selectedOption.room_type_excursion_option}
                            </span>{" "}
                            <span className="item-subtitle">
                              ({selectedOption.board_basis_ticket_option})
                            </span>
                          </div>
                          <span className="item-price">
                            {selectedOption.currency} {selectedOption.price}
                          </span>
                        </div>
                      ) : (
                        "Select Ticket Option"
                      )}
                    </div>
                    {isOpen && (
                      <div className="options-wrapper">
                        {props?.data?.pricingData?.map((item, index) => (
                          <div
                            className="dropdown-option"
                            key={index}
                            onClick={() => handleSelect(item)}
                          >
                            <div className="dropdown-firstwrapper">
                              {" "}
                              <span className="item-title">
                                {item?.room_type_excursion_option}
                              </span>{" "}
                              <span className="item-subtitle">
                                ({item?.board_basis_ticket_option})
                              </span>
                            </div>

                            <span className="item-price">
                              {item?.currency} {item?.price}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <TextWrapperChildPolicy>
                    Please take a moment to check our child policy before moving
                    forward.
                  </TextWrapperChildPolicy>
                  <Button>
                    <CheckAvilable
                      hotelData={props?.data?.filter || props?.data}
                      state={{
                        adult: adults,
                        children: children,
                        infants: infants,
                        checkIn: checkIn,
                        priceRange: priceRange,
                        activityTime: selectedTime,
                        hotelDetailSate:
                          state?.hotelDetailSate != undefined &&
                          state?.hotelDetailSate
                            ? state?.hotelDetailSate
                            : {},
                        selectedActivities: selectedActivities,
                        currentActivity: currentActivity,
                        isCombined:
                          state?.isCombined != undefined && state?.isCombined
                            ? state?.isCombined
                            : false,
                        redirectTo:
                          state?.redirectTo != undefined && state?.redirectTo
                            ? state?.redirectTo
                            : "/hotelbooking",
                      }}
                      loggedIn={props.loggedIn}
                      setLoggedIn={props.setLoggedIn}
                      error={error}
                      setError={setError}
                    />{" "}
                  </Button>
                </Form>
              </Card.Body>
              <Card.Body style={{ backgroundColor: "white" }}>
                <TextIcon>
                  <FaArrowCircleUp />
                </TextIcon>
                <TextWrapper>Almost there! Pack your bags.</TextWrapper>
              </Card.Body>
              {/* <Card.Body
                style={{
                  backgroundColor: "#f6f6f6",
                  borderRadius: " 0 0 5px 5px",
                }}
              >
                <Image src={Percentage} alt="Percentage Icon" />
                <TextWrapper>
                  Best Price GuaranteedWe compare prices across 30 sites to get
                  you the best deals.
                </TextWrapper>
              </Card.Body> */}
            </Card>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header
                style={{ backgroundColor: "#fff", textAlign: "center" }}
                closeButton
              >
                <div className="d-flex">
                  <span className="text-danger"></span>
                </div>
              </Modal.Header>
              <TextFormWrapper>
                <FormContainerNameValue>
                  <Text>
                    <TextOne>Adults*</TextOne>
                    <TextOne>Children</TextOne>
                    <TextOne>Infants</TextOne>
                  </Text>
                  <TextSelect>
                    {/* <Label htmlFor="">Room  </Label> */}
                    <TextSelectInput>
                      <TextSelectWrapper>
                        <select
                          style={inputStyleOne}
                          onChange={(e) => setAdults(e.target.value)}
                          value={adults}
                        >
                          <option selected value="0">
                            Select
                          </option>
                          <option value={1}>1 Adult</option>
                          <option value={2}>2 Adults</option>
                          <option value={3}>3 Adults</option>
                          <option value={4}>4 Adults</option>
                          <option value={5}>5 Adults</option>
                          <option value={6}>6 Adults</option>
                          <option value={7}>7 Adults</option>
                          <option value={8}>8 Adults</option>
                          <option value={9}>9 Adults</option>
                          <option value={10}>10 Adults</option>
                          <option value={11}>11 Adults</option>
                          <option value={12}>12 Adults</option>
                          <option value={13}>13 Adults</option>
                          <option value={14}>14 Adults</option>
                          <option value={15}>15 Adults</option>
                        </select>
                      </TextSelectWrapper>
                      <TextSelectWrapper>
                        <select
                          style={inputStyleOne}
                          onChange={(e) => setChildren(e.target.value)}
                          value={children}
                        >
                          <option selected value="0">
                            Select
                          </option>
                          <option value={1}>1 Child</option>
                          <option value={2}>2 Children</option>
                          <option value={3}>3 Children</option>
                          <option value={4}>4 Children</option>
                          <option value={5}>5 Children</option>
                          <option value={6}>6 Children</option>
                          <option value={7}>7 Children</option>
                          <option value={8}>8 Children</option>
                          <option value={9}>9 Children</option>
                          <option value={10}>10 Children</option>
                          <option value={11}>11 Children</option>
                          <option value={12}>12 Children</option>
                          <option value={13}>13 Children</option>
                          <option value={14}>14 Children</option>
                          <option value={15}>15 Children</option>
                        </select>
                      </TextSelectWrapper>
                      <TextSelectWrapper>
                        <select
                          style={inputStyleOne}
                          onChange={(e) => setInfants(e.target.value)}
                          value={infants}
                        >
                          <option selected value="0">
                            Select
                          </option>
                          <option value={1}>1 Infant</option>
                          <option value={2}>2 Infants</option>
                          <option value={3}>3 Infants</option>
                          <option value={4}>4 Infants</option>
                          <option value={5}>5 Infants</option>
                          <option value={6}>6 Infants</option>
                          <option value={7}>7 Infants</option>
                          <option value={8}>8 Infants</option>
                          <option value={9}>9 Infants</option>
                          <option value={10}>10 Infants</option>
                          <option value={11}>11 Infants</option>
                          <option value={12}>12 Infants</option>
                          <option value={13}>13 Infants</option>
                          <option value={14}>14 Infants</option>
                          <option value={15}>15 Infants</option>
                        </select>
                      </TextSelectWrapper>
                    </TextSelectInput>
                  </TextSelect>
                </FormContainerNameValue>
              </TextFormWrapper>
              <TextBorderWrapper onClick={handleClose1}>
                <TextBtnDiv
                  // disabled={adults !== "0" ? false : true}
                  style={{ opacity: adults !== "0" ? 1 : 0.5 }}
                >
                  <BsCheckCircleFill /> Apply
                </TextBtnDiv>
              </TextBorderWrapper>
            </Modal>
          </TextContainerDiv>
        </Container>
      </Root>
    </>
  );
};

export default ActivityDeals;
