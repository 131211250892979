import React from "react";
import styled from "styled-components";
import Topuplist from "./Topuplist";
const Root = styled.div`
  padding-top: 85px;
`;
const NavBarBackground = styled.div`
  width: 100vw;
  height: 84.8px;
  position: fixed;
  background-color: #051036;
  top: 0;
  @media (max-width: 768px) {
    height: 56px;
  }
`;

const TopupCard = ({ title, loggedIn, setLoggedIn }) => {
  document.title = title === undefined ? "" : title;
  return (
    <Root className="mob-pt-0">
      {/* <NavBarBackground></NavBarBackground> */}
      {/* <TopupCardTabs /> */}
      <Topuplist loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
    </Root>
  );
};

export default TopupCard;
