import React from 'react';
import styled from "styled-components";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { FaUser } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';

const Form = styled.form`
   text-align: center;
   margin-top: 10px;
   padding: 0px 10px;
`;

const Input = styled.input`
    width: 100%;
    border: none;
    outline: none;
    height: 30px;
    border-radius: 3px;
    @media(max-width: 425px){
    margin-top: 5px;
}
`;

const Button = styled.button`
    background-color: #007a87;
    width: 90%;
    border: none;
    outline: none;
    color: #fff;
    height: 30px;
    border-radius: 3px;
    @media(max-width: 425px){
    margin-top: 5px;
    width: 100%;
}
`;

const TextHeading = styled.div`
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    margin-top: 5px;
    color: #fff;
`;

const TextWrapperDiv = styled.div`
      background-color: #60cbc9;
      height: 100px;
      margin-top: 50px;
      border-radius: 5px;
      @media(max-width: 425px){
    height: 200px;
}
`;

const userStyle = {
  position: "absolute",
  marginTop:"5px",
  marginLeft: "10px"
}

const editStyle = {
  position: "absolute",
  marginTop: "6px",
  right: "15px"
}

const BangaloreInput = () => {
  return (
    <>
<Container>
  <TextWrapperDiv>
    <TextHeading>Resorts & Places To Stay</TextHeading>
  <Form>
   <div className="row">
    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
    <Input type="date" placeholder='Check In'/>
    </div>
    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
    <Input type="date" placeholder='Check Out'/>
    </div>
    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
    <FaUser style={userStyle}/>
    <Input type="text" placeholder='1 Room 2 Adults' style={{textAlign: "center"}}/>
    <FaRegEdit style={editStyle}/>
    </div>
    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
    <Button>Book Now</Button>
    </div>
  </div>
  </Form>     
  </TextWrapperDiv>
    </Container>
    </>
  )
}

export default BangaloreInput