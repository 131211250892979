import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Hotel from "../../../../images/hotelimg1.jpg";
import { FaCaretDown, FaCheckSquare } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import { useLocation, useNavigate } from "react-router-dom";
import { environmentVariables } from "../../../../config/config";

const ImageWrapper = styled.div`
  padding: 10px 0px 0px 2px;
`;
const Image = styled.img`
  width: 100%;
  height: 290px;
`;

const TextWrapperTwo = styled.div`
  padding: 5px;
`;

const TextCardTitle = styled.div`
  font-size: 30px;
`;

const TextCardHeaderDiv = styled.div`
  display: flex;
`;
const TextCardHeaderOne = styled.div`
  height: 30px;
  width: 62%;
  background-color: #f9f9f9;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const TextCardHeaderTwo = styled.div`
  width: 36%;
  height: 30px;
  background-color: #f9f9f9;
  text-align: end;
  padding-right: 23px;
  color: #0cb09b;
  font-size: 18px;
  /* cursor: pointer; */
  @media (max-width: 1024px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const TextCardHeaderThree = styled.div`
  position: relative;
  top: 0px;
  right: 20px;
  color: #0cb09b;
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;

const TextParagraph = styled.div`
  width: 100%;
  overflow: auto;
  font-size: 15px;
  color: #666;
  text-align: justify;
  height: 100px;
  line-height: 25px;
  /* padding: 1px 5px; */
  padding: 1px 20px 0 3px;
  overflow: auto;
  margin-top: 5px;
  @media (max-width: 768px) {
    width: 100%;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #fff;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
    height: 30px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: grey;
  }
`;

const TextCardHeaderFour = styled.div`
  width: 35%;
  font-size: 16px;
  line-height: 31px;
  background-color: #f9f9f9;
  margin: 8px 17px 0px 10px;
  border: 1px solid #eee;
  color: #6e6666;
  @media (max-width: 1200px) {
    line-height: 21px;
  }
  @media (max-width: 1024px) {
    line-height: 21px;
  }
  @media (max-width: 990px) {
    line-height: 17px;
    font-size: 14px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const TextCardHeaderFive = styled.div`
  display: flex;
`;

const TextCardHeaderSix = styled.div`
  width: 60%;
  background-color: #f9f9f9;
  position: absolute;
  top: 267px;
  @media (max-width: 768px) {
    width: 93%;
  }
`;
const Root = styled.div`
  padding: 40px 0px 40px;
  margin: 10px 100px;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: rgb(145 145 145) 0px 0px 15px 1px;
  @media (max-width: 768px) {
    width: 90vw;
    margin: 0;
  }
`;

const BangaloreResorts = () => {
  const baseUrl = environmentVariables.apiUrl;
  const [data, setData] = useState();
  const { state } = useLocation();
  const location = useLocation();

  useEffect(() => {
    setData(state && state);
  }, [state]);

  return (
    <Root>
      <Container>
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
            <ImageWrapper>
              <Image
                src={`${baseUrl}uploadcitiespictures/${
                  data !== undefined && data.picture
                }`}
              />
            </ImageWrapper>
          </div>
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
            <TextWrapperTwo>
              <TextCardTitle>
                {data !== undefined && data.cityName}
              </TextCardTitle>
              <TextCardHeaderDiv>
                <TextCardHeaderOne></TextCardHeaderOne>
                <TextCardHeaderTwo></TextCardHeaderTwo>
                {/* <TextCardHeaderThree><FaCaretDown/></TextCardHeaderThree> */}
              </TextCardHeaderDiv>
              <TextCardHeaderFive>
                <TextParagraph>
                  {data !== undefined && data.content}
                </TextParagraph>
                {/* <TextCardHeaderFour>
                  <ul
                    style={{
                      listStyleType: "none",
                      marginLeft: "8px",
                      marginTop: "5px",
                    }}
                  >
                    <li>
                      <FaCheckSquare /> India's IT capital
                    </li>
                    <li>
                      <FaCheckSquare /> Foodie Paradise
                    </li>
                    <li>
                      <FaCheckSquare /> Rich heritage and culture
                    </li>
                    <li>
                      <FaCheckSquare /> Well known landmarks and national
                      monuments
                    </li>
                    <li>
                      <FaCheckSquare /> Ideal for Adventure buffs
                    </li>
                  </ul>
                </TextCardHeaderFour> */}
              </TextCardHeaderFive>
              {/* <TextCardHeaderSix><a href="#">Why Visit ?</a> (Things to see, Things to do, Food, Notes)</TextCardHeaderSix> */}
            </TextWrapperTwo>
          </div>
        </div>
      </Container>
    </Root>
  );
};

export default BangaloreResorts;
