import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import Password from "../../../images/lock.png";
import EmailIcon from "../../../images/email-icon.png";
import { LoadingButton } from "@mui/lab";
import {
  FooterText,
  Form,
  Heading,
  Image,
  Input,
  InputWrapper,
  Link,
} from "../../styledcomponents/popupStyledcomponents";

import axios from "axios";
import Swal from "sweetalert2";
import { AuthContext, useAuth } from "./../../../ContextApi/Contextapi";
import { useFormik } from "formik";
import { LoginSchema } from "./Schemas/LoginSchema";
import { environmentVariables } from "./../../../config/config";
import ReCAPTCHA from "react-google-recaptcha";

const Root = styled.div`
  width: 100%;
`;
const RightText = styled.div`
  display: flex;
  justify-content: right;
  font-size: 12px;
  margin-top: 10px;
  color: #717171;
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
`;
function Login({
  setLoginState,
  setShowPopup,
  pageRequest = "login",
  setLoginHandler,
  setLoggedIn,
}) {
  const baseUrl = environmentVariables.apiUrl;
  const siteKey = environmentVariables.siteKey;
  const [isApiResponse, setIsApiResponse] = useState(false);
  const { updateAuthData } = useAuth();
  // const { authData, setAuthData } = useContext(AuthContext);
  const captchaRef = useRef();

  const initialValues = {
    email: "",
    password: "",
    captchaValue: "",
  };
  let formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, action) => {
      setIsApiResponse(true);
      await axios({
        method: "post",
        url: `${baseUrl}auth/login`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          email: values.email,
          password: values.password,
          captchaValue: values.captchaValue,
        },
      })
        .then((response) => {
          setIsApiResponse(false);
          if (response.data.message === "User is not verified") {
            Swal.fire({
              icon: "error",
              title: "Email is not verified",
            });
            setShowPopup(false);
          } else if (response.data.message === "Again signin plz") {
            Swal.fire({
              icon: "error",
              title: "Email & Password does not correct",
            });
            setShowPopup(false);
          } else if (response.data.message === "Invalid Details") {
            Swal.fire({
              icon: "error",
              title: "Invalid Credentials",
            });
            setShowPopup(false);
          } else {
            const expirationTime = 1 * 60 * 1000; // 1 hour in milliseconds
            const expirationTimestamp = new Date().getTime() + expirationTime;
            const { encryptedDataForToken } = response.data;
            const {data} = response.data;


            // localStorage.setItem(
            //   "authdata",
            //   JSON.stringify({ encryptedDataForToken, expirationTimestamp })
            // );
            // localStorage.setItem(
            //   "userdata",
            //   JSON.stringify({ data })
            // );

            // updateAuthData(encryptedDataForToken);
            updateAuthData(data);
            // setAuthData({ data, expirationTimestamp });
            setLoggedIn(true);
            Swal.fire({
              icon: "success",
              text: "You have Logged In Successfully!",
              timer: "1000",
            });
            setShowPopup(false);
          }
        })
        .catch((error) => {
          setIsApiResponse(false);
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
          });
          setShowPopup(false);
        });
      action.resetForm();
      captchaRef.current.reset();
    },
  });

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    formik;
  useEffect(() => {
    function handleKeyPress(event) {
      if (event.key === "Enter") {
        handleSubmit();
      }
    }
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const onChangeRecaptcha = (value) => {
    formik.setFieldValue("captchaValue", value);
  };
  return (
    <Root>
      <Heading>Login</Heading>
      <Form>
        <InputWrapper>
          <Image src={EmailIcon} />
          <Input
            type="email"
            placeholder="Your Email*"
            name="email"
            value={values.email}
            onChange={
              // setEmail(e.target.value);
              handleChange
            }
            onBlur={handleBlur}
          />
        </InputWrapper>
        {errors.email && touched.email ? (
          <ErrorMessage className="form-error">{errors.email}</ErrorMessage>
        ) : null}
        <InputWrapper>
          <Image src={Password} />
          <Input
            type="password"
            name="password"
            placeholder="Your Password*"
            value={values.password}
            onChange={
              // setPassword(e.target.value);
              handleChange
            }
            onBlur={handleBlur}
            autoComplete="off"
          />
        </InputWrapper>
        {errors.password && touched.password ? (
          <ErrorMessage className="form-error">{errors.password}</ErrorMessage>
        ) : null}

        <InputWrapper CustomCaptaWrapper>
          <ReCAPTCHA
            sitekey={siteKey}
            onChange={onChangeRecaptcha}
            ref={captchaRef}
          />
          {errors.captchaValue && touched.captchaValue ? (
            <ErrorMessage className="form-error">
              {errors.captchaValue}
            </ErrorMessage>
          ) : null}
        </InputWrapper>
        {/* {error && <ErrorMessage>{error}</ErrorMessage>} */}
        <LoadingButton
          onClick={handleSubmit}
          disabled={isApiResponse}
          loading={isApiResponse}
          variant="contained"
          fullWidth={true}
          size="large"
          color="primary"
        >
          Login
        </LoadingButton>
        {/* <Button onClick={handleSubmit}>Login</Button> */}
        <RightText
          onClick={() => {
            setLoginState("forgotPassword");
          }}
        >
          Forgot password?
        </RightText>
        <FooterText>
          Don’t have an account?{" "}
          <Link
            style={{ color: "#0c6068", fontWeight: 600 }}
            onClick={() => {
              setLoginState("signUp");
            }}
          >
            Signup
          </Link>
        </FooterText>
      </Form>
    </Root>
  );
}

export default Login;
