import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Loader = () => (
  <div className="loader">
    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
  </div>
);
export const LoaderForSelectActivities = () => (
  <div className="loader-selected-activities">
    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
  </div>
);

export default Loader;