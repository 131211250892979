import React, { useState, useEffect, useRef } from "react";
import hill_black from "../../images/hill_black.png";
import hearts_black from "../../images/hearts-black.png";
import heritage_black from "../../images/heritage_black.png";
import sandals_black from "../../images/sandals_black.png";
import paw_black from "../../images/paw_black.png";
import {
  FilterWrapper,
  Root,
  FilterElements,
  FilterElements1,
  FilterIcon,
  FilterName,
  FilterStayType,
  ClearAllButton,
  FilterStayTypeMenu,
  FilterStayTypeOption,
  FilterElementsWrapper,
  FilterButtonsWrapper,
  FilterAllOptions,
} from "./RangeForm.styles";
import styled from "styled-components";

const Image = styled.img``;

const RangeForm = ({ interested, setInterested, stayType, setStayType }) => {
  const [value1, setValue1] = useState([0, 50]);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [stayTypeText, setStayTypeText] = useState("Stay Type");
  const [showStayType, setShowStayType] = useState(false);
  const [isApplyActive, setIsApplyActive] = useState(false);

  const stayTypeRef = useRef();

  const handleClear = () => {
    setInterested("all");
    setStayType("all");
    setStayTypeText("Stay Type");
    setValue1([0, 50]);
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      setLat(-1);
      setLong(-1);
    }
  };
  const showPosition = (position) => {
    setLat(position.coords.latitude);
    setLong(position.coords.longitude);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (stayTypeRef.current && !stayTypeRef.current.contains(event.target)) {
        setShowStayType(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (
      interested === "all" &&
      stayType === "all" &&
      value1[0] === 0 &&
      value1[1] === 50
    ) {
      setIsApplyActive(false);
    } else {
      setIsApplyActive(true);
    }
  }, [interested, stayType, value1]);
  useEffect(() => {
    getLocation();
  }, []);
  return (
    <Root>
      {" "}
      <FilterWrapper>
        <FilterElementsWrapper>
          <FilterElements
            isActive={interested === "beach"}
            onClick={() => setInterested("beach")}
          >
            <FilterIcon>
              <Image src={sandals_black} />
            </FilterIcon>
            <FilterName>Beach</FilterName>
          </FilterElements>
          <FilterElements
            isActive={interested === "wildlife"}
            onClick={() => setInterested("wildlife")}
          >
            <FilterIcon>
              <Image src={paw_black} />
            </FilterIcon>
            <FilterName>Wildlife</FilterName>
          </FilterElements>
          <FilterElements
            isActive={interested === "romantic"}
            onClick={() => setInterested("romantic")}
          >
            <FilterIcon>
              <Image src={hearts_black} />
            </FilterIcon>
            <FilterName>Romantic</FilterName>
          </FilterElements>
          <FilterElements
            isActive={interested === "hill"}
            onClick={() => setInterested("hill")}
          >
            <FilterIcon>
              <Image src={hill_black} />
            </FilterIcon>
            <FilterName>Hill</FilterName>
          </FilterElements>
          <FilterElements
            isActive={interested === "heritage"}
            onClick={() => setInterested("heritage")}
          >
            <FilterIcon>
              <Image src={heritage_black} />
            </FilterIcon>
            <FilterName>Heritage</FilterName>
          </FilterElements>
        </FilterElementsWrapper>
        {/* <FilterElements1 ref={stayTypeRef}>
          <FilterStayType>
            <FilterAllOptions onClick={() => setShowStayType(!showStayType)}>
              <FilterName>{stayTypeText}</FilterName>
              <i className="fa fa-light fa-angle-down"></i>
            </FilterAllOptions>

            {showStayType && (
              <FilterStayTypeMenu>
                <FilterStayTypeOption
                  onClick={() => {
                    setStayType("economy");
                    setStayTypeText("Economy");
                    setShowStayType(false);
                  }}
                >
                  Economy
                </FilterStayTypeOption>
                <FilterStayTypeOption
                  onClick={() => {
                    setStayType("midrange");
                    setStayTypeText("Mid Range");
                    setShowStayType(false);
                  }}
                >
                  Mid Range
                </FilterStayTypeOption>
                <FilterStayTypeOption
                  onClick={() => {
                    setStayType("luxury");
                    setStayTypeText("Luxury");
                    setShowStayType(false);
                  }}
                >
                  Luxury
                </FilterStayTypeOption>
                <FilterStayTypeOption
                  onClick={() => {
                    setStayType("all");
                    setStayTypeText("All");
                    setShowStayType(false);
                  }}
                >
                  All
                </FilterStayTypeOption>
              </FilterStayTypeMenu>
            )}
          </FilterStayType>
        </FilterElements1> */}

        <FilterButtonsWrapper>
          <ClearAllButton onClick={handleClear}>Clear All</ClearAllButton>
        </FilterButtonsWrapper>
      </FilterWrapper>
    </Root>
  );
};

export default RangeForm;
