import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "react-bootstrap/Card";
import "./HotelWithLocation.css";
import Slider from "react-slick";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import LoginPopup from "../Pages/Login/LoginPopup";
import { environmentVariables } from "../../config/config";
import ContentLoader from "react-content-loader";
import Loader from "../Shortlistedloader/Loader";
import { useQuery } from "react-query";
import { useAuth } from "../../ContextApi/Contextapi";

const Root = styled.div`
  /* margin: 20px 0; */
  padding: 10px 0;
`;
const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100vw;
  }
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const TextWrapper = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 10px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    padding-left: 0;
    align-items: center;
    text-align: flex-start;
  }
`;
const Text = styled.text`
  font-size: 26px;
  font-weight: 500;
  padding: 10px 0;
  @media (max-width: 768px) {
    font-size: 20px;
    padding: 0;
  }
`;

const CardWrapper = styled.div`
  margin: 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  padding: 0 10px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const CityWrapper = styled.div`
  margin: 20px 0;
`;
const HeartWrapper = styled.div`
  width: 40px;
  border-radius: 3px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  /* left: 88%; */
  right: 15px;
  top: 15px;
  /* right: 1px; */
  background-color: #fff;
  cursor: pointer;
  :hover {
    color: red !important;
  }
`;

const Msgdata = styled.div`
  color: #d63b3b;
  font-size: 20px;
  text-align: center;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 15px;
  }

  ${(p) =>
    p.errormessage &&
    `
    margin-top: 50px;
`}
`;

const HotelsWithLocation = ({
  message,
  statefromapp,
  updateSelectCityPopup,
  setLoggedIn,
}) => {
  const baseUrl = environmentVariables.apiUrl;

  const getCity = localStorage.getItem("city");
  const getCityName = localStorage.getItem("cityname");
  const { authData } = useAuth();
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const navigation = useNavigate();
  const { state } = useLocation();
  const [isHeartLoading, setIsHeartLoading] = useState({});

  const GetMainData = async (state) => {
    let response;
    if (state) {
      response = await axios.post(`${baseUrl}auth/gethotelforhotelpage`, {
        state: state?.stateName,
        city: state?.cityName,
      });
    } else {
      response = await axios.post(`${baseUrl}auth/gethotelforhotelpage`, {
        state: getCity,
        city: getCityName,
      });
    }

    return response.data.message;
  };

  const {
    data: data1,
    error,
    isLoading,
  } = useQuery(["hotels", state], () => GetMainData(state));

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // afterChange: (currentSlide) => {
    //   const newPage = Math.floor(currentSlide / settings.slidesToShow) + 8;
    //   setCurrentPage(newPage);
    // },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setData(data);
  }, [state]);

  const onClickLike = async (item, key, index) => {
    const compoundKey = `${index}-${item._id}-${key}`;
    if (authData === undefined) {
      setShowPopup(true);
    } else {
      setIsHeartLoading((prevState) => ({ ...prevState, [compoundKey]: true }));
      await axios({
        url: `${baseUrl}auth/getshortlisted/${item._id}`,
        method: "post",
        headers: {
          _token: authData?.token,
        },
      })
        .then((response) => {
          getUser(compoundKey);
        })
        .catch((error) => {
          // console.log("error", error);
        });
    }
  };

  const getUser = async (key) => {
    if (authData !== undefined) {
      try {
        const data = await axios.get(`${baseUrl}auth/getuserdata`, {
          headers: {
            _token: authData?.token,
          },
        });
        setUserData(data.data.data.shortlistedArray);
        setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
      } catch (error) {
        setIsHeartLoading((prevState) => ({ ...prevState, [key]: false }));
        return error?.message;
      }
    }
  };

  useEffect(() => {
    // getThemesByName();
    getUser();
  }, [authData, getCity, state, updateSelectCityPopup]);
  useEffect(() => {
    if (!authData) {
      setUserData(null);
    }
  }, [authData]);
  if (isLoading)
    return (
      <ContentLoader
        width={2500}
        height={575}
        viewBox="0 0 1100 575"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ width: "100%" }}
      >
        <rect x="0" y="58" rx="2" ry="2" width="250" height="300" />
        <rect x="280" y="57" rx="2" ry="3" width="250" height="300" />
        <rect x="560" y="56" rx="2" ry="2" width="250" height="300" />
        <rect x="840" y="56" rx="2" ry="2" width="250" height="300" />
        <rect x="0" y="615" rx="2" ry="2" width="250" height="300" />
        <rect x="280" y="615" rx="2" ry="2" width="250" height="300" />
        <rect x="560" y="615" rx="2" ry="2" width="250" height="300" />
        <rect x="840" y="615" rx="2" ry="2" width="250" height="300" />
      </ContentLoader>
    );

  if (error) {
    return <Msgdata errormessage>Data Not Found</Msgdata>;
  }

  return (
    <Root>
      <Container>
        <div className="row mt-4">
          {(data1 && data1.length === 0) || data1 === undefined ? (
            <Msgdata>Data Unavailable: Keep Exploring!</Msgdata>
          ) : (
            data1 &&
            data1.length &&
            data1.map((value, index) => {
              return (
                <CityWrapper key={index}>
                  <TextWrapper>
                    <Text>
                      {value?.heading} {getCityName}
                    </Text>
                  </TextWrapper>
                  {/* {item.allData.length} */}
                  {value?.allData?.length <= 4 &&
                  value?.allData?.length >= 1 ? (
                    <CardWrapper>
                      {value?.allData.map((item, key) => {
                        return (
                          <Card id="hotel-cards" key={key}>
                            <Card.Img
                              style={{
                                maxHeight: "217px",
                                cursor: "pointer",
                                minHeight: "160px",
                                cursor: "pointer",
                              }}
                              variant="top"
                              src={
                                item.image[0] && item?.image[0] != "medianew"
                                  ? `https://bastionex-travels.b-cdn.net/uploads/${item.image[0]}`
                                  : `https://bastionex-travels.b-cdn.net/uploads/photo_2024-09-06_21-43-47.jpg`
                              }
                              onClick={() =>
                                navigation(`/hoteldetails/${item?._id}`)
                              }
                            />
                            <Tooltip
                              title="Shortlist"
                              styled={{ fontSize: "50px" }}
                            >
                              <HeartWrapper
                                onClick={() => onClickLike(item, key, index)}
                              >
                                {isHeartLoading[
                                  `${index}-${item._id}-${key}`
                                ] ? (
                                  <Loader />
                                ) : (
                                  <i
                                    style={{
                                      fontSize: "20px",
                                      color:
                                        userData && userData.includes(item._id)
                                          ? "red"
                                          : "#747474",
                                    }}
                                    className="fa fa-thin fa-heart style"
                                  ></i>
                                )}
                              </HeartWrapper>
                            </Tooltip>
                            <Card.Body
                              style={{
                                maxHeight: "100px",
                                marginBottom: "20px",
                                minHeight: "100px",
                              }}
                            >
                              <Card.Title
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                {item.hotelname}
                              </Card.Title>
                              <Card.Title
                                style={{
                                  fontWeight: "300",
                                  fontSize: "14px",
                                }}
                              >
                                {item.city} , {item.state}
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        );
                      })}
                    </CardWrapper>
                  ) : (
                    <Slider {...settings}>
                      {value?.allData?.map((item, key) => {
                        return (
                          <Card key={key}>
                            <div
                              style={{ height: "200px", overflow: "hidden" }}
                            >
                              <Card.Img
                                style={{
                                  width: "100%",
                                  cursor: "pointer",
                                }}
                                variant="top"
                                src={
                                  item.image[0] && item?.image[0] != "medianew"
                                    ? `https://bastionex-travels.b-cdn.net/uploads/${item.image[0]}`
                                    : `https://bastionex-travels.b-cdn.net/uploads/photo_2024-09-06_21-43-47.jpg`
                                }
                                onClick={() =>
                                  navigation(`/hoteldetails/${item?._id}`)
                                }
                              />
                            </div>
                            <Tooltip
                              title="Shortlist"
                              styled={{ fontSize: "50px" }}
                            >
                              <HeartWrapper
                                onClick={() => onClickLike(item, key, index)}
                              >
                                {isHeartLoading[
                                  `${index}-${item._id}-${key}`
                                ] ? (
                                  <Loader />
                                ) : (
                                  <i
                                    style={{
                                      fontSize: "20px",
                                      color:
                                        userData && userData.includes(item._id)
                                          ? "red"
                                          : "#747474",
                                    }}
                                    className="fa fa-thin fa-heart style"
                                  ></i>
                                )}
                              </HeartWrapper>
                            </Tooltip>
                            <Card.Body
                              style={{
                                maxHeight: "100px",
                                marginBottom: "20px",
                                minHeight: "100px",
                              }}
                            >
                              <Card.Title
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                {item.hotelname}
                              </Card.Title>
                              <Card.Title
                                style={{
                                  fontWeight: "300",
                                  fontSize: "14px",
                                }}
                              >
                                {item.city} , {item.state}
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        );
                      })}
                    </Slider>
                  )}
                </CityWrapper>
              );
            })
          )}
          {/* (data1 && data1.length === 0) || data1 === undefined ? (
            <Msgdata>Data Unavailable: Keep Exploring!</Msgdata>
          ) : (
           
          ) */}
        </div>
        {showPopup && (
          <LoginPopup
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            setLoggedIn={setLoggedIn}
          />
        )}
      </Container>
    </Root>
  );
};

export default HotelsWithLocation;
