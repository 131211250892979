import styled from "styled-components";

export const Root = styled.div`
  position: fixed;
  width: 600px;
  padding: 60px 30px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  @media (max-width: 700px) {
    width: 90%;
  }
  @media (max-width: 425px) {
    width: 95%;
    padding: 50px 20px;
  }
`;

export const OverLay = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 20px;
`;

export const Heading = styled.div`
  margin-bottom: 20px;
  font-size: 24px;
  color: #000;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
`;
export const Form = styled.form`
  width: 100%;
  & input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 2px;
    min-width: 20px;
    min-height: 20px;
  }
`;
export const Input = styled.input`
  width: 100%;
  padding: 12px 0px 12px 55px;
  border: 1px solid rgb(53 84 209 / 16%);
  background-color: #f5f5f5;
  border-radius: 6px;
  outline: none;
`;
export const Image = styled.img`
  position: absolute;
  top: 15px;
  left: 15px;
`;

export const MainInputWrapper = styled.div`
  position: relative;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  ${(p)=>p.CustomCaptaWrapper && `
  display: flex !important;
  flex-direction: column !important;
  margin-bottom: 5px;
  `}
`;
export const Label = styled.label`
  display: flex;
  width: 100%;
  font-weight: 400;
`;
export const Text = styled.div`
  font-size: 14px;
  color: #3d3d3d;
  justify-content: left;
`;

export const Button = styled.button`
  width: 100%;
  border: none;
  background-color: #3554d1;
  padding: 15px 0;
  border-radius: 5px;
  color: #fff;
  margin-top: 10px;
  outline: none;
`;

export const Button1 = styled.button`
  width: 40%;
  border: none;
  background-color: #0c6068;
  padding: 10px 0;
  border-radius: 5px;
  color: #fff;
  margin-top: 20px;
  outline: none;
`;

export const Link = styled.span`
  margin-left: 5px;
  cursor: pointer;
`;
export const FooterText = styled.div`
  font-size: 14px;
  text-align: center;
  display: flex;
  color: #000000db;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-top: 10px;
`;
export const SpanText = styled.span`
  cursor: pointer;
  color: rgb(53, 84, 209);
  font-weight: 600;
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
