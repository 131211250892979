import styled from "styled-components";
export const Root = styled.div`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 1600px) {
    width: 1200px;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px 20px 20px;
    background-color: #dadbe2;   
  }
  @media (max-width: 1024px) {
  // padding-top: 110px;
  padding: 50px 20px;
  }
  @media (max-width: 768px) {
    border-bottom: 1px solid #00000026;
    background-color: #f0f0f0;
    padding-top: 30px;
  }
`;
export const FilterPopUpContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  top: 0;
`;
export const FilterStayTypeMenu = styled.div`
  position: absolute;
  background-color: #efefef;
  padding: 0px;
  width: 125px;
  font-weight: 400;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px #0000002b;
  border: 1px solid #5266ab1c;
  z-index: 9;
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FilterStayTypeOption = styled.div`
  text-align: left;
  padding: 10px 0px;
  transition: 0.5s;
  padding: 9px 0px 9px 15px;
  :hover {
    background-color: #3554d1;
    font-weight: 500;
    color: #fff;
  }
`;
export const FilterPopUp = styled.div`
  width: 100px;
  height: 100px;
  background-color: #fff;
`;

export const FilterStayType = styled.div`
  position: relative;
  cursor: pointer;
  @media (max-width: 1200px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FilterTravelPreferences = styled.div`
  position: relative;
`;

export const FilterPopUpHeading = styled.div``;

export const FilterPopUpHorizontalLine = styled.hr``;

export const FilterPopUpClearButton = styled.div``;

export const FilterPopUpApplyButton = styled.div``;

export const FilterPopUpButtonWrapper = styled.div``;

export const FilterWrapper = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    align-items: center;
    padding-top: 20px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    align-items: center;
    padding-top: 20px;
  }
`;
export const DistanceRangeNum1 = styled.div`
  position: absolute;
  left: -7px;
  font-weight: 700;
`;
export const DistanceRangeNum2 = styled.div`
  position: absolute;
  font-weight: 700;
  right: -15px;
`;
export const FilterElements = styled.div`
  display: flex;
  background-color: ${(props) => (props.isActive ? "#dcdddd" : "#fff")};
  align-items: center;
  margin-right: 15px;
  border-radius: 5px;
  padding: 5px 15px;
  cursor: pointer;
  :hover {
    background-color: #dcdddd;
  }

  @media (max-width: 768px) {
    padding: 5px 10px;
    margin-right: 0px;
    flex-direction: column;
    background-color: ${(props) => (props.isActive ? "#fff" : "#f0f0f0")};
    :hover {
      background-color: #fff;
    }
  }
`;
export const FilterElementsWrapper = styled.div`
  display: flex;
`;
export const FilterButtonsWrapper = styled.div`
  display: flex;
  @media (max-width: 1200px) {
    padding-top: 0px;
    width: 80%;
  }
  @media (max-width: 768px) {
    padding-top: 0px;
    width: 100%;
  }
`;
export const FilterElements1 = styled.div`
  display: flex;

  align-items: center;
  margin-right: 30px;
  width: 120px;
  @media (max-width: 1200px) {
    margin-right: 0px;
    width: 100%;
    padding: 10px 0px 0;
    justify-content: center;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    margin-right: 0px;
    width: 100%;
    padding: 10px 0px 0;
    justify-content: center;
    margin-bottom: 20px;
  }
`;

export const FilterIcon = styled.div`
  margin-right: 10px;
  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;

export const FilterName = styled.div`
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const FilterButton = styled.div``;

export const FilterButtonWrapper = styled.div`
  border: 1px solid #3d3d3d;
  cursor: pointer;
  padding: 15px 10px;
  width: 100px;
  border-radius: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FilterButtonIcon = styled.div``;

export const FilterCloseIcon = styled.div``;

export const FilterStayTypeHeading = styled.div``;

export const FilterTravelPreferencesHeading = styled.div`
  position: absolute;
  top: 16px;
  left: -8px;
  
  @media (max-width: 768px) {
    width: 100%;
    left: 0px;
    top: 8px;
  }
`;

export const ClearAllButton = styled.div`
  border: 1px solid black;
  cursor: pointer;
  padding: 7px 0px;
  width: 110px;
  text-align: center;
  border-radius: 8px;
  margin-left: 50px;
  margin-right: 20px;
  font-size: 18px;
  font-weight: 500;
  :hover {
    background-color: #051036;
    color: #fff;
  }
  @media (max-width: 1200px) {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 20px;
    width: 100%;
    padding: 12px 0px;
    border-radius: 5px;
    background-color: #dadbe2;

  }
 
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    padding: 12px 0px;
    border-radius: 5px;
    background-color: #dadbe2;
  }
`;

export const ApplyButton = styled.div`
  cursor: ${(props) => (props.isApplyActive ? "pointer" : "default")};
  padding: 7px 0;
  width: 110px;
  text-align: center;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid #051036;
  background-color: ${(props) => (props.isApplyActive ? "#3554d1" : "#444f78")};
  color: #fff;
  :hover {
    background-color: #051036;
    color: #fff;
  }
`;

export const SliderWrapper = styled.div`
  width: 150px;
  @media (max-width: 1200px) {
    width: 75vw;
  }

  @media (max-width: 768px) {
    width: 80vw;
  }
`;

export const DistanceRange = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SliderLabels = styled.div``;

export const FilterAllOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1200px) {
    border-radius: 3px;
    color: #fff;
    background-color: #3452cc;
    width: 100%;
    padding: 15px;
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    border-radius: 3px;
    color: #fff;
    background-color: #3452cc;
    width: 100%;
    padding: 15px;
    margin-top: 10px;
  }
`;
